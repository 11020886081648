import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';

export type CadastrarVendaLeitaoRequest = {
  data: string;
  qntd: number;
  pesoMedio: number;
  aprovFinanceiro: number;
  observacao: string;
};

export type CadastrarVendaLeitaoResponse = {
  id: string;
};

const cadastrarVendaLeitao = async (
  loteId: string,
  body: CadastrarVendaLeitaoRequest
): Promise<CadastrarVendaLeitaoResponse> => {
  const response = await api.post<CadastrarVendaLeitaoResponse>(
    `lote/${loteId}/venda`,
    body
  );
  return response.data;
};

export type InputParams = {
  onSuccess?: (data: CadastrarVendaLeitaoResponse) => void;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  mutate: UseMutateFunction<
    CadastrarVendaLeitaoResponse,
    unknown,
    { loteId: string; body: CadastrarVendaLeitaoRequest },
    unknown
  >;
  isLoading: boolean;
};

export const useCadastrarVendaLeitao = ({
  onSuccess,
  onError
}: InputParams) => {
  return useMutation(
    (body: { loteId: string; body: CadastrarVendaLeitaoRequest }) =>
      cadastrarVendaLeitao(body.loteId, body.body),
    {
      onSuccess,
      onError
    }
  );
};
