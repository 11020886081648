import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ArrowUpOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  ConfigProvider,
  Empty,
  List,
  Card as AntdCard,
  BackTop,
  Button,
  Form,
  Row,
  Col,
  Input,
  Select
} from 'antd';

import {
  listarLotes,
  ListarLoteRequest,
  ListarLoteResponse
} from 'api/listarLotes/useListarLotes';

import { IPaginacao } from 'models';
import { useDidMount } from 'hooks';
import { getColorByType, mapperStatusLote } from 'utils/utils';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import Card from 'components/Card';
import SelectFaseLote from 'components/SelectFaseLote';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';

const LoteLista: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isMounted = useDidMount();
  const [isLoading, setIsLoading] = useState(false);
  const [lotes, setLotes] = useState<ListarLoteResponse[]>([]);
  const [filtro, setFiltro] = useState({
    faseIn: ['MATERNIDADE', 'CRECHE', 'CRESCIMENTO', 'TERMINACAO']
  } as ListarLoteRequest);
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    page: 0,
    perPage: 50,
    totalItems: 0,
    totalPages: 0
  });

  const buscarLotes = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const data = await listarLotes({
          tipoIn: filtro.tipoIn,
          faseIn: filtro.faseIn,
          numeroIn: filtro.numeroIn,
          page: paginacao.page,
          perPage: paginacao.perPage
        });
        setPaginacao((prev) => ({
          ...prev,
          totalItems: data.total
        }));
        setLotes((prev) => [...prev, ...data.items]);
      } catch (error) {
        setLotes([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isMounted) {
      setLotes([]);
      if (paginacao.page !== 0) {
        setPaginacao((prev) => ({ ...prev, page: 0 }));
      } else {
        buscarLotes();
      }
    }
  }, [paginacao.page, filtro]);

  useEffect(() => {
    buscarLotes();
  }, [paginacao.page]);

  useEffect(() => {
    dispatch(updateID('lotes-lotes'));
    dispatch(updateTitle('Lista de Lotes'));
  }, [lotes]);

  return (
    <>
      <AntdCard key="filtro" title="Filtros" style={{ marginBottom: '16px' }}>
        <Form
          layout="vertical"
          form={form}
          colon={false}
          initialValues={{
            fase: filtro.faseIn
          }}
          onFinish={(values) => {
            console.log(values);

            setFiltro({
              tipoIn: values?.tipo ? [values?.tipo] : undefined,
              faseIn: values?.fase ? values.fase : undefined,
              numeroIn: values?.numero ? [values.numero] : undefined,
              page: paginacao.page,
              perPage: paginacao.perPage
            });
          }}
          wrapperCol={{ style: { width: '100%' } }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Número"
                name="numero"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Input allowClear />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Tipo do Lote"
                name="tipo"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Select placeholder="Selecionar" allowClear>
                  <Select.Option key="LOTE_EXTERNO" value="LOTE_EXTERNO">
                    Lote Externo
                  </Select.Option>
                  <Select.Option key="LOTE_INTERNO" value="LOTE_INTERNO">
                    Lote Interno
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Status"
                name="fase"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <SelectFaseLote
                  allowClear
                  mode="multiple"
                  placeholder="Selecionar"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="end">
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Button
                block
                type="primary"
                htmlType="button"
                loading={isLoading}
                icon={<SearchOutlined />}
                onClick={() => {
                  form.submit();
                }}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </AntdCard>

      <BackTop
        target={() =>
          document.getElementById('scrollable-content-div') || window
        }
      >
        <Button
          shape="round"
          type="primary"
          htmlType="button"
          icon={<ArrowUpOutlined />}
        >
          Voltar
        </Button>
      </BackTop>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={false}
            description={
              isLoading ? <LoadingOutlined /> : 'Nenhum lote encontrado!'
            }
          />
        )}
      >
        <InfiniteScrollWrapper
          next={() => {
            setPaginacao((prev) => ({
              ...prev,
              page: prev.page + 1
            }));
          }}
          dataLength={lotes.length}
          hasMore={lotes.length < paginacao.totalItems}
          scrollableTarget="scrollable-content-div"
          style={{ overflow: 'hidden' }}
        >
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 3,
              md: 4,
              lg: 5,
              xl: 5,
              xxl: 6
            }}
            dataSource={lotes}
            renderItem={(lote) => (
              <List.Item key={`${lote.id}-item`}>
                <Card
                  key={lote.id}
                  style={{
                    backgroundColor: getColorByType(lote.fase)
                  }}
                  onClick={() => {
                    history.push(`/lote/ficha/${lote.id}`);
                  }}
                >
                  {`${lote.numero} - ${
                    lote.tipo === 'LOTE_EXTERNO'
                      ? 'Lote Externo'
                      : 'Lote Interno'
                  } (${mapperStatusLote(lote.fase)})`}
                </Card>
              </List.Item>
            )}
          />
        </InfiniteScrollWrapper>
      </ConfigProvider>
    </>
  );
};

export default LoteLista;
