import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type ListarDescartesPorMotivoRequest = PageRequest & {
  periodo?: RangeRequest<string>;
};

export type ListarDescartesPorMotivoResponse = {
  motivo: string;
  motivoDescricao: string;
  qntd: number;
  porcentagem: number;
};

export const listarDescartesLeitaoPorMotivo = async (
  params: ListarDescartesPorMotivoRequest
): Promise<PageResponse<ListarDescartesPorMotivoResponse>> => {
  const response = await api.get<
    PageResponse<ListarDescartesPorMotivoResponse>
  >('relatorio/lote/descartes-por-motivo', {
    params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
  });
  return response.data;
};

export type InputParams = {
  params: ListarDescartesPorMotivoRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarDescartesPorMotivoResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<PageResponse<ListarDescartesPorMotivoResponse>, unknown>
  >;
};

export const useListarDescartesLeitaoPorMotivo = (
  input: InputParams
): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-descartes-por-motivo', input.params],
    () => listarDescartesLeitaoPorMotivo(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading, refetch };
};
