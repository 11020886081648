import { useEffect, useState } from 'react';
import { Select, SelectProps, notification } from 'antd';

import { ListarRacaResponse } from 'api/racas';
import { getRacas } from 'services/racaService';
import { CustomSelect } from 'components/shared/CustomSelect';

type Props = SelectProps & {
  onlyActive?: boolean;
};

const SelectRacas: React.FC<Props> = (props) => {
  const [racas, setRacas] = useState<ListarRacaResponse[]>([]);

  const buscarRacas = async () => {
    try {
      const data = await getRacas();
      setRacas(props.onlyActive ? data.filter((r) => r.ativa) : data);
    } catch (error) {
      notification.error({
        message: 'Erro ao carregar as raças disponíveis'
      });
    }
  };

  useEffect(() => {
    buscarRacas();
  }, []);

  return (
    <CustomSelect
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .startsWith(input.toLowerCase())
      }
    >
      {racas.map((raca) => (
        <Select.Option key={raca.id} value={raca.id}>
          {raca.nome}
        </Select.Option>
      ))}
    </CustomSelect>
  );
};
export default SelectRacas;
