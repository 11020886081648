import { useQuery } from 'react-query';

import api from 'services/api';

type Params = {
  onError?: (error: unknown) => void;
  onSuccess?: (data: Blob) => void;
};

export const useGetAlvosProducaoPDF = (params: Params) => {
  return useQuery({
    queryKey: ['get-alvos-producao-pdf'],
    queryFn: async () => {
      const response = await api.get<Blob>('/relatorio/alvos-producao/pdf', {
        responseType: 'blob'
      });

      return response.data;
    },
    retry: 0,
    staleTime: 0,
    cacheTime: 0,
    enabled: false,
    initialData: undefined,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: params.onError,
    onSuccess: params.onSuccess
  });
};
