export type ScoreCorporalOption = 'UM' | 'DOIS' | 'TRES' | 'QUATRO' | 'CINCO';

export const scoreCorporalOptions = [
  { key: 'UM', title: '1 - MUITO MAGRO(A)' },
  { key: 'DOIS', title: '2 - MAGRO(A)' },
  { key: 'TRES', title: '3 - NORMAL (IDEAL)' },
  { key: 'QUATRO', title: '4 - GORDO(A)' },
  { key: 'CINCO', title: '5 - MUITO GORDO(A)' }
];

export interface AvaliacaoCorporalForm {
  peso?: number;
  data: string;
  score: ScoreCorporalOption;
  observacao?: string;
}
