import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Row,
  Table,
  notification
} from 'antd';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';

import CustomRangePicker from 'components/CustomRangePicker';
import {
  firstAndLastDayOfMonth as currentMonth,
  formatToISO
} from 'utils/utils';
import { PageRequest } from 'api/models';
import {
  ListarDescartesPorFaseResponse,
  useListarDescartesLeitaoPorFase
} from 'api/relatorios/lotes/listarDescartesLeitaoPorFase/useListarDescartesLeitaoPorFase';
import { usePDFDescartesLeitaoPorFase } from 'api/relatorios/lotes/getPDFDescartesLeitaoPorFase/useGetPDFDescartesLeitaoPorFase';

type SearchParams = PageRequest & {
  periodo?: [Date, Date];
};

const RelatorioMortesLotePorFase: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 50,
    periodo: [currentMonth().firstDay, currentMonth().lastDay]
  });
  const { data, isLoading } = useListarDescartesLeitaoPorFase({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFDescartesLeitaoPorFase({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    },
    onSuccess: (data: Blob) => {
      JsFileDownload(data, `mortes-por-setor.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        layout="vertical"
        colon={false}
        form={form}
        onFinish={(values) => {
          setSearchParams((prev) => ({
            page: 0,
            perPage: prev.perPage,
            periodo: values.periodo
          }));
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Período"
              name="periodo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject();
                    if (!value[0] || !value[1]) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <CustomRangePicker
                initialValue={searchParams.periodo}
                callback={(startDate, endDate) => {
                  form.setFieldsValue({
                    periodo: [startDate, endDate]
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma morte no período!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          style={{ marginTop: '32px' }}
          loading={{
            spinning: isLoading || isLoadingPDF,
            indicator: <LoadingOutlined spin />
          }}
          pagination={false}
          dataSource={data?.items || []}
          columns={[
            {
              title: 'Setor',
              dataIndex: 'faseDescricao'
            },
            {
              title: 'Mortes/Causa',
              dataIndex: 'qntd'
            },
            {
              title: '%',
              render: (record: ListarDescartesPorFaseResponse) =>
                (record.porcentagem * 100).toFixed(2) + '%'
            }
          ]}
          summary={(data) => {
            const total = data.reduce((acc, elem) => acc + elem.qntd, 0);

            return (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span style={{ fontWeight: 'bold' }}>Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <span style={{ fontWeight: 'bold' }}>{total}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <span style={{ fontWeight: 'bold' }}>100%</span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default RelatorioMortesLotePorFase;
