import { takeLatest, all } from 'redux-saga/effects';

import history from '../../../services/history';
import { authActionsType } from './actionsType';

export function signInSuccess() {
  history.push('/como-usar');
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest(authActionsType.SIGN_OUT, signOut),
  takeLatest(authActionsType.SIGN_IN_SUCCESS, signInSuccess)
]);
