export function updateTitle(title) {
  return {
    type: '@current_page/UPDATE_TITLE',
    payload: { title }
  };
}

export function updateID(id) {
  return {
    type: '@current_page/UPDATE_ID',
    payload: { id }
  };
}
