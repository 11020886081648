import QueryString from 'qs';
import { useQuery } from 'react-query';

import api from 'services/api';
import { PageRequest, PageResponse } from 'api/models';

export type ListarMatrizCicloRequest = PageRequest & {
  codigo?: string;
  partosMin?: number;
  partosMax?: number;
  statusNotIn?: string[];
};

export type ListarMatrizCicloResponse = {
  id: string;
  codigo: string;
  idade: number;
  partos: number;
  coberturas: number;
  nascidosVivos: number;
  nascidosNatimortos: number;
  nascidosMumificados: number;
  desmamados: number;
  pesoMedioNascimento: number;
  pesoMedioDesmame: number;
};

export const listarMatrizCiclo = async (
  params: ListarMatrizCicloRequest
): Promise<PageResponse<ListarMatrizCicloResponse>> => {
  const response = await api.get<PageResponse<ListarMatrizCicloResponse>>(
    'relatorio/matrizes/ciclo',
    {
      params,
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarMatrizCicloRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarMatrizCicloResponse>;
  isLoading: boolean;
};

export const useListarMatrizCiclo = (input: InputParams): OutputParams => {
  const { data, isLoading } = useQuery(
    ['listar-matriz-ciclo', input.params],
    () => listarMatrizCiclo(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading };
};
