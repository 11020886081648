import { EditarRacaRequest } from './types';
import { BaseHttpParams, useHttp } from 'api/useHttp';

type InputParams = BaseHttpParams<void> & {
  id: string;
};

export const useUpdateRaca = (params: InputParams) => {
  return useHttp<EditarRacaRequest, void, void>({
    url: `raca/${params.id}`,
    method: 'PUT',
    onSuccess: params.onSuccess,
    onError: params.onError
  });
};
