import { useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row } from 'antd';

import api from 'services/api';
import history from 'services/history';

import FormWrapper from 'components/FormWrapper';

const EsqueciMinhaSenha: React.FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const mostrarInformacoes = () => {
    Modal.info({
      title: 'Solicitação recebida',
      content:
        'Se o e-mail informado existir em nossa base de dados, você receberá um link para resetar a senha dentro de alguns minutos!',
      okText: 'Voltar para login',
      onOk: () => {
        history.replace('/login');
      }
    });
  };

  const enviarEmail = async (email: string) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await api.post(
          '/produtor/esqueci-minha-senha',
          {},
          { params: { email: email } }
        );
        mostrarInformacoes();
      } catch (error) {
        notification.error({
          message:
            'Ocorreu um erro ao enviar o e-mail, por favor tente novamente'
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Row style={{ height: '100vh', paddingTop: '80px' }} justify="center">
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          enviarEmail(values.email);
        }}
      >
        <div
          style={{
            marginBottom: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <h2 style={{ color: '#4cab5b' }}>MeuSuino</h2>
        </div>

        <Col span={24}>
          <FormWrapper
            label="E-mail"
            name="email"
            rules={[
              { type: 'email', message: 'E-mail inválido' },
              { required: true, message: 'Campo obrigatório' }
            ]}
          >
            <Input />
          </FormWrapper>
        </Col>

        <Form.Item style={{ marginBottom: '8px', marginTop: '48px' }}>
          <Button
            block
            type="primary"
            htmlType="button"
            loading={isLoading}
            style={{
              backgroundColor: '#4cab5b',
              borderWidth: 0
            }}
            onClick={() => {
              form.submit();
            }}
          >
            Recuperar senha
          </Button>
        </Form.Item>

        <Form.Item style={{ marginBottom: '8px' }}>
          <Button
            block
            type="primary"
            htmlType="button"
            onClick={() => {
              history.replace('/login');
            }}
          >
            Voltar para login
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default EsqueciMinhaSenha;
