import { useState } from 'react';
import { ConfigProvider, Empty, Modal, Table } from 'antd';

import { RacaDTO, fasesLote } from 'models';
import {
  ListarLoteResponse,
  useListarLotes
} from 'api/listarLotes/useListarLotes';
import { PageRequest } from 'api/models';

interface Props {
  loteIdToExclude?: string;
  open: boolean;
  onOK: (lote: ListarLoteResponse) => void;
  onCancel: () => void;
}

const ModalLotes: React.FC<Props> = ({
  open = false,
  onOK,
  onCancel,
  loteIdToExclude
}) => {
  const [loteSelecionado, setLoteSelecionado] = useState(
    {} as ListarLoteResponse
  );
  const [pageRequest, setPageRequest] = useState<PageRequest>({
    page: 0,
    perPage: 5
  });
  const { data: pageData } = useListarLotes({
    params: { ...pageRequest, idNotIn: [loteIdToExclude] }
  });
  const columns = [
    {
      title: 'Número',
      dataIndex: 'numero'
    },
    {
      title: 'Idade (dias)',
      dataIndex: 'idade'
    },
    {
      title: 'Fase',
      dataIndex: 'fase',
      render: (value: string) =>
        fasesLote.find((status) => status.key === value)?.value || value
    },
    {
      title: 'Raça',
      dataIndex: 'raca',
      render: (value: RacaDTO) => value.nome
    },
    {
      title: 'Saldo',
      dataIndex: 'saldo'
    }
  ];

  return (
    <Modal
      title="Lotes"
      width={'70%'}
      open={open}
      okText="Selecionar"
      onOk={() => {
        onOK(loteSelecionado);
        setLoteSelecionado({} as ListarLoteResponse);
      }}
      okButtonProps={{
        disabled: !loteSelecionado.id
      }}
      cancelText="Cancelar"
      onCancel={() => {
        onCancel();
      }}
    >
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum lote encontrado!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={pageData?.items || []}
          onRow={(item) => ({
            onClick: () => {
              setLoteSelecionado(item);
            }
          })}
          rowKey={'id'}
          rowSelection={{
            type: 'radio',
            onSelect: (record) => {
              setLoteSelecionado(record);
            },
            selectedRowKeys: loteSelecionado ? [loteSelecionado.id] : []
          }}
          pagination={{
            size: 'small',
            total: pageData?.total || 0,
            pageSize: pageRequest.perPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: false,
            onChange: (page) => {
              setPageRequest((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
        />
      </ConfigProvider>
    </Modal>
  );
};

export default ModalLotes;
