import { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  notification
} from 'antd';
import { ClearOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';

import ModalMatrizesLactantes from './ModalMatrizesLactantes';
import PigDatePicker from 'components/PigDatePicker';
import SelectScoreCorporal from 'components/SelectScoreCorporal';
import { FormItem, FormItemNoStyle, defaultFormProps } from 'components/Antd';
import { useCadastrarAdocaoLote } from 'api/cadastrarAdocaoLote/useCadastrarAdocaoLote';
import { formatToISO } from 'utils/utils';
import { convertFormattedToNumber } from 'utils/masks';

type AdocaoForm = {
  data: Date;
  maeAdotiva: {
    id: string;
    codigo: string;
  };
  maeBiologica: {
    peso: string;
    scoreCorporal: string;
  };
};

type Props = {
  loteId: string;
  exigirMaeBiologica: boolean;
};

export const Adocao: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<AdocaoForm>();
  const { mutate: cadastrarAdocao, isLoading } = useCadastrarAdocaoLote({
    onSuccess: () => {
      form.resetFields();
      notification.success({ message: 'Adoção registrada com sucesso' });
    },
    onError: () => {
      notification.error({
        message: 'Ocorreu um erro ao registrar a adoção'
      });
    }
  });

  return (
    <>
      <ModalMatrizesLactantes
        open={open}
        onSelect={(matriz) => {
          setOpen(false);
          form.setFieldsValue({
            maeAdotiva: {
              id: matriz.id,
              codigo: matriz.codigo
            }
          });
        }}
        onCancel={() => {
          setOpen(false);
        }}
      />

      <Form
        form={form}
        {...defaultFormProps}
        onFinish={(values) => {
          cadastrarAdocao({
            loteId: props.loteId,
            body: {
              data: formatToISO(values.data),
              maeAdotivaId: values.maeAdotiva.id,
              maeBiologica: {
                peso: convertFormattedToNumber(values.maeBiologica.peso),
                scoreCorporal: values.maeBiologica.scoreCorporal
              }
            }
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <FormItemNoStyle name={['maeAdotiva', 'id']} hidden>
              <Input type="hidden" />
            </FormItemNoStyle>

            <Form.Item
              label="Mãe Adotiva"
              name={['maeAdotiva', 'codigo']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.Search
                disabled
                placeholder="Buscar matriz"
                enterButton={
                  <Button type="primary" icon={<SearchOutlined />} />
                }
                onSearch={() => {
                  setOpen(true);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <FormItem
              name="data"
              label="Data"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <PigDatePicker
                callback={(_, date) => {
                  form.setFieldsValue({
                    data: date
                  });
                }}
              />
            </FormItem>
          </Col>

          {props.exigirMaeBiologica && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
              <FormItem
                label="Mãe Biológica - Peso"
                name={['maeBiologica', 'peso']}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input allowClear />
              </FormItem>
            </Col>
          )}

          {props.exigirMaeBiologica && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
              <FormItem
                label="Mãe Biológica - Score"
                name={['maeBiologica', 'scoreCorporal']}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <SelectScoreCorporal allowClear />
              </FormItem>
            </Col>
          )}
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              loading={isLoading}
              icon={<SaveOutlined />}
              onClick={() => {
                form.submit();
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>

      <Space direction="vertical" style={{ width: '100%', marginTop: '48px' }}>
        <Alert
          type="warning"
          message="Observações"
          description={
            <div>
              <span>
                1. Caso a matriz desejada para mãe adotiva não apareça,
                certifique-se de que ela foi desmamada
              </span>
              <br />
              <span>
                2. Caso a mãe biológica tenha falecido, primeiro execute a
                doação e em seguida o descarte da mesma
              </span>
              <br />
              <span>
                3. A mãe biológica será desmamada e constará nos registros o
                motivo por doação
              </span>
              <br />
              <span>
                4. Os dados referentes a avaliação corporal da mãe biológica,
                somente são solicitados para lotes do tipo Lote Interno
              </span>
            </div>
          }
        />
      </Space>
    </>
  );
};
