import QueryString from 'qs';
import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { UseQueryPDFInput } from 'api/relatorios/types';
import { TodasMatrizesRequest } from '../listarTodasMatrizes/useListarTodasMatrizes';

export const getPDFTodasMatrizes = async (
  params: TodasMatrizesRequest
): Promise<Blob> => {
  const { data } = await api.get<Blob>('relatorio/matrizes/pdf', {
    responseType: 'blob',
    params,
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    }
  });
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFTodasMatrizes = (
  input: UseQueryPDFInput<TodasMatrizesRequest>
): OutputParams => {
  return useQuery(
    ['get-pdf-todas-matrizes', input.params],
    () => getPDFTodasMatrizes(input.params),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
