import api from 'services/api';
import { UseMutateFunction, UseMutationResult, useMutation } from 'react-query';
import { InputParams } from 'api/models';

export type EditarFaseRequest = {
  fim: string;
  pesoFinal: number;
};

export type EditarLoteRequest = {
  racaId: string;
  codigoMae: string;
  maeRacaId: string;
  codigoPai: string;
  paiRacaId: string;
  maternidade: EditarFaseRequest;
  creche: EditarFaseRequest;
  crescimento: EditarFaseRequest;
  terminacao: EditarFaseRequest;
};

const editarLote = async (
  loteId: string,
  body: EditarLoteRequest
): Promise<void> => {
  const response = await api.put<void>(`lote/${loteId}`, body);
  return response.data;
};

type Params = { loteId: string; body: EditarLoteRequest };

export const useEditarLote = ({
  onSuccess,
  onError
}: InputParams<void>): UseMutationResult<void, unknown, Params, unknown> => {
  return useMutation(
    (params: Params) => editarLote(params.loteId, params.body),
    {
      onSuccess,
      onError
    }
  );
};
