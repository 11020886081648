import { useMutation } from 'react-query';

import api from 'services/api';
import { InputParams } from 'api/models';

const excluirGestacao = async (matrizId: string): Promise<void> => {
  await api.delete<void>(`matriz/${matrizId}/gestacao`);
};

export const useExcluirGestacao = ({
  onSuccess,
  onError
}: InputParams<void>) => {
  return useMutation(
    ['EXCLUIR_GESTACAO'],
    (matrizId: string) => excluirGestacao(matrizId),
    {
      onSuccess,
      onError
    }
  );
};
