import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { applyHttpRangeFormat } from 'api/useHttp';
import { UseQueryPDFInput } from 'api/relatorios/types';
import { ListarLeitoaRequest } from '../listarLeitoas/useListarLeitoas';

export const getPDFLeitoas = async (
  params: ListarLeitoaRequest
): Promise<Blob> => {
  const { data } = await api.get<Blob>('relatorio/matrizes/leitoas/pdf', {
    responseType: 'blob',
    params: {
      ...params,
      cioPrevisto: applyHttpRangeFormat(params.cioPrevisto),
      partoPrevisto: applyHttpRangeFormat(params.partoPrevisto)
    }
  });
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFLeitoas = (
  input: UseQueryPDFInput<ListarLeitoaRequest>
): OutputParams => {
  return useQuery(
    ['get-pdf-leitoas', input.params],
    () => getPDFLeitoas(input.params),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
