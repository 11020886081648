import { RangeRequest } from 'api/models';
import { BaseHttpParams, applyHttpRangeFormat, useHttp } from 'api/useHttp';

type InputParams = BaseHttpParams<Blob> & {
  queryParams: { periodo?: RangeRequest<string>; codigoIn?: string[] };
};

export function useGetMapaUsoPDF(params: InputParams) {
  const useHttpResp = useHttp<
    void,
    { periodo: string; codigoIn?: string[] },
    Blob
  >({
    method: 'GET',
    url: 'relatorio/reprodutores/mapa-uso/pdf',
    responseType: 'blob',
    onSuccess: params.onSuccess,
    onError: params.onError
  });

  const fetchData = (
    props: { periodo?: RangeRequest<string>; codigoIn?: string[] } = undefined
  ) => {
    useHttpResp.fetchData({
      queryParams: {
        periodo:
          applyHttpRangeFormat(props?.periodo) ||
          applyHttpRangeFormat(params?.queryParams?.periodo),
        codigoIn: props?.codigoIn || params?.queryParams?.codigoIn
      }
    });
  };

  return { ...useHttpResp, fetchData };
}
