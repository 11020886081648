import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

import {
  EstoqueGranjaResponse,
  useGetEstoqueGranja,
  useGetEstoqueGranjaPDF
} from 'api/estoque-granja';
import { CustomTable } from 'components';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { applyMaskDecimalWithComma } from 'utils/masks';

type DataSourceType = {
  categoria: string;
  qntd: number;
  percentual: string;
  children: {
    categoria: string;
    qntd: number;
    percentual: string;
  }[];
};

export const EstoqueGranja: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const { data, isLoading, refetch } = useGetEstoqueGranja();
  const {
    data: dataPDF,
    isLoading: isLoadingPDF,
    refetch: refetchPDF
  } = useGetEstoqueGranjaPDF();

  const toDataSourceType = (data: EstoqueGranjaResponse) => {
    const newDataSource: DataSourceType[] = [];

    const totalFemeas = data.femeas
      .map((e) => e.total)
      .reduce((prev, actual) => prev + actual, 0);
    const totalMachos = data.machos
      .map((e) => e.total)
      .reduce((prev, actual) => prev + actual, 0);
    const totalLeitoes = data.leitoes
      .map((e) => e.total)
      .reduce((prev, actual) => prev + actual, 0);
    const total = totalFemeas + totalMachos + totalLeitoes;

    newDataSource.push({
      categoria: 'Matrizes',
      qntd: totalFemeas,
      percentual: applyMaskDecimalWithComma((totalFemeas / total) * 100),
      children: data.femeas.map((e) => ({
        categoria: e.subCategoria,
        qntd: e.total,
        percentual: applyMaskDecimalWithComma((e.total / totalFemeas) * 100)
      }))
    });

    newDataSource.push({
      categoria: 'Reprodutores',
      qntd: totalMachos,
      percentual: applyMaskDecimalWithComma((totalMachos / total) * 100),
      children: data.machos.map((e) => ({
        categoria: e.subCategoria,
        qntd: e.total,
        percentual: applyMaskDecimalWithComma((e.total / totalMachos) * 100)
      }))
    });

    newDataSource.push({
      categoria: 'Leitões',
      qntd: totalLeitoes,
      percentual: applyMaskDecimalWithComma((totalLeitoes / total) * 100),
      children: data.leitoes.map((e) => ({
        categoria: e.subCategoria,
        qntd: e.total,
        percentual: applyMaskDecimalWithComma((e.total / totalLeitoes) * 100)
      }))
    });

    return newDataSource;
  };

  useEffect(() => {
    if (!data) return;
    setDataSource(toDataSourceType(data));
  }, [data]);

  useEffect(() => {
    if (dataPDF) useDownloadFile(dataPDF, 'estoque-granja.pdf');
  }, [dataPDF]);

  return (
    <>
      <Row gutter={[16, 16]} justify="end">
        <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
          <Button
            block
            danger
            type="primary"
            htmlType="button"
            icon={<FilePdfOutlined />}
            loading={isLoadingPDF}
            onClick={() => {
              refetchPDF();
            }}
          >
            Relatório
          </Button>
        </Col>
      </Row>

      <CustomTable
        style={{ marginTop: '32px' }}
        isLoading={isLoading}
        hasPagination={false}
        data={dataSource || []}
        columns={[
          {
            title: 'Categoria',
            dataIndex: 'categoria'
          },
          { title: 'Qntd', dataIndex: 'qntd' },
          { title: '%', dataIndex: 'percentual' }
        ]}
        summary={(rows) => {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {dataSource.reduce((acc, i) => acc + i.qntd, 0)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>100%</Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </>
  );
};
