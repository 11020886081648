import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { differenceInDays, parseISO } from 'date-fns';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select
} from 'antd';

import api from 'services/api';
import { formatToISO } from 'utils/utils';
import { onErrorNotification } from 'api/models';
import {
  applyMaskDecimalWithComma,
  convertFormattedToNumber,
  integerRegex
} from 'utils/masks';
import { StatusSuinoOptions } from 'models';

import SelectRacas from 'components/SelectRacas';
import PigDatePicker from 'components/PigDatePicker';
import { SuinoResponse } from 'api/getMatrizById/useGetMatrizById';
import { useEditarMatriz } from 'api/editarMatriz/useEditarMatriz';
import { useEditarReprodutor } from 'api/editarReprodutor/useEditarReprodutor';

interface IValid<T = { sexo: string; codigo: string }> {
  help: string;
  lastValue: T;
  hasFeedback: boolean;
  validateStatus: '' | 'success' | 'warning' | 'error' | 'validating';
}

interface InformacoesBasicasProps {
  suino: SuinoResponse;
  isLoading: boolean;
}

const InformacoesBasicas: React.FC<InformacoesBasicasProps> = ({
  suino,
  isLoading
}) => {
  const [form] = Form.useForm();
  const { mutate: updateReprodutor, isLoading: updatingReprodutor } =
    useEditarReprodutor({
      onSuccess: () => {
        notification.success({
          message: 'Dados atualizados com sucesso!'
        });
      },
      onError: (error) => {
        onErrorNotification(error);
      }
    });
  const { mutate: updateMatriz, isLoading: updatingMatriz } = useEditarMatriz({
    onSuccess: () => {
      notification.success({
        message: 'Dados atualizados com sucesso!'
      });
    },
    onError: (error) => {
      onErrorNotification(error);
    }
  });
  const [validacao, setValidacao] = useState<IValid>({
    help: '',
    hasFeedback: false,
    validateStatus: ''
  } as IValid);

  const calcularGPD = (
    pesoInicial: number,
    dataInicial: Date,
    pesoFinal: number,
    dataFinal: Date
  ): number => {
    if (pesoInicial !== 0 && !pesoInicial) return 0;
    if (!dataInicial) return 0;
    if (pesoFinal !== 0 && !pesoFinal) return 0;
    if (!dataFinal) return 0;
    return (pesoFinal - pesoInicial) / differenceInDays(dataFinal, dataInicial);
  };

  const calcularTotalPartos = (
    partosPreSistema: number,
    partosPosSistema: number
  ) => {
    let totalPartos = 0;
    if (partosPosSistema) totalPartos += partosPosSistema;
    if (partosPreSistema) totalPartos += partosPreSistema;
    return totalPartos;
  };

  const validarCodigoIdentificacao = async (
    id: string,
    codigo: string,
    sexo: string
  ) => {
    if (
      sexo &&
      codigo &&
      (sexo !== validacao?.lastValue?.sexo ||
        codigo !== validacao?.lastValue?.codigo) &&
      validacao.validateStatus !== 'validating'
    ) {
      try {
        setValidacao({
          hasFeedback: true,
          help: 'Verificando...',
          validateStatus: 'validating',
          lastValue: {
            sexo: sexo,
            codigo: codigo
          }
        });
        const tipo = sexo === 'MACHO' ? 'reprodutor' : 'matriz';
        await api.get(`/${tipo}/verificar-codigo`, {
          params: {
            codigo: codigo,
            id: id
          },
          paramsSerializer: (params) => {
            return QueryString.stringify(params, { arrayFormat: 'repeat' });
          }
        });
        setValidacao({
          help: '',
          hasFeedback: true,
          validateStatus: 'success',
          lastValue: {
            sexo: sexo,
            codigo: codigo
          }
        });
      } catch (error) {
        setValidacao({
          hasFeedback: true,
          help: 'Número em uso',
          validateStatus: 'error',
          lastValue: {
            sexo: sexo,
            codigo: codigo
          }
        });
      }
    }
  };

  const isObjectNullOrEmpty = (o: any) => {
    if (o == null || o == undefined) return true;
    return Object.keys(o).length > 0;
  };

  const onValuesChange = (change: any, values: any) => {
    form.setFieldsValue({
      idade: !isObjectNullOrEmpty(values.dataNascimento)
        ? differenceInDays(
            parseISO(new Date().toISOString()),
            values.dataNascimento
          )
        : suino.idade,
      tempoPlantel: !isObjectNullOrEmpty(values.dataEntradaPlantel)
        ? differenceInDays(
            parseISO(new Date().toISOString()),
            values.dataEntradaPlantel
          )
        : suino.tempoPlantel,
      totalPartos: calcularTotalPartos(
        values.partosPreSistema,
        values.partosPosSistema
      ),
      gpd: applyMaskDecimalWithComma(
        calcularGPD(
          0,
          isObjectNullOrEmpty(values.dataNascimento)
            ? null
            : values.dataNascimento,
          convertFormattedToNumber(values.pesoEntradaPlantel),
          isObjectNullOrEmpty(values.dataEntradaPlantel)
            ? null
            : values.dataEntradaPlantel
        ),
        3
      )
    });
  };

  useEffect(() => {
    if (suino) {
      form.setFieldsValue({
        ...suino,
        racaUUID: suino.raca ? suino.raca.id : null,
        dataNascimento: parseISO(suino.dataNascimento),
        dataEntradaPlantel: parseISO(suino.dataEntradaPlantel),
        dataUltimaAvaliacaoCorporal: suino.dataUltimaAvaliacaoCorporal
          ? parseISO(suino.dataUltimaAvaliacaoCorporal)
          : null,
        pesoEntradaPlantel: applyMaskDecimalWithComma(
          suino.pesoEntradaPlantel,
          0
        ),
        pesoHoje: applyMaskDecimalWithComma(suino.pesoHoje, 0),
        gpd: applyMaskDecimalWithComma(
          calcularGPD(
            0,
            parseISO(suino.dataNascimento),
            suino.pesoEntradaPlantel,
            parseISO(suino.dataEntradaPlantel)
          ),
          3
        ),
        idade: suino.idade,
        tempoPlantel: suino.tempoPlantel,
        totalPartos:
          Number(suino.partosPreSistema) + Number(suino.partosPosSistema)
      });
    }
  }, [suino]);

  return (
    <Form
      layout="vertical"
      colon={false}
      form={form}
      wrapperCol={{ style: { width: '100%' } }}
      onValuesChange={onValuesChange}
      onFinish={(values) => {
        const body = {
          id: suino.id,
          body: {
            racaId: values.racaUUID,
            apelido: values.apelido,
            codigo: values.codigo,
            mae: values.mae,
            pai: values.pai,
            registroPBB: values.registroPBB,
            granjaOrigem: values.granjaOrigem,
            pesoEntradaPlantel: convertFormattedToNumber(
              values.pesoEntradaPlantel
            ),
            pesoHoje: convertFormattedToNumber(values.pesoHoje),
            dataNascimento: formatToISO(values.dataNascimento),
            dataEntradaPlantel: formatToISO(values.dataEntradaPlantel)
          }
        };
        if (values.sexo === 'MACHO') updateReprodutor(body);
        else
          updateMatriz({
            id: body.id,
            body: { ...body.body, partosPreSistema: values.partosPreSistema }
          });
      }}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Sexo"
            name="sexo"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Select placeholder="Selecionar" disabled>
              <Select.Option key="FEMEA" value="FEMEA">
                FEMEA
              </Select.Option>
              <Select.Option key="MACHO" value="MACHO">
                MACHO
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Status do Animal"
            name="status"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Select placeholder="Selecionar" disabled>
              {StatusSuinoOptions.map((e) => (
                <Select.Option key={e.key} value={e.key}>
                  {e.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Número/Identificação"
            name="codigo"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            hasFeedback={validacao.hasFeedback}
            validateStatus={validacao.validateStatus}
            help={validacao.help}
          >
            <Input
              disabled={!!suino.descarte}
              onBlur={(e) => {
                validarCodigoIdentificacao(
                  suino.id,
                  e.currentTarget.value,
                  form.getFieldValue('sexo')
                );
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Apelido"
            name="apelido"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Registro PBB"
            name="registroPBB"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Raça"
            name="racaUUID"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <SelectRacas placeholder="Selecionar" allowClear showSearch />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Granja Origem"
            name="granjaOrigem"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.dataNascimento !== next.dataNascimento
            }
          >
            {({ getFieldValue, getFieldsValue }) => (
              <Form.Item
                label="Data de Nascimento"
                name="dataNascimento"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <PigDatePicker
                  startValue={getFieldValue('dataNascimento')}
                  callback={(_, date) => {
                    const changed = { dataNascimento: date };
                    form.setFieldsValue(changed);
                    onValuesChange(changed, getFieldsValue());
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Idade"
            name="idade"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input suffix="dias" disabled />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.dataEntradaPlantel !== next.dataEntradaPlantel
            }
          >
            {({ getFieldValue, getFieldsValue }) => (
              <Form.Item
                label="Data de Entrada no Plantel"
                name="dataEntradaPlantel"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <PigDatePicker
                  startValue={getFieldValue('dataEntradaPlantel')}
                  callback={(_, date) => {
                    const changed = { dataEntradaPlantel: date };
                    form.setFieldsValue(changed);
                    onValuesChange(changed, getFieldsValue());
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Tempo de Plantel"
            name="tempoPlantel"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input suffix="dias" disabled />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Peso de Entrada no Plantel"
            name="pesoEntradaPlantel"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[
              { required: true, message: 'Campo obrigatório' },
              {
                pattern: integerRegex,
                message: 'Deve estar no formato 0'
              }
            ]}
          >
            <Input suffix="kgs" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Peso Hoje"
            name="pesoHoje"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[
              {
                pattern: integerRegex,
                message: 'Deve estar no formato 0'
              }
            ]}
          >
            <Input suffix="kgs" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="GPD (Entrada Plantel)"
            name="gpd"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input suffix="kgs" disabled />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Pai"
            name="pai"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Mãe"
            name="mae"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input />
          </Form.Item>
        </Col>

        {form.getFieldValue('sexo') == 'FEMEA' && (
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Partos Pré Sistema"
              name="partosPreSistema"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <InputNumber step={1} min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        )}

        {form.getFieldValue('sexo') == 'FEMEA' && (
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Partos Pós Sistema"
              name="partosPosSistema"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <InputNumber
                step={1}
                min={0}
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
        )}

        {form.getFieldValue('sexo') == 'FEMEA' && (
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Total Partos"
              name="totalPartos"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        )}

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.dataUltimaAvaliacaoCorporal !==
              next.dataUltimaAvaliacaoCorporal
            }
          >
            {({ getFieldValue, getFieldsValue }) => (
              <Form.Item
                label="Última Avaliação Corporal"
                name="dataUltimaAvaliacaoCorporal"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <PigDatePicker
                  disabled
                  startValue={getFieldValue('dataUltimaAvaliacaoCorporal')}
                  callback={(_, date) => {
                    const changed = { dataUltimaAvaliacaoCorporal: date };
                    form.setFieldsValue(changed);
                    onValuesChange(changed, getFieldsValue());
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="end">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Button
            block
            type="primary"
            htmlType="button"
            icon={<SaveOutlined />}
            loading={isLoading || updatingReprodutor || updatingMatriz}
            onClick={() => {
              form.submit();
            }}
          >
            Atualizar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InformacoesBasicas;
