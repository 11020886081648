import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';

export type EditarFornecedorRequest = {
  id: string;
  nome: string;
  ativo: boolean;
};

const editarFornecedor = async (
  body: EditarFornecedorRequest
): Promise<void> => {
  const response = await api.put(`fornecedor/${body.id}`, body);
  return response.data;
};

export type InputParams = {
  onSuccess: () => void;
  onError: (error: unknown) => void;
};

export type OutputParams = {
  mutate: UseMutateFunction<void, unknown, EditarFornecedorRequest, unknown>;
  isLoading: boolean;
};

export const useEditarFornecedor = ({
  onSuccess,
  onError
}: InputParams): OutputParams => {
  const { mutate, isLoading } = useMutation(
    (body: EditarFornecedorRequest) => editarFornecedor(body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
