export const decimalRegexWithComma = new RegExp(
  /^([0-9]{1,}[,][0-9]{2})$/,
  'g'
);

export const integerRegex = new RegExp(/^([0-9]{1,})$/, 'g');

type CurrencyMaskOptions = {
  places?: number;
  locale?: string;
  currency?: string;
};

export function applyCurrencyMask(
  value: number,
  options: CurrencyMaskOptions = { places: 2, locale: 'pt-BR', currency: 'BRL' }
) {
  return Intl.NumberFormat(options.locale, {
    currency: options.currency,
    maximumFractionDigits: options.places,
    minimumFractionDigits: options.places
  }).format(value);
}

type NumberMaskOptions = {
  locale?: string;
  places?: number;
};

export function applyNumberMask(
  value: number,
  options: NumberMaskOptions = { locale: 'pt-BR', places: 2 }
) {
  return Intl.NumberFormat(options.locale, {
    maximumFractionDigits: options.places,
    minimumFractionDigits: options.places
  }).format(value);
}

export function convertFormattedToNumber(value: string): number {
  if (!value) return 0;
  return Number(value.replace(',', '.'));
}

export function applyMaskDecimalWithComma(value: number, places = 2): string {
  if (value !== 0 && !value)
    return String(Number(0).toFixed(places)).replace('.', ',');
  return String(value.toFixed(places)).replace('.', ',');
}

export function applyMaskInteger(value: number): string {
  if (value !== 0 && !value) return '0';
  return String(value.toFixed(2)).split('.')[0];
}

export function applyMaskCellPhoneBr(value: string | undefined | null): string {
  value = value + '';
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{0})(\d)/, `$1($2`);
  value = value.replace(/(\d{2})(\d)/, `$1) $2`);
  value = value.replace(/(\d{5})(\d)/, `$1-$2`);
  return value;
}

export const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
