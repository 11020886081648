import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Row,
  Table,
  notification
} from 'antd';

import { ProducaoRacaoTotalDTO } from 'models';
import {
  firstAndLastDayOfMonth as currentMonth,
  formatToISO
} from 'utils/utils';

import { applyMaskDecimalWithComma } from 'utils/masks';
import CustomRangePicker from 'components/CustomRangePicker';
import { useGetTotalRacao } from 'api/relatorios/racao/getTotalRacao/useGetTotalRacao';
import { usePDFTotalRacao } from 'api/relatorios/racao/getPDFTotalRacao/useGetPDFTotalRacao';

type SearchParams = {
  periodo?: [Date, Date];
};

const ProducaoTotalRacao: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    periodo: [currentMonth().firstDay, currentMonth().lastDay]
  });
  const { data, isLoading } = useGetTotalRacao({
    params: {
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFTotalRacao({
    params: {
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    },
    onSuccess: (data) => {
      JsFileDownload(data, `producao-racao.pdf`);
    },
    onError: (error) => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  const summary = (data: readonly ProducaoRacaoTotalDTO[]) => {
    let totalPre = 0.0;
    let totalInicial1 = 0.0;
    let totalInicial2 = 0.0;
    let totalCrescimento = 0.0;
    let totalTerminacao = 0.0;
    let totalReposicao = 0.0;
    let totalGestacao = 0.0;
    let totalPreLactacao = 0.0;
    let totalLactacao = 0.0;
    data.forEach((e) => {
      totalPre += e.preEmKg;
      totalInicial1 += e.inicial1EmKg;
      totalInicial2 += e.inicial2EmKg;
      totalCrescimento += e.crescimentoEmKg;
      totalTerminacao += e.terminacaoEmKg;
      totalReposicao += e.reposicaoEmKg;
      totalGestacao += e.gestacaoEmKg;
      totalPreLactacao += e.preLactacaoEmKg;
      totalLactacao += e.lactacaoEmKg;
    });
    const total =
      totalPre +
      totalInicial1 +
      totalInicial2 +
      totalCrescimento +
      totalTerminacao +
      totalReposicao +
      totalGestacao +
      totalPreLactacao +
      totalLactacao;

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={2}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalPre / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalInicial1 / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalInicial2 / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalCrescimento / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalTerminacao / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalReposicao / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalGestacao / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalPreLactacao / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            <span style={{ fontWeight: 'bold' }}>
              {`${((totalLactacao / total || 0) * 100).toFixed(0)}%`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11} colSpan={2}>
            <span style={{ fontWeight: 'bold' }}>100%</span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        onFinish={(values) => {
          setSearchParams({
            periodo: values.periodo
          });
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Período"
              name="periodo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  required: true,
                  message: 'Campo Obrigatório'
                },
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject();
                    if (!value[0] || !value[1]) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <CustomRangePicker
                initialValue={searchParams.periodo}
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ periodo: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Sem produção para o período!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          style={{ marginTop: '32px', marginBottom: '16px' }}
          dataSource={data ? [data] : []}
          scroll={{ x: '100vw' }}
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          summary={summary}
          pagination={false}
          columns={[
            {
              title: 'Pré',
              dataIndex: 'preEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Inicial 1',
              dataIndex: 'inicial1EmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Inicial 2',
              dataIndex: 'inicial2EmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Crescimento',
              dataIndex: 'crescimentoEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Terminação',
              dataIndex: 'terminacaoEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Reposição',
              dataIndex: 'reposicaoEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Gestação',
              dataIndex: 'gestacaoEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Pré Lactação',
              dataIndex: 'preLactacaoEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Lactação',
              dataIndex: 'lactacaoEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            },
            {
              title: 'Total (Kgs)',
              dataIndex: 'totalEmKg',
              render: (value: number) => applyMaskDecimalWithComma(value, 0)
            }
          ]}
        />
      </ConfigProvider>
    </>
  );
};

export default ProducaoTotalRacao;
