import { ptBR } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ConfigProvider, Empty, Modal, Table } from 'antd';

import api from 'services/api';
import { ListBisnagaSemenResponse, IPaginacao, SemenResponse } from 'models';

import { formatToBRL } from 'utils/utils';
import { PageResponse } from 'api/models';

interface IKey extends ListBisnagaSemenResponse {
  key: string;
}

interface IProps {
  visible: boolean;
  onOK: (bisnaga: ListBisnagaSemenResponse) => void;
  onCancel: () => void;
}

const ModalEstoques: React.FC<IProps> = ({
  visible = false,
  onOK,
  onCancel
}) => {
  const [bisnagaSelecionada, setBisnagaSelecionada] =
    useState<ListBisnagaSemenResponse>({} as ListBisnagaSemenResponse);
  const [estoques, setEstoques] = useState<IKey[]>([]);
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    page: 0,
    perPage: 5,
    totalItems: 0,
    totalPages: 0
  });

  const columns = [
    {
      title: 'Raça',
      dataIndex: 'doador',
      render: (value: SemenResponse) => value.racaDoador.nome
    },
    {
      title: 'Reprodutor/Fornecedor',
      dataIndex: 'doador',
      render: (value: SemenResponse) => value.codigoDoador
    },
    {
      title: 'Data Coleta',
      dataIndex: 'dataColeta',
      render: (value: string) => formatToBRL(value)
    },
    {
      title: 'Saldo Doses',
      dataIndex: 'saldo'
    },
    {
      title: 'Observação',
      render: (record: ListBisnagaSemenResponse) => {
        const distance = formatDistanceToNow(new Date(record.dataVencimento), {
          addSuffix: true,
          locale: ptBR
        });
        return record.isVencido ? 'Vencido ' + distance : 'Vence ' + distance;
      }
    }
  ];

  const buscarBisnagas = async () => {
    try {
      const { data } = await api.get<PageResponse<ListBisnagaSemenResponse>>(
        '/estoque-semen',
        {
          params: {
            perPage: paginacao.perPage,
            page: paginacao.page,
            dosesMin: 1
          }
        }
      );
      setEstoques(
        data.items.map((estoque) => ({ ...estoque, key: estoque.id }))
      );
      setPaginacao((currentState) => ({
        ...currentState,
        totalItems: data.total
      }));
    } catch (error) {
      setEstoques([]);
    }
  };

  useEffect(() => {
    buscarBisnagas();
  }, [paginacao.page]);

  return (
    <Modal
      title="Bisnagas de Sêmen"
      width={'70%'}
      open={visible}
      okText="Confirmar"
      onOk={() => {
        onOK(bisnagaSelecionada);
      }}
      okButtonProps={{
        disabled: !bisnagaSelecionada?.id,
        title: !bisnagaSelecionada?.id ? 'Selecione uma bisnaga' : 'Confirmar'
      }}
      cancelText="Cancelar"
      onCancel={() => {
        onCancel();
      }}
    >
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma bisnaga encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={estoques}
          rowSelection={{
            type: 'radio',
            onSelect: (record) => {
              setBisnagaSelecionada(record);
            },
            selectedRowKeys: bisnagaSelecionada ? [bisnagaSelecionada?.id] : []
          }}
          pagination={{
            size: 'small',
            total: paginacao.totalItems,
            pageSize: paginacao.perPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: false,
            onChange: (page) => {
              setPaginacao((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
        />
      </ConfigProvider>
    </Modal>
  );
};

export default ModalEstoques;
