import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type ListarPrevisaoVendaLeitaoRequest = PageRequest & {
  periodo?: RangeRequest<string>;
};

export type ListarPrevisaoVendaLeitaoResponse = {
  id: string;
  numero: number;
  idade: number;
  raca: string;
  saldo: number;
  data: string;
  atrasada: boolean;
};

export const listarPrevisaoVendaLeitao = async (
  params: ListarPrevisaoVendaLeitaoRequest
): Promise<PageResponse<ListarPrevisaoVendaLeitaoResponse>> => {
  const response = await api.get<
    PageResponse<ListarPrevisaoVendaLeitaoResponse>
  >('relatorio/lote/vendas-previstas', {
    params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
  });
  return response.data;
};

export type InputParams = {
  params: ListarPrevisaoVendaLeitaoRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarPrevisaoVendaLeitaoResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<
      PageResponse<ListarPrevisaoVendaLeitaoResponse>,
      unknown
    >
  >;
};

export const useListarPrevisaoVendaLeitao = (
  input: InputParams
): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-previsao-venda-leitao', input.params],
    () => listarPrevisaoVendaLeitao(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading, refetch };
};
