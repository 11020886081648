import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';
import { InputParams } from 'api/models';

export type AtualizarProducaoRacaoRequest = {
  preEmKg: number;
  inicial1EmKg: number;
  inicial2EmKg: number;
  crescimentoEmKg: number;
  terminacaoEmKg: number;
  reposicaoEmKg: number;
  gestacaoEmKg: number;
  preLactacaoEmKg: number;
  lactacaoEmKg: number;
};

const atualizarProducaoRacao = async (
  dia: string,
  body: AtualizarProducaoRacaoRequest
): Promise<void> => {
  const response = await api.put<void>(`racao/${dia}`, body);
  return response.data;
};

type Variables = {
  dia: string;
  body: AtualizarProducaoRacaoRequest;
};

export type OutputParams = {
  mutate: UseMutateFunction<void, unknown, Variables, unknown>;
  isLoading: boolean;
};

export const useAtualizarProducaoRacao = ({
  onSuccess,
  onError
}: InputParams<void>): OutputParams => {
  const { mutate, isLoading } = useMutation(
    ['update-producao-racao'],
    (params: Variables) => atualizarProducaoRacao(params.dia, params.body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
