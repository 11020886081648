import { Button as AntdButton } from 'antd';

export type ButtonParams = {
  onClick?: () => void;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  shape?: 'circle' | 'default' | 'round';
  size?: 'small' | 'middle' | 'large' | undefined;
  variant?: 'default' | 'alert' | 'danger' | 'success' | 'delete';
};

export const Button: React.FC<ButtonParams> = ({
  size = 'small',
  shape = 'default',
  variant = 'default',
  ...params
}) => {
  let baseStyle: React.CSSProperties = {};

  switch (variant) {
    case 'danger':
      baseStyle = {
        background: '#DC3545',
        borderColor: '#DC3545',
        color: '#fff'
      };
      break;

    case 'alert':
      baseStyle = {
        background: '#ffc107',
        borderColor: '#ffc107',
        color: '#fff'
      };
      break;

    case 'success':
      baseStyle = {
        background: '#4BB543',
        borderColor: '#4BB543',
        color: '#fff'
      };
      break;

    case 'delete':
      baseStyle = {
        background: '#323232',
        borderColor: '#323232',
        color: '#fff'
      };
      break;

    default:
      break;
  }

  return (
    <AntdButton
      size={size}
      shape={shape}
      icon={params.icon}
      style={{ ...params.style, ...baseStyle }}
      onClick={params.onClick}
    >
      {params.children}
    </AntdButton>
  );
};
