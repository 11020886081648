import api from 'services/api';
import { useQuery } from 'react-query';
import { PageRequest, PageResponse, RacaResponse } from 'api/models';

export type ListarReprodutorRequest = PageRequest & {
  codigo: string;
  status: string;
};

export type ListarReprodutorResponse = {
  id: string;
  codigo: string;
  status: string;
  idade: number;
  nascimento: string;
  raca: RacaResponse;
};

export const listarReprodutores = async (
  params: ListarReprodutorRequest
): Promise<PageResponse<ListarReprodutorResponse>> => {
  const response = await api.get<PageResponse<ListarReprodutorResponse>>(
    'reprodutor',
    {
      params
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarReprodutorRequest;
};

export type OutputParams = {
  data: PageResponse<ListarReprodutorResponse>;
  isLoading: boolean;
};

export const useListarReprodutores = (input: InputParams): OutputParams => {
  const { data, isLoading } = useQuery(
    ['listar-reprodutores', input.params],
    () => listarReprodutores(input.params)
  );
  return { data, isLoading };
};
