import { useEffect, useState } from 'react';
import {
  ClearOutlined,
  DeleteFilled,
  LoadingOutlined,
  SearchOutlined,
  SendOutlined
} from '@ant-design/icons';
import {
  Col,
  Form,
  Select,
  InputNumber,
  Row,
  Input,
  Button,
  notification,
  Table,
  Empty,
  ConfigProvider,
  Divider,
  Tag,
  Modal
} from 'antd';

import { formatToBRL, formatToISO } from 'utils/utils';
import { onErrorNotification } from 'api/models';
import { useExcluirOcorrencia } from 'api/excluirOcorrencia/excluirOcorrencia';
import { TransferenciaLeitaoResponse } from 'api/getTransferenciasByLoteId/useGetTransferenciasByLoteId';
import { useCadastrarTransferenciaLeitao } from 'api/cadastrarTransferenciaLeitao/useCadastrarTransferenciaLeitao';

import ModalLotes from './ModalLotes';
import PigDatePicker from 'components/PigDatePicker';

interface IProps {
  lote: {
    id: string;
    saldo: number;
    numero: string;
  };
  onSubmitCallback: () => void;
  transferencias: TransferenciaLeitaoResponse[];
}

const Transferencia: React.FC<IProps> = ({
  lote,
  onSubmitCallback,
  transferencias
}) => {
  const [form] = Form.useForm();
  const isDisabled = lote?.saldo === 0 || false;
  const [openModal, setOpenModal] = useState(false);
  const [showLotes, setShowLotes] = useState(false);
  const { mutate: excluirOcorrencia, isLoading: isLoadingExclusao } =
    useExcluirOcorrencia({
      onSuccess: (response) => {
        notification.success({
          message: 'Transferência excluída com sucesso!'
        });
        onSubmitCallback();
        form.resetFields();
        setOpenModal(false);
      },
      onError: (error) => {
        onErrorNotification(error);
      }
    });
  const { mutate: cadastrarTransferencia, isLoading } =
    useCadastrarTransferenciaLeitao({
      onSuccess: () => {
        notification.success({
          message: 'Transferência registrada com sucesso!'
        });
        onSubmitCallback();
        form.resetFields();
      },
      onError: (error) => {
        onErrorNotification(error);
      }
    });
  const columns = [
    {
      title: 'Data da Transferência',
      render: (value: TransferenciaLeitaoResponse) => formatToBRL(value.data)
    },
    {
      title: 'Quantidade',
      render: (value: TransferenciaLeitaoResponse) => value.qntd
    },
    {
      title: 'Nº Lote Origem',
      render: (value: TransferenciaLeitaoResponse) => value.loteOrigemNumero
    },
    {
      title: 'Nº Lote Destino',
      render: (value: TransferenciaLeitaoResponse) => value.loteDestinoNumero
    },
    {
      title: 'Operação',
      render: (value: TransferenciaLeitaoResponse) =>
        getTagOperacao(value.tipoTransferencia)
    },
    {
      title: 'Excluir',
      render: function excluir(value: TransferenciaLeitaoResponse) {
        return (
          <>
            <Button
              shape="circle"
              size="small"
              danger
              icon={<DeleteFilled />}
              onClick={() => setOpenModal(true)}
            />
            <Modal
              title="Excluir transferência?"
              okType="danger"
              open={openModal}
              okText="Excluir"
              onOk={() =>
                excluirOcorrencia({ loteId: lote.id, ocorrenciaId: value.id })
              }
              onCancel={() => setOpenModal(false)}
              confirmLoading={isLoading || isLoadingExclusao}
            >
              <span>
                Ao excluir a transferência, os animais transferidos serão
                devolvidos aos lotes.
              </span>
            </Modal>
          </>
        );
      }
    }
  ];
  const summary = (data: readonly TransferenciaLeitaoResponse[]) => {
    let totalAnimais = 0;

    data.forEach((value) => {
      totalAnimais += value.qntd || 0;
    });

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>Resumo: </span>
          </Table.Summary.Cell>

          <Table.Summary.Cell index={1} colSpan={5} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {totalAnimais?.toFixed(0) || 0}
            </span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const getTagOperacao = (tipoTransferencia: string) => {
    if (tipoTransferencia === 'SAIDA') return <Tag color="red">SAÍDA</Tag>;
    return <Tag color="green">ENTRADA</Tag>;
  };

  useEffect(() => {
    form.setFieldsValue({
      saldo: lote?.saldo || 0
    });
  }, [lote.saldo]);

  return (
    <>
      <ModalLotes
        loteIdToExclude={lote.id}
        open={showLotes}
        onCancel={() => {
          setShowLotes(false);
        }}
        onOK={(lote) => {
          if (form.getFieldValue('tipoOperacao') === 'SAIDA') {
            form.setFieldsValue({
              loteDestinoUUID: lote.id,
              loteDestinoNumero: lote.numero,
              loteSelecionadoSaldo: lote.saldo
            });
          } else if (form.getFieldValue('tipoOperacao') === 'ENTRADA') {
            form.setFieldsValue({
              loteOrigemUUID: lote.id,
              loteOrigemNumero: lote.numero,
              loteSelecionadoSaldo: lote.saldo
            });
          }
          form.validateFields([
            'loteOrigemNumero',
            'loteDestinoNumero',
            'qntdAnimais'
          ]);
          setShowLotes(false);
        }}
      />

      <Form
        layout="vertical"
        form={form}
        colon={false}
        disabled={isDisabled}
        initialValues={{ saldo: lote.saldo }}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          cadastrarTransferencia({
            loteId: lote.id,
            body: {
              qntd: values.qntdAnimais,
              data: formatToISO(values.dataTransferencia),
              loteOrigemId: values.loteOrigemUUID,
              loteDestinoId: values.loteDestinoUUID
            }
          });
        }}
        onValuesChange={(changedValues) => {
          if (changedValues.tipoOperacao && !changedValues.tipoOperacao) {
            form.setFieldsValue({
              loteOrigemUUID: null,
              loteOrigemNumero: null,
              loteDestinoUUID: null,
              loteDestinoNumero: null,
              loteSelecionadoSaldo: null
            });
          }

          if (changedValues.tipoOperacao === 'SAIDA') {
            form.setFieldsValue({
              loteOrigemUUID: lote.id,
              loteOrigemNumero: lote.numero,
              loteDestinoUUID: null,
              loteDestinoNumero: null
            });
          }

          if (changedValues.tipoOperacao === 'ENTRADA') {
            form.setFieldsValue({
              loteOrigemUUID: null,
              loteOrigemNumero: null,
              loteDestinoUUID: lote.id,
              loteDestinoNumero: lote.numero
            });
          }
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Saldo Hoje"
              name="saldo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Form.Item noStyle name="loteSelecionadoSaldo">
            <Input type="hidden" />
          </Form.Item>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.dataTransferencia !== next.dataTransferencia
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Data da Transferência"
                  name="dataTransferencia"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataTransferencia')}
                    callback={(_, date) => {
                      form.setFieldsValue({ dataTransferencia: date });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.tipoOperacao !== next.tipoOperacao
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    label="Qntd Animais"
                    name="qntdAnimais"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={
                      getFieldValue('loteSelecionadoSaldo')
                        ? [
                            { required: true, message: 'Campo obrigatório' },
                            {
                              type: 'number',
                              min: 1,
                              message: 'Deve ser pelo menos 1'
                            },
                            {
                              type: 'number',
                              max:
                                getFieldValue('tipoOperacao') === 'ENTRADA'
                                  ? getFieldValue('loteSelecionadoSaldo')
                                  : lote.saldo,
                              message: 'Lote origem não tem saldo suficiente'
                            }
                          ]
                        : [
                            { required: true, message: 'Campo obrigatório' },
                            {
                              type: 'number',
                              min: 1,
                              message: 'Deve ser pelo menos 1'
                            }
                          ]
                    }
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Tipo da Operação"
              name="tipoOperacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select placeholder="Selecionar" allowClear>
                <Select.Option key="SAIDA" value="SAIDA">
                  Saída
                </Select.Option>
                <Select.Option key="ENTRADA" value="ENTRADA">
                  Entrada
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.tipoOperacao !== next.tipoOperacao
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Lote Origem"
                  name="loteOrigemNumero"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    {
                      validator: (_, value) => {
                        if (!value || !getFieldValue('loteDestinoNumero'))
                          return Promise.resolve();

                        if (value === getFieldValue('loteDestinoNumero')) {
                          return Promise.reject();
                        }

                        return Promise.resolve();
                      },
                      message: 'Lotes devem ser diferentes'
                    }
                  ]}
                >
                  <Input.Search
                    enterButton={
                      <Button
                        type="primary"
                        htmlType="button"
                        icon={<SearchOutlined />}
                        disabled={getFieldValue('tipoOperacao') !== 'ENTRADA'}
                      />
                    }
                    onSearch={() => {
                      setShowLotes(true);
                    }}
                    prefix="Nº"
                    disabled
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Form.Item noStyle name="loteOrigemUUID">
            <Input type="hidden" />
          </Form.Item>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.tipoOperacao !== next.tipoOperacao
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Lote Destino"
                  name="loteDestinoNumero"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    {
                      validator: (_, value) => {
                        if (!value || !getFieldValue('loteOrigemNumero'))
                          return Promise.resolve();

                        if (value === getFieldValue('loteOrigemNumero')) {
                          return Promise.reject();
                        }

                        return Promise.resolve();
                      },
                      message: 'Lotes devem ser diferentes'
                    }
                  ]}
                >
                  <Input.Search
                    enterButton={
                      <Button
                        type="primary"
                        htmlType="button"
                        icon={<SearchOutlined />}
                        disabled={getFieldValue('tipoOperacao') !== 'SAIDA'}
                      />
                    }
                    onSearch={() => {
                      setShowLotes(true);
                    }}
                    prefix="Nº"
                    disabled
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Form.Item noStyle name="loteDestinoUUID">
            <Input type="hidden" />
          </Form.Item>
        </Row>

        <Row gutter={[16, 16]} justify="end" style={{ marginTop: '32px' }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              disabled={isLoading || isDisabled}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={isLoading}
              disabled={isDisabled}
              onClick={() => {
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider orientation="left" style={{ marginTop: '32px' }}>
        Histórico das Transferências
      </Divider>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma transferência encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          dataSource={transferencias}
          pagination={{
            size: 'small',
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: true
          }}
          columns={columns}
          summary={summary}
        />
      </ConfigProvider>
    </>
  );
};
export default Transferencia;
