import { useHttp } from 'api/useHttp';

type EstoqueGranjaCategoria = {
  categoria: string;
  subCategoria: string;
  total: number;
};

export type EstoqueGranjaResponse = {
  femeas: EstoqueGranjaCategoria[];
  machos: EstoqueGranjaCategoria[];
  leitoes: EstoqueGranjaCategoria[];
  // total: number;
  // totalFemeas: number;
  // totalMachos: number;
};

export function useGetEstoqueGranja() {
  return useHttp<void, void, EstoqueGranjaResponse>({
    method: 'GET',
    url: 'relatorio/estoque-granja',
    callOnMount: true
  });
}

export function useGetEstoqueGranjaPDF() {
  return useHttp<void, void, Blob>({
    method: 'GET',
    url: 'relatorio/estoque-granja/pdf',
    responseType: 'blob'
  });
}
