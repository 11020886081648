import axios from 'axios';
import { useSelector } from 'react-redux';
import { CSSProperties, useState } from 'react';
import { Button, Card, Col, Form, Input, notification, Row } from 'antd';

import api from 'services/api';
import { CriarContaDTO } from 'models';
import { applyMaskCellPhoneBr } from 'utils/masks';
import { formItemStyle, formStyle } from 'styles/globalAntd';

interface IReduxSelectorState {
  user: {
    user: CriarContaDTO;
  };
}

interface Props {
  style?: CSSProperties;
}

const DadosBasicos: React.FC<Props> = ({ style }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dadosProdutor = useSelector(
    (state: IReduxSelectorState) => state.user.user
  );

  const atualizarDados = async (values: CriarContaDTO) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await api.put('/produtor', values);
        notification.success({ message: 'Dados atualizados com sucesso!' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          notification.error({
            message: 'Erro ao atualizar os dados!',
            description: error.response.data.message
          });
        } else if (error instanceof Error) {
          notification.error({
            message: 'Erro ao atualizar os dados!',
            description: error.message
          });
        }
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Card title="Dados Básicos" style={style}>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: formStyle }}
        initialValues={dadosProdutor}
        onFinish={(values) => {
          atualizarDados({
            ...values,
            telefone: values.telefone
              .replaceAll(/[()-]/g, '')
              .replaceAll(' ', '')
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="nome"
              label="Nome"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo óbrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="nomeGranja"
              label="Nome da Granja"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo óbrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="email"
              label="Email"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo óbrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="telefone"
              label="Telefone"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo óbrigatório' }]}
            >
              <Input
                maxLength={15}
                onChange={(event) => {
                  const formatted = applyMaskCellPhoneBr(event.target.value);
                  form.setFieldsValue({ telefone: formatted });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Col span={6}>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting ? 'Salvando...' : 'Salvar'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default DadosBasicos;
