import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Result, Row, Spin } from 'antd';

import api from 'services/api';
import history from 'services/history';

const LinkInvalido: React.FC = () => {
  return (
    <Result
      status="error"
      title="Link Inválido!"
      subTitle="O link pode ter expirado ou a conta já foi ativada. Em caso de dúvidas, por favor nos contate pelo e-mail contato@meusuino.com."
    />
  );
};

const ContaAtivada: React.FC = () => {
  return (
    <Result
      status="success"
      title="Conta Ativada!"
      subTitle="Sua conta foi ativada com sucesso e você já pode fazer login no sistema."
      extra={[
        <Button
          type="primary"
          key="login"
          onClick={() => {
            history.replace('/login');
          }}
        >
          Ir para login
        </Button>
      ]}
    />
  );
};

const ConfirmarCadastro: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValido, setIsTokenValido] = useState(false);

  const validarToken = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await api.post(`/produtor/confirmar-cadastro/${token}`);
        setIsTokenValido(true);
      } catch (error) {
        setIsTokenValido(false);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validarToken();
  }, []);

  return (
    <Spin
      size="large"
      spinning={isLoading}
      tip="Verificando link"
      style={{ paddingTop: '20%' }}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Row justify="center">
        <Col span={12}>
          {!isLoading && isTokenValido && <ContaAtivada />}
          {!isLoading && !isTokenValido && <LinkInvalido />}
        </Col>
      </Row>
    </Spin>
  );
};

export default ConfirmarCadastro;
