import { BaseHttpParams, useHttp } from 'api/useHttp';
import { CadastrarRacaRequest, CadastrarRacaResponse } from './types';

export const useCreateRaca = (
  params: BaseHttpParams<CadastrarRacaResponse>
) => {
  return useHttp<CadastrarRacaRequest, void, CadastrarRacaResponse>({
    method: 'POST',
    url: 'raca',
    enableErrorNotification: true,
    ...params
  });
};
