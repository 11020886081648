import { Form, FormProps, FormItemProps } from 'antd';
import React from 'react';

export const defaultFormProps = {
  colon: false,
  layout: 'vertical',
  wrapperCol: { style: { width: '100%' } }
} as FormProps;

export const FormItem: React.FC<FormItemProps> = (props: any) => {
  return (
    <Form.Item
      {...props}
      style={{ flexDirection: 'column', alignItems: 'flex-start' }}
    />
  );
};

export const FormItemNoStyle: React.FC<FormItemProps> = (props: any) => {
  return <Form.Item noStyle {...props} />;
};
