import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { UseQueryPDFInput } from '../types';

export const getPDFFichaReprodutor = async (id: string): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    `relatorio/reprodutores/${id}/ficha/pdf`,
    {
      responseType: 'blob'
    }
  );
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFFichaReprodutor = (
  input: UseQueryPDFInput<{ id: string }>
): OutputParams => {
  return useQuery(
    ['get-pdf-ficha-reprodutor', input.params],
    () => getPDFFichaReprodutor(input.params.id),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
