import QueryString from 'qs';

import api from 'services/api';
import { PageRequest, PageResponse } from 'api/models';
import { UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';

export type TodasMatrizesRequest = PageRequest & {
  codigo?: string;
  status?: string[];
  situacao?: string[];
};

export type TodasMatrizesResponse = {
  id: string;
  codigo: string;
  status: string;
  situacao: string;
  maturidade: string;
  idade: number;
  tempoVazia: number;
  raca: string;
  partos: number;
};

export const listarTodasMatrizes = async (
  params: TodasMatrizesRequest
): Promise<PageResponse<TodasMatrizesResponse>> => {
  const response = await api.get<PageResponse<TodasMatrizesResponse>>(
    'relatorio/matrizes',
    {
      params,
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      }
    }
  );
  return response.data;
};

export type InputParams = {
  params: TodasMatrizesRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<TodasMatrizesResponse>;
  isLoading: boolean;
};

export const useListarTodasMatrizes = ({
  params,
  onError
}: InputParams): UseInfiniteQueryResult<
  PageResponse<TodasMatrizesResponse>,
  unknown
> => {
  return useInfiniteQuery({
    queryKey: ['get-listar-todas-matrizes', params],
    queryFn: ({ pageParam }) =>
      listarTodasMatrizes({ ...params, page: params.page || pageParam }),
    onError,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 < lastPage.totalPages) return lastPage.page + 1;
      return undefined;
    }
  });
};
