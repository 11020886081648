import QueryString from 'qs';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';

import api from 'services/api';
import { applyHttpRangeFormat } from 'api/useHttp';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';

export type ListarMarraRequest = PageRequest & {
  codigo?: string;
  statusNotIn?: string[];
  vacina1?: RangeRequest<string>;
  vacina2?: RangeRequest<string>;
};

export type ListarMarraResponse = {
  id: string;
  codigo: string;
  status: string;
  idade: number;
  vacinaDose1: string;
  vacinaDose2: string;
  dnp: number;
  raca: string;
  observacao: string;
};

export const listarMarras = async (
  params: ListarMarraRequest
): Promise<PageResponse<ListarMarraResponse>> => {
  const response = await api.get<PageResponse<ListarMarraResponse>>(
    'relatorio/matrizes/marras',
    {
      params: {
        ...params,
        vacina1: applyHttpRangeFormat(params.vacina1),
        vacina2: applyHttpRangeFormat(params.vacina2)
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarMarraRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarMarraResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<PageResponse<ListarMarraResponse>, unknown>>;
};

export const useListarMarras = (input: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-marras', input.params],
    () => listarMarras(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading, refetch };
};
