import { useQuery } from 'react-query';

import api from 'services/api';
import { applyHttpRangeFormat } from 'api/useHttp';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';
import { EnumResponse } from 'models';

export type ListarMatrizesDescartadasRequest = PageRequest & {
  codigo?: string;
  periodo?: RangeRequest<string>;
};

export type ListarMatrizesDescartadasResponse = {
  id: string;
  codigo: string;
  totalPartos: number;
  idade: number;
  status: string;
  data: string;
  motivo: EnumResponse;
  peso: number;
  aprovFinanceiro: number;
};

export const listarMatrizesDecartadas = async (
  params: ListarMatrizesDescartadasRequest
): Promise<PageResponse<ListarMatrizesDescartadasResponse>> => {
  const response = await api.get<
    PageResponse<ListarMatrizesDescartadasResponse>
  >('relatorio/matrizes/descartadas', {
    params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
  });
  return response.data;
};

export type InputParams = {
  params: ListarMatrizesDescartadasRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarMatrizesDescartadasResponse>;
  isLoading: boolean;
};

export const useListarMatrizesDescartadas = (
  input: InputParams
): OutputParams => {
  const { data, isLoading } = useQuery(
    ['listar-matrizes-descartadas', input.params],
    () => listarMatrizesDecartadas(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading };
};
