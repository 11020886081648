import { Tag } from 'antd';
import { RangeRequest } from 'api/models';
import { differenceInDays, format, parseISO } from 'date-fns';

interface IFirstAndLastDayOfMonth {
  firstDay: Date;
  lastDay: Date;
}

export const fromDateArrayToRange = (
  values: [Date, Date]
): RangeRequest<string> => {
  return {
    inicio: values && values[0] ? formatToISO(values[0]) : undefined,
    fim: values && values[1] ? formatToISO(values[1]) : undefined
  };
};

export const calcularGPD = (
  pesoInicial: number,
  dataInicial: Date,
  pesoFinal: number,
  dataFinal: Date
): number => {
  if (pesoInicial !== 0 && !pesoInicial) return 0;
  if (!dataInicial) return 0;
  if (pesoFinal !== 0 && !pesoFinal) return 0;
  if (!dataFinal) return 0;

  const gpd = differenceInDays(dataFinal, dataInicial)
    ? (pesoFinal - pesoInicial) / differenceInDays(dataFinal, dataInicial)
    : 0;

  return gpd;
};

export function calcularPigDay(dateISO: string): number {
  const initPigCalendar = '1969-01-01';
  const totalDays = differenceInDays(
    parseISO(dateISO),
    parseISO(initPigCalendar)
  );
  return (totalDays % 1000) + 1;
}

export function calculateAgeInDays(dateISO: string): number {
  return differenceInDays(
    parseISO(new Date().toISOString()),
    parseISO(dateISO)
  );
}

export function countDays(startISO: string, endISO: string): number {
  return differenceInDays(parseISO(endISO), parseISO(startISO));
}

export function formatToBRL(dateISO: string): string {
  return format(parseISO(dateISO), 'dd/MM/yyyy');
}

export function formatToISO(date: Date): string {
  return date ? format(date, 'yyyy-MM-dd') : null;
}

export function firstAndLastDayOfMonth(): IFirstAndLastDayOfMonth {
  const today = new Date();
  return {
    firstDay: new Date(today.getFullYear(), today.getMonth(), 1),
    lastDay: new Date(today.getFullYear(), today.getMonth() + 1, 0)
  };
}

export function mapperUltimoEvento(value: string): React.ReactNode {
  switch (value) {
    case 'REPETICAO_CIO':
      return 'REPETIÇÃO DE CIO';
    case 'ENTRADA_PLANTEL':
      return 'ENTRADA PLANTEL';
    default:
      return value;
  }
}

export function mapperTipoSuino(value: string): string {
  switch (value) {
    case 'MARRA':
      return 'MARRÃ';
    default:
      return value;
  }
}

export function mapperTagTipoSuino(value: string): React.ReactNode {
  switch (value) {
    case 'MARRA':
      return <Tag color={'green'}>MARRÃ</Tag>;
    case 'LEITOA':
      return <Tag color={'pink'}>LEITOA</Tag>;
    case 'MATRIZ':
      return <Tag color={'red'}>MATRIZ</Tag>;
    case 'REPRODUTOR':
      return <Tag color={'blue'}>REPRODUTOR</Tag>;
    default:
      return '';
  }
}

export function mapperTagStatusGestacao(value: string): React.ReactNode {
  switch (value) {
    case 'EM_GESTACAO':
      return <Tag color="geekblue">EM GESTAÇÃO</Tag>;
    case 'REPETICAO_CIO':
      return <Tag color="orange">REP. CIO</Tag>;
    case 'ABORTO':
      return <Tag color="red">ABORTO</Tag>;
    case 'DESCARTE':
      return <Tag color="blue">DESCARTE</Tag>;
    case 'PARTO':
      return <Tag color="green">PARTO REALIZADO</Tag>;
    default:
      return '';
  }
}

export function mapperTagUltimoEvento(value: string): React.ReactNode {
  switch (value) {
    case 'NASCIMENTO':
      return <Tag>NASCIMENTO</Tag>;
    case 'ENTRADA_PLANTEL':
      return <Tag>ENTRADA PLANTEL</Tag>;
    case 'COBERTURA':
      return <Tag color="geekblue">COBERTURA</Tag>;
    case 'ABORTO':
      return <Tag color="red">ABORTO</Tag>;
    case 'REPETICAO_CIO':
      return <Tag color="orange">REPETIÇÃO DE CIO</Tag>;
    case 'PARTO':
      return <Tag color="green">PARTO</Tag>;
    case 'DOACAO':
      return <Tag>DOAÇÃO</Tag>;
    case 'ADOCAO':
      return <Tag>ADOÇÃO</Tag>;
    case 'DESMAME':
      return <Tag color="purple">DESMAME</Tag>;
    case 'DESCARTE':
      return <Tag color="blue">DESCARTE</Tag>;
    default:
      return <Tag>{value}</Tag>;
  }
}

export function mapperStatusAnimal(value: string): React.ReactNode {
  switch (value) {
    case 'GESTANTE':
      return 'GESTANTE';
    case 'LACTANTE':
      return 'LACTANTE';
    case 'VAZIA':
      return 'VAZIA';
    case 'ATIVO':
      return 'ATIVO';
    case 'INATIVO':
      return 'INATIVO';
    case 'DESCARTADO':
      return 'DESCARTADO';
    default:
      return '';
  }
}

export function mapperStatusLote(value: string): React.ReactNode {
  switch (value) {
    case 'SEM_SALDO':
      return 'SEM SALDO';
    case 'TERMINACAO':
      return 'TERMINAÇÃO';
    default:
      return value;
  }
}

export function mapperTagStatusAnimal(value: string): React.ReactNode {
  switch (value) {
    case 'GESTANTE':
      return <Tag color="magenta">GESTANTE</Tag>;
    case 'LACTANTE':
      return <Tag color="green">LACTANTE</Tag>;
    case 'VAZIA':
      return <Tag color="orange">VAZIA</Tag>;
    case 'ATIVO':
      return <Tag color="blue">ATIVO</Tag>;
    case 'INATIVO':
      return <Tag color="gray">ATIVO</Tag>;
    case 'DESCARTADO':
      return <Tag color="default">DESCARTADO</Tag>;
    default:
      return '';
  }
}

export function getColorByType(type: string): string {
  if (type === 'VAZIA') return '#FFA500';
  if (type === 'GESTANTE') return '#FF69B4';
  if (type === 'LACTANTE') return '#00C0F0';

  if (type === 'ATIVO') return 'rgba(255, 0, 0, 0.8)';
  if (type === 'INATIVO') return 'rgba(255, 0, 0, 0.8)';

  if (type === 'MATERNIDADE') return '#00C0F0';
  if (type === 'CRECHE') return '#FFB300';
  if (type === 'CRESCIMENTO') return '#A27D52';
  if (type === 'TERMINACAO') return '#4CAF50';

  return '#4F4F4F';
}
