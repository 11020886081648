import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Card as AntdCard,
  List,
  ConfigProvider,
  Empty,
  Form,
  Row,
  Col,
  Select,
  Input,
  notification
} from 'antd';
import {
  SearchOutlined,
  ArrowUpOutlined,
  LoadingOutlined
} from '@ant-design/icons';

import history from 'services/history';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import Card from 'components/Card';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import {
  getColorByType,
  mapperStatusAnimal,
  mapperUltimoEvento
} from 'utils/utils';
import {
  TodasMatrizesRequest,
  useListarTodasMatrizes
} from 'api/relatorios/matrizes/listarTodasMatrizes/useListarTodasMatrizes';

const Matrizes: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [params, setParams] = useState({
    status: ['VAZIA', 'GESTANTE', 'LACTANTE']
  } as TodasMatrizesRequest);
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useListarTodasMatrizes({
      params: {
        codigo: params.codigo,
        status: params.status,
        perPage: 50
      },
      onError: (error) => {
        console.log(error);
        notification.error({ message: 'Erro ao buscar matrizes' });
      }
    });

  useEffect(() => {
    dispatch(updateID('suino-matrizes'));
    dispatch(updateTitle('Matrizes'));
  }, []);

  return (
    <>
      <AntdCard key="filtro" title="Filtros" style={{ marginBottom: '16px' }}>
        <Form
          layout="vertical"
          form={form}
          colon={false}
          initialValues={{
            status: ['VAZIA', 'GESTANTE', 'LACTANTE']
          }}
          onFinish={(values) => {
            setParams((prev) => ({
              codigo: values.codigo || undefined,
              status: values?.status || undefined
            }));
          }}
          wrapperCol={{ style: { width: '100%' } }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Código"
                name="codigo"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Status"
                name="status"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Select mode="multiple" placeholder="Selecione" allowClear>
                  <Select.Option key="VAZIA" value="VAZIA">
                    Vazia
                  </Select.Option>
                  <Select.Option key="GESTANTE" value="GESTANTE">
                    Gestante
                  </Select.Option>
                  <Select.Option key="LACTANTE" value="LACTANTE">
                    Lactante
                  </Select.Option>
                  <Select.Option key="DESCARTADO" value="DESCARTADO">
                    Descartada
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="end">
            <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
              <Button
                block
                type="primary"
                htmlType="button"
                loading={isLoading}
                icon={<SearchOutlined />}
                onClick={() => {
                  form.submit();
                }}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </AntdCard>

      <BackTop
        target={() =>
          document.getElementById('scrollable-content-div') || window
        }
      >
        <Button
          shape="round"
          type="primary"
          htmlType="button"
          icon={<ArrowUpOutlined />}
        >
          Voltar
        </Button>
      </BackTop>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={false}
            description={
              isLoading ? <LoadingOutlined /> : 'Nenhuma matriz encontrada!'
            }
          />
        )}
      >
        <InfiniteScrollWrapper
          next={() => {
            fetchNextPage();
          }}
          style={{ overflow: 'hidden' }}
          hasMore={hasNextPage}
          scrollableTarget="scrollable-content-div"
          dataLength={data?.pages.flatMap((p) => p.items).length || 0}
        >
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 3,
              md: 4,
              lg: 5,
              xl: 5,
              xxl: 6
            }}
            dataSource={data?.pages.flatMap((p) => p.items) || []}
            renderItem={(suino) => (
              <List.Item key={`item-${suino.id}`}>
                <Card
                  key={suino.id}
                  style={{
                    backgroundColor: getColorByType(suino.status)
                  }}
                  onClick={() => {
                    history.push(`/suino/matriz/${suino.id}/ficha`);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: 'inherit'
                    }}
                  >
                    <div>
                      {suino.codigo} - {mapperStatusAnimal(suino.status)}
                    </div>
                    {suino.status === 'VAZIA' &&
                      suino.maturidade == 'MARRA' && <div>{'MARRÃ'}</div>}
                    {suino.status === 'VAZIA' &&
                      suino.maturidade !== 'MARRA' && (
                        <div>{mapperUltimoEvento(suino.situacao)}</div>
                      )}
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </InfiniteScrollWrapper>
      </ConfigProvider>
    </>
  );
};

export default Matrizes;
