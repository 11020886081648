import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { PageRequest, PageResponse, RacaResponse } from 'api/models';

export type ListarMatrizRequest = PageRequest & {
  codigo?: string;
  status?: string;
  evento?: string;
  idadeMin?: number;
};

export type ListarMatrizResponse = {
  id: string;
  sexo: string;
  codigo: string;
  status: string;
  situacao: string;
  idade: number;
  tempoVazia: number;
  raca: RacaResponse;
  partos: number;
};

export const listarMatrizes = async (
  params: ListarMatrizRequest
): Promise<PageResponse<ListarMatrizResponse>> => {
  const response = await api.get<PageResponse<ListarMatrizResponse>>('matriz', {
    params
  });
  return response.data;
};

export type InputParams = {
  params: ListarMatrizRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarMatrizResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<PageResponse<ListarMatrizResponse>, unknown>
  >;
};

export const useListarMatrizes = ({
  params,
  onError
}: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-matrizes', params],
    () => listarMatrizes(params),
    {
      onError
    }
  );
  return { data, isLoading, refetch };
};
