import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import {
  ConfigProvider,
  Empty,
  Form,
  Row,
  Col,
  Input,
  Button,
  InputNumber,
  notification
} from 'antd';

import { MatrizCicloDTO } from 'models';
import { applyMaskDecimalWithComma } from 'utils/masks';

import { CustomTable } from 'components';
import { defaultFormProps } from 'components/Antd';
import { PageRequest } from 'api/models';
import {
  ListarMatrizCicloResponse,
  useListarMatrizCiclo
} from 'api/relatorios/matrizes/listarMatrizesCiclo/useListarMatrizesCiclo';
import { usePDFMatrizesPorCiclo } from 'api/relatorios/matrizes/getPDFMatrizesCiclo/useGetPDFMatrizesCiclo';

type SearchParams = PageRequest & {
  codigo?: string;
  partosMin?: number;
  partosMax?: number;
};

const RelatorioMatrizesPorCiclo: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    codigo: null,
    partosMin: null,
    partosMax: null
  });
  const { data, isLoading } = useListarMatrizCiclo({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      partosMin: searchParams.partosMin,
      partosMax: searchParams.partosMax,
      statusNotIn: ['DESCARTADO']
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFMatrizesPorCiclo({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      partosMin: searchParams.partosMin,
      partosMax: searchParams.partosMax
    },
    onSuccess: (data) => {
      JsFileDownload(data, `matrizes-por-ciclo.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        form={form}
        {...defaultFormProps}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            partosMin: values.partosMin,
            partosMax: values.partosMax
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Matriz"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Mínimo de Partos"
              name="partosMin"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <InputNumber min={0} step={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Máximo de Partos"
              name="partosMax"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <InputNumber min={0} step={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma matriz encontrada!"
          />
        )}
      >
        <CustomTable
          style={{ marginTop: '32px' }}
          isLoading={isLoading}
          data={data?.items || []}
          pagination={{
            page: searchParams.page,
            perPage: searchParams.perPage,
            totalElements: data?.total || 0,
            totalPages: data?.totalPages || 0
          }}
          changePageNumber={(page) => {
            setSearchParams((prev) => ({ ...prev, page: page - 1 }));
          }}
          changePageSize={(_, size) => {
            setSearchParams((prev) => ({
              ...prev,
              page: 0,
              perPage: size
            }));
          }}
          columns={[
            {
              title: 'Nº Matriz',
              dataIndex: 'codigo'
            },
            {
              title: 'Idade',
              dataIndex: 'idade'
            },
            {
              title: 'Partos',
              dataIndex: 'partos'
            },
            {
              title: 'Coberturas',
              dataIndex: 'coberturas'
            },
            {
              title: 'Total NV',
              dataIndex: 'nascidosVivos'
            },
            {
              title: 'Total NM',
              dataIndex: 'nascidosNatimortos'
            },
            {
              title: 'Total MM',
              dataIndex: 'nascidosMumificados'
            },
            {
              title: 'Total Desmamados',
              dataIndex: 'desmamados'
            },
            {
              title: 'Peso Médio Nasc.',
              render: (record: ListarMatrizCicloResponse) =>
                applyMaskDecimalWithComma(record.pesoMedioNascimento)
            },
            {
              title: 'Peso Médio Desmame',
              render: (record: MatrizCicloDTO) =>
                applyMaskDecimalWithComma(record.pesoMedioDesmame)
            }
          ]}
        />
      </ConfigProvider>
    </>
  );
};

export default RelatorioMatrizesPorCiclo;
