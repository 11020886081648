import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import Plano from './Plano';
import Senha from './Senha';
import DadosBasicos from './DadosBasicos';

const Perfil: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateID('meus-dados'));
    dispatch(updateTitle('Meus Dados'));
  }, []);

  return (
    <div>
      <DadosBasicos style={{ marginBottom: '24px' }} />

      <Senha style={{ marginBottom: '24px' }} />

      <Plano style={{ marginBottom: '24px' }} />
    </div>
  );
};

export default Perfil;
