import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { UseQueryPDFInput } from 'api/relatorios/types';
import { applyHttpRangeFormat } from 'api/useHttp';
import { ListarDescartesPorMotivoRequest } from '../listarDescartesLeitaoPorMotivo/useListarDescartesLeitaoPorMotivo';

export const getPDFDescartesLeitaoPorMotivo = async (
  params: ListarDescartesPorMotivoRequest
): Promise<Blob> => {
  const { data } = await api.get<Blob>(
    'relatorio/lote/descartes-por-motivo/pdf',
    {
      responseType: 'blob',
      params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
    }
  );
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFDescartesLeitaoPorMotivo = (
  input: UseQueryPDFInput<ListarDescartesPorMotivoRequest>
): OutputParams => {
  return useQuery(
    ['get-pdf-descartes-leitao-motivo', input.params],
    () => getPDFDescartesLeitaoPorMotivo(input.params),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
