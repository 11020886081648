import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Form, Input, Row } from 'antd';

import { formatToBRL } from 'utils/utils';
import { formItemStyle, formStyle } from 'styles/globalAntd';

interface IReduxSelectorState {
  user: {
    user: {
      id: string;
      nome: string;
      nomeGranja: string;
      contrato: {
        id: string;
        qntdMatrizes: number;
        contratadoEm: string;
        venceEm: string;
      };
    };
  };
}

interface Props {
  style?: CSSProperties;
}

const Plano: React.FC<Props> = ({ style }) => {
  const [form] = Form.useForm();
  const contrato = useSelector(
    (state: IReduxSelectorState) => state.user.user.contrato
  );

  return (
    <Card title="Plano" style={style}>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: formStyle }}
        initialValues={{
          qntdMatrizes: contrato.qntdMatrizes,
          contratadoEm: formatToBRL(contrato.contratadoEm),
          venceEm: formatToBRL(contrato.venceEm)
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="qntdMatrizes"
              label="Qntd Matrizes"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="contratadoEm"
              label="Data da Contratação"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="venceEm"
              label="Data do Vencimento"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Plano;
