import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Table,
  notification
} from 'antd';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';

import { PageRequest } from 'api/models';
import { applyMaskDecimalWithComma } from 'utils/masks';
import { formatToBRL, formatToISO, mapperTagStatusGestacao } from 'utils/utils';
import CustomRangePicker from 'components/CustomRangePicker';
import {
  ListarMatrizOcorrenciaResponse,
  useListarMatrizOcorrencias
} from 'api/relatorios/matrizes/listarMatrizOcorrencias/useListarMatrizOcorrencias';
import { usePDFOcorrenciasMatrizes } from 'api/relatorios/matrizes/getPDFOcorrenciasMatrizes/useGetPDFOcorrenciasMatrizes';

type SearchParams = PageRequest & {
  codigo?: string;
  statusGestacao?: string;
  periodo: [Date, Date];
};

const RelatorioMatrizesOcorrencias: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    codigo: null,
    periodo: [null, null],
    statusGestacao: null
  });
  const { data, isLoading } = useListarMatrizOcorrencias({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      },
      statusGestacao: searchParams.statusGestacao
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFOcorrenciasMatrizes({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      },
      statusGestacao: searchParams.statusGestacao
    },
    onSuccess: (data) => {
      JsFileDownload(data, `ocorrencias-matrizes.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            periodo: values.periodo,
            statusGestacao: values.statusGestacao
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Matriz"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Situação"
              name="statusGestacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Select placeholder="Selecionar" allowClear>
                <Select.Option key="ABORTO" value="ABORTO">
                  ABORTO
                </Select.Option>
                <Select.Option key="REPETICAO_CIO" value="REPETICAO_CIO">
                  REPETIÇÃO DE CIO
                </Select.Option>
                <Select.Option key="EM_GESTACAO" value="EM_GESTACAO">
                  EM GESTAÇÃO
                </Select.Option>
                <Select.Option key="PARTO" value="PARTO">
                  PARTO
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Período"
              name="periodo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ periodo: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma ocorrência encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          style={{ marginTop: '32px' }}
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          dataSource={data?.items || []}
          scroll={{ x: '100vw' }}
          pagination={{
            size: 'small',
            total: data?.total || 0,
            pageSize: searchParams.perPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: true,
            onShowSizeChange: (_, pageSize) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: 0,
                perPage: pageSize
              }));
            },
            onChange: (page) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
          columns={[
            {
              title: 'Nº Matriz',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record?.codigo || '-'
            },
            {
              title: 'Cobertura',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record.dataCobertura ? formatToBRL(record.dataCobertura) : '-'
            },
            {
              title: 'Repro/Fornec',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record?.reprodutor || '-'
            },
            {
              title: 'Situação',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record.status ? mapperTagStatusGestacao(record.status) : '-'
            },
            {
              title: 'Parto Provável',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record.partoPrevisto ? formatToBRL(record.partoPrevisto) : '-'
            },
            {
              title: 'Parto Real',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record.status === 'PARTO' && record.dataStatus
                  ? formatToBRL(record.dataStatus)
                  : '-'
            },
            {
              title: 'Aborto/Cio',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record.status !== 'PARTO' && record.dataStatus
                  ? formatToBRL(record.dataStatus)
                  : '-'
            },
            {
              title: 'Leitões Nascidos',
              children: [
                {
                  title: 'VV',
                  render: (record: ListarMatrizOcorrenciaResponse) =>
                    record.status === 'PARTO' && record.nascidosVivos
                      ? record.nascidosVivos
                      : '-'
                },
                {
                  title: 'NM',
                  render: (record: ListarMatrizOcorrenciaResponse) =>
                    record.status === 'PARTO' && record.nascidosNatimortos
                      ? record.nascidosNatimortos
                      : '-'
                },
                {
                  title: 'MF',
                  render: (record: ListarMatrizOcorrenciaResponse) =>
                    record.status === 'PARTO' && record.nascidosMumificados
                      ? record.nascidosMumificados
                      : '-'
                },
                {
                  title: 'Total',
                  render: (record: ListarMatrizOcorrenciaResponse) =>
                    record.status === 'PARTO' && record.totalNascidos
                      ? record.totalNascidos
                      : '-'
                }
              ]
            },
            {
              title: 'Peso',
              children: [
                {
                  title: 'Nasc.',
                  render: (record: ListarMatrizOcorrenciaResponse) =>
                    record.status === 'PARTO' && record.pesoMedioNascimento
                      ? applyMaskDecimalWithComma(record.pesoMedioNascimento, 2)
                      : '-'
                },
                {
                  title: 'Desm.',
                  render: (record: ListarMatrizOcorrenciaResponse) =>
                    record.pesoMedioDesmame
                      ? applyMaskDecimalWithComma(record.pesoMedioDesmame, 2)
                      : '-'
                }
              ]
            },
            {
              title: 'Desmame',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record.desmameReal ? formatToBRL(record.desmameReal) : '-'
            },
            {
              title: 'Nº Lote',
              render: (record: ListarMatrizOcorrenciaResponse) =>
                record?.numeroLote || '-'
            }
          ]}
        />
      </ConfigProvider>
    </>
  );
};

export default RelatorioMatrizesOcorrencias;
