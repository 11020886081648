import { useQuery } from 'react-query';

import api from 'services/api';
import { RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type TotalRacaoRequest = {
  periodo?: RangeRequest<string>;
};

export type TotalRacaoResponse = {
  tipoMovimentacao: string;
  preEmKg: number;
  inicial1EmKg: number;
  inicial2EmKg: number;
  crescimentoEmKg: number;
  terminacaoEmKg: number;
  reposicaoEmKg: number;
  gestacaoEmKg: number;
  preLactacaoEmKg: number;
  lactacaoEmKg: number;
  totalEmKg: number;
  totalCevadoEmKg: number;
};

export const getTotalRacao = async (
  params: TotalRacaoRequest
): Promise<TotalRacaoResponse> => {
  const response = await api.get<TotalRacaoResponse>('relatorio/racao/total', {
    params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
  });
  return response.data;
};

export type InputParams = {
  params: TotalRacaoRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: TotalRacaoResponse;
  isLoading: boolean;
};

export const useGetTotalRacao = ({
  params,
  onError
}: InputParams): OutputParams => {
  return useQuery(['get-total-racao', params], () => getTotalRacao(params), {
    onError
  });
};
