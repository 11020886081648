import { Form, FormItemProps } from 'antd';

const FormWrapper: React.FC<FormItemProps> = ({ children, ...props }) => {
  return (
    <Form.Item
      style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      {...props}
    >
      {children}
    </Form.Item>
  );
};

export default FormWrapper;
