import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  ConfigProvider,
  Table,
  Empty,
  Form,
  Row,
  Col,
  Input,
  Button,
  Tag,
  Popover,
  notification
} from 'antd';

import { PageRequest } from 'api/models';
import { formatToBRL, formatToISO } from 'utils/utils';

import { defaultFormProps } from 'components/Antd';
import CustomRangePicker from 'components/CustomRangePicker';
import {
  ListarLactanteResponse,
  useListarLactantes
} from 'api/relatorios/matrizes/listarLactantes/useListarLactantes';
import { usePDFLactantes } from 'api/relatorios/matrizes/getPDFLactantes/useGetPDFLactantes';

type SearchParams = PageRequest & {
  codigo?: string;
  desmame?: [Date, Date];
};

const RelatorioMatrizesLactantes: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    codigo: null,
    desmame: [null, null]
  });
  const { data, isLoading } = useListarLactantes({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      desmame: {
        inicio: searchParams?.desmame[0]
          ? formatToISO(searchParams.desmame[0])
          : undefined,
        fim: searchParams?.desmame[1]
          ? formatToISO(searchParams.desmame[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFLactantes({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      desmame: {
        inicio: searchParams?.desmame[0]
          ? formatToISO(searchParams.desmame[0])
          : undefined,
        fim: searchParams?.desmame[1]
          ? formatToISO(searchParams.desmame[1])
          : undefined
      }
    },
    onSuccess: (data) => {
      JsFileDownload(data, `matrizes-lactantes.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  const vacinaPopover = () => (
    <Popover
      title={<strong>Vacina</strong>}
      content={
        <div>
          <span>Parvovirose</span>
          <br />
          <span>Leptospirose</span>
          <br />
          <span>Erisipela</span>
        </div>
      }
    >
      Vacina
    </Popover>
  );

  return (
    <>
      <Form
        form={form}
        {...defaultFormProps}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            desmame: values.desmame
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Matriz"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Desmame"
              name="desmame"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ desmame: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma matriz lactante encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          rowKey={'id'}
          style={{ marginTop: '32px' }}
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          dataSource={data?.items || []}
          pagination={{
            size: 'small',
            total: data?.total || 0,
            pageSize: data?.perPage || 0,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: true,
            onShowSizeChange: (_, pageSize) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: 0,
                perPage: pageSize
              }));
            },
            onChange: (page) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
          columns={[
            {
              title: 'Nº Matriz',
              dataIndex: 'codigo'
            },
            {
              title: 'Idade (dias)',
              dataIndex: 'idade'
            },
            {
              title: 'Partos',
              dataIndex: 'partos'
            },
            {
              title: 'Lactação (início)',
              render: (record: ListarLactanteResponse) =>
                formatToBRL(record.inicio)
            },
            {
              title: 'Lactação (dias)',
              dataIndex: 'diasLactantes'
            },
            {
              title: vacinaPopover,
              render: (record: ListarLactanteResponse) => {
                return record.diasLactantes >= 8 &&
                  record.diasLactantes <= 12 ? (
                  <Tag color={'red'}>Aplicar Vacina</Tag>
                ) : (
                  <Tag color={'default'}>Fora do Período</Tag>
                );
              }
            },
            {
              title: 'Nº Lote',
              dataIndex: 'numeroLote'
            },
            {
              title: 'Desmame Previsto',
              render: (record: ListarLactanteResponse) =>
                record.fim ? formatToBRL(record.fim) : '-'
            }
          ]}
        />
      </ConfigProvider>
    </>
  );
};

export default RelatorioMatrizesLactantes;
