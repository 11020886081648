import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';

import { applyHttpRangeFormat } from 'api/useHttp';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';

export type ListarMatrizOcorrenciaRequest = PageRequest & {
  codigo?: string;
  statusGestacao?: string;
  periodo?: RangeRequest<string>;
};

export type ListarMatrizOcorrenciaResponse = {
  id: string;
  codigo: string;
  dataCobertura: string;
  reprodutor: string;
  status: string;
  partoPrevisto: string;
  dataStatus: string;
  nascidosVivos: number;
  nascidosNatimortos: number;
  nascidosMumificados: number;
  totalNascidos: number;
  pesoMedioNascimento: number;
  pesoMedioDesmame: number;
  desmameReal: string;
  desmamePrevisto: string;
  desmameQntd: number;
  numeroLote: string;
  idc: number;
  ipp: number;
  scoreCorporal: string;
};

export const listarMatrizOcorrencias = async (
  params: ListarMatrizOcorrenciaRequest
): Promise<PageResponse<ListarMatrizOcorrenciaResponse>> => {
  const response = await api.get<PageResponse<ListarMatrizOcorrenciaResponse>>(
    'relatorio/matrizes/ocorrencias',
    {
      params: {
        ...params,
        periodo: applyHttpRangeFormat(params?.periodo)
      }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarMatrizOcorrenciaRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarMatrizOcorrenciaResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<PageResponse<ListarMatrizOcorrenciaResponse>, unknown>
  >;
};

export const useListarMatrizOcorrencias = (
  input: InputParams
): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-matriz-ocorrencia', input.params],
    () => listarMatrizOcorrencias(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading, refetch };
};
