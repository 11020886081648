import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';

interface PrivateRouteProps extends RouteProps {
  isPrivate?: boolean;
}

type ReduxStateAuth = {
  auth: {
    token: string | null;
    logged: boolean;
    loading: boolean;
  };
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { logged } = useSelector((state: ReduxStateAuth) => state.auth);

  if (!logged && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (logged && !isPrivate) {
    return <Redirect to="/suino/matrizes" />;
  }

  const Layout = logged ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default PrivateRoute;
