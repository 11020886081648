import api from 'services/api';
import { useMutation } from 'react-query';
import { InputParams } from 'api/models';

export type LoginRequest = {
  email: string;
  senha: string;
};

export type ContratoResponse = {
  id: string;
  qntdMatrizes: number;
  contratadoEm: string;
  venceEm: string;
};

export type LoginResponse = {
  id: string;
  nome: string;
  nomeGranja: string;
  accessToken: string;
  contrato: ContratoResponse;
};

const makeLogin = async (body: LoginRequest): Promise<LoginResponse> => {
  const response = await api.post<LoginResponse>('autenticacao', body);
  return response.data;
};

export type OutputParams<T = any> = {
  mutate: T;
  isLoading: boolean;
};

export const useMakeLogin = ({
  onSuccess,
  onError
}: InputParams<LoginResponse>): OutputParams => {
  const { mutate, isLoading } = useMutation(
    async (body: LoginRequest) => makeLogin(body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
