import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type ListarDescartesPorFaseRequest = PageRequest & {
  periodo?: RangeRequest<string>;
};

export type ListarDescartesPorFaseResponse = {
  fase: string;
  faseDescricao: string;
  qntd: number;
  porcentagem: number;
};

export const listarDescartesLeitaoPorFase = async (
  params: ListarDescartesPorFaseRequest
): Promise<PageResponse<ListarDescartesPorFaseResponse>> => {
  const response = await api.get<PageResponse<ListarDescartesPorFaseResponse>>(
    'relatorio/lote/descartes-por-fase',
    {
      params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarDescartesPorFaseRequest;
  onError?: (error: unknown) => void;
};

export const useListarDescartesLeitaoPorFase = (input: InputParams) => {
  return useQuery(
    ['listar-descartes-por-fase', input.params],
    () => listarDescartesLeitaoPorFase(input.params),
    {
      onError: input.onError
    }
  );
};
