import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import RelatorioPrevisaoFaseLote from './RelatorioPrevisaoFaseLote';
import RelatorioFaseLoteEncerrada from './RelatorioFaseLoteEncerrada';
import RelatorioPrevisaoVendaLeitao from './RelatorioPrevisaoVendaLeitao';
import RelatorioVendasRealizadas from './RelatorioVendasRealizadas';
import RelatorioMortesLotePorCausa from './RelatorioMortesLotePorCausa';
import RelatorioMortesLotePorFase from './RelatorioMortesLotePorFase';

const RelatorioLotes: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateID('relatorios-lotes'));
    dispatch(updateTitle('Relatórios dos Lotes'));
  }, []);

  return (
    <div style={{ marginBottom: '64px' }}>
      <Collapse>
        <Collapse.Panel key="desmamas" header="Desmamas Realizadas">
          <RelatorioFaseLoteEncerrada fase="MATERNIDADE" />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel key="sair-creche" header="Previsão de Saída da Creche">
          <RelatorioPrevisaoFaseLote fase="CRECHE" />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel
          key="saidas-creche"
          header="Saídas da Creche Realizadas"
        >
          <RelatorioFaseLoteEncerrada fase="CRECHE" />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel
          key="sair-crescimento"
          header="Previsão de Saída do Crescimento"
        >
          <RelatorioPrevisaoFaseLote fase="CRESCIMENTO" />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel
          key="saidas-crescimento"
          header="Saídas do Crescimento Realizadas"
        >
          <RelatorioFaseLoteEncerrada fase="CRESCIMENTO" />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel
          key="sair-terminacao"
          header="Previsão de Saída da Terminação"
        >
          <RelatorioPrevisaoFaseLote fase="TERMINACAO" />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel
          key="saidas-terminacao"
          header="Saídas da Terminação Realizadas"
        >
          <RelatorioFaseLoteEncerrada fase="TERMINACAO" />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel key="previsao-vendas" header="Previsão de Vendas">
          <RelatorioPrevisaoVendaLeitao />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel key="vendas-realizadas" header="Vendas Realizadas">
          <RelatorioVendasRealizadas />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel key="mortes-causa" header="Mortes por Causa">
          <RelatorioMortesLotePorCausa />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ marginTop: '16px' }}>
        <Collapse.Panel key="mortes-setor" header="Mortes por Setor">
          <RelatorioMortesLotePorFase />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default RelatorioLotes;
