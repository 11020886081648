import React, { CSSProperties } from 'react';
import { Card as AntdCard } from 'antd';

export type CardProps = {
  key: React.Key;
  style: CSSProperties;
  onClick: () => void;
  children: React.ReactNode;
};

const Card: React.FC<CardProps> = (props: CardProps) => {
  return (
    <AntdCard
      key={`card-${props.key}`}
      style={{
        ...props.style,
        color: '#fff',
        height: '120px',
        borderRadius: '8px',
        cursor: 'pointer',
        padding: '0px'
      }}
      bodyStyle={{
        padding: '16px',
        height: '100%'
      }}
      onClick={props.onClick}
    >
      {props.children}
    </AntdCard>
  );
};

export default Card;
