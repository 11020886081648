import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';
import { InputParams } from 'api/models';

export type EncerrarFaseRequest = {
  matriz?: {
    peso: number;
    scoreCorporal: string;
  };
  data: string;
  peso: number;
};

const encerrarFaseLote = async (
  loteId: string,
  body: EncerrarFaseRequest
): Promise<void> => {
  const response = await api.patch<void>(`lote/${loteId}/encerrar-fase`, body);
  return response.data;
};

type Params = { loteId: string; body: EncerrarFaseRequest };

export type OutputParams = {
  mutate: UseMutateFunction<void, unknown, Params, unknown>;
  isLoading: boolean;
};

export const useEncerrarFaseLote = ({
  onSuccess,
  onError
}: InputParams<void>): OutputParams => {
  const { mutate, isLoading } = useMutation(
    (params: Params) => encerrarFaseLote(params.loteId, params.body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
