import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';

import api from 'services/api';
import { applyHttpRangeFormat } from 'api/useHttp';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';

export type ListarPrevisaoFaseLoteRequest = PageRequest & {
  fase: string;
  periodo?: RangeRequest<string>;
  numeroLote?: number;
};

export type FasePrevistaResponse = {
  id: string;
  numeroLote: number;
  idadeLote: number;
  fase: string;
  raca: string;
  saldo: number;
  data: string;
  atrasada: boolean;
};

export const listarPrevisaoFaseLote = async (
  params: ListarPrevisaoFaseLoteRequest
): Promise<PageResponse<FasePrevistaResponse>> => {
  const response = await api.get<PageResponse<FasePrevistaResponse>>(
    'relatorio/lote/fase-prevista',
    {
      params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarPrevisaoFaseLoteRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<FasePrevistaResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<PageResponse<FasePrevistaResponse>, unknown>
  >;
};

export const useListarPrevisaoFaseLote = (input: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-previsao-fase-lote', input.params],
    () => listarPrevisaoFaseLote(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading, refetch };
};
