import moment from 'moment';
import { DatePicker } from 'antd';
import { useEffect, useState } from 'react';

interface Props {
  initialValue?: [Date, Date];
  callback: (startDate: Date, endDate: Date) => void;
}

const CustomRangePicker: React.FC<Props> = ({ initialValue, callback }) => {
  const format = 'DD/MM/YYYY';
  const [value, setValue] = useState<[moment.Moment, moment.Moment]>([
    initialValue && initialValue[0] ? moment(initialValue[0]) : null,
    initialValue && initialValue[1] ? moment(initialValue[1]) : null
  ]);

  useEffect(() => {
    const startDate = value && value[0] ? value[0].toDate() : null;
    const endDate = value && value[1] ? value[1].toDate() : null;
    callback(startDate, endDate);
  }, [value]);

  return (
    <DatePicker.RangePicker
      format={format}
      style={{ width: '100%' }}
      value={[value[0], value[1]]}
      onChange={(rangeDate, formatString) => {
        if (rangeDate) {
          setValue([
            moment(formatString[0], format),
            moment(formatString[1], format)
          ]);
        } else {
          setValue([null, null]);
        }
      }}
    />
  );
};

export default CustomRangePicker;
