import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Row,
  Table,
  Tag,
  notification
} from 'antd';

import CustomRangePicker from 'components/CustomRangePicker';
import {
  firstAndLastDayOfMonth as currentMonth,
  formatToBRL,
  formatToISO
} from 'utils/utils';
import { PageRequest } from 'api/models';
import {
  ListarPrevisaoVendaLeitaoResponse,
  useListarPrevisaoVendaLeitao
} from 'api/relatorios/lotes/listarPrevisaoVendaLeitao/useListarPrevisaoVendaLeitao';
import { usePDFVendaLeitaoPrevista } from 'api/relatorios/lotes/getPDFVendaLeitaoPrevista/useGetPDFVendaLeitaoPrevista';

type SearchParams = PageRequest & {
  periodo?: [Date, Date];
};

const RelatorioPrevisaoVendaLeitao: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    periodo: [currentMonth().firstDay, currentMonth().lastDay]
  });
  const { data, isLoading } = useListarPrevisaoVendaLeitao({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFVendaLeitaoPrevista({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    },
    onSuccess: (data: Blob) => {
      JsFileDownload(data, `vendas-previstas.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            periodo: values.periodo
          });
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Venda Prevista"
              name="periodo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject();
                    if (!value[0] || !value[1]) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <CustomRangePicker
                initialValue={searchParams.periodo}
                callback={(startDate, endDate) => {
                  form.setFieldsValue({
                    periodo: [startDate, endDate]
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma venda prevista para o período!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          style={{ marginTop: '32px' }}
          dataSource={data?.items || []}
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          pagination={{
            size: 'small',
            total: data?.total || 0,
            pageSize: searchParams.perPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: true,
            onShowSizeChange: (_, pageSize) => {
              setSearchParams((prev) => ({
                ...prev,
                page: 0,
                perPage: pageSize
              }));
            },
            onChange: (page) => {
              setSearchParams((prev) => ({ ...prev, page: page - 1 }));
            }
          }}
          columns={[
            {
              title: 'Nº Lote',
              dataIndex: 'numero'
            },
            {
              title: 'Idade (dias)',
              dataIndex: 'idade'
            },
            {
              title: 'Raça',
              dataIndex: 'raca'
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo'
            },
            {
              title: 'Previsão Venda',
              render: (record: ListarPrevisaoVendaLeitaoResponse) =>
                formatToBRL(record.data)
            },
            {
              title: 'Observação',
              render: (record: ListarPrevisaoVendaLeitaoResponse) =>
                record.atrasada ? (
                  <Tag color="red">ATRASADA</Tag>
                ) : (
                  <Tag>NO PRAZO</Tag>
                )
            }
          ]}
          summary={(data) => {
            const total = data.reduce((acc, elem) => acc + elem.saldo, 0);

            return (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={6} align="left">
                    <span style={{ fontWeight: 'bold' }}>
                      Saldo Total: {total}
                    </span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default RelatorioPrevisaoVendaLeitao;
