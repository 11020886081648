import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { UseQueryPDFInput } from '../types';

export const getPDFFichaLote = async (id: string): Promise<Blob> => {
  const { data } = await api.get<Blob>(`relatorio/lote/${id}/ficha/pdf`, {
    responseType: 'blob'
  });
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFFichaLote = (
  input: UseQueryPDFInput<{ id: string }>
): OutputParams => {
  return useQuery(
    ['get-pdf-ficha-lote', input.params],
    () => getPDFFichaLote(input.params.id),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
