import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'services/api';
import { atualizarMotivosEmCache } from './actions';
import { descarteActionsType } from './actionsType';

export function* buscarMotivos() {
  const { data } = yield call(api.get, '/produtor/motivos-descarte');
  yield put(atualizarMotivosEmCache(data));
}

export default all([
  takeLatest(descarteActionsType.BUSCAR_MOTIVOS, buscarMotivos)
]);
