import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import PigDatePicker from 'components/PigDatePicker';
import { decimalRegexWithComma } from 'utils/masks';
import { calcularGPD } from 'utils/utils';
import { NamePath } from 'antd/lib/form/interface';
import { parse } from 'date-fns';

export type Params = {
  fase: string;
  faseLabel: string;
  dataInicial: string;
  dataFinal: string;
  pesoInicial: number;
  pesoFinal: number;
  qntdInicial: number;
  qntdFinal: number;
  gpd: number;
  previsao: boolean;
  setFieldValue: (name: NamePath, value: any) => void;
};

export const FaseForm: React.FC<Params> = (params) => {
  const updateGPD = (pesoFinal: string, dataFinal: Date) => {
    if (pesoFinal) {
      const gpd = calcularGPD(
        params.pesoInicial,
        parse(params.dataInicial, 'yyyy-MM-dd', new Date()),
        Number(pesoFinal.replace(',', '.')),
        dataFinal
      );

      if (gpd) {
        params.setFieldValue(
          [params.fase, 'gpd'],
          gpd.toFixed(3).replace('.', ',')
        );
      }
    } else {
      params.setFieldValue([params.fase, 'gpd'], '0,000');
    }
  };

  return (
    <Row gutter={[16, 0]} hidden={!params.dataFinal}>
      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => {
            return prev[params.fase]?.data !== next[params.fase]?.data;
          }}
        >
          {({ getFieldValue }) => (
            <Form.Item
              label={params.faseLabel + ' - Data Saída'}
              name={[params.fase, 'data']}
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                {
                  required: !!params.dataFinal,
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <PigDatePicker
                startValue={getFieldValue([params.fase, 'data'])}
                callback={(_, date) => {
                  params.setFieldValue([params.fase, 'data'], date);
                  updateGPD(getFieldValue([`${params.fase}`, 'peso']), date);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => {
            return prev[params.fase]?.peso !== next[params.fase]?.peso;
          }}
        >
          {({ getFieldValue }) => (
            <Form.Item
              label={params.faseLabel + ' - Peso Médio'}
              name={[params.fase, 'peso']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  required: !params.previsao,
                  message: 'Campo obrigatório'
                },
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input
                disabled={params.previsao}
                suffix="kgs"
                onChange={(event) => {
                  params.setFieldValue(
                    [params.fase, 'peso'],
                    event.target.value
                  );
                  updateGPD(
                    event.target.value,
                    getFieldValue([`${params.fase}`, 'data'])
                  );
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <Form.Item
          label={params.faseLabel + ' - GPD'}
          name={[params.fase, 'gpd']}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Input disabled suffix="kgs" />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <Form.Item
          label={params.faseLabel + ' - Saldo'}
          name={[params.fase, 'qntd']}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Input disabled />
        </Form.Item>
      </Col>
    </Row>
  );
};
