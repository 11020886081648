import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type ListarVendaLeitaoRealizadaRequest = PageRequest & {
  periodo?: RangeRequest<string>;
};

export type ListarVendaLeitaoRealizadaResponse = {
  id: string;
  data: string;
  numeroLote: string;
  qntd: number;
  idadeLote: number;
  pesoMedio: number;
  gpdMedio: number;
  pesoVendido: number;
  aprovFinanceiro: number;
  precoMedioKg: number;
};

export const listarVendaLeitaoRealizada = async (
  params: ListarVendaLeitaoRealizadaRequest
): Promise<PageResponse<ListarVendaLeitaoRealizadaResponse>> => {
  const response = await api.get<
    PageResponse<ListarVendaLeitaoRealizadaResponse>
  >('relatorio/lote/vendas-realizadas', {
    params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
  });
  return response.data;
};

export type InputParams = {
  params: ListarVendaLeitaoRealizadaRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarVendaLeitaoRealizadaResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<
      PageResponse<ListarVendaLeitaoRealizadaResponse>,
      unknown
    >
  >;
};

export const useListarVendaLeitaoRealizada = (
  input: InputParams
): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-venda-leitao-realizada', input.params],
    () => listarVendaLeitaoRealizada(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading, refetch };
};
