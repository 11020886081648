import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { PageRequest, PageResponse } from 'api/models';

export type ListarFornecedorRequest = PageRequest & {
  nome?: string;
  ativo?: boolean;
};

export type ListarFornecedorResponse = {
  id: string;
  nome: string;
  ativo: boolean;
};

export const listarFornecedores = async (
  params: ListarFornecedorRequest
): Promise<PageResponse<ListarFornecedorResponse>> => {
  const response = await api.get<PageResponse<ListarFornecedorResponse>>(
    'fornecedor',
    {
      params
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarFornecedorRequest;
};

export type OutputParams = {
  isLoading: boolean;
  data: PageResponse<ListarFornecedorResponse>;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<PageResponse<ListarFornecedorResponse>, unknown>
  >;
};

export const useListarFornecedores = (input: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-fornecedores', input.params],
    () => listarFornecedores(input.params)
  );
  return { data, isLoading, refetch };
};
