import JsFileDownload from 'js-file-download';
import { Button, Col, notification, Row } from 'antd';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';

import { CustomTable } from 'components';
import { applyMaskDecimalWithComma } from 'utils/masks';
import {
  useGetAlvosProducao,
  useGetAlvosProducaoPDF
} from 'api/relatorios/alvos-producao';

const AlvosProducao: React.FC = () => {
  const { data, isLoading, refetch } = useGetAlvosProducao({
    onError: () => {
      notification.error({ message: 'Erro ao buscar alvos de produção' });
    }
  });
  const { isLoading: isLoadingPDF, refetch: refetchPDF } =
    useGetAlvosProducaoPDF({
      onError: () => {
        notification.error({ message: 'Erro ao gerar PDF' });
      },
      onSuccess: (reportBytes) => {
        JsFileDownload(reportBytes, `alvos-producao.pdf`);
      }
    });

  const formatValue = (hasDecimalPlace: boolean, value: number) => {
    if (!hasDecimalPlace) return applyMaskDecimalWithComma(value, 0);
    return applyMaskDecimalWithComma(value, 2);
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="end">
        <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
          <Button
            block
            danger
            type="primary"
            htmlType="button"
            icon={<FilePdfOutlined />}
            loading={isLoadingPDF}
            disabled={isLoading}
            onClick={() => {
              refetchPDF();
            }}
          >
            PDF
          </Button>
        </Col>

        <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
          <Button
            block
            type="primary"
            htmlType="button"
            icon={<SearchOutlined />}
            loading={isLoading}
            disabled={isLoadingPDF}
            onClick={() => {
              refetch();
            }}
          >
            Buscar
          </Button>
        </Col>
      </Row>

      <CustomTable
        style={{ marginTop: '32px' }}
        data={data || []}
        isLoading={isLoading}
        hasPagination={false}
        key={'indice'}
        changePageSize={() => {
          //
        }}
        changePageNumber={() => {
          //
        }}
        columns={[
          {
            title: 'Nome',
            render: (record: any) => record.indice.descricao
          },
          {
            title: 'Valor Alvo',
            render: (record: any) => formatValue(true, record.valorAlvo)
          },
          {
            title: 'Valor Real',
            render: (record: any) => formatValue(true, record.valorReal)
          },
          {
            title: 'Análise',
            render: (record: any) => record.analise
          }
        ]}
      />
    </>
  );
};

export default AlvosProducao;
