import QueryString from 'qs';

import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { UseQueryPDFInput } from 'api/relatorios/types';
import { ListarMatrizCicloRequest } from '../listarMatrizesCiclo/useListarMatrizesCiclo';

export const getPDFMatrizesPorCiclo = async (
  params: ListarMatrizCicloRequest
): Promise<Blob> => {
  const { data } = await api.get<Blob>('relatorio/matrizes/ciclo/pdf', {
    responseType: 'blob',
    params,
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    }
  });
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFMatrizesPorCiclo = (
  input: UseQueryPDFInput<ListarMatrizCicloRequest>
): OutputParams => {
  return useQuery(
    ['get-pdf-matrizes-por-ciclo', input.params],
    () => getPDFMatrizesPorCiclo(input.params),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
