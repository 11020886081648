import { parseISO } from 'date-fns';
import { Col, Form, Input, Modal, notification, Row } from 'antd';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';

import { useCadastrarAborto } from 'api/cadastrarAborto/useCadastrarAborto';

import { countDays, formatToBRL, formatToISO } from 'utils/utils';
import { convertFormattedToNumber, integerRegex } from 'utils/masks';
import PigDatePicker from 'components/PigDatePicker';
import { defaultFormProps, FormItem } from 'components/Antd';
import SelectScoreCorporal from 'components/SelectScoreCorporal';

export interface IModalAbortoProps {
  visible: boolean;
  matrizGestante: { id: string; codigo: string; dataCobertura: string };
  onCancel: () => void;
  callback?: () => void;
}

const ModalAborto: React.FC<IModalAbortoProps> = ({
  visible,
  matrizGestante,
  onCancel,
  callback
}) => {
  const [form] = Form.useForm();
  const { mutate: cadastrarAborto, isLoading } = useCadastrarAborto({
    onSuccess: () => {
      notification.success({ message: 'Aborto cadastrado com sucesso' });
      form.resetFields();
      onCancel();
      callback();
    },
    onError: () => {
      notification.error({ message: 'Ocorreu um erro ao cadastrar o aborto' });
    }
  });

  return (
    <Modal
      open={visible}
      confirmLoading={isLoading}
      title={`Aborto - ${matrizGestante?.codigo}`}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      okText="Registrar"
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{
        icon: <SendOutlined />
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        icon: <CloseOutlined />,
        danger: true
      }}
    >
      <Form
        {...defaultFormProps}
        form={form}
        initialValues={{
          dataCobertura: matrizGestante?.dataCobertura
            ? formatToBRL(matrizGestante.dataCobertura)
            : undefined
        }}
        onFinish={(values) => {
          cadastrarAborto({
            matrizId: matrizGestante.id,
            body: {
              data: formatToISO(values.dataAborto),
              peso: convertFormattedToNumber(values.matriz.peso),
              scoreCorporal: values.matriz.scoreCorporal
            }
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <FormItem
              label="Data da Cobertura"
              name={['dataCobertura']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <PigDatePicker
                disabled
                startValue={parseISO(matrizGestante.dataCobertura)}
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label="Dias Não Produtivos"
              name={['diasNaoProdutivos']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled suffix="dias" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <FormItem
              label="Matriz - Score Corporal"
              name={['matriz', 'scoreCorporal']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectScoreCorporal allowClear />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label="Matriz - Peso"
              name={['matriz', 'peso']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: integerRegex,
                  message: 'Deve estar no formato 0'
                }
              ]}
            >
              <Input suffix="kgs" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.dataAborto !== next.dataAborto}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  required
                  name="dataAborto"
                  label="Data do Aborto"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataAborto')}
                    callback={(_, date) => {
                      form.setFieldsValue({
                        dataAborto: date,
                        diasNaoProdutivos:
                          date && matrizGestante?.dataCobertura
                            ? countDays(
                                matrizGestante.dataCobertura,
                                date.toISOString()
                              )
                            : '-'
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default ModalAborto;
