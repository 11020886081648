import axios from 'axios';
import QueryString from 'qs';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import {
  ClearOutlined,
  ManOutlined,
  SendOutlined,
  WomanOutlined
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select
} from 'antd';

import api from 'services/api';
import { SuinoForm } from 'models';
import { integerRegex } from 'utils/masks';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import SelectRacas from 'components/SelectRacas';
import PigDatePicker from 'components/PigDatePicker';
import SelectScoreCorporal from 'components/SelectScoreCorporal';
import { calcularGPD, formatToISO } from 'utils/utils';

interface IValid<T = { sexo: string; codigo: string }> {
  help: string;
  lastValue: T;
  hasFeedback: boolean;
  validateStatus: '' | 'success' | 'warning' | 'error' | 'validating';
}

const Cadastrar: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [validacao, setValidacao] = useState<IValid>({
    help: '',
    hasFeedback: false,
    validateStatus: ''
  } as IValid);

  const calcularTotalPartos = (
    partosPreSistema: number,
    partosPosSistema: number
  ) => {
    let totalPartos = 0;
    if (partosPosSistema) totalPartos += partosPosSistema;
    if (partosPreSistema) totalPartos += partosPreSistema;
    return totalPartos;
  };

  const cadastrarSuino = async (values: SuinoForm) => {
    if (!isLoading && validacao.validateStatus !== 'validating') {
      setIsLoading(true);
      try {
        const body = {
          racaId: values.racaId,
          dataNascimento: values.dataNascimento,
          dataEntradaPlantel: values.dataEntradaPlantel,
          codigoIdentificacao: values.codigoIdentificacao,
          registroPBB: values.registroPBB,
          pesoEntradaPlantel: values.pesoEntradaPlantel,
          pesoHoje: values.pesoHoje,
          granjaOrigem: values.granjaOrigem,
          apelido: values.apelido,
          pai: values.pai,
          mae: values.mae,
          scoreCorporal: values.scoreCorporal
        };

        if (values.sexo == 'MACHO') {
          await api.post('/reprodutor', body);
        } else {
          await api.post('/matriz', {
            ...body,
            partosPreSistema: values.partosPreSistema
          });
        }
        notification.success({
          message: 'Animal registrado com sucesso!'
        });
        form.resetFields();
        setValidacao({
          help: '',
          hasFeedback: false,
          validateStatus: '',
          lastValue: {
            sexo: '',
            codigo: ''
          }
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          notification.error({
            message: 'Erro ao registrar animal no plantel!',
            description: error.response.data.message
          });
        } else if (error instanceof Error) {
          notification.error({
            message: 'Erro ao registrar animal no plantel!',
            description: error.message
          });
        }
      }
      setIsLoading(false);
    }
  };

  const validarCodigoIdentificacao = async (codigo: string, sexo: string) => {
    if (
      sexo &&
      codigo &&
      (sexo !== validacao?.lastValue?.sexo ||
        codigo !== validacao?.lastValue?.codigo) &&
      validacao.validateStatus !== 'validating'
    ) {
      try {
        setValidacao({
          hasFeedback: true,
          help: 'Verificando...',
          validateStatus: 'validating',
          lastValue: {
            sexo: sexo,
            codigo: codigo
          }
        });
        const tipo = sexo === 'MACHO' ? 'reprodutor' : 'matriz';
        await api.get(`/${tipo}/verificar-codigo`, {
          params: {
            codigo: codigo
          },
          paramsSerializer: (params) => {
            return QueryString.stringify(params, { arrayFormat: 'repeat' });
          }
        });
        setValidacao({
          help: '',
          hasFeedback: true,
          validateStatus: 'success',
          lastValue: {
            sexo: sexo,
            codigo: codigo
          }
        });
      } catch (error) {
        setValidacao({
          hasFeedback: true,
          help: 'Número em uso',
          validateStatus: 'error',
          lastValue: {
            sexo: sexo,
            codigo: codigo
          }
        });
      }
    }
  };

  const onValuesChange = (changedValues: any, values: any) => {
    form.setFieldsValue({
      idade: values.dataNascimento
        ? differenceInDays(
            parseISO(new Date().toISOString()),
            parseISO(values.dataNascimento.toISOString())
          )
        : 0,
      tempoPlantel: values.dataEntradaPlantel
        ? differenceInDays(
            parseISO(new Date().toISOString()),
            parseISO(values.dataEntradaPlantel.toISOString())
          )
        : 0,
      totalPartos: calcularTotalPartos(
        values.partosPreSistema,
        values.partosPosSistema
      ),
      gpd: calcularGPD(
        0,
        values.dataNascimento
          ? parseISO(values.dataNascimento.toISOString())
          : null,
        values.pesoEntradaPlantel
          ? Number(values.pesoEntradaPlantel.replace(',', '.'))
          : null,
        values.dataEntradaPlantel
          ? parseISO(values.dataEntradaPlantel.toISOString())
          : null
      )
        .toFixed(3)
        .toString()
        .replace('.', ',')
    });
  };

  useEffect(() => {
    dispatch(updateID('suino-cadastrar'));
    dispatch(updateTitle('Cadastro de Suíno'));
  }, []);

  return (
    <Card title="Informações Básicas">
      <Form
        layout="vertical"
        colon={false}
        form={form}
        wrapperCol={{ style: { width: '100%' } }}
        onValuesChange={onValuesChange}
        onFinish={(values) => {
          cadastrarSuino({
            ...values,
            dataNascimento: formatToISO(values.dataNascimento),
            dataEntradaPlantel: formatToISO(values.dataEntradaPlantel),
            pesoEntradaPlantel: values.pesoEntradaPlantel
              ? Number(values.pesoEntradaPlantel.replace(',', '.'))
              : null,
            pesoHoje: values.pesoHoje
              ? Number(values.pesoHoje.replace(',', '.'))
              : null
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Sexo"
              name="sexo"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                placeholder="Selecionar"
                onBlur={(_) => {
                  validarCodigoIdentificacao(
                    form.getFieldValue('codigoIdentificacao'),
                    form.getFieldValue('sexo')
                  );
                }}
              >
                <Select.Option key="FEMEA" value="FEMEA">
                  FEMEA {<WomanOutlined style={{ color: 'red' }} />}
                </Select.Option>
                <Select.Option key="MACHO" value="MACHO">
                  MACHO {<ManOutlined style={{ color: 'blue' }} />}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Número/Identificação"
              name="codigoIdentificacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
              hasFeedback={validacao.hasFeedback}
              validateStatus={validacao.validateStatus}
              help={validacao.help}
            >
              <Input
                onBlur={(e) => {
                  validarCodigoIdentificacao(
                    e.currentTarget.value,
                    form.getFieldValue('sexo')
                  );
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Apelido"
              name="apelido"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Registro PBB"
              name="registroPBB"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Raça"
              name="racaId"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectRacas
                placeholder="Selecionar"
                onlyActive
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Granja Origem"
              name="granjaOrigem"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => {
                return prev.dataNascimento !== next.dataNascimento;
              }}
            >
              {({ getFieldValue, getFieldsValue }) => (
                <Form.Item
                  label="Data de Nascimento"
                  name="dataNascimento"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataNascimento')}
                    callback={(_, date) => {
                      const changed = { dataNascimento: date };
                      form.setFieldsValue(changed);
                      onValuesChange(changed, getFieldsValue());
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Idade"
              name="idade"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input suffix="dias" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.dataEntradaPlantel !== next.dataRepeticaoCio
              }
            >
              {({ getFieldValue, getFieldsValue }) => (
                <Form.Item
                  label="Data de Entrada no Plantel"
                  name="dataEntradaPlantel"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataEntradaPlantel')}
                    callback={(_, date) => {
                      const changedValue = { dataEntradaPlantel: date };
                      form.setFieldsValue(changedValue);
                      onValuesChange(changedValue, getFieldsValue());
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Tempo de Plantel"
              name="tempoPlantel"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input suffix="dias" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso de Entrada no Plantel"
              name="pesoEntradaPlantel"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: integerRegex,
                  message: 'Deve estar no formato 0'
                }
              ]}
            >
              <Input suffix="kgs" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso Hoje"
              name="pesoHoje"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  pattern: integerRegex,
                  message: 'Deve estar no formato 0'
                }
              ]}
            >
              <Input suffix="kgs" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="GPD"
              name="gpd"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input suffix="kgs" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Pai"
              name="pai"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Mãe"
              name="mae"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.sexo !== next.sexo}
          >
            {({ getFieldValue }) => {
              return (
                getFieldValue('sexo') === 'FEMEA' && (
                  <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                    <Form.Item
                      label="Partos Pré Sistema"
                      name="partosPreSistema"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <InputNumber step={1} min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Score Corporal"
              name="scoreCorporal"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectScoreCorporal
                placeholder="Selecionar"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              disabled={isLoading}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={isLoading}
              onClick={() => {
                if (!form.getFieldValue('codigoIdentificacao')) {
                  setValidacao((prev) => ({
                    ...prev,
                    hasFeedback: true,
                    help: 'Campo obrigatório',
                    validateStatus: 'error'
                  }));
                }
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Cadastrar;
