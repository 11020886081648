import { racaActionsType } from './actionsType';
import { authActionsType } from '../auth/actionsType';

const INITIAL_STATE = {
  racas: []
};

export default function racas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case racaActionsType.UPDATE:
      return { racas: action.payload.racas };

    case authActionsType.SIGN_OUT:
      return { racas: [] };

    default:
      return state;
  }
}
