import { RacaDTO } from 'models/raca';
import { ScoreCorporalOption } from './avaliacao-corporal';

export type TipoSuino = 'REPRODUTOR' | 'MATRIZ' | 'MARRA' | 'LEITOA';

export const TipoSuinoOptions = [
  { key: 'REPRODUTOR', title: 'REPRODUTOR', sexo: 'MACHO' },
  { key: 'MATRIZ', title: 'MATRIZ', sexo: 'FEMEA' },
  { key: 'MARRA', title: 'MARRÃ', sexo: 'FEMEA' },
  { key: 'LEITOA', title: 'LEITOA', sexo: 'FEMEA' }
];

export type TipoEventoZootecnico =
  | 'NASCIMENTO'
  | 'ENTRADA_PLANTEL'
  | 'COBERTURA'
  | 'ABORTO'
  | 'REPETICAO_CIO'
  | 'PARTO'
  | 'DESMAME'
  | 'DESCARTE';

export const TipoEventoZootecnicoOptions = [
  { key: 'NASCIMENTO', title: 'NASCIMENTO' },
  { key: 'ENTRADA_PLANTEL', title: 'ENTRADA PLANTEL' },
  { key: 'COBERTURA', title: 'COBERTURA' },
  { key: 'ABORTO', title: 'ABORTO' },
  { key: 'REPETICAO_CIO', title: 'REPETIÇÃO CIO' },
  { key: 'PARTO', title: 'PARTO' },
  { key: 'DESMAME', title: 'DESMAME' },
  { key: 'DESCARTE', title: 'DESCARTE' }
];

export type StatusSuino =
  | 'ATIVO'
  | 'INATIVO'
  | 'VAZIA'
  | 'GESTANTE'
  | 'LACTANTE'
  | 'DESCARTADO';

export const StatusSuinoOptions = [
  { key: 'ATIVO', title: 'ATIVO' },
  { key: 'INATIVO', title: 'INATIVO' },
  { key: 'VAZIA', title: 'VAZIA' },
  { key: 'GESTANTE', title: 'GESTANTE' },
  { key: 'LACTANTE', title: 'LACTANTE' },
  { key: 'DESCARTADO', title: 'DESCARTADO' }
];

export type MatrizCodigoDTO = {
  codigo: string;
  apelido: string;
};

export type MatrizCicloFilter = {
  partosMin?: number;
  partosMax?: number;
  codigoIdentificacao?: string;
};

export type MatrizCicloDTO = {
  codigo: string;
  dataNascimento: string;
  totalPartos: number;
  totalCoberturas: number;
  totalNascidosVivos: number;
  totalNatimortos: number;
  totalMumificados: number;
  totalDesmamados: number;
  pesoMedioNascimento: number;
  pesoMedioDesmame: number;
};

export type SuinoResumidoDTO = {
  uuid: string;
  raca: RacaDTO;
  dataNascimento: string;
  codigoIdentificacao: string;
  qntdPartosPreCadastroSistema: string;
  qntdPartosPosCadastroSistema: string;
};

export interface SuinoAtualizarForm {
  apelido: string;
  codigoIdentificacao: string;
  granjaOrigem: string;
  mae: string;
  pai: string;
  pesoHoje: number;
  pesoEntradaPlantel: number;
  qntdPartosPreCadastroSistema: number;
  racaUUID: string;
  registroPBB: string;
}

export interface SuinoForm {
  sexo?: '' | 'MACHO' | 'FEMEA';
  racaId: string;
  dataNascimento: string;
  dataEntradaPlantel: string;
  codigoIdentificacao: string;
  registroPBB: string;
  pesoEntradaPlantel: number;
  pesoHoje: number;
  granjaOrigem: string;
  apelido: string;
  mae: string;
  pai: string;
  partosPreSistema: number;
  scoreCorporal: ScoreCorporalOption;
}

export type MatrizVaziaFilter = {
  codigoIdentificacao: string;
  situacao: TipoEventoZootecnico;
};

export type MatrizVaziaDTO = {
  uuid: string;
  codigoIdentificacao: string;
  situacao: TipoEventoZootecnico;
  vaziaInicio: string;
  dataNascimento: string;
  raca: RacaDTO;
  qntdPartosPreCadastroSistema: number;
  qntdPartosPosCadastroSistema: number;
};

export type MatrizLactanteFilter = {
  codigoIdentificacao: string;
  desmamaInicio: string;
  desmamaFim: string;
};

export type MatrizLactanteDTO = {
  uuid: string;
  codigoIdentificacao: string;
  dataNascimento: string;
  qntdPartosPreCadastroSistema: number;
  qntdPartosPosCadastroSistema: number;
  numeroLote: number;
  dataParto: string;
  dataDesmama: string;
};

export type MatrizGestanteFilter = {
  codigoIdentificacao?: string;
  partoPrevistoInicio?: string;
  partoPrevistoFim?: string;
  cioPrevistoInicio?: string;
  cioPrevistoFim?: string;
};

export type MatrizGestanteDTO = {
  uuid: string;
  codigoIdentificacao: string;
  dataNascimento: string;
  tipo: TipoSuino;
  qntdPartosPreCadastroSistema: number;
  qntdPartosPosCadastroSistema: number;
  dataInicioGestacao: string;
  partoPrevisto: string;
  cioPrevisto: string;
};

export type MarraFilter = {
  codigoIdentificacao?: string;
  vacinaDose1Inicio?: string;
  vacinaDose1Fim?: string;
  vacinaDose2Inicio?: string;
  vacinaDose2Fim?: string;
};

export type MarraDTO = {
  uuid: string;
  codigoIdentificacao: string;
  tipo: TipoSuino;
  status: StatusSuino;
  dataNascimento: string;
  raca: RacaDTO;
  vacinaDose1: string;
  vacinaDose2: string;
  diasNaoProdutivos: number;
};

export type LeitoaDTO = {
  uuid: string;
  codigoIdentificacao: string;
  dataNascimento: string;
  dataPrimeiraCobertura: string;
  dataInicioGestacao: string;
  partoPrevisto: string;
  cioPrevisto: string;
  dataEntradaPlantel: string;
};

export type ServicoReprodutorDTO = {
  total: number;
  totalColeta: number;
  totalMontaNatural: number;
  codigoIdentificacao: string;
};

export type PorcentagemUsoReprodutorDTO = {
  codigoIdentificacao: string;
  totalUso: number;
  porcentagemUso: number;
};

export type MapaUsoReprodutorDTO = {
  dataUso: string;
  totalUsoPorDia: number;
  servicos: ServicoReprodutorDTO[];
};

export type FichaControleReprodutorDTO = {
  totalUso: number;
  resumoUsoPorDia: MapaUsoReprodutorDTO[];
  resumoUsoPorReprodutor: PorcentagemUsoReprodutorDTO[];
};

export type FiltroMatrizes = {
  tipo: TipoSuino[];
  codigoIdentificacao?: string;
  statusIgual?: StatusSuino[];
  statusDiferente?: StatusSuino[];
  situacao?: TipoEventoZootecnico;
};

export type SuinoFiltro = {
  racaUUID: string;
  tipo: TipoSuino[];
  situacaoSuino: TipoEventoZootecnico;
  idadeMinima: number;
  codigoIdentificacao: string;
  codigoIdentificacaoOuApelido: string;
  statusIgual: StatusSuino[];
  statusDiferente: StatusSuino[];
};
