import { AxiosError, isAxiosError } from 'axios';
import { notification } from 'antd';

export type ErrorAPI = {
  message: string;
  errors: { message: string }[];
};

export type InputParams<T> = {
  onSuccess?: (data: T) => void;
  onError?: (error: AxiosError) => void;
};

export type RangeRequest<T> = {
  inicio: T;
  fim: T;
};

export type RacaResponse = {
  id: string;
  nome: string;
};

export type PageRequest = {
  page?: number;
  perPage?: number;
};

export type PageResponse<T> = {
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
  items: T[];
};

export type OcorrenciaLoteResponse = {
  id: string;
  tipo: string;
  idade: number;
  pesoMedio: number;
  pesoTotal: number;
  qntd: number;
  data: string;
  faseOcorencia: string;
};

export const toErrorAPI = (e: AxiosError<ErrorAPI>): ErrorAPI => {
  return e.response.data;
};

export const onErrorNotification = (e: any) => {
  if (isAxiosError(e)) {
    const error = toErrorAPI(e);

    if (error?.errors && error.errors.length > 0) {
      notification.error({ message: error.errors[0].message });
    } else {
      notification.error({ message: error.message });
    }
  } else if (e instanceof Error) {
    notification.error({ message: e.message });
  }
};
