import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button, Form, Modal, notification } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

import { IPagination } from 'models';
import { CustomTable, CustomInput } from 'components';
import { CustomSelect } from 'components/shared/CustomSelect';
import { ListarRacaResponse, useUpdateRaca } from 'api/racas';
import { buscarTodasRacas } from 'store/modules/racas/actions';

const EditarRaca: React.FC<{
  raca: ListarRacaResponse;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (error: any) => void;
}> = ({ raca, open, onSuccess, onError, onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<ListarRacaResponse>();
  const { isLoading, fetchData } = useUpdateRaca({
    id: raca?.id,
    onSuccess: () => {
      dispatch(buscarTodasRacas());
      form.resetFields();
      onSuccess();
      onClose();
    },
    onError
  });

  return (
    <Modal
      open={open}
      title="Editar Raça"
      okText="Salvar"
      onOk={() => form.submit()}
      okButtonProps={{
        icon: <SaveOutlined />
      }}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        danger: true,
        icon: <CloseOutlined />
      }}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        colon={false}
        layout="vertical"
        initialValues={{
          nome: raca?.nome || undefined,
          ativa: String(raca?.ativa) || undefined
        }}
        onFinish={(values) => {
          fetchData({ body: { nome: values.nome, ativa: values.ativa } });
        }}
      >
        <Form.Item
          label="Nome"
          name="nome"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <CustomInput />
        </Form.Item>

        <Form.Item
          label="Status"
          name="ativa"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <CustomSelect
            options={[
              { value: 'true', label: 'ATIVA' },
              { value: 'false', label: 'INATIVA' }
            ]}
          />
        </Form.Item>
      </Form>

      <Alert
        showIcon
        type="warning"
        description="As raças inativas não ficam disponíveis para seleção nos cadastros."
      />
    </Modal>
  );
};

type Props = {
  isLoading: boolean;
  data: ListarRacaResponse[];
  changePageNumber: (page: number) => void;
  changePageSize: (current: number, size: number) => void;
  pagination: IPagination;
  onSaveCallback: () => void;
};

export const Table = ({
  isLoading,
  data,
  changePageNumber,
  changePageSize,
  pagination,
  onSaveCallback
}: Props): any => {
  const [editarRaca, setEditarRaca] = useState({
    open: false,
    raca: undefined
  });

  const columns = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      width: '40%'
    },
    {
      title: 'Status',
      key: 'ativa',
      dataIndex: 'ativa',
      width: '40%',
      render: (value: boolean) => (value ? 'ATIVA' : 'INATIVA')
    },
    {
      title: 'Editar',
      key: 'acao',
      dataIndex: '',
      width: '20%',
      render: (
        value: string | null | undefined,
        record: ListarRacaResponse
      ) => (
        <Button
          id={`edit-button-${record.id}`}
          size="small"
          shape="round"
          htmlType="button"
          icon={<EditOutlined />}
          onClick={() => {
            setEditarRaca({ open: true, raca: record });
          }}
        />
      )
    }
  ];

  return (
    <>
      {editarRaca?.open && (
        <EditarRaca
          open={editarRaca.open}
          raca={editarRaca.raca}
          onClose={() => {
            setEditarRaca({ open: false, raca: undefined });
          }}
          onSuccess={() => {
            notification.success({ message: 'Raça editada com sucesso' });
            onSaveCallback();
          }}
          onError={(error) => {
            notification.error({ message: 'Erro ao editar raça' });
          }}
        />
      )}

      <CustomTable
        data={data}
        hasPagination
        columns={columns}
        isLoading={isLoading}
        emptyMsg="Nenhuma raça encontrada"
        pagination={pagination}
        changePageSize={changePageSize}
        changePageNumber={changePageNumber}
      />
    </>
  );
};
