import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { OcorrenciaLoteResponse } from 'api/models';

export type MorteLeitaoResponse = OcorrenciaLoteResponse & {
  motivo: string;
  observacao: string;
};

export const getMortesByLoteId = async (
  loteId: string
): Promise<MorteLeitaoResponse[]> => {
  const response = await api.get<MorteLeitaoResponse[]>(
    `lote/${loteId}/descarte`
  );
  return response.data;
};

export type InputParams = {
  id: string;
  onSuccess?: (mortes: MorteLeitaoResponse[]) => void;
  onError?: () => void;
};

export type OutputParams = {
  data: MorteLeitaoResponse[];
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<MorteLeitaoResponse[], unknown>>;
};

export const useGetMortesByLoteId = ({
  id,
  onError,
  onSuccess
}: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['get-mortes-by-lote-id', id],
    () => getMortesByLoteId(id),
    {
      onError,
      onSuccess
    }
  );
  return { data, isLoading, refetch };
};
