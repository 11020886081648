import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ClearOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select
} from 'antd';

import api from 'services/api';
import { decimalRegexWithComma } from 'utils/masks';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import ModalEstoques from './ModalEstoques';
import PigDatePicker from 'components/PigDatePicker';

interface ISaida {
  doses: number;
  bisnagaId: string;
  dataEvento: string;
  tipoEvento: 'VENDA' | 'DESCARTE';
  aproveitamentoFinanceiro: string;
}

const Saida: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchReprodutor, setSearchReprodutor] = useState(false);

  const cadastrarSaida = async (values: ISaida) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await api.post(`/estoque-semen/${values.bisnagaId}/saida`, {
          ...values,
          aproveitamentoFinanceiro: values.aproveitamentoFinanceiro
            ? values.aproveitamentoFinanceiro.replace(',', '.')
            : null
        });
        form.resetFields();
        notification.success({
          message: `Operação registrada com sucesso!`
        });
      } catch (error) {
        notification.error({
          message: 'Ocorreu um erro ao registrar a operação!'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(updateID('estoque-semen-saida'));
    dispatch(updateTitle('Estoque Sêmen - Venda/Descarte'));
  }, []);

  return (
    <Card>
      <ModalEstoques
        visible={searchReprodutor}
        onOK={(item) => {
          form.setFieldsValue({
            doses: 0,
            bisnagaId: item.id,
            estoqueSaldo: item.saldo,
            estoqueNome:
              item.doador.racaDoador.nome + ' - ' + item.doador.codigoDoador
          });
          setSearchReprodutor(false);
        }}
        onCancel={() => {
          setSearchReprodutor(false);
        }}
      />

      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        onValuesChange={(changedValues) => {
          if (changedValues.tipoEvento) {
            form.setFieldsValue({
              aproveitamentoFinanceiro: undefined
            });
          }
        }}
        onFinish={(values) => {
          cadastrarSaida(values);
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Tipo da Operação"
              name="tipoEvento"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select placeholder="Selecionar" allowClear>
                <Select.Option key="VENDA" value="VENDA">
                  Venda
                </Select.Option>
                <Select.Option key="DESCARTE" value="DESCARTE">
                  Descarte
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Estoque"
              name="estoqueNome"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.Search
                disabled
                placeholder="Buscar estoque"
                enterButton={
                  <Button type="primary" icon={<SearchOutlined />} />
                }
                onSearch={() => {
                  setSearchReprodutor(true);
                }}
              />
            </Form.Item>
          </Col>

          <Form.Item noStyle name="bisnagaId" hidden>
            <Input type="hidden" disabled />
          </Form.Item>

          <Form.Item noStyle name="estoqueSaldo" hidden>
            <Input type="hidden" disabled />
          </Form.Item>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.dataEvento !== next.dataEvento}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="dataEvento"
                  label="Data da Operação"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataEvento')}
                    callback={(_, date) => {
                      form.setFieldsValue({ dataEvento: date });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.tipoEvento !== next.tipoEvento}
          >
            {({ getFieldValue }) => {
              const estoqueSaldo = getFieldValue('estoqueSaldo');
              const saldo =
                estoqueSaldo !== undefined && estoqueSaldo !== null
                  ? estoqueSaldo
                  : Number.MAX_VALUE;
              return (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Quantidade de Doses"
                    name="doses"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={[
                      { required: true, message: 'Campo obrigatório' },
                      {
                        type: 'number',
                        min: 1,
                        message: 'Deve ser no mínimo 1'
                      },
                      {
                        type: 'number',
                        max: saldo,
                        message: `Sem saldo, a bisnaga tem ${saldo} dose(s)`
                      }
                    ]}
                  >
                    <InputNumber step={1} min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.tipoEvento !== next.tipoEvento}
          >
            {({ getFieldValue }) =>
              getFieldValue('tipoEvento') === 'VENDA' && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Aproveitamento Financeiro"
                    name="aproveitamentoFinanceiro"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={[
                      {
                        pattern: decimalRegexWithComma,
                        message: 'Deve estar no formato 0,00'
                      }
                    ]}
                  >
                    <Input prefix="R$" />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>
        </Row>

        <Row gutter={[16, 16]} justify="end" style={{ marginTop: '16px' }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              disabled={isLoading}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={isLoading}
              onClick={() => {
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Saida;
