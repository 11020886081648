import api from 'services/api';
import { useMutation } from 'react-query';

export type EditarReprodutorRequest = {
  racaId: string;
  codigo: string;
  dataNascimento: string;
  dataEntradaPlantel: string;
  registroPBB: string;
  pesoEntradaPlantel: number;
  pesoHoje: number;
  granjaOrigem: string;
  apelido: string;
  pai: string;
  mae: string;
};

const editarReprodutor = async (
  id: string,
  body: EditarReprodutorRequest
): Promise<void> => {
  const response = await api.put(`reprodutor/${id}`, body);
  return response.data;
};

export type InputParams = {
  onSuccess: () => void;
  onError: (error: unknown) => void;
};

export const useEditarReprodutor = ({ onSuccess, onError }: InputParams) => {
  return useMutation(
    (params: { id: string; body: EditarReprodutorRequest }) =>
      editarReprodutor(params.id, params.body),
    {
      onSuccess,
      onError
    }
  );
};
