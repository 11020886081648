import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';
import { InputParams } from 'api/models';

export type CadastrarAdocaoRequest = {
  data: string;
  maeAdotivaId: string;
  maeBiologica: {
    peso: number;
    scoreCorporal: string;
  };
};

const cadastrarAdocaoLote = async (
  loteId: string,
  body: CadastrarAdocaoRequest
): Promise<void> => {
  const response = await api.post<void>(`lote/${loteId}/adocao`, body);
  return response.data;
};

export type Params = { loteId: string; body: CadastrarAdocaoRequest };

export type OutputParams = {
  mutate: UseMutateFunction<void, unknown, Params, unknown>;
  isLoading: boolean;
};

export const useCadastrarAdocaoLote = ({
  onSuccess,
  onError
}: InputParams<void>): OutputParams => {
  const { mutate, isLoading } = useMutation(
    (params: Params) => cadastrarAdocaoLote(params.loteId, params.body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
