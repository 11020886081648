import { UseMutateFunction, useMutation } from 'react-query';
import api from 'services/api';
import { InputParams } from 'api/models';

export type CadastrarRepeticaoCioRequest = {
  data: string;
  peso: number;
  scoreCorporal: string;
};

const cadastrarRepeticaoCio = async (
  matrizId: string,
  body: CadastrarRepeticaoCioRequest
): Promise<void> => {
  const response = await api.put<void>(`matriz/${matrizId}/repetir-cio`, body);
  return response.data;
};

type Variables = {
  matrizId: string;
  body: CadastrarRepeticaoCioRequest;
};

export type OutputParams = {
  mutate: UseMutateFunction<void, unknown, Variables, unknown>;
  isLoading: boolean;
};

export const useCadastrarRepeticaoCio = ({
  onSuccess,
  onError
}: InputParams<void>): OutputParams => {
  const { mutate, isLoading } = useMutation(
    (params: Variables) => cadastrarRepeticaoCio(params.matrizId, params.body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
