import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Table,
  notification
} from 'antd';
import { PageRequest } from 'api/models';
import { defaultFormProps } from 'components/Antd';
import { mapperTagUltimoEvento } from 'utils/utils';
import {
  TodasMatrizesResponse,
  useListarTodasMatrizes
} from 'api/relatorios/matrizes/listarTodasMatrizes/useListarTodasMatrizes';
import { usePDFMatrizesVazias } from 'api/relatorios/matrizes/getPDFMatrizesVazias/useGetPDFMatrizesVazias';

type SearchParams = PageRequest & {
  codigo?: string;
  situacao?: string[];
};

const RelatorioMatrizesVazias: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    codigo: null,
    situacao: null
  });
  const { data, isLoading } = useListarTodasMatrizes({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      situacao: searchParams.situacao,
      status: ['VAZIA']
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFMatrizesVazias({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      situacao: searchParams.situacao,
      status: ['VAZIA']
    },
    onSuccess: (data) => {
      JsFileDownload(data, `matrizes-vazias.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        form={form}
        {...defaultFormProps}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            situacao: values.situacao
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Matriz"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Situação"
              name="situacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Select placeholder="Selecionar" allowClear>
                <Select.Option key="ABORTO" value="ABORTO">
                  ABORTO
                </Select.Option>
                <Select.Option key="REPETICAO_CIO" value="REPETICAO_CIO">
                  REPETIÇÃO CIO
                </Select.Option>
                <Select.Option key="DESMAME" value="DESMAME">
                  DESMAME
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma matriz vazia encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          rowKey={'id'}
          style={{ marginTop: '32px' }}
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          dataSource={data?.pages.flatMap((p) => p.items) || []}
          pagination={{
            size: 'small',
            total: data?.pages[0].total || 0,
            pageSize: searchParams.perPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: true,
            onShowSizeChange: (_, pageSize) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: 0,
                perPage: pageSize
              }));
            },
            onChange: (page) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
          columns={[
            {
              title: 'Nº Matriz',
              dataIndex: 'codigo'
            },
            {
              title: 'Situação',
              render: (record: TodasMatrizesResponse) =>
                mapperTagUltimoEvento(record.situacao)
            },
            {
              title: 'Vazia à (dias)',
              dataIndex: 'tempoVazia'
            },
            {
              title: 'Idade (dias)',
              dataIndex: 'idade'
            },
            {
              title: 'Raça',
              render: (record: TodasMatrizesResponse) => record.raca
            },
            {
              title: 'Partos',
              dataIndex: 'partos'
            }
          ]}
        />
      </ConfigProvider>
    </>
  );
};

export default RelatorioMatrizesVazias;
