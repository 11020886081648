import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { ClearOutlined, SendOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select
} from 'antd';

import { LoteForm } from 'models';
import { decimalRegexWithComma } from 'utils/masks';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import SelectRacas from 'components/SelectRacas';
import PigDatePicker from 'components/PigDatePicker';
import { cadastrarLoteExterno } from 'api/cadastrarLoteExterno/useCadastrarLoteExterno';

const LoteCadastro: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const atualizarTotalNascidos = (
    vivos: number,
    natimortos: number,
    mumificados: number
  ) => {
    let total = 0;

    if (vivos) total += vivos;
    if (natimortos) total += natimortos;
    if (mumificados) total += mumificados;

    form.setFieldsValue({
      totalNascidos: total
    });
  };

  const cadastrarLote = async (values: LoteForm) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        console.log(values);
        const data = await cadastrarLoteExterno({
          racaId: values.racaUUID,
          nascimento: {
            data: values.dataNascimento,
            peso: Number(values.pesoMedioNascimento.replace(',', '.')),
            vivos: values.qntdNascidosVivos,
            natimortos: values.qntdNascidosNatimortos,
            mumificados: values.qntdNascidosMumificados
          },
          codigoMae: values.maeCodigo,
          maeRacaId: values.maeRacaUUID,
          codigoPai: values.paiCodigo,
          paiRacaId: values.paiRacaUUID
        });
        form.resetFields();
        notification.success({
          message: 'Lote registrado com sucesso!',
          description: `Foi gerado um lote com Nº ${data.numero}`
        });
      } catch (error) {
        notification.error({
          message: 'Erro ao registrar lote no plantel!'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(updateID('lotes-cadastrar-lote-externo'));
    dispatch(updateTitle('Cadastro de Lote'));
  }, []);

  return (
    <Card title="Informações Básicas">
      <Form
        layout="vertical"
        colon={false}
        form={form}
        initialValues={{ tipo: 'LOTE_EXTERNO' }}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          cadastrarLote({
            ...values,
            dataNascimento: values.dataNascimento.toISOString()
          });
        }}
        onValuesChange={(changedValues, values) => {
          atualizarTotalNascidos(
            values.qntdNascidosVivos,
            values.qntdNascidosNatimortos,
            values.qntdNascidosMumificados
          );
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Tipo do Lote"
              name="tipo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Select placeholder="Selecionar" disabled>
                <Select.Option key="LOTE_EXTERNO" value="LOTE_EXTERNO">
                  Lote Externo
                </Select.Option>
                <Select.Option key="LOTE_INTERNO" value="LOTE_INTERNO">
                  Lote Interno
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Raça"
              name="racaUUID"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectRacas placeholder="Selecionar" allowClear onlyActive />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.dataNascimento !== next.dataNascimento
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Data de Nascimento"
                  name="dataNascimento"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataNascimento')}
                    callback={(_, date) => {
                      form.setFieldsValue({
                        dataNascimento: date,
                        idade: date ? differenceInDays(new Date(), date) : null
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Idade"
              name="idade"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled suffix="dias" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso Médio de Nascimento"
              name="pesoMedioNascimento"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input suffix="kgs" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              required
              name="qntdNascidosVivos"
              label="Qntd Vivos"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={0} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              required
              name="qntdNascidosNatimortos"
              label="Qntd Natimortos"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={0} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              required
              name="qntdNascidosMumificados"
              label="Qntd Mumificados"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={0} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name="totalNascidos"
              label="Total Nascidos"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Deve ser maior que 0' },
                { type: 'number', min: 1, message: 'Deve ser maior que 0' }
              ]}
            >
              <InputNumber disabled style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name="maeCodigo"
              label="Nº Mãe"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name="maeRacaUUID"
              label="Raça da Mãe"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <SelectRacas placeholder="Selecionar" allowClear onlyActive />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name="paiCodigo"
              label="Nº Pai"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name="paiRacaUUID"
              label="Raça do Pai"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <SelectRacas placeholder="Selecionar" allowClear onlyActive />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={isLoading}
              onClick={() => {
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default LoteCadastro;
