import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { applyHttpRangeFormat } from 'api/useHttp';
import { UseQueryPDFInput } from 'api/relatorios/types';
import { TotalRacaoRequest } from '../getTotalRacao/useGetTotalRacao';

export const getPDFTotalRacao = async (
  params: TotalRacaoRequest
): Promise<Blob> => {
  const { data } = await api.get<Blob>('relatorio/racao/total/pdf', {
    responseType: 'blob',
    params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
  });
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFTotalRacao = (
  input: UseQueryPDFInput<TotalRacaoRequest>
): OutputParams => {
  return useQuery(
    ['get-pdf-total-racao', input.params],
    () => getPDFTotalRacao(input.params),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
