import React, { useEffect, useState } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import {
  SendOutlined,
  ClearOutlined,
  LoadingOutlined,
  DeleteFilled
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Table
} from 'antd';

import { fasesLote } from 'models';
import { onErrorNotification } from 'api/models';
import {
  applyMaskDecimalWithComma,
  convertFormattedToNumber,
  decimalRegexWithComma
} from 'utils/masks';

import PigDatePicker from 'components/PigDatePicker';
import { formatToBRL, formatToISO } from 'utils/utils';
import { useExcluirOcorrencia } from 'api/excluirOcorrencia/excluirOcorrencia';
import { VendaLeitaoResponse } from 'api/getVendasByLoteId/useGetVendasByLoteId';
import { useCadastrarVendaLeitao } from 'api/cadastrarVendaLeitao/useCadastrarVendaLeitao';

interface IProps {
  lote: {
    id: string;
    dataNascimento: string;
    previsaoVenda: string;
    saldo: number;
  };
  vendas: VendaLeitaoResponse[];
  onSubmitCallback: () => void;
}

const Venda: React.FC<IProps> = ({ lote, vendas, onSubmitCallback }) => {
  const [form] = Form.useForm();
  const isDisabled = lote?.saldo === 0 || false;
  const [openModal, setOpenModal] = useState(false);
  const { mutate: excluirOcorrencia, isLoading: isLoadingExclusao } =
    useExcluirOcorrencia({
      onSuccess: (response) => {
        notification.success({ message: 'Venda excluída com sucesso!' });
        onSubmitCallback();
        form.resetFields();
        setOpenModal(false);
      },
      onError: (error) => {
        onErrorNotification(error);
      }
    });
  const { mutate: cadastrarVenda, isLoading } = useCadastrarVendaLeitao({
    onSuccess: (response) => {
      notification.success({ message: 'Venda registrada com sucesso!' });
      onSubmitCallback();
      form.resetFields();
    },
    onError: (error) => {
      onErrorNotification(error);
    }
  });
  const columns = [
    {
      title: 'Data',
      render: (value: VendaLeitaoResponse) => formatToBRL(value.data)
    },
    {
      title: 'Fase',
      render: (value: VendaLeitaoResponse) =>
        fasesLote.find((causa) => causa.key === value.faseOcorencia)?.value ||
        '-'
    },
    {
      title: 'Qntd',
      render: (value: VendaLeitaoResponse) => value.qntd
    },
    {
      title: 'Idade (dias)',
      render: (value: VendaLeitaoResponse) => value.idade
    },
    {
      title: 'Peso Médio (Kg)',
      render: (value: VendaLeitaoResponse) =>
        applyMaskDecimalWithComma(value.pesoMedio, 2)
    },
    {
      title: 'GPD (Kg)',
      render: (value: VendaLeitaoResponse) => {
        return applyMaskDecimalWithComma(value.gpd, 3);
      }
    },
    {
      title: 'Peso Total (Kg)',
      render: (value: VendaLeitaoResponse) =>
        applyMaskDecimalWithComma(value.pesoTotal, 2)
    },
    {
      title: 'Aprov. (R$)',
      render: (value: VendaLeitaoResponse) =>
        applyMaskDecimalWithComma(value.aprovFinanceiro, 2)
    },
    {
      title: 'R$/Kg',
      render: (value: VendaLeitaoResponse) =>
        applyMaskDecimalWithComma(value.precoMedioKg, 2)
    },
    {
      title: 'Excluir',
      render: function excluir(value: VendaLeitaoResponse) {
        return (
          <>
            <Button
              shape="circle"
              size="small"
              danger
              icon={<DeleteFilled />}
              onClick={() => setOpenModal(true)}
            />
            <Modal
              title="Excluir venda?"
              okType="danger"
              open={openModal}
              okText="Excluir"
              onOk={() =>
                excluirOcorrencia({ loteId: lote.id, ocorrenciaId: value.id })
              }
              onCancel={() => setOpenModal(false)}
              confirmLoading={isLoading || isLoadingExclusao}
            >
              <span>
                Ao excluir a venda, os animais vendidos serão devolvidos ao
                saldo do lote.
              </span>
            </Modal>
          </>
        );
      }
    }
  ];
  const summary = (data: readonly VendaLeitaoResponse[]) => {
    let aprovFinanceiro = 0;
    let totalAnimais = 0;
    let pesoTotal = 0;
    let gpdMedio = 0;
    let idadeMedia = 0;

    data.forEach((value) => {
      aprovFinanceiro += value.aprovFinanceiro || 0;
      totalAnimais += value.qntd || 0;
      pesoTotal += value.pesoTotal || 0;
      idadeMedia += value.idade * value.qntd || 0;
      gpdMedio += value.gpd * value.qntd || 0;
    });

    idadeMedia = totalAnimais === 0 ? 0 : idadeMedia / totalAnimais;
    gpdMedio = totalAnimais === 0 ? 0 : gpdMedio / totalAnimais;
    const pesoMedioKg = totalAnimais === 0 ? 0 : pesoTotal / totalAnimais;
    const precoMedioKg =
      aprovFinanceiro === 0 ? 0 : aprovFinanceiro / pesoTotal;

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={2} align="left">
            <span style={{ fontWeight: 'bold' }}>Resumo: </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {applyMaskDecimalWithComma(totalAnimais, 0)}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {applyMaskDecimalWithComma(idadeMedia, 0)}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {applyMaskDecimalWithComma(pesoMedioKg, 2)}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {applyMaskDecimalWithComma(gpdMedio, 3)}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {applyMaskDecimalWithComma(pesoTotal, 2)}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {applyMaskDecimalWithComma(aprovFinanceiro, 2)}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {applyMaskDecimalWithComma(precoMedioKg, 2)}
            </span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const calcularPesoTotalDescartado = (qntd: number, pesoMedio: number) => {
    if (!qntd || !pesoMedio) return 0;

    return qntd * pesoMedio;
  };

  const calcularPrecoMedioKg = (aprovFinanceiro: number, pesoTotal: number) => {
    if (!aprovFinanceiro || !pesoTotal) return 0;

    return aprovFinanceiro / pesoTotal;
  };

  const calcularGpdVenda = (
    dataNascimento: Date,
    dataVenda: Date,
    pesoMedioVenda: number
  ) => {
    if (!dataNascimento || !dataVenda || !pesoMedioVenda) return 0;

    const gpd =
      pesoMedioVenda /
      differenceInDays(
        parseISO(dataVenda.toISOString()),
        parseISO(dataNascimento.toISOString())
      );

    return gpd;
  };

  const onValuesChange = (changedValues: any, values: any) => {
    if (changedValues.dataVenda || changedValues.pesoMedio) {
      const gpd = calcularGpdVenda(
        parseISO(lote.dataNascimento),
        values.dataVenda,
        values.pesoMedio ? Number(values.pesoMedio.replace(',', '.')) : 0
      );
      form.setFieldsValue({
        gpdVenda: applyMaskDecimalWithComma(gpd, 3)
      });
    }

    if (changedValues.qntd || changedValues.pesoMedio) {
      const pesoVendido = calcularPesoTotalDescartado(
        values.qntd,
        values.pesoMedio ? Number(values.pesoMedio.replace(',', '.')) : 0
      );
      form.setFieldsValue({
        pesoTotal: applyMaskDecimalWithComma(pesoVendido, 2)
      });
    }

    if (changedValues.pesoTotal || changedValues.aprovFinanceiro) {
      const precoMedioKg = calcularPrecoMedioKg(
        convertFormattedToNumber(values.aprovFinanceiro),
        convertFormattedToNumber(values.pesoTotal)
      );
      form.setFieldsValue({
        precoMedioKg: applyMaskDecimalWithComma(precoMedioKg, 2)
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      saldo: lote?.saldo || 0,
      previsaoVenda: parseISO(lote.previsaoVenda)
    });
  }, [lote.saldo, lote.previsaoVenda]);

  return (
    <>
      <Form
        layout="vertical"
        colon={false}
        form={form}
        wrapperCol={{ style: { width: '100%' } }}
        onValuesChange={onValuesChange}
        onFinish={(values) => {
          cadastrarVenda({
            loteId: lote.id,
            body: {
              data: formatToISO(values.dataVenda),
              aprovFinanceiro: convertFormattedToNumber(values.aprovFinanceiro),
              observacao: values.observacao,
              pesoMedio: convertFormattedToNumber(values.pesoMedio),
              qntd: values.qntd
            }
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Saldo Hoje"
              name="saldo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.previsaoVenda !== next.previsaoVenda
              }
            >
              {({ getFieldValue, getFieldsValue }) => (
                <Form.Item
                  label="Previsão de Venda"
                  name="previsaoVenda"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <PigDatePicker
                    disabled
                    startValue={getFieldValue('previsaoVenda')}
                    callback={(_, date) => {
                      const changed = { previsaoVenda: date };
                      form.setFieldsValue(changed);
                      onValuesChange(changed, getFieldsValue());
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.dataVenda !== next.dataVenda}
            >
              {({ getFieldValue, getFieldsValue }) => (
                <Form.Item
                  label="Data da Venda"
                  name="dataVenda"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    disabled={isDisabled}
                    startValue={getFieldValue('dataVenda')}
                    callback={(_, date) => {
                      const changed = { dataVenda: date };
                      form.setFieldsValue(changed);
                      onValuesChange(changed, getFieldsValue());
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Qntd Animais"
              name="qntd"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 1, message: 'Não deve ser menor que 1' },
                {
                  type: 'number',
                  max: lote.saldo,
                  message: 'Sem saldo suficiente'
                }
              ]}
            >
              <InputNumber disabled={isDisabled} style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso Médio/Animal"
              name="pesoMedio"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input suffix="kgs" disabled={isDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso Total Vendido"
              name="pesoTotal"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input suffix="kgs" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="GPD Venda"
              name="gpdVenda"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input suffix="Kgs" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Valor da Venda"
              name="aprovFinanceiro"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input prefix="R$" disabled={isDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Valor/Kg"
              name="precoMedioKg"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input suffix="R$/Kg" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Observação"
              name="observacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input.TextArea
                rows={1}
                showCount
                maxLength={250}
                style={{ width: '100%' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end" style={{ marginTop: '32px' }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              disabled={isLoading || isDisabled || isLoadingExclusao}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={isLoading}
              disabled={isDisabled}
              onClick={() => {
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider orientation="left" style={{ marginTop: '32px' }}>
        Histórico das Vendas
      </Divider>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma venda encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          dataSource={vendas}
          pagination={{
            size: 'small',
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: true
          }}
          columns={columns}
          summary={summary}
        />
      </ConfigProvider>
    </>
  );
};

export default Venda;
