import { useMemo } from 'react';

import { PageRequest, PageResponse } from 'api/models';
import { GetMapaUsoParams, MapaUsoResponse } from '../types';
import { BaseHttpParams, applyHttpRangeFormat, useHttp } from 'api/useHttp';

type R = PageResponse<MapaUsoResponse>;
type Q = PageRequest & { periodo: string };

type InputParams = BaseHttpParams<R> & { queryParams: GetMapaUsoParams };

export function useGetMapaUso(params: InputParams) {
  const getParams = useMemo(() => {
    return {
      ...params.queryParams,
      periodo: applyHttpRangeFormat(params.queryParams.periodo),
      codigoIn: params?.queryParams?.codigoIn
    };
  }, [JSON.stringify(params)]);

  return useHttp<void, Q, R>({
    method: 'GET',
    url: 'relatorio/reprodutores/mapa-uso',
    queryParams: getParams
  });
}
