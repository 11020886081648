import QueryString from 'qs';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import api from 'services/api';
import { PageRequest, PageResponse, RacaResponse } from 'api/models';

export type ListarLoteRequest = PageRequest & {
  tipoIn?: string[];
  idNotIn?: string[];
  numeroIn?: number[];
  faseIn?: string[];
  faseNotIn?: string[];
};

export type ListarLoteResponse = {
  id: string;
  numero: number;
  idade: number;
  tipo: string;
  fase: string;
  raca: RacaResponse;
  saldo: number;
};

export const listarLotes = async (
  params: ListarLoteRequest
): Promise<PageResponse<ListarLoteResponse>> => {
  const response = await api.get<PageResponse<ListarLoteResponse>>('lote', {
    params,
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    }
  });
  return response.data;
};

export type InputParams = {
  params: ListarLoteRequest;
};

export type OutputParams = {
  data: PageResponse<ListarLoteResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<PageResponse<ListarLoteResponse>, unknown>>;
};

export const useListarLotes = (input: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-lotes', input.params],
    () => listarLotes(input.params),
    {
      keepPreviousData: true
    }
  );
  return { data, isLoading, refetch };
};
