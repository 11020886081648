import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';

export type ResultadoAnualRequest = {
  ano: number;
};

export type ResultadoAnualResponse = {
  ano: number;
  indice: string;
  indiceEnum: string;
  hasDecimalPlace: boolean;
  janeiro: number;
  fevereiro: number;
  marco: number;
  abril: number;
  maio: number;
  junho: number;
  julho: number;
  agosto: number;
  setembro: number;
  outubro: number;
  novembro: number;
  dezembro: number;
};

export const getResultadoAnual = async (
  params: ResultadoAnualRequest
): Promise<ResultadoAnualResponse[]> => {
  const response = await api.get<ResultadoAnualResponse[]>(
    'relatorio/resultado-geral',
    {
      params
    }
  );
  return response.data;
};

export type InputParams = {
  params: ResultadoAnualRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: ResultadoAnualResponse[];
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<ResultadoAnualResponse[], unknown>>;
};

export const useGetResultadoAnual = ({
  params,
  onError
}: InputParams): OutputParams => {
  return useQuery(
    ['get-resultado-geral-anual', params],
    () => getResultadoAnual(params),
    {
      onError
    }
  );
};
