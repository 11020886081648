import { EnumResponse } from 'models';
import { descarteActionsType } from './actionsType';

export function buscarMotivos(): any {
  return {
    type: descarteActionsType.BUSCAR_MOTIVOS
  };
}

export function atualizarMotivosEmCache(motivos: EnumResponse[]): any {
  const outrasCausas = motivos.filter(
    (motivo) => motivo.key === 'OUTRAS_CAUSAS'
  );
  const arrayAtualizado = motivos.filter(
    (motivo) => motivo.key !== 'OUTRAS_CAUSAS'
  );

  return {
    type: descarteActionsType.ATUALIZAR_MOTIVOS_EM_CACHE,
    payload: { motivos: [...arrayAtualizado, ...outrasCausas] }
  };
}
