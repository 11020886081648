import { useQuery } from 'react-query';

import api from 'services/api';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type ListarLactanteRequest = PageRequest & {
  codigo?: string;
  desmame?: RangeRequest<string>;
};

export type ListarLactanteResponse = {
  id: string;
  codigo: string;
  idade: number;
  partos: number;
  inicio: string;
  fim: string;
  diasLactantes: number;
  numeroLote: number;
  observacao: string;
};

export const listarLactantes = async (
  params: ListarLactanteRequest
): Promise<PageResponse<ListarLactanteResponse>> => {
  const response = await api.get<PageResponse<ListarLactanteResponse>>(
    'relatorio/matrizes/lactantes',
    {
      params: { ...params, desmame: applyHttpRangeFormat(params.desmame) }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarLactanteRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarLactanteResponse>;
  isLoading: boolean;
};

export const useListarLactantes = (input: InputParams): OutputParams => {
  const { data, isLoading } = useQuery(
    ['listar-lactantes', input.params],
    () => listarLactantes(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading };
};
