import {
  CoberturaDTO,
  RacaDTO,
  ReproducaoDTO,
  ScoreCorporalOption
} from 'models';
import { MotivoDescarte, EnumResponse } from './descarte';

export type StatusLote =
  | 'SELECIONAR'
  | 'MATERNIDADE'
  | 'CRECHE'
  | 'CRESCIMENTO'
  | 'TERMINACAO'
  | 'SEM_SALDO';

export const fasesLote = [
  { key: 'MATERNIDADE', value: 'MATERNIDADE' },
  { key: 'CRECHE', value: 'CRECHE' },
  { key: 'CRESCIMENTO', value: 'CRESCIMENTO' },
  { key: 'TERMINACAO', value: 'TERMINAÇÃO' },
  { key: 'SEM_SALDO', value: 'SEM SALDO' }
];

export type TipoLote = 'LOTE_INTERNO' | 'LOTE_EXTERNO';

export const tipoLote = [
  { key: 'LOTE_EXTERNO', value: 'LOTE EXTERNO' },
  { key: 'LOTE_INTERNO', value: 'LOTE INTERNO' }
];

export interface FiltroLote {
  racaUUID?: string;
  maeCodigo?: string;
  numero?: number;
  tipo?: TipoLote;
  status?: StatusLote;
  statusDiferente?: StatusLote[];
  dataNascimentoInicio?: string;
  dataNascimentoFim?: string;
  dataDesmamaInicio?: string;
  dataDesmamaFim?: string;
  previsaoVendaInicio?: string;
  previsaoVendaFim?: string;
}

export type LoteForm = {
  racaUUID: string;
  reproducaoUUID: string;
  paiCodigo: string;
  paiRacaUUID: string;
  maeCodigo: string;
  maeRacaUUID: string;
  tipo: TipoLote;
  qntdDiasParaDesmame: number;
  dataNascimento: string;
  pesoMedioNascimento: string;
  qntdNascidosVivos?: number;
  qntdNascidosNatimortos?: number;
  qntdNascidosMumificados?: number;
};

export type LoteFormAtualizar = {
  uuid: string;
  raca: RacaDTO;
  reproducao: any;
  coberturas: CoberturaDTO[];
  paiCodigo: string;
  paiRacaUUID: string;
  maeCodigo: string;
  maeRacaUUID: string;
  numero: number;
  tipo: TipoLote;
  status: StatusLote;
  dataNascimento: string;
  pesoMedioNascimento: string;
  qntdNascidosVivos: number;
  qntdNascidosNatimortos: number;
  qntdNascidosMumificados: number;
  qntdNascidosTotal: number;
  saldo: number;
  gpdNascimentoDesmama: string;
  desmamaPesoMedio: string;
  desmamaQntdVivos: number;
  desmamaData: string;
  gpdDesmamaSaidaCreche: string;
  saidaCrechePesoMedio: string;
  saidaCrecheQntdVivos: number;
  saidaCrecheData: string;
  gpdSaidaCrecheSaidaCrecimento: string;
  saidaCrescimentoPesoMedio: string;
  saidaCrescimentoQntdVivos: number;
  saidaCrescimentoData: string;
  gpdSaidaCrescimentoSaidaEngorda: string;
  saidaEngordaPesoMedio: string;
  saidaEngordaQntdVivos: number;
  saidaEngordaData: string;
  previsaoVenda: string;
};

export type LoteDTO = {
  uuid: string;
  raca: RacaDTO;
  paiCodigo: '';
  paiRacaUUID: '';
  maeCodigo: '';
  maeRacaUUID: '';
  reproducao: ReproducaoDTO;
  numero: number;
  tipo: TipoLote;
  status: StatusLote;
  dataNascimento: string;
  pesoMedioNascimento: number;
  qntdNascidosVivos: number;
  qntdNascidosNatimortos: number;
  qntdNascidosMumificados: number;
  qntdNascidosTotal: number;
  saldo: number;
  dataSaldoZerado: string;
  gpdNascimentoDesmama: number;
  desmamaPesoMedio: number;
  desmamaQntdVivos: number;
  desmamaData: string;
  gpdDesmamaSaidaCreche: number;
  saidaCrechePesoMedio: number;
  saidaCrecheQntdVivos: number;
  saidaCrecheData: string;
  gpdSaidaCrecheSaidaCrecimento: number;
  saidaCrescimentoPesoMedio: number;
  saidaCrescimentoQntdVivos: number;
  saidaCrescimentoData: string;
  gpdSaidaCrescimentoSaidaEngorda: number;
  saidaEngordaPesoMedio: number;
  saidaEngordaQntdVivos: number;
  saidaEngordaData: string;
  previsaoVenda: string;
  scoreCorporaMatriz: ScoreCorporalOption;
  pesoMatriz: number;
};

export const causasMorteLote = [
  { key: 'DIARREIA', value: 'DIARRÉIA' },
  { key: 'ESMAGADO', value: 'ESMAGADO' },
  { key: 'EDEMA', value: 'EDEMA' },
  { key: 'FRACO', value: 'FRACO' },
  { key: 'DEFEITO_CONGENITO', value: 'DEFEITO CONGÊNITO' },
  { key: 'FRIO', value: 'FRIO' },
  { key: 'FOME', value: 'FOME' },
  { key: 'HIPOGLICEMIA', value: 'HIPOGLICEMIA' },
  { key: 'BRIGA', value: 'BRIGA' },
  { key: 'PNEUMONIA', value: 'PNEUMONIA' },
  { key: 'OUTRA', value: 'OUTRA' }
];

export type MorteLoteForm = {
  dataMorte: string;
  qntdAnimais: number;
  causaMorte: MotivoDescarte;
  observacao: string;
  pesoMedioAnimal: string;
};

export type MorteLoteDTO = {
  uuid: string;
  dataMorte: string;
  qntdAnimais: number;
  idade: number;
  causaMorte: EnumResponse;
  observacao: string;
  statusLote: StatusLote;
  pesoMedioAnimal: number;
  pesoTotalDescartado: number;
};

export type MorteLotePorStatusDTO = {
  totalMortes: number;
  statusLote: StatusLote;
  porcentagem: number;
};

export type MorteLotePorCausaDTO = {
  totalMortes: number;
  causaMorte: MotivoDescarte;
  porcentagem: number;
};

export interface IVendaLoteFilter {
  loteUUID?: string;
  loteNumero?: number;
  dataInicial?: string;
  dataFinal?: string;
}

export interface IVendaLoteForm {
  dataVenda: string;
  observacao: string;
  pesoMedioAnimal: number;
  qntdAnimais: number;
  valorVenda: number;
}

export interface IVendaLoteDTO {
  dataVenda: string;
  gpd: number;
  idadeDias: number;
  observacao: string;
  pesoMedioAnimal: number;
  pesoTotalVendido: number;
  qntdAnimais: number;
  statusLote: StatusLote;
  uuid: string;
  valorVenda: number;
}

export interface ITransferenciaLoteForm {
  qntdAnimais: number;
  dataTransferencia: string;
  loteOrigemUUID: string;
  loteDestinoUUID: string;
}

export interface ITransferenciaLoteDTO {
  uuid: string;
  qntdAnimais: number;
  dataTransferencia: string;
  loteDestinoNumero: number;
  loteDestinoUUID: string;
  loteOrigemNumero: number;
  loteOrigemUUID: string;
}

export interface ILoteFaseDTO {
  numero: number;
  dataNascimento: string;
  data: string;
  qntd: number;
  pesoMedio: number;
  gpdMedio: number;
}
