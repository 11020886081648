import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Form, Card, Button, Modal, Input, notification } from 'antd';
import { CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

import { Table } from './table';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';
import {
  useCadastrarFornecedor,
  CadastrarFornecedorRequest,
  CadastrarFornecedorResponse
} from 'api/cadastrarFornecedor/useCadastrarFornecedor';
import {
  useListarFornecedores,
  ListarFornecedorRequest
} from 'api/listarFornecedores/useListarFornecedores';

const NovoFornecedor: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: (fornecedor: CadastrarFornecedorResponse) => void;
  onError: (error: unknown) => void;
}> = ({ open, setOpen, onSuccess, onError }) => {
  const [form] = Form.useForm<CadastrarFornecedorRequest>();
  const { mutate, isLoading } = useCadastrarFornecedor({
    onSuccess: (fornecedor) => {
      form.resetFields();
      onSuccess(fornecedor);
    },
    onError
  });

  return (
    <Modal
      open={open}
      title="Novo Fornecedor"
      okText="Salvar"
      onOk={() => form.submit()}
      okButtonProps={{
        icon: <SaveOutlined />
      }}
      onCancel={() => {
        form.resetFields(['nome']);
        setOpen(false);
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        danger: true,
        icon: <CloseOutlined />
      }}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        colon={false}
        layout="vertical"
        onFinish={(values) => {
          mutate({ nome: values.nome });
        }}
      >
        <Form.Item
          label="Nome"
          name="nome"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Fornecedores: React.FC = () => {
  const dispatch = useDispatch();
  const [openNovoFornecedor, setOpenNovoFornecedor] = useState(false);
  const [params, setParams] = useState<ListarFornecedorRequest>({
    page: 0,
    perPage: 10
  });
  const { data, isLoading, refetch } = useListarFornecedores({ params });

  useEffect(() => {
    dispatch(updateID('estoque-semen-fornecedores'));
    dispatch(updateTitle('Fornecedores'));
  }, []);

  return (
    <Card
      title={
        <Button
          type="primary"
          htmlType="button"
          icon={<PlusOutlined />}
          onClick={() => setOpenNovoFornecedor(true)}
        >
          Novo Fornecedor
        </Button>
      }
      style={{ margin: '0px 0px 64px 0px' }}
    >
      <NovoFornecedor
        open={openNovoFornecedor}
        setOpen={(value) => {
          setOpenNovoFornecedor(value);
        }}
        onSuccess={(raca) => {
          refetch();
          setOpenNovoFornecedor(false);
          notification.success({
            message: 'Fornecedor cadastrado com sucesso'
          });
        }}
        onError={(error) => {
          notification.error({ message: 'Erro ao cadastrar fornecedor' });
        }}
      />

      <Table
        isLoading={isLoading}
        onSaveCallback={() => {
          refetch();
        }}
        data={data?.items || []}
        pagination={{
          page: data?.page || 0,
          perPage: data?.perPage || 10,
          totalElements: data?.total || 0,
          totalPages: 0
        }}
        changePageSize={(_, perPage) => {
          setParams((currentState) => ({
            ...currentState,
            page: 0,
            perPage: perPage
          }));
        }}
        changePageNumber={(page) => {
          console.log(page);

          setParams((currentState) => ({
            ...currentState,
            page: page - 1
          }));
        }}
      />
    </Card>
  );
};

export default Fornecedores;
