import { useDispatch } from 'react-redux';
import { Button, Card, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import JsFileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';

import { ITabKey } from 'models';
import { updateTitle } from 'store/modules/currentPage/actions';

import api from 'services/api';
import { onErrorNotification } from 'api/models';
import { SuinoResponse } from 'api/getMatrizById/useGetMatrizById';

import Descarte from './Descarte';
import ScoreCorporal from './ScoreCoporal';
import InformacoesBasicas from './InformacoesBasicas';
import { usePDFFichaMatriz } from 'api/relatorios/matrizes/getPDFFichaMatriz/useGetPDFFichaMatriz';
import { usePDFFichaReprodutor } from 'api/relatorios/getPDFFichaReprodutor/useGetPDFFichaReprodutor';

const Ficha: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ sexo: string; id: string }>();
  const [data, setData] = useState({} as SuinoResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('informacoes');
  const [showModalAvaliacao, setShowModalAvaliacao] = useState(true);
  const { isLoading: isLoadingFichaMatriz, refetch: refetchFichaMatriz } =
    usePDFFichaMatriz({
      params: {
        id: params.id
      },
      onSuccess: (reprot) => {
        JsFileDownload(reprot, `matriz-${data.codigo}.pdf`);
      },
      onError: onErrorNotification
    });
  const {
    isLoading: isLoadingFichaReprodutor,
    refetch: refetchFichaReprodutor
  } = usePDFFichaReprodutor({
    params: {
      id: params.id
    },
    onSuccess: (reprot) => {
      JsFileDownload(reprot, `reprodutor-${data.codigo}.pdf`);
    },
    onError: onErrorNotification
  });
  const tabs: ITabKey = {
    informacoes: <InformacoesBasicas suino={data} isLoading={isLoading} />,
    score: (
      <ScoreCorporal
        suinoId={params.id}
        suinoSexo={data.sexo}
        isDescartado={!!data.descarte}
      />
    ),
    descarte: (
      <Descarte
        suinoId={params.id}
        suinoSexo={data.sexo}
        descarte={data.descarte}
        onSubmitCallback={(id: string) => {
          buscarSuino();
        }}
      />
    )
  };

  const getFicha = () => {
    if (data.sexo === 'FEMEA') {
      refetchFichaMatriz();
    } else {
      refetchFichaReprodutor();
    }
  };

  const verificarAvaliacaoNecessaria = (suino: SuinoResponse) => {
    if (
      showModalAvaliacao &&
      suino.precisaAvaliacaoCorporal &&
      suino.sexo === 'FEMEA'
    ) {
      Modal.info({
        title: 'Avaliação Corporal Necessária!',
        content: 'A matriz mudou de status recentemente.'
      });
      setShowModalAvaliacao(false);
    } else if (
      showModalAvaliacao &&
      suino.precisaAvaliacaoCorporal &&
      suino.sexo === 'MACHO'
    ) {
      Modal.info({
        title: 'Avaliação Corporal Necessária!',
        content: `O reprodutor deve ser avaliado a cada 90 dias.`
      });
      setShowModalAvaliacao(false);
    }
  };

  const buscarSuino = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const { data } = await api.get<SuinoResponse>(
          `/${params.sexo}/${params.id}`
        );
        setData(data);
        verificarAvaliacaoNecessaria(data);
      } catch (error) {
        onErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    buscarSuino();
    dispatch(updateTitle(`Ficha do Animal`));
  }, []);

  return (
    <Card
      title={`${data.maturidade} - ${data.codigo}`}
      extra={
        <Button
          danger
          type="primary"
          htmlType="button"
          icon={<FilePdfOutlined />}
          loading={
            isLoading || isLoadingFichaMatriz || isLoadingFichaReprodutor
          }
          onClick={() => {
            getFicha();
          }}
        >
          Ficha
        </Button>
      }
      onTabChange={(key) => {
        setActiveTabKey(key);
      }}
      activeTabKey={activeTabKey}
      tabList={[
        { key: 'informacoes', tab: 'Informações Básicas' },
        { key: 'score', tab: 'Score Corporal' },
        { key: 'descarte', tab: 'Descarte' }
      ]}
    >
      {tabs[activeTabKey]}
    </Card>
  );
};

export default Ficha;
