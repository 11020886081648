import { CSSProperties, useState } from 'react';
import { Button, Card, Col, Form, Input, notification, Row } from 'antd';

import api from 'services/api';
import { formItemStyle, formStyle } from 'styles/globalAntd';
import axios from 'axios';

interface Props {
  style?: CSSProperties;
}

const Senha: React.FC<Props> = ({ style }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [differentPasswords, setDifferentPasswords] = useState('');

  const atualizarSenha = async (
    senhaAtual: string,
    novaSenha: string,
    confirmacaoNovaSenha: string
  ) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await api.patch('/produtor/atualizar/senha', {
          senhaAtual: senhaAtual,
          novaSenha: novaSenha
        });
        notification.success({
          message: 'Senha alterada com sucesso!'
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          notification.error({
            message: 'Erro ao alterar senha!',
            description: error.response.data.message
          });
        } else if (error instanceof Error) {
          notification.error({
            message: 'Erro ao alterar senha!',
            description: error.message
          });
        }
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Card style={style} title="Senha">
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: formStyle }}
        onValuesChange={(_, values) => {
          if (
            values.confirmacaoNovaSenha &&
            values.confirmacaoNovaSenha !== values.novaSenha
          ) {
            setDifferentPasswords('As senhas são diferentes');
          } else {
            setDifferentPasswords('');
          }
        }}
        onFinish={(values) => {
          atualizarSenha(
            values.senhaAtual,
            values.novaSenha,
            values.confirmacaoNovaSenha
          );
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="senhaAtual"
              label="Senha Atual"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="novaSenha"
              label="Nova Senha"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="confirmacaoNovaSenha"
              label="Confirmação Senha"
              style={formItemStyle}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
              help={differentPasswords}
              validateStatus={differentPasswords ? 'error' : ''}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Col span={6}>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isSubmitting ? 'Salvando...' : 'Salvar'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Senha;
