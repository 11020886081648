import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import Raca from 'pages/Raca';
import CalendarioPIG from 'pages/CalendarioPIG';
import CriteriosManejo from 'pages/CriteriosManejo';
import ProducaoRacao from 'pages/ProducaoRacao';
import RelatorioLotes from 'pages/Relatorios/Lotes';
import RelatorioRacao from 'pages/Relatorios/Racao';
import RelatorioMatrizes from 'pages/Relatorios/Matrizes';
import RelatorioReprodutores from 'pages/Relatorios/Reprodutores';
import Autenticacao from 'pages/Autenticacao';
import CriarConta from 'pages/CriarConta';
import CoberturaCadastro from 'pages/Cobertura/Cadastro';
import CoberturaHistorico from 'pages/Cobertura/Historico';
import LoteLista from 'pages/Lote/Lotes';
import LoteFicha from 'pages/Lote/Ficha';
import LoteCadastro from 'pages/Lote/Cadastro';
import Matrizes from 'pages/Suino/Matrizes';
import Reprodutores from 'pages/Suino/Reprodutores';
import SuinoFicha from 'pages/Suino/Ficha';
import SuinoCadastro from 'pages/Suino/Cadastrar';
import Saida from 'pages/EstoqueSemen/Saida';
import Entrada from 'pages/EstoqueSemen/Entrada';
import Estoques from 'pages/EstoqueSemen/Estoques';
import Fornecedores from 'pages/EstoqueSemen/Fornecedores';
import Perfil from 'pages/Perfil';
import EsqueciMinhaSenha from 'pages/EsqueciMinhaSenha';
import ResetarSenha from 'pages/ResetarSenha';
import ConfirmarCadastro from 'pages/ConfirmarCadastro';
import RelatoriosAlvos from 'pages/Relatorios/Alvos';
import { ComoUsar } from 'pages/ComoUsar';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <PrivateRoute path="/login" component={Autenticacao} />
      {/* <PrivateRoute path="/criar-conta" component={CriarConta} /> */}
      <PrivateRoute path="/esqueci-minha-senha" component={EsqueciMinhaSenha} />
      <PrivateRoute path="/resetar-senha/:token" component={ResetarSenha} />
      <PrivateRoute
        path="/confirmar-cadastro/:token"
        component={ConfirmarCadastro}
      />

      <PrivateRoute
        path="/criterios-manejo"
        component={CriteriosManejo}
        isPrivate
      />

      <PrivateRoute path="/meus-dados" component={Perfil} isPrivate />
      <PrivateRoute path="/como-usar" component={ComoUsar} isPrivate />

      <PrivateRoute path="/racas" component={Raca} isPrivate />

      <PrivateRoute
        path="/producao-racao"
        component={ProducaoRacao}
        isPrivate
      />

      <PrivateRoute
        path="/estoque-semen/lista"
        component={Estoques}
        isPrivate
      />
      <PrivateRoute
        path="/estoque-semen/fornecedores"
        component={Fornecedores}
        isPrivate
      />
      <PrivateRoute
        path="/estoque-semen/entrada"
        component={Entrada}
        isPrivate
      />
      <PrivateRoute path="/estoque-semen/saida" component={Saida} isPrivate />

      <PrivateRoute
        path="/suino/reprodutores"
        component={Reprodutores}
        isPrivate
      />
      <PrivateRoute path="/suino/matrizes" component={Matrizes} isPrivate />
      <PrivateRoute
        path="/suino/cadastro"
        component={SuinoCadastro}
        isPrivate
      />
      <PrivateRoute
        path="/suino/:sexo/:id/ficha"
        component={SuinoFicha}
        isPrivate
      />

      <PrivateRoute
        path="/cobertura/cadastro"
        component={CoberturaCadastro}
        isPrivate
      />

      <PrivateRoute
        path="/cobertura/historico"
        component={CoberturaHistorico}
        isPrivate
      />

      <PrivateRoute path="/lote/lista" component={LoteLista} isPrivate />
      <PrivateRoute path="/lote/cadastro" component={LoteCadastro} isPrivate />
      <PrivateRoute path="/lote/ficha/:uuid" component={LoteFicha} isPrivate />

      <PrivateRoute
        path="/relatorios/alvos-producao"
        component={RelatoriosAlvos}
        isPrivate
      />
      <PrivateRoute
        path="/relatorios/lotes"
        component={RelatorioLotes}
        isPrivate
      />
      <PrivateRoute
        path="/relatorios/matrizes"
        component={RelatorioMatrizes}
        isPrivate
      />
      <PrivateRoute
        path="/relatorios/reprodutores"
        component={RelatorioReprodutores}
        isPrivate
      />
      <PrivateRoute
        path="/relatorios/racao"
        component={RelatorioRacao}
        isPrivate
      />
      <PrivateRoute
        path="/calendario-pig"
        component={CalendarioPIG}
        isPrivate
      />
    </Switch>
  );
};

export default Routes;
