import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';

export type CadastrarVendaLeitaoRequest = {
  loteOrigemId: string;
  loteDestinoId: string;
  data: string;
  qntd: number;
};

const cadastrarTransferenciaLeitao = async (
  loteId: string,
  body: CadastrarVendaLeitaoRequest
): Promise<void> => {
  const response = await api.post<void>(`lote/transferencia`, body);
  return response.data;
};

export type InputParams = {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
};

export const useCadastrarTransferenciaLeitao = ({
  onSuccess,
  onError
}: InputParams) => {
  return useMutation(
    (body: { loteId: string; body: CadastrarVendaLeitaoRequest }) =>
      cadastrarTransferenciaLeitao(body.loteId, body.body),
    {
      onSuccess,
      onError
    }
  );
};
