import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Result,
  Row,
  Spin
} from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import api from 'services/api';
import history from 'services/history';
import FormWrapper from 'components/FormWrapper';

const LinkExpirado: React.FC = () => {
  return (
    <Result
      status="error"
      title="Link expirou"
      subTitle="O link pode ter expirado ou a senha já foi redefinida. Você pode solicitar outro caso ainda não consiga acessar o sistema. Em caso de dúvidas, por favor nos contate pelo e-mail contato@meusuino.com."
      extra={[
        <Button
          key="esqueci-minha-senha"
          onClick={() => {
            history.replace('/esqueci-minha-senha');
          }}
        >
          Solicitar outro
        </Button>,
        <Button
          type="primary"
          key="login"
          onClick={() => {
            history.replace('/login');
          }}
        >
          Ir para login
        </Button>
      ]}
    />
  );
};

const ResetarSenha: React.FC = () => {
  const [form] = Form.useForm();
  const { token } = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValido, setIsTokenValido] = useState(false);

  const validarToken = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await api.get(`/produtor/esqueci-minha-senha/${token}`);
        setIsTokenValido(true);
      } catch (error) {
        setIsTokenValido(false);
      }
      setIsLoading(false);
    }
  };

  const mostrarInformacoes = () => {
    Modal.success({
      title: 'Senha alterada',
      content:
        'Sua senha foi redefinida e você já pode fazer login no sistema.',
      okText: 'Ir para login',
      onOk: () => {
        history.replace('/login');
      }
    });
  };

  const resetarSenha = async (senha: string, confirmacaoSenha: string) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await api.post(`/produtor/redefinir-senha/${token}`, {
          senha,
          confirmacaoSenha
        });
        mostrarInformacoes();
      } catch (error) {
        notification.error({
          message:
            'Ocorreu um erro ao redefinir a senha, por favor tente novamente.'
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validarToken();
  }, []);

  return (
    <Spin
      size="large"
      spinning={isLoading}
      tip="Verificando link"
      style={{ paddingTop: '20%' }}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Row justify="center">
        <Col span={12}>{!isLoading && !isTokenValido && <LinkExpirado />}</Col>
      </Row>
      {isTokenValido && (
        <Row style={{ height: '100vh', paddingTop: '80px' }} justify="center">
          <Form
            layout="vertical"
            form={form}
            colon={false}
            wrapperCol={{ style: { width: '100%' } }}
            onFinish={(values) => {
              resetarSenha(values.senha, values.confirmacaoSenha);
            }}
          >
            <div
              style={{
                marginBottom: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}
            >
              <h2 style={{ color: '#4cab5b' }}>MeuSuino</h2>
            </div>

            <Col span={24}>
              <FormWrapper
                label="Nova Senha"
                name="senha"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input.Password />
              </FormWrapper>
            </Col>

            <Col span={24}>
              <FormWrapper
                label="Confirmação da Senha"
                name="confirmacaoSenha"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input.Password />
              </FormWrapper>
            </Col>

            <Form.Item style={{ marginBottom: '8px', marginTop: '48px' }}>
              <Button
                block
                type="primary"
                htmlType="button"
                loading={isLoading}
                style={{
                  backgroundColor: '#4cab5b',
                  borderWidth: 0
                }}
                onClick={() => {
                  form.submit();
                }}
              >
                Redefinir senha
              </Button>
            </Form.Item>
          </Form>
        </Row>
      )}
    </Spin>
  );
};

export default ResetarSenha;
