import { Select, SelectProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { EnumResponse } from 'models';
import { buscarMotivos } from 'store/modules/descarte/actions';

interface IReduxState {
  descarte: {
    motivos: EnumResponse[];
  };
}

const SelectMotivoDescarte: React.FC<SelectProps> = (props) => {
  const dispatch = useDispatch();
  const { motivos } = useSelector((state: IReduxState) => state.descarte);

  useEffect(() => {
    if (!(motivos && motivos.length > 0)) {
      dispatch(buscarMotivos());
    }
  }, []);

  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .startsWith(input.toLowerCase())
      }
    >
      {motivos &&
        motivos.map((raca) => (
          <Select.Option key={raca.key} value={raca.key}>
            {raca.descricao}
          </Select.Option>
        ))}
    </Select>
  );
};
export default SelectMotivoDescarte;
