import { useEffect, useState } from 'react';
import { ConfigProvider, Empty, Modal, Table } from 'antd';

import api from 'services/api';
import { IPaginacao, RacaDTO } from 'models';
import { PageResponse } from 'api/models';
import { ListarReprodutorResponse } from 'api/listarReprodutores/useListarReprodutores';
import { formatToBRL } from 'utils/utils';

interface IKey extends ListarReprodutorResponse {
  key: string;
}

interface Props {
  visible: boolean;
  onOK: (suino: ListarReprodutorResponse) => void;
  onCancel: () => void;
}

const ModalReprodutores: React.FC<Props> = ({
  visible = false,
  onOK,
  onCancel
}) => {
  const [reprodutorSelecionado, setReprodutorSelecionado] =
    useState<ListarReprodutorResponse>({} as ListarReprodutorResponse);
  const [reprodutores, setReprodutores] = useState<IKey[]>([]);
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    page: 0,
    perPage: 5,
    totalItems: 0,
    totalPages: 0
  });

  const columns = [
    {
      title: 'Número',
      dataIndex: 'codigo'
    },
    {
      title: 'Nascimento',
      dataIndex: 'nascimento',
      render: (value: string) => formatToBRL(value)
    },
    {
      title: 'Idade (dias)',
      dataIndex: 'idade'
    },
    {
      title: 'Raça',
      dataIndex: 'raca',
      render: (value: RacaDTO) => value.nome
    }
  ];

  const buscarReprodutores = async () => {
    try {
      const { data } = await api.get<PageResponse<ListarReprodutorResponse>>(
        '/reprodutor',
        {
          params: {
            idadeMin: 210,
            sexo: 'MACHO',
            perPage: paginacao.perPage,
            page: paginacao.page
          }
        }
      );
      setReprodutores(
        data.items.map((reprodutor) => ({
          ...reprodutor,
          key: reprodutor.id
        }))
      );
      setPaginacao((currentState) => ({
        ...currentState,
        totalItems: data.total
      }));
    } catch (error) {
      setReprodutores([]);
    }
  };

  useEffect(() => {
    buscarReprodutores();
  }, [paginacao.page]);

  return (
    <Modal
      title="Reprodutores"
      width={'70%'}
      open={visible}
      okText="Selecionar"
      onOk={() => {
        onOK(reprodutorSelecionado);
        setReprodutorSelecionado({} as ListarReprodutorResponse);
      }}
      okButtonProps={{
        disabled: !reprodutorSelecionado.id
      }}
      cancelText="Cancelar"
      onCancel={() => {
        onCancel();
      }}
    >
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum reprodutor encontrado!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={reprodutores}
          onRow={(item) => ({
            onClick: () => {
              setReprodutorSelecionado(item);
            }
          })}
          rowSelection={{
            type: 'radio',
            onSelect: (record) => {
              setReprodutorSelecionado(record);
            },
            selectedRowKeys: reprodutorSelecionado
              ? [reprodutorSelecionado.id]
              : []
          }}
          pagination={{
            size: 'small',
            total: paginacao.totalItems,
            pageSize: paginacao.perPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: false,
            onChange: (page) => {
              setPaginacao((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
        />
      </ConfigProvider>
    </Modal>
  );
};

export default ModalReprodutores;
