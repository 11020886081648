import { useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  SendOutlined
} from '@ant-design/icons';
import {
  Button,
  Card,
  Modal,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  notification
} from 'antd';

import { convertFormattedToNumber, integerRegex } from 'utils/masks';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import ModalReprodutores from './ModalReprodutores';
import ModalMatrizesVazias from './ModalMatrizesVazias';
import ModalEstoques from 'pages/EstoqueSemen/Saida/ModalEstoques';

import { formatToISO } from 'utils/utils';
import { onErrorNotification } from 'api/models';

import PigDatePicker from 'components/PigDatePicker';
import SelectScoreCorporal from 'components/SelectScoreCorporal';
import { useCadastrarCobertura } from 'api/cadastrarCobertura/useCadastrarCobertura';

interface IMontaSelecionada {
  ordem: number;
  data: string;
  bisnagaId?: string;
  reprodutorId?: string;
  tipoMonta: 'MONTA_NATURAL' | 'INSEMINACAO_ARTIFICIAL';
  qntdDoses: number;
  reprodutor?: string;
  fornecedor?: string;
  raca: string;
}

const Cobertura: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchMatriz, setSearchMatriz] = useState(false);
  const [searchEstoque, setSearchEstoque] = useState(false);
  const [searchReprodutor, setSearchReprodutor] = useState(false);
  const [montas, setMontas] = useState<IMontaSelecionada[]>([]);
  const { mutate: cadastrarCobertura, isLoading } = useCadastrarCobertura({
    onSuccess: () => {
      notification.success({ message: 'Cobertura cadastrada com sucesso' });
      setMontas([]);
      form.resetFields();
    },
    onError: (error) => {
      onErrorNotification(error);
    }
  });
  const columns = [
    {
      dataIndex: 'ordem',
      render: (value: any) => value + 'ª'
    },
    {
      title: 'Data',
      dataIndex: 'data',
      render: (value: any) => format(parseISO(value), 'dd/MM/yyyy')
    },
    {
      title: 'Reprodutor/Fornecedor',
      render: (monta: IMontaSelecionada) =>
        monta.reprodutor ? monta.reprodutor : monta.fornecedor
    },
    {
      title: 'Raça',
      dataIndex: 'raca'
    },
    {
      title: 'Tipo da Cobertura',
      dataIndex: 'tipoMonta',
      render: (value: any) =>
        value
          ? value === 'MONTA_NATURAL'
            ? 'MN - Monta Natural'
            : 'IA - Inseminação Artificial'
          : ''
    },
    {
      title: 'Qntd Doses',
      dataIndex: 'qntdDoses',
      render: (value: string) => (value ? value : '-')
    },
    {
      title: 'Remover',
      render: (value: any, record: any, index: any) => (
        <Button
          key={index}
          danger
          shape="round"
          htmlType="button"
          icon={<DeleteOutlined />}
          onClick={() => {
            setMontas((prev) => prev.filter((_, i) => i !== index));
          }}
        />
      )
    }
  ];

  const confirmarTrocaMatriz = (id: string, codigo: string) => {
    Modal.confirm({
      title: 'Matriz alterada!',
      content:
        'A matriz selecionada é diferente da matriz das outras montas. Ao fazer isso as montas presentes na tabela serão perdidas, deseja continuar?',
      okText: 'Confirmar',
      onOk: () => {
        setMontas([]);
        form.resetFields();
        form.setFieldsValue({
          matrizId: id,
          matrizCodigo: codigo
        });
      },
      cancelText: 'Cancelar'
    });
  };

  useEffect(() => {
    dispatch(updateID('cobertura-cadastrar'));
    dispatch(updateTitle('Cadastrar Cobertura'));
  }, []);

  return (
    <Card style={{ margin: '0px 0px 64px 0px' }}>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          const monta: IMontaSelecionada = {
            ordem: montas.length + 1,
            data: formatToISO(values.data),
            bisnagaId: values.bisnagaId,
            reprodutorId: values.reprodutorId,
            qntdDoses: values.qntdDoses,
            tipoMonta: values.tipoMonta,
            raca: values.raca,
            reprodutor: values.reprodutorCodigo,
            fornecedor: values.fornecedorNome
          };
          setMontas((prev) => [...prev, monta]);
          form.resetFields();
          form.setFieldsValue({
            matrizId: values.matrizId,
            matrizCodigo: values.matrizCodigo,
            scoreCorporal: values.scoreCorporal,
            peso: values.peso
          });
        }}
      >
        <ModalMatrizesVazias
          visible={searchMatriz}
          onOK={(matriz) => {
            setSearchMatriz(false);
            const formMatrizUUID = form.getFieldValue('matrizId');
            if (formMatrizUUID && formMatrizUUID !== matriz.id) {
              confirmarTrocaMatriz(matriz.id, matriz.codigo);
            } else {
              form.setFieldsValue({
                matrizId: matriz.id,
                matrizCodigo: matriz.codigo
              });
            }
          }}
          onCancel={() => {
            setSearchMatriz(false);
          }}
        />

        <ModalReprodutores
          visible={searchReprodutor}
          onOK={(reprodutor) => {
            form.setFieldsValue({
              raca: reprodutor.raca.nome,
              reprodutorId: reprodutor.id,
              reprodutorCodigo: reprodutor.codigo,
              fornecedorNome: undefined,
              qntdDoses: undefined,
              saldoBisnaga: undefined,
              bisnagaId: undefined,
              estoqueSelecionado: undefined
            });
            setSearchReprodutor(false);
          }}
          onCancel={() => {
            setSearchReprodutor(false);
          }}
        />

        <ModalEstoques
          visible={searchEstoque}
          onOK={(bisnaga) => {
            form.setFieldsValue({
              reprodutorId: undefined,
              reprodutorCodigo: undefined,
              estoqueSelecionado: `${bisnaga?.doador.racaDoador.nome} - ${bisnaga?.doador.codigoDoador}`,
              fornecedorNome: bisnaga?.doador.codigoDoador,
              bisnagaId: bisnaga?.id,
              saldoBisnaga: bisnaga?.saldo,
              raca: bisnaga?.doador.racaDoador.nome
            });
            setSearchEstoque(false);
          }}
          onCancel={() => {
            setSearchEstoque(false);
          }}
        />

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Matriz"
              name="matrizCodigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.Search
                enterButton={
                  <Button type="primary" icon={<SearchOutlined />} />
                }
                disabled
                placeholder="Buscar matriz"
                onSearch={() => {
                  setSearchMatriz(true);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Score Corporal"
              name="scoreCorporal"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectScoreCorporal allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso"
              name="peso"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: integerRegex,
                  message: 'Deve estar no formato 0'
                }
              ]}
            >
              <Input suffix="kgs" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Form.Item name="matrizId" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Tipo da Cobertura"
              name="tipoMonta"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select placeholder="Selecione o tipo da monta" allowClear>
                <Select.Option key="monta-natural" value="MONTA_NATURAL">
                  Monta Natural (MN)
                </Select.Option>
                <Select.Option
                  key="inseminacao-artificial"
                  value="INSEMINACAO_ARTIFICIAL"
                >
                  Inseminação Artificial (IA)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.data !== next.data}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Data"
                  name="data"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <PigDatePicker
                    startValue={getFieldValue('data')}
                    callback={(_, date) => {
                      form.setFieldsValue({ data: date });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.tipoMonta !== next.tipoMonta}
          >
            {({ getFieldValue }) =>
              getFieldValue('tipoMonta') === 'MONTA_NATURAL' && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Reprodutor"
                    name="reprodutorCodigo"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Input.Search
                      disabled
                      placeholder="Buscar reprodutor"
                      enterButton={
                        <Button type="primary" icon={<SearchOutlined />} />
                      }
                      onSearch={() => {
                        setSearchReprodutor(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>

          <Form.Item name="reprodutorId" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item name="raca" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.tipoMonta !== next.tipoMonta}
          >
            {({ getFieldValue }) =>
              getFieldValue('tipoMonta') === 'INSEMINACAO_ARTIFICIAL' && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Estoque Sêmen"
                    name="estoqueSelecionado"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <Input.Search
                      disabled
                      placeholder="Buscar estoque"
                      enterButton={
                        <Button type="primary" icon={<SearchOutlined />} />
                      }
                      onSearch={() => {
                        setSearchEstoque(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>

          <Form.Item name="fornecedorNome" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item name="bisnagaId" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item name="saldoBisnaga" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.tipoMonta !== next.tipoMonta ||
              prev.saldoEntrada !== next.saldoEntrada
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('tipoMonta') === 'INSEMINACAO_ARTIFICIAL' && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Qntd Doses"
                    name="qntdDoses"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={[
                      { required: true, message: 'Campo obrigatório' },
                      {
                        pattern: /[0-9]/,
                        message: 'Deve conter somente números'
                      },
                      {
                        type: 'number',
                        min: 1,
                        message: 'Deve ser maior que 0'
                      },
                      {
                        type: 'number',
                        max: getFieldValue('saldoBisnaga'),
                        message: `Saldo disponível é ${getFieldValue(
                          'saldoBisnaga'
                        )}`
                      }
                    ]}
                  >
                    <InputNumber min={0} step={1} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              style={{ marginTop: '32px' }}
              type="primary"
              htmlType="button"
              icon={<PlusOutlined />}
              block
              onClick={() => {
                form.submit();
              }}
            >
              Adicionar
            </Button>
          </Col>
        </Row>

        <Divider orientation="left">Montas</Divider>

        <ConfigProvider
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nenhuma monta adicionada"
            />
          )}
        >
          <Table
            bordered
            size="small"
            rowKey={'ordem'}
            columns={columns}
            pagination={false}
            dataSource={montas}
          />
        </ConfigProvider>
      </Form>

      <Row gutter={[16, 16]} justify="end" style={{ marginTop: '32px' }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Button
            block
            type="primary"
            htmlType="button"
            icon={<SendOutlined />}
            loading={isLoading}
            style={{ marginTop: '32px' }}
            onClick={() => {
              cadastrarCobertura({
                matrizId: form.getFieldValue('matrizId'),
                body: {
                  peso: convertFormattedToNumber(form.getFieldValue('peso')),
                  scoreCorporal: form.getFieldValue('scoreCorporal'),
                  coberturas: montas.map((e) => ({
                    bisnagaId: e.bisnagaId,
                    reprodutorId: e.reprodutorId,
                    doses: e.qntdDoses,
                    data: e.data,
                    ordem: e.ordem
                  }))
                }
              });
            }}
            disabled={montas.length === 0}
          >
            Finalizar
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Cobertura;
