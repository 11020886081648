import { Select, SelectProps } from 'antd';

import { fasesLote } from 'models';

const SelectFaseLote: React.FC<SelectProps> = (props) => {
  const mapValue = (value: string) => {
    if (value === 'MATERNIDADE') return 'MATERNIDADE/DESMAMA';
    if (value === 'TERMINAÇÃO') return 'TERMINAÇÃO/VENDA';
    return value;
  };

  return (
    <Select {...props}>
      {fasesLote.map((tipo) => (
        <Select.Option key={tipo.key} value={tipo.key}>
          {mapValue(tipo.value)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectFaseLote;
