import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';
import { UseQueryPDFInput } from 'api/relatorios/types';

export const getPDFFichaMatriz = async (id: string): Promise<Blob> => {
  const { data } = await api.get<Blob>(`relatorio/matrizes/${id}/ficha/pdf`, {
    responseType: 'blob'
  });
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFFichaMatriz = (
  input: UseQueryPDFInput<{ id: string }>
): OutputParams => {
  return useQuery(
    ['get-pdf-ficha-matriz', input.params],
    () => getPDFFichaMatriz(input.params.id),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
