import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { parseISO } from 'date-fns';
import { SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  notification
} from 'antd';

import { tipoLote } from 'models';
import { LoteResponse } from 'api/getLoteById/useGetLoteById';
import {
  decimalRegexWithComma,
  applyMaskDecimalWithComma,
  convertFormattedToNumber
} from 'utils/masks';

import { FormItem } from 'components/Antd';
import SelectRacas from 'components/SelectRacas';
import PigDatePicker from 'components/PigDatePicker';
import SelectFaseLote from 'components/SelectFaseLote';

import { FaseForm } from './FaseForm';
import { EncerrarFase } from './EncerrarFase';
import { useEditarLote } from 'api/editarLote/useEditarLote';
import { formatToISO } from 'utils/utils';

interface IProps {
  isLoading: boolean;
  lote: LoteResponse;
  onSubmitCallback: () => void;
}

type LoteFaseForm = {
  data: Date;
  peso: string;
  gpd: string;
  qntd: number;
};

type LoteForm = {
  tipo: string;
  codigo: string;
  fase: string;
  raca: string;
  dataNascimento: Date;
  idade: number;
  pesoMedioNascimento: string;
  nascidosVivos: number;
  nascidosNatimortos: number;
  nascidosMumificados: number;
  nascidosTotal: number;
  saldo: number;
  mae: { codigo: string; raca: string };
  pai: { codigo: string; raca: string };
  maternidade: LoteFaseForm | undefined;
  creche: LoteFaseForm | undefined;
  crescimento: LoteFaseForm | undefined;
  terminacao: LoteFaseForm | undefined;
  previsaoVenda: Date;
};

const InformacoesBasicas: React.FC<IProps> = ({
  isLoading,
  lote,
  onSubmitCallback
}) => {
  const [form] = Form.useForm<LoteForm>();
  const { mutate, isLoading: isUpdating } = useEditarLote({
    onSuccess: () => {
      notification.success({ message: 'Lote atualizado com sucesso' });
      onSubmitCallback();
    },
    onError: () => {
      notification.error({ message: 'Erro ao atualizar lote' });
    }
  });

  useEffect(() => {
    if (!isEmpty(lote)) {
      form.setFieldsValue({
        tipo: lote.tipo,
        codigo: lote.codigo,
        fase: lote.fase,
        raca: lote.raca.id,
        dataNascimento: parseISO(lote.dataNascimento),
        idade: lote.idade,
        pesoMedioNascimento: applyMaskDecimalWithComma(
          lote.pesoMedioNascimento
        ),
        nascidosVivos: lote.nascidosVivos,
        nascidosNatimortos: lote.nascidosNatimortos,
        nascidosMumificados: lote.nascidosMumificados,
        nascidosTotal: lote.nascidosTotal,
        saldo: lote.saldo,
        mae: {
          codigo: lote?.mae?.codigo,
          raca: lote?.mae?.raca.id
        },
        pai: {
          codigo: lote?.pai?.codigo,
          raca: lote?.pai?.raca.id
        },
        maternidade: lote?.fases?.maternidade
          ? {
              data: parseISO(lote.fases.maternidade.fim),
              gpd: lote.fases.maternidade.previsao
                ? undefined
                : applyMaskDecimalWithComma(lote.fases.maternidade.gpd, 3),
              peso: lote.fases.maternidade.previsao
                ? undefined
                : applyMaskDecimalWithComma(
                    lote.fases.maternidade.pesoFinal,
                    2
                  ),
              qntd: lote.fases.maternidade.saldoFinal
            }
          : undefined,
        creche: lote?.fases?.creche
          ? {
              data: parseISO(lote.fases.creche.fim),
              gpd: lote.fases.creche.previsao
                ? undefined
                : applyMaskDecimalWithComma(lote.fases.creche.gpd, 3),
              peso: lote.fases.creche.previsao
                ? undefined
                : applyMaskDecimalWithComma(lote.fases.creche.pesoFinal, 2),
              qntd: lote.fases.creche.saldoFinal
            }
          : undefined,
        crescimento: lote?.fases?.crescimento
          ? {
              data: parseISO(lote.fases.crescimento.fim),
              gpd: lote.fases.crescimento.previsao
                ? undefined
                : applyMaskDecimalWithComma(lote.fases.crescimento.gpd, 3),
              peso: lote.fases.crescimento.previsao
                ? undefined
                : applyMaskDecimalWithComma(
                    lote.fases.crescimento.pesoFinal,
                    2
                  ),
              qntd: lote.fases.crescimento.saldoFinal
            }
          : undefined,
        terminacao: lote?.fases?.terminacao
          ? {
              data: parseISO(lote.fases.terminacao.fim),
              gpd: lote.fases.terminacao.previsao
                ? undefined
                : applyMaskDecimalWithComma(lote.fases.terminacao.gpd, 3),
              peso: lote.fases.terminacao.previsao
                ? undefined
                : applyMaskDecimalWithComma(lote.fases.terminacao.pesoFinal, 2),
              qntd: lote.fases.terminacao.saldoInicial
            }
          : undefined,
        previsaoVenda: parseISO(lote.previsaoVenda)
      });
    }
  }, [lote]);

  return (
    <>
      <Form
        layout="vertical"
        colon={false}
        form={form}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          mutate({
            loteId: lote.id,
            body: {
              racaId: values.raca,
              codigoMae: values.mae.codigo,
              maeRacaId: values.mae.raca,
              codigoPai: values.pai.codigo,
              paiRacaId: values.pai.raca,
              maternidade: {
                fim: formatToISO(values?.maternidade?.data),
                pesoFinal: convertFormattedToNumber(values?.maternidade?.peso)
              },
              creche: {
                fim: formatToISO(values?.creche?.data),
                pesoFinal: convertFormattedToNumber(values?.creche?.peso)
              },
              crescimento: {
                fim: formatToISO(values?.crescimento?.data),
                pesoFinal: convertFormattedToNumber(values?.crescimento?.peso)
              },
              terminacao: {
                fim: formatToISO(values?.terminacao?.data),
                pesoFinal: convertFormattedToNumber(values?.terminacao?.peso)
              }
            }
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Tipo do Lote"
              name="tipo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Select placeholder="Selecionar" disabled>
                {tipoLote.map((tipo) => (
                  <Select.Option key={tipo.key} value={tipo.key}>
                    {tipo.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Número/Identificação"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Status"
              name="fase"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <SelectFaseLote placeholder="Selecionar" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Raça"
              name="raca"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <SelectRacas placeholder="Selecionar" allowClear showSearch />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => {
                return prev.dataNascimento !== next.dataNascimento;
              }}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Data de Nascimento"
                  name="dataNascimento"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <PigDatePicker
                    disabled
                    startValue={getFieldValue('dataNascimento')}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Idade"
              name="idade"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled suffix="dias" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <FormItem
              label="Peso Médio de Nascimento"
              name="pesoMedioNascimento"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input style={{ width: '100%' }} />
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name="saldo"
              label="Saldo Hoje"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <InputNumber disabled style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nascidos Vivos"
              name="nascidosVivos"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={1}
                min={0}
                disabled
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nascidos Natimortos"
              name="nascidosNatimortos"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={1}
                min={0}
                disabled
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nascidos Mumificados"
              name="nascidosMumificados"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={1}
                min={0}
                disabled
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Total Nascidos"
              name="nascidosTotal"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Deve ser maior que 0' },
                { type: 'number', min: 1, message: 'Deve ser maior que 0' }
              ]}
            >
              <InputNumber disabled style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name={['mae', 'codigo']}
              label="Nº Mãe"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Input disabled={lote.tipo === 'LOTE_INTERNO'} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name={['mae', 'raca']}
              label="Raça da Mãe"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <SelectRacas
                allowClear
                showSearch
                placeholder="Selecionar"
                disabled={lote.tipo === 'LOTE_INTERNO'}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name={['pai', 'codigo']}
              label="Nº Pai"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Input disabled={lote.tipo === 'LOTE_INTERNO'} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              name={['pai', 'raca']}
              label="Raça do Pai"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <SelectRacas
                allowClear
                showSearch
                placeholder="Selecionar"
                disabled={lote.tipo === 'LOTE_INTERNO'}
              />
            </Form.Item>
          </Col>
        </Row>

        {lote?.fases?.maternidade && (
          <FaseForm
            setFieldValue={form.setFieldValue}
            fase="maternidade"
            faseLabel="Maternidade"
            gpd={lote?.fases?.maternidade.gpd}
            dataInicial={lote?.fases?.maternidade.inicio}
            dataFinal={lote?.fases?.maternidade.fim}
            pesoInicial={lote?.fases?.maternidade.pesoInicial}
            pesoFinal={lote?.fases?.maternidade.pesoFinal}
            qntdInicial={lote?.fases?.maternidade.saldoInicial}
            qntdFinal={lote?.fases?.maternidade.saldoFinal}
            previsao={lote?.fases?.maternidade.previsao}
          />
        )}

        {lote?.fases?.creche && (
          <FaseForm
            setFieldValue={form.setFieldValue}
            fase="creche"
            faseLabel="Creche"
            gpd={lote?.fases?.creche.gpd}
            dataInicial={lote?.fases?.creche.inicio}
            dataFinal={lote?.fases?.creche.fim}
            pesoInicial={lote?.fases?.creche.pesoInicial}
            pesoFinal={lote?.fases?.creche.pesoFinal}
            qntdInicial={lote?.fases?.creche.saldoInicial}
            qntdFinal={lote?.fases?.creche.saldoFinal}
            previsao={lote?.fases?.creche.previsao}
          />
        )}

        {lote?.fases?.crescimento && (
          <FaseForm
            setFieldValue={form.setFieldValue}
            fase="crescimento"
            faseLabel="Crescimento"
            gpd={lote?.fases?.crescimento.gpd}
            dataInicial={lote?.fases?.crescimento.inicio}
            dataFinal={lote?.fases?.crescimento.fim}
            pesoInicial={lote?.fases?.crescimento.pesoInicial}
            pesoFinal={lote?.fases?.crescimento.pesoFinal}
            qntdInicial={lote?.fases?.crescimento.saldoInicial}
            qntdFinal={lote?.fases?.crescimento.saldoFinal}
            previsao={lote?.fases?.crescimento.previsao}
          />
        )}

        {lote?.fases?.terminacao && (
          <FaseForm
            setFieldValue={form.setFieldValue}
            fase="terminacao"
            faseLabel="Terminação"
            gpd={lote?.fases?.terminacao.gpd}
            dataInicial={lote?.fases?.terminacao.inicio}
            dataFinal={lote?.fases?.terminacao.fim}
            pesoInicial={lote?.fases?.terminacao.pesoInicial}
            pesoFinal={lote?.fases?.terminacao.pesoFinal}
            qntdInicial={lote?.fases?.terminacao.saldoInicial}
            qntdFinal={lote?.fases?.terminacao.saldoFinal}
            previsao={lote?.fases?.terminacao.previsao}
          />
        )}

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.previsaoVenda !== next.previsaoVenda
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="previsaoVenda"
                label="Previsão de Venda"
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <PigDatePicker
                  disabled
                  startValue={getFieldValue('previsaoVenda')}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SaveOutlined />}
              loading={isLoading || isUpdating}
              onClick={() => {
                form.submit();
              }}
            >
              Atualizar
            </Button>
          </Col>
        </Row>
      </Form>

      {lote.fase && lote.fase !== 'SEM_SALDO' && lote.fase !== 'TERMINACAO' && (
        <Divider orientation="left" style={{ marginTop: '32px' }}>
          {'Encerrar fase da(o) ' + lote.fase.toLowerCase()}
        </Divider>
      )}

      {lote.fase && lote.fase !== 'SEM_SALDO' && lote.fase !== 'TERMINACAO' && (
        <EncerrarFase
          loteId={lote.id}
          fase={lote?.fase}
          tipoLote={lote.tipo}
          dataNascimento={lote.dataNascimento}
          submitCallback={() => {
            onSubmitCallback();
          }}
        />
      )}
    </>
  );
};
export default InformacoesBasicas;
