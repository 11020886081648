import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  Form,
  Table,
  Row,
  Col,
  Button,
  ConfigProvider,
  Empty,
  Input,
  notification
} from 'antd';

import { applyMaskDecimalWithComma } from 'utils/masks';
import { formatToBRL, formatToISO, mapperTagStatusAnimal } from 'utils/utils';
import { PageRequest } from 'api/models';

import CustomRangePicker from 'components/CustomRangePicker';
import {
  ListarMatrizesDescartadasResponse,
  useListarMatrizesDescartadas
} from 'api/relatorios/matrizes/listarMatrizesDescartadas/useListarMatrizesDescartadas';
import { usePDFMatrizesDescartadas } from 'api/relatorios/matrizes/getPDFMatrizesDescartadas/useGetPDFMatrizesDescartadas';

type SearchParams = PageRequest & {
  codigo?: string;
  periodo?: [Date, Date];
};

const RelatorioMatrizesDescartadas: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    codigo: null,
    periodo: [null, null]
  });
  const { data, isLoading } = useListarMatrizesDescartadas({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFMatrizesDescartadas({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    },
    onSuccess: (data) => {
      JsFileDownload(data, `matrizes-descartadas.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            periodo: values.periodo
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Matriz"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Período"
              name="periodo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ periodo: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum descarte encontrado!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          rowKey={'id'}
          style={{ marginTop: '32px' }}
          dataSource={data?.items || []}
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          pagination={{
            size: 'small',
            total: data?.total || 0,
            pageSize: searchParams.perPage,
            showTotal: (total, range) => `${total} registros`,
            showSizeChanger: true,
            onShowSizeChange: (_, pageSize) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: 0,
                perPage: pageSize
              }));
            },
            onChange: (page) => {
              setSearchParams((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
          columns={[
            {
              title: 'Nº Matriz',
              dataIndex: 'codigo'
            },
            {
              title: 'Total Partos',
              dataIndex: 'totalPartos'
            },
            {
              title: 'Idade (dias)',
              dataIndex: 'idade'
            },
            {
              title: 'Status',
              render: (record: ListarMatrizesDescartadasResponse) =>
                mapperTagStatusAnimal(record.status)
            },
            {
              title: 'Data',
              render: (record: ListarMatrizesDescartadasResponse) =>
                formatToBRL(record.data)
            },
            {
              title: 'Motivo',
              render: (record: ListarMatrizesDescartadasResponse) =>
                record.motivo.descricao
            },
            {
              title: 'Peso (Kg)',
              render: (record: ListarMatrizesDescartadasResponse) =>
                applyMaskDecimalWithComma(record.peso)
            },
            {
              title: 'Aprov. Financeiro. (R$)',
              render: (record: ListarMatrizesDescartadasResponse) =>
                applyMaskDecimalWithComma(record.aprovFinanceiro)
            }
          ]}
          summary={(data) => {
            const totais = data
              .map((i) => ({
                partos: i.totalPartos,
                idade: i.idade,
                pesoMedio: i.peso,
                aprov: i.aprovFinanceiro
              }))
              .reduce(
                (acc, i) => ({
                  partos: i.partos + acc.partos,
                  idade: i.idade + acc.idade,
                  pesoMedio: i.pesoMedio + acc.pesoMedio,
                  aprov: i.aprov + acc.aprov
                }),
                {
                  partos: 0,
                  idade: 0,
                  pesoMedio: 0,
                  aprov: 0
                }
              );

            return (
              <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                <Table.Summary.Cell index={0} colSpan={1} align="left">
                  Médias
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1} align="left">
                  {applyMaskDecimalWithComma(totais.partos / data.length, 0)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={1} align="left">
                  {applyMaskDecimalWithComma(totais.idade / data.length, 0)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={1} align="left" />
                <Table.Summary.Cell index={4} colSpan={1} align="left" />
                <Table.Summary.Cell index={5} colSpan={1} align="left" />
                <Table.Summary.Cell index={6} colSpan={1} align="left">
                  {applyMaskDecimalWithComma(totais.pesoMedio / data.length, 2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} colSpan={1} align="left">
                  {applyMaskDecimalWithComma(totais.aprov / data.length, 2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default RelatorioMatrizesDescartadas;
