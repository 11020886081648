import { Select, SelectProps } from 'antd';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';

import { scoreCorporalOptions } from 'models';

const SelectScoreCorporal: React.FC<SelectProps> = ({
  placeholder,
  ...rest
}) => {
  const getColor = (variant: string) => {
    if (variant === 'UM' || variant === 'CINCO') return 'red';
    if (variant === 'DOIS' || variant === 'QUATRO') return 'orange';
    if (variant === 'TRES') return 'green';
    return 'black';
  };

  const getIcon = (variant: string) => {
    if (variant === 'UM' || variant === 'CINCO')
      return <WarningOutlined style={{ color: 'red' }} />;
    if (variant === 'DOIS' || variant === 'QUATRO')
      return <WarningOutlined style={{ color: 'orange' }} />;
    return <CheckCircleOutlined style={{ color: 'green' }} />;
  };

  return (
    <Select placeholder={placeholder ? placeholder : 'Selecionar'} {...rest}>
      {scoreCorporalOptions.map((tipo) => (
        <Select.Option key={tipo.key} value={tipo.key}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              color: getColor(tipo.key)
            }}
          >
            {tipo.title} {getIcon(tipo.key)}
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectScoreCorporal;
