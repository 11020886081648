import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Table } from 'antd';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';

import { formatToBRL, formatToISO } from 'utils/utils';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { applyMaskDecimalWithComma } from 'utils/masks';
import CustomRangePicker from 'components/CustomRangePicker';
import { MapaUsoResponse } from 'api/relatorios/reprodutores/types';
import { useGetMapaUso, useGetMapaUsoPDF } from 'api/relatorios/reprodutores';

export type ServicosPorDia = {
  groupedBy: string;
  usoTotal: number;
  servicos: MapaUsoResponse[];
};

const RelatorioMapaUsoReprodutores: React.FC = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<ServicosPorDia[]>([]);
  const [searchParams, setSearchParams] = useState({
    page: 0,
    perPage: 20,
    periodo: null,
    codigoIn: null
  });
  const { data, isLoading } = useGetMapaUso({
    queryParams: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      periodo: searchParams?.periodo,
      codigoIn: searchParams?.codigoIn
    }
  });
  const { fetchData: fetchPDF, isLoading: isLoadingPDF } = useGetMapaUsoPDF({
    queryParams: {
      periodo: searchParams?.periodo,
      codigoIn: searchParams?.codigoIn
    },
    onSuccess: (data) => {
      useDownloadFile(data, 'mapa-uso.pdf');
    }
  });

  const getColumns = () => {
    if (dataSource.length > 0) {
      const codigos = Array.from(new Set(data?.items.map((i) => i.codigo)));

      const columns = codigos.map((codigo) => ({
        title: codigo,
        children: [
          {
            title: 'IA',
            render: (record: ServicosPorDia) => {
              const servico = record.servicos.filter(
                (s) => s.codigo === codigo
              );
              return servico.length > 0 ? servico.at(0).totalColetas : 0;
            }
          },
          {
            title: 'MN',
            render: (record: ServicosPorDia) => {
              const servico = record.servicos.filter(
                (s) => s.codigo === codigo
              );
              return servico.length > 0 ? servico.at(0).totalMontas : 0;
            }
          }
        ]
      }));

      return [
        {
          title: 'Data',
          render: (record: ServicosPorDia) => formatToBRL(record.groupedBy)
        },
        ...columns,
        {
          title: 'Total',
          render: (record: ServicosPorDia) => record.usoTotal
        }
      ];
    }

    return [{ title: 'Data' }, { title: 'Total' }];
  };

  const groupByData = (items: MapaUsoResponse[]): ServicosPorDia[] => {
    const rows: ServicosPorDia[] = [];

    const dias = Array.from(new Set(items.map((i) => i.data)));

    dias.forEach((d) => {
      const servicosDia = items.filter((i) => i.data === d);
      const usoTotalDia = servicosDia
        .map((i) => i.total)
        .reduce((acc, current) => acc + current, 0);

      rows.push({
        groupedBy: d,
        usoTotal: usoTotalDia,
        servicos: servicosDia
      });
    });

    return rows;
  };

  const groupByCodigo = (items: MapaUsoResponse[]): ServicosPorDia[] => {
    const rows: ServicosPorDia[] = [];

    const codigos = Array.from(new Set(items.map((i) => i.codigo)));

    codigos.forEach((c) => {
      const servicos = items.filter((i) => i.codigo === c);
      const usoTotal = servicos
        .map((i) => i.total)
        .reduce((acc, current) => acc + current, 0);

      rows.push({
        groupedBy: c,
        usoTotal: usoTotal,
        servicos: servicos
      });
    });

    return rows;
  };

  useEffect(() => {
    setDataSource(groupByData(data?.items || []));
  }, [data]);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          setSearchParams((prev) => ({
            ...prev,
            periodo: {
              inicio: formatToISO(values?.dataUso[0]),
              fim: formatToISO(values?.dataUso[1])
            },
            codigoIn: values?.codigo ? [values?.codigo] : null
          }));
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Reprodutor"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Data do Uso"
              name="dataUso"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório'
                },
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject();
                    if (!value[0] || !value[1]) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ dataUso: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                fetchPDF();
              }}
            >
              PDF
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        bordered
        size="small"
        style={{ marginTop: '32px' }}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined spin />
        }}
        pagination={false}
        columns={getColumns()}
        dataSource={dataSource}
        summary={(rows) => {
          const items = groupByCodigo(data?.items || []);
          const totalServicos = items
            .map((i) => i.usoTotal)
            .reduce((acc, i) => acc + i, 0);

          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                {items.map((i, index) => {
                  const porcentagemUso = applyMaskDecimalWithComma(
                    (i.usoTotal / totalServicos) * 100
                  );

                  return (
                    <Table.Summary.Cell
                      index={index + 1}
                      key={`${i?.groupedBy}`}
                      align="center"
                      colSpan={2}
                    >
                      {`${i.usoTotal} (${porcentagemUso}%)`}
                    </Table.Summary.Cell>
                  );
                })}
                <Table.Summary.Cell index={rows ? rows.length + 3 : 1}>
                  {`${totalServicos} (100%)`}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </>
  );
};

export default RelatorioMapaUsoReprodutores;
