import { authActionsType } from './actionsType';

export function signInRequest(email, senha) {
  return {
    type: authActionsType.SIGN_IN_REQUEST,
    payload: { email, senha }
  };
}

export function signInSuccess(token, user) {
  return {
    type: authActionsType.SIGN_IN_SUCCESS,
    payload: { token, user }
  };
}

export function signFailure() {
  return {
    type: authActionsType.SIGN_FAILURE
  };
}

export function signOut() {
  return {
    type: authActionsType.SIGN_OUT
  };
}
