import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import RelatorioMapaUsoReprodutores from './RelatorioMapaUsoReprodutores';

const RelatorioReprodutores: React.FC = () => {
  const dispatch = useDispatch();
  const collapseStyle = { marginTop: '16px' };

  useEffect(() => {
    dispatch(updateID('relatorios-reprodutores'));
    dispatch(updateTitle('Relatórios dos Reprodutores'));
  }, []);

  return (
    <div style={{ marginBottom: '64px' }}>
      <Collapse>
        <Collapse.Panel key="mapa-uso" header="Mapa de Uso">
          <RelatorioMapaUsoReprodutores />
        </Collapse.Panel>
      </Collapse>

      {/* <Collapse style={collapseStyle}>
        <Collapse.Panel key="matriz-descartes-por-motivo" header="Descartes">
          <RelatorioDescarte tipoSuino="REPRODUTOR" />
        </Collapse.Panel>
      </Collapse> */}
    </div>
  );
};

export default RelatorioReprodutores;
