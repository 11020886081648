import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Input, Row, Col, Button, notification } from 'antd';

import { formatToBRL, formatToISO } from 'utils/utils';

import { PageRequest } from 'api/models';

import { CustomTable } from 'components';
import CustomRangePicker from 'components/CustomRangePicker';
import {
  ListarLeitoaResponse,
  useListarLeitoas
} from 'api/relatorios/matrizes/listarLeitoas/useListarLeitoas';
import { usePDFLeitoas } from 'api/relatorios/matrizes/getPDFLeitoas/useGetPDFLeitoas';

type SearchParams = PageRequest & {
  codigo?: string;
  partoPrevisto?: [Date, Date];
  cioPrevisto?: [Date, Date];
};

const RelatorioLeitoas: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    codigo: null,
    partoPrevisto: [null, null],
    cioPrevisto: [null, null]
  });
  const { data, isLoading } = useListarLeitoas({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      partoPrevisto: {
        inicio: searchParams?.partoPrevisto[0]
          ? formatToISO(searchParams.partoPrevisto[0])
          : undefined,
        fim: searchParams?.partoPrevisto[1]
          ? formatToISO(searchParams.partoPrevisto[1])
          : undefined
      },
      cioPrevisto: {
        inicio: searchParams?.cioPrevisto[0]
          ? formatToISO(searchParams.cioPrevisto[0])
          : undefined,
        fim: searchParams?.cioPrevisto[1]
          ? formatToISO(searchParams.cioPrevisto[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFLeitoas({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      partoPrevisto: {
        inicio: searchParams?.partoPrevisto[0]
          ? formatToISO(searchParams.partoPrevisto[0])
          : undefined,
        fim: searchParams?.partoPrevisto[1]
          ? formatToISO(searchParams.partoPrevisto[1])
          : undefined
      },
      cioPrevisto: {
        inicio: searchParams?.cioPrevisto[0]
          ? formatToISO(searchParams.cioPrevisto[0])
          : undefined,
        fim: searchParams?.cioPrevisto[1]
          ? formatToISO(searchParams.cioPrevisto[1])
          : undefined
      }
    },
    onSuccess: (data) => {
      JsFileDownload(data, `leitoas.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            partoPrevisto: values.partoPrevisto,
            cioPrevisto: values.cioPrevisto
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Leitoa"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Parto Previsto"
              name="partoPrevisto"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                initialValue={searchParams?.partoPrevisto || undefined}
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ partoPrevisto: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Cio Previsto"
              name="cioPrevisto"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                initialValue={searchParams?.cioPrevisto || undefined}
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ cioPrevisto: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <CustomTable
        isLoading={isLoading}
        style={{ marginTop: '32px' }}
        emptyMsg="Nenhuma marrã encontrada"
        data={data?.items || []}
        pagination={{
          page: searchParams.page,
          perPage: searchParams.perPage,
          totalElements: data?.total || 0,
          totalPages: data?.totalPages || 0
        }}
        changePageSize={(_, size) => {
          setSearchParams((currentState) => ({
            ...currentState,
            page: 0,
            perPage: size
          }));
        }}
        changePageNumber={(page) => {
          setSearchParams((currentState) => ({
            ...currentState,
            page: page - 1
          }));
        }}
        columns={[
          {
            title: 'Nº Matriz',
            dataIndex: 'codigo'
          },
          {
            title: 'Idade (dias)',
            dataIndex: 'idade'
          },
          {
            title: 'Idade 1ª Cobertura (dias)',
            dataIndex: 'idadePrimeiraCobertura'
          },
          {
            title: 'Cobertura',
            render: (record: ListarLeitoaResponse) =>
              formatToBRL(record.primeiraCobertura)
          },
          {
            title: 'Gestação (dias)',
            dataIndex: 'diasGestante'
          },
          {
            title: 'Parto Previsto',
            render: (record: ListarLeitoaResponse) =>
              formatToBRL(record.partoPrevisto)
          },
          {
            title: 'Cio Previsto',
            render: (record: ListarLeitoaResponse) =>
              formatToBRL(record.cioPrevisto)
          },
          {
            title: 'Entrada Plantel',
            render: (record: ListarLeitoaResponse) =>
              formatToBRL(record.entradaPlanel)
          },
          {
            title: 'Tempo Plantel (dias)',
            dataIndex: 'tempoPlantel'
          }
        ]}
      />
    </>
  );
};

export default RelatorioLeitoas;
