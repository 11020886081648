import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Card as AntdCard,
  List,
  ConfigProvider,
  Empty,
  Form,
  Row,
  Col,
  Input
} from 'antd';
import {
  SearchOutlined,
  ArrowUpOutlined,
  LoadingOutlined
} from '@ant-design/icons';

import history from 'services/history';

import { useDidMount } from 'hooks';
import { IPaginacao } from 'models';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import {
  ListarReprodutorRequest,
  ListarReprodutorResponse,
  listarReprodutores
} from 'api/listarReprodutores/useListarReprodutores';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import Card from 'components/Card';
import { getColorByType } from 'utils/utils';

const Reprodutores: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isMounted = useDidMount();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    perPage: 50
  } as ListarReprodutorRequest);
  const [suinos, setSuinos] = useState<ListarReprodutorResponse[]>([]);
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    page: 0,
    perPage: 50,
    totalItems: 0,
    totalPages: 0
  });

  const buscarSuinos = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const response = await listarReprodutores({
          codigo: params.codigo,
          status: params.status,
          page: params.page,
          perPage: params.perPage
        });
        setPaginacao((prev) => ({
          ...prev,
          totalItems: response.total
        }));
        setSuinos((prev) => [...prev, ...response.items]);
      } catch (error) {
        setSuinos([]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isMounted) {
      setSuinos([]);
      if (paginacao.page !== 0) {
        setPaginacao((prev) => ({ ...prev, page: 0 }));
      } else {
        buscarSuinos();
      }
    }
  }, [params]);

  useEffect(() => {
    buscarSuinos();
  }, [paginacao.page]);

  useEffect(() => {
    dispatch(updateID('suino-reprodutores'));
    dispatch(updateTitle('Reprodutores'));
  }, []);

  return (
    <>
      <AntdCard key="filtro" title="Filtros" style={{ marginBottom: '16px' }}>
        <Form
          layout="vertical"
          form={form}
          colon={false}
          initialValues={{}}
          onFinish={(values) => {
            setParams((prev) => ({
              ...prev,
              codigo: values.codigo,
              page: 0,
              perPage: 50
            }));
          }}
          wrapperCol={{ style: { width: '100%' } }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Código"
                name="codigo"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Input />
              </Form.Item>
            </Col>

            {/*<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Incluir Descartados?"
                name="incluirDescartados"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Radio.Group>
                  <Radio value={false}>Nao</Radio>
                  <Radio value={true}>Sim</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>*/}
          </Row>

          <Row gutter={[16, 16]} justify="end">
            <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
              <Button
                block
                type="primary"
                htmlType="button"
                loading={loading}
                icon={<SearchOutlined />}
                onClick={() => {
                  form.submit();
                }}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </AntdCard>

      <BackTop
        target={() =>
          document.getElementById('scrollable-content-div') || window
        }
      >
        <Button
          shape="round"
          type="primary"
          htmlType="button"
          icon={<ArrowUpOutlined />}
        >
          Voltar
        </Button>
      </BackTop>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={false}
            description={
              loading ? <LoadingOutlined /> : 'Nenhum reprodutor encontrado!'
            }
          />
        )}
      >
        <InfiniteScrollWrapper
          next={() => {
            setPaginacao((prev) => ({
              ...prev,
              page: prev.page + 1
            }));
          }}
          dataLength={suinos.length}
          hasMore={suinos.length < paginacao.totalItems}
          scrollableTarget="scrollable-content-div"
          style={{ overflow: 'hidden' }}
        >
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 3,
              md: 4,
              lg: 5,
              xl: 5,
              xxl: 6
            }}
            dataSource={suinos}
            renderItem={(suino) => (
              <List.Item key={`item-${suino.id}`}>
                <Card
                  key={suino.id}
                  style={{
                    backgroundColor: getColorByType(suino.status)
                  }}
                  onClick={() => {
                    history.push(`/suino/reprodutor/${suino.id}/ficha`);
                  }}
                >
                  {suino.codigo} - {suino.status}
                </Card>
              </List.Item>
            )}
          />
        </InfiniteScrollWrapper>
      </ConfigProvider>
    </>
  );
};

export default Reprodutores;
