import { Card } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateID, updateTitle } from 'store/modules/currentPage/actions';

export const ComoUsar: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateID('como-usar'));
    dispatch(updateTitle('Como usar?'));
  }, []);

  return (
    <Card title="Como usar?">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <strong>Parabéns!</strong>
        Você acabou ter acesso ao mais moderno e prático sistema de gestão da
        sua suinocultura.
        <br />
        Que vai permitir análises corretas dos índices zootécnico e lhe fornece
        centenas de relatórios de todos os setores da sua granja.
        <br />
        <br />
        Sugestões para implantação do software na sua granja, independentemente
        do tamanho da mesma.
        <br />
        <br />
        O ideal é que suas matrizes e reprodutores estejam todos identificados
        com brinco, mossa ou tatuagem. É ideal também que além da identificação
        individual de cada animal, você tivesse as seguintes informações que
        serão cadastradas no sistema:
        <br />
        <ul style={{ marginLeft: '32px' }}>
          <li>Sexo (fêmea ou macho)</li>
          <li>Número de Identificação (brinco, mossa ou tatuagem)</li>
          <li>Apelido (preta, manchada e etc)</li>
          <li>Registro PBB (número de registro, se for animal registrado)</li>
          <li>Raça (identificar)</li>
          <li>
            Granja de Origem (se foi animal comprado de fora identificar de onde
            veio, se for da sua granja identificar como Plantel)
          </li>
          <li>
            Data de Nascimento (ideal que tenha a data de nascimento, que está
            no boletim ou PBB que vem da granja de origem. Se não tiver a data
            correta, pelo menos aproximada. Dia/Mês/Ano, 00/00/0000)
          </li>
          <li>Idade (em dias vai aparecer neste campo)</li>
          <li>
            Data de entrada no Plantel (data de entrada normalmente 5 a 6 meses
            após os nascimento, se você não tiver a data exata)
          </li>
          <li>
            Tempo de Plantel (em dias, vai aparecer neste campo, considerando a
            data da entrada e o dia de hoje)
          </li>
          <li>
            Peso na Entrada do plantel (peso em kg do animal no dia que chegou
            na granja)
          </li>
          <li>Peso Hoje (pode ou não ser diferente do peso na entrada)</li>
          <li>
            GPD (ganho de peso diário, esta informação vai aparecer
            automaticamente após cadastrar a idade e o peso)
          </li>
          <li>Pai (número do pai, raça ou origem, se tiver esta informação)</li>
          <li>Mãe (número do mãe, raça ou origem, se tiver esta informação)</li>
          <li>
            <div>
              Score Corporal (estado físico hoje)
              <ol style={{ marginLeft: '16px' }}>
                <li>MUITO MAGRA</li>
                <li>MAGRA</li>
                <li>NORMAL (IDEAL)</li>
                <li>GORDA</li>
                <li>MUITO GORDA</li>
              </ol>
            </div>
          </li>
          <li>
            Partos Pré Sistema (ao cadastrar fêmea vai aparecer este campo, onde
            deverá ser cadastrado o número de partos desta matriz, que ela já
            teve, se for marrã ou leitoa o ciclo será zero, se for matriz que já
            pariu anteriormente, informar o número de partos já ocorridos, ex:
            1, 2, 3, 4, 5, 6...)
          </li>
        </ul>
        <br />
        Da mesma forma que cadastrou as matrizes, agora cadastrar os
        reprodutores da sua granja, que terá as mesmas informações da matriz,
        menos o parto pré sistema.
        <br />
        <br />
        Terminando de cadastrar as matrizes e reprodutores, vamos para a segunda
        etapa.
        <br />
        <br />
        <strong>Cadastrar as Coberturas e ou Inseminação</strong>
        O ideal e que tenha as anotações das ÚLTIMAS COBERTURAS. Coloque -as em
        ordem crescente de 150 dias atrás, até as coberturas de hoje. Exemplo,
        estamos hoje no dia 10 de janeiro.
        <br />
        Volte 150 dias atrás: que neste exercício seria 10 agosto (lembre-se que
        é uma simulação de datas).
        <br />
        <br />
        <span>
          Voltando 150 dias atrás, comece a cadastrar as coberturas e ou
          inseminações das matrizes. Se for Inseminação, deverá cadastrar a
          coleta de sêmen, qual reprodutor doador do plantel ou sêmen comprado
          de fora, a data, o numero de doses e se tiver o valor R$ de cada dose.
        </span>
        <br />
        <strong>Cadastro Cobertura</strong>
        O alvo de cobertura de uma granja, é de 5% por SEMANA das fêmeas do
        plantel.
        <br />
        <br />
        Exemplo: se meu plantel é de 20 matrizes, devo me organizar para cobrir
        1 matriz por semana.
        <br />
        <br />
        {`Ao clicar no BUSCAR MATRIZ, vai aparecer a relação de todas as suas
          matrizes cadastradas, no sistema "que até então" estão todas vazias
          (no sistema)`}
        <br />
        <br />
        Clique no número da matriz escolhida, SELECIONAR, cadastrar o SCORE
        CORPORAL (como vimos acima: 1, 2, 3, 4, e 5), ao escolher o score
        corporal, cadastre o peso aproximado (em kg).
        <br />
        <br />
        Tipo de Cobertura: pode ser Monta Natural e ou Inseminação (que deve ter
        cadastrado a coleta de sêmen). Ao confirmar o tipo de cobertura, vai
        para data da cobertura (ex: 21/08/2023) e na frente vai aparecer a data
        no CP (Calendário Pig).
        <br />
        <br />
        Confirmado a data da primeira cobertura, vai aparecer Reprodutores: vai
        selecionar o reprodutor que fez a primeira monta ADICIONAR.
        <br />
        Vai selecionar o reprodutor e a data da segunda monta, que pode ter sido
        no mesmo dia ou no dia seguinte ADICIONAR.
        <br />
        Vai selecionar o reprodutor e a data da terceira monta, ADICIONAR.
        <br />
        E assim sucessivamente todas as montas e ou inseminação do reprodutor ou
        reprodutores, se for mais de um que cobriu esta fêmea neste cio.
        Confirmou ADICIONAR. Aí confirma FINALIZAR.
        <br />
        <br />
        A MATRIZ passa a ser uma matriz gestante, e você já tem relatório de
        gestantes, previsão de partos, possíveis repetições de cio.
        <br />
        <br />
        Controle de uso dos seus reprodutores, Número de montas que cada um tem
        feito por mês e ou coleta de sêmen.
        <br />
        <br />
        Assim proceder até ter todas as matrizes do plantel cadastradas com as
        coberturas.
        <br />
        Neste período que está cadastrando as cobertura, se tiver alguma RC
        repetição de cio, será cadastrada RC , da mesma forma cadastrada ABORTOS
        SE HOUVER.
        <br />
        Ao cadastrar Repetição de Cio ou Aborto, a Matriz muda da condição de
        GESTANTE para VAZIA, Até que seja cadastrada nova cobertura, para que
        ela volte a condição de GESTANTE.
        <br />
        <br />
        Após cadastrar todas as coberturas. Terá como emitir relatório de
        Matrizes GESTANTES.
        <br />
        <br />
        Vai perceber durante os cadastros das ocorrências, que temos as
        categorias de matrizes Vazias (ainda não cobertas), Desmamadas, Marrãs
        (novas virgens que nunca foram cobertas)
        <br />
        <br />
        Gestantes: que podem ser matrizes de diversos partos, anteriores que não
        foi cadastrado, e não deve cadastrar. E as Leitoas Primíparas cobertas
        pela primeira vez que ainda não pariu.
        <br />
        <br />
        Depois teremos as Lactantes.
        <br />
        <br />
        Acabamos de cadastrar as coberturas RETROATIVAS, 150 dias atrás, temos
        fêmeas das primeiras coberturas, que estão PARIDAS na maternidade,
        <br />
        <br />
        Vamos cadastrar os PARTOS, daqui para frente, seguindo a ordem
        cronológica das coberturas e gestação, e data REAL DO PARTO
        <br />
        <br />
        <strong>CADASTRO DO PARTO</strong>
        Vá no MENU, em Histórico. Clique aí
        <br />
        Vai abrir tela de históricos de cobertura, em ordem cronológica do
        primeiro parto previsto para o segundo, terceiro, quarto... Nesta tela
        você tem opção de cadastrar:
        <ul style={{ marginLeft: '32px' }}>
          <li>Parto (ícone verde)</li>
          <li>Repetição de Cio (ícone amarelo)</li>
          <li>Aborto (ícone vermelho)</li>
        </ul>
        Vamos cadastrar parto (ícone verde)
        <br />
        Tem relatório das fêmeas com parto previsto.
        <br />
        Escolheu PARTO da primeira fêmea, vai pedir SCORE CORPORAL (1,2,3,4 ou
        5). A finalidade de cadastrar o SCORE CORPORAL, é para avaliar o manejo
        nutricional, a qualidade da nutrição, se tivermos fêmeas variando muito
        no SCORE, Precisamos rever nosso manejo nutricional e as formulas das
        nossas rações. Cadastrar PESO por Kg da matriz (ex: 245 Kg).
        <br />
        <br />
        Aparece data da Cobertura e data do parto Previsto.
        <br />
        <br />
        Vamos cadastrar data do PARTO (REAL) DIA 21/12/2023 CP... Ao cadastrar a
        data do parto (REAL), sempre conferir quantos dias foi a gestação, 112 a
        117 dias em media, não deveria ser menos e nem mais que isto.
        <br />
        <br />
        Confirmar a raça do lote.
        <br />
        Peso médio ao nascer. Ex: 1,45 Kg Peso Médio dos leitões, (pesa a
        leitegada toda e divide pelo número de leitões nascidos vivos)
        <br />
        Quantidade de Nascidos VIVOS
        <br />
        Natimortos <br /> Mumificados
        <br /> Ao confirmar o PARTO, vai aparecer o numero do LOTE.
        <br /> Que no sistema da sua granja será lote 1, 2, 3, 4... Cada PARTO
        vai gerar um LOTE.
        <br />
        <br />
        Após 1 ano de cadastro de PARTOS, uma granja de 100 matrizes deverá ter
        pelo menos 245 lotes. Esta referência serve para qualquer granja, de
        qualquer tamanho. A regra é a mesma, para cada PARTO cadastrado temos um
        LOTE gerado.
        <br />
        <br />
        <strong>
          Informações Básicas do Lote (todos os dados de cada lote)
        </strong>
        <ul style={{ marginLeft: '32px' }}>
          <li>Número do Lote (gerado automaticamente no cadastro do parto)</li>
          <li>
            Tipo do Lote
            <ul style={{ marginLeft: '16px' }}>
              <li>
                Lote interno (os que foram gerados de um parto cadastrado)
              </li>
              <li>
                Lote externo (para cadastrar lotes de animais comprados de
                outras granjas)
              </li>
            </ul>
          </li>

          <li>
            Status (local onde o lote se encontra)
            <ul style={{ marginLeft: '16px' }}>
              <li>Maternidade (aguardando a desmama)</li>
              <li>Creche</li>
              <li>Crescimento</li>
              <li>Terminação (aguardando a venda)</li>
            </ul>
          </li>
          <li>Raça</li>
          <li>Idade</li>
          <li>Peso ao Nascer</li>
          <li>Saldo (estoque)</li>
          <li>Nascidos Vivos (NV)</li>
          <li>Nascidos Natimortos (NN)</li>
          <li>Nascidos Mumificados (NM)</li>
          <li>Total Nascidos (NV + NN + NM)</li>
          <li>Número da Mãe</li>
          <li>Raça da Mãe</li>
          <li>Número do Pai</li>
          <li>Raça do Pai</li>
          <li>Datas de MANEJO (para serem cadastradas)</li>
        </ul>
        Saída da Maternidade (Desmama) ao cadastrar a desmama, a matriz passa da
        condição de LACTANTE para VAZIA (DESMAMADA), já aparecendo a previsão do
        próximo CIO/COBERTURA.
        <br />
        Ao cadastrar a data da desmama e peso da desmama, o sistema já calcula o
        GPD na maternidade.
        <br />
        Após a DESMAMA, os lotes estarão na CRECHE (período entre a desmama e
        Crescimento)
        <br />
        <br />
        <strong>CRECHE</strong>
        Na saída da creche anotar a data da saída, peso médio dos animais na
        saída, (peso médio por animal) Anotando a data da saída da creche e o
        peso (Kg) o sistema vai calcular o GPD neste período de creche.
        <br />
        <br />
        <strong>CRESCIMENTO</strong>
        Ao cadastrar saída de creche, o animal automaticamente esta no setor de
        crescimento. Na saída do crescimento anotar a data e o peso médio.
        <br />
        <br />
        <strong>TERMINAÇÃO</strong>
        Ao cadastrar a saída do crescimento o animal esta no setor de terminação
        (engorda ou acabamento, que vai até a venda).
        <br />
        <br />
        Havendo mortes de animais e ou transferência, procure fazer o cadastro,
        dentro do período correto em que ocorreu o evento.
        <br />
        <br />
        <strong>Morte no Lote</strong>
        <ul style={{ marginLeft: '32px' }}>
          <li>Data da Morte (dia da ocorrência)</li>
          <li>Qntd de Animais (número de animais que vieram a óbito)</li>
          <li>Peso Médio/Animal (média de peso dos animais mortos)</li>
          <li>
            Peso Descartado (será calculado automaticamente com base no peso
            médio e o número de animais)
          </li>
          <li>
            Causa da Morte (irá constar nos relatórios de manejo, ajudando a
            identificar possíveis problemas)
          </li>
        </ul>
        <strong>Transferência no Lote</strong>
        <ul style={{ marginLeft: '32px' }}>
          <li>Data</li>
          <li>Qntd de Animais (número de animais transferidos)</li>
          <li>Tipo da Operação (saída ou entrada)</li>
          <li>Lote de Origem (DOADOR)</li>
          <li>Lote de Destino (RECEPTOR)</li>
        </ul>
        <br />
        <strong>PREVISÃO DE VENDA</strong>
        A VENDA (real) pode ocorrer em qualquer fase, leitões na desmama, na
        creche, no crescimento ou na terminação, sempre lembrando de cadastrar a
        venda no dia da ocorrência, o peso médio (em kg) de cada animal e o
        valor R$, para ir para as RECEITAS DA GRANJA.
        <br />
        Cadastrar a data da VENDA, que pode ser o lote inteiro ou parcial, ate 1
        animal de cada vez, não deixe de cadastrar a DATA, o PESO de cada animal
        e o valor total de cada operação de venda.
        <br />
        Ao cadastrar a venda vai aparecer o GPD do nascimento até a venda, o GPD
        no período da terminação e o valor do Kg vivo (R$/Kg) de cada animal
        vendido.
        <br />
        <br />
        Ao iniciar as vendas cadastrados no sistema, recomendo começar a
        cadastrar as rações consumidas. Ex: Kg de ração Pré Inicial. (ou
        qualquer outra) comprada PRONTA, ou fabricada na granja, Hoje 100 Kg de
        ração Pré inicial, 200 Kg de ração Crescimento e etc. Vai cadastrando
        todo dia a ração comprada (Kg) ou fabricada. Assim procedendo, no fim do
        mês terá a conversão alimentar CA de cevado, e de rebanho. Veja os ALVOS
        DE PRODUÇÃO.
        <br />
        <br />
        RECAPITULANDO
        <br />
        Quando cadastrar a DESMAMA, a fêmea (desmamada) que era LACTANTE, passa
        a ser VAZIA, apta a ser coberta assim que entrar no cio novamente (pós
        desmame) Todos os relatórios de MANEJO, OCORRENCIAS, PREVISÃO, FICHAS DE
        LOTES, MATRIZ, REPRODUTOR, ALVOS DE PRODUÇÃO, RESULTADO GERAL DE
        SUINOCULTURA, ESTOQUE DA GRANJA, MAPA DE USO DE REPRODUTOR, entre outras
        (DEZENAS DE RELATORIOS) podem ser transformados em PDF, e impressos.
        <br />
        <br />
        Qualquer dúvida na avaliação zootécnica da sua granja, estou a
        disposição.
        <br />
        <br />
        Eduardo von Atzingen
        <br />
        Responsável Zootécnico
        <br />
        Whatsapp: (62) 99605-0507
        <br />
        E-mail: sossuinos1@uol.com.br
      </div>
    </Card>
  );
};
