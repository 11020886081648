import { parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { SendOutlined, ClearOutlined } from '@ant-design/icons';
import { Form, Input, Row, Col, Button, notification } from 'antd';

import api from 'services/api';
import { DescarteForm } from 'models';
import { applyMaskDecimalWithComma, integerRegex } from 'utils/masks';

import PigDatePicker from 'components/PigDatePicker';
import SelectMotivoDescarte from 'components/SelectMotivoDescarte';
import { SuinoDescarteResponse } from 'api/getMatrizById/useGetMatrizById';

interface DescarteProps {
  suinoId: string;
  suinoSexo: string;
  descarte: SuinoDescarteResponse;
  onSubmitCallback: (id: string) => void;
}

const Descarte: React.FC<DescarteProps> = ({
  suinoId,
  suinoSexo,
  descarte,
  onSubmitCallback
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const registrarDescarte = async (values: DescarteForm) => {
    if (!loading) {
      setLoading(true);
      try {
        const sexo = suinoSexo === 'FEMEA' ? 'matriz' : 'reprodutor';
        const { data } = await api.post(`${sexo}/${suinoId}/descartar`, values);
        notification.success({ message: 'Descarte registrado com sucesso!' });
        form.setFieldsValue({
          suinoUUID: suinoId,
          motivo: values.motivo,
          pesoDescarte: values.peso
            ? String(values.peso).replace('.', ',')
            : null,
          valorVenda: values.aprovFinanceiro
            ? String(values.aprovFinanceiro).replace('.', ',')
            : null,
          dataDescarte: parseISO(values.data),
          observacao: values.observacao
        });
        onSubmitCallback(data.id);
      } catch (error) {
        notification.error({
          message: 'Erro ao registrar descarte!'
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (descarte) {
      form.setFieldsValue({
        suinoUUID: suinoId,
        motivo: descarte.motivo,
        pesoDescarte: applyMaskDecimalWithComma(descarte.peso),
        valorVenda: applyMaskDecimalWithComma(
          descarte.aproveitamentoFinanceiro
        ),
        dataDescarte: parseISO(descarte.data),
        observacao: descarte.observacao
      });
    }
  }, [descarte]);

  return (
    <Form
      layout="vertical"
      colon={false}
      form={form}
      wrapperCol={{ style: { width: '100%' } }}
      onFinish={(values) => {
        registrarDescarte({
          motivo: values.motivo,
          peso: values.pesoDescarte,
          aprovFinanceiro: values.valorVenda,
          data: values.dataDescarte,
          observacao: values.observacao
        });
      }}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Motivo"
            name="motivo"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <SelectMotivoDescarte
              showSearch
              disabled={!!descarte}
              placeholder="Selecionar"
              allowClear
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.dataDescarte !== next.dataDescarte
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                label="Data do Descarte"
                name="dataDescarte"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <PigDatePicker
                  disabled={!!descarte}
                  startValue={getFieldValue('dataDescarte')}
                  callback={(_, date) => {
                    form.setFieldsValue({ dataDescarte: date });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Peso no Descarte"
            name="pesoDescarte"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[
              {
                pattern: integerRegex,
                message: 'Deve estar no formato 0'
              }
            ]}
          >
            <Input disabled={!!descarte} suffix="Kgs" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Form.Item
            label="Aproveitamento Financeiro"
            name="valorVenda"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[
              {
                pattern: integerRegex,
                message: 'Deve estar no formato 0'
              }
            ]}
          >
            <Input disabled={!!descarte} prefix="R$" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Observação"
            name="observacao"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Input.TextArea disabled={!!descarte} showCount maxLength={250} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="end" style={{ marginTop: '32px' }}>
        <Col span={4}>
          <Button
            block
            danger
            disabled={loading || !!descarte}
            htmlType="button"
            icon={<ClearOutlined />}
            onClick={() => {
              form.resetFields();
            }}
          >
            Limpar
          </Button>
        </Col>

        <Col span={4}>
          <Button
            block
            type="primary"
            htmlType="button"
            loading={loading}
            disabled={!!descarte}
            icon={<SendOutlined />}
            onClick={() => {
              form.submit();
            }}
          >
            Registrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Descarte;
