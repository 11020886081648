import api from 'services/api';
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from 'react-query';

import { applyHttpRangeFormat } from 'api/useHttp';
import { UseQueryPDFInput } from 'api/relatorios/types';
import { ListarPrevisaoVendaLeitaoRequest } from '../listarPrevisaoVendaLeitao/useListarPrevisaoVendaLeitao';

export const getPDFVendaLeitaoPrevista = async (
  params: ListarPrevisaoVendaLeitaoRequest
): Promise<Blob> => {
  const { data } = await api.get<Blob>('relatorio/lote/vendas-previstas/pdf', {
    responseType: 'blob',
    params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
  });
  return data;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const usePDFVendaLeitaoPrevista = (
  input: UseQueryPDFInput<ListarPrevisaoVendaLeitaoRequest>
): OutputParams => {
  return useQuery(
    ['get-pdf-fase-vendas-previstas', input.params],
    () => getPDFVendaLeitaoPrevista(input.params),
    {
      enabled: false,
      onError: input.onError,
      onSuccess: input.onSuccess
    }
  );
};
