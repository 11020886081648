import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  StopOutlined
} from '@ant-design/icons';

import { formatToBRL } from 'utils/utils';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import { PageRequest } from 'api/models';

import { CustomTable, Button } from 'components';
import ModalParto, { IModalPartoProps } from './ModalParto';
import ModalAborto, { IModalAbortoProps } from './ModalAborto';
import { ModalExclusao, ModalExclusaoProps } from './ModalExcluir';
import ModalRepeticaoCio, {
  IModalRepeticaoCioProps
} from './ModalRepeticaoCio';
import {
  ListarGestanteResponse,
  useListGestantes
} from 'api/relatorios/matrizes';

const matrizEmpty = {
  id: '',
  codigo: '',
  dataCobertura: ''
};

const CoberturaHistorico: React.FC = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState<PageRequest>({
    page: 0,
    perPage: 10
  });
  const { data, isLoading, refetch } = useListGestantes({
    queryParams: { page: pagination.page, perPage: pagination.perPage }
  });
  const resetPagination = () => {
    if (pagination.page == 0 && pagination.perPage == 10) {
      refetch();
    } else {
      setPagination({ page: 0, perPage: 10 });
    }
  };
  const [aborto, setAborto] = useState<IModalAbortoProps>({
    visible: false,
    matrizGestante: matrizEmpty,
    onCancel: () => {
      setAborto((currentState) => ({
        ...currentState,
        visible: false,
        matrizGestante: matrizEmpty
      }));
    },
    callback: resetPagination
  });
  const [exclusao, setExclusao] = useState<ModalExclusaoProps>({
    visible: false,
    matriz: {
      id: matrizEmpty.id,
      codigo: matrizEmpty.codigo
    },
    onCancel: () => {
      setExclusao((currentState) => ({
        ...currentState,
        visible: false,
        matriz: {
          id: matrizEmpty.id,
          codigo: matrizEmpty.codigo
        }
      }));
    },
    onCallback: resetPagination
  });
  const [repeticaoCio, setRepeticaoCio] = useState<IModalRepeticaoCioProps>({
    visible: false,
    matrizGestante: matrizEmpty,
    onCancel: () => {
      setRepeticaoCio((currentState) => ({
        ...currentState,
        visible: false,
        matrizGestante: matrizEmpty
      }));
    },
    callback: resetPagination
  });
  const [parto, setParto] = useState<IModalPartoProps>({
    visible: false,
    matrizGestante: undefined,
    onCancel: () => {
      setParto((currentState) => ({
        ...currentState,
        visible: false,
        matrizGestante: undefined
      }));
    },
    callback: resetPagination
  });
  const columns = [
    {
      title: 'Nº Matriz',
      dataIndex: 'codigo'
    },
    {
      title: 'Data Cobertura',
      dataIndex: 'dataCobertura',
      render: (value: string) => formatToBRL(value)
    },
    {
      title: 'Nº Reprodutor',
      dataIndex: 'pai',
      render: (value: { codigo: string }) => value.codigo
    },
    {
      title: 'Dias de Gestação',
      dataIndex: 'diasGestante'
    },
    {
      title: 'Cio Previsto',
      dataIndex: 'cioPrevisto',
      render: (value: string) => formatToBRL(value)
    },
    {
      title: 'Parto Previsto',
      dataIndex: 'partoPrevisto',
      render: (value: string) => formatToBRL(value)
    },
    {
      title: 'Parto',
      dataIndex: '',
      render: (record: ListarGestanteResponse) => (
        <Button
          shape="round"
          variant="success"
          icon={<EditOutlined />}
          onClick={() => {
            setParto((currentState) => ({
              ...currentState,
              visible: true,
              matrizGestante: record
            }));
          }}
        />
      )
    },
    {
      title: 'Rep de Cio',
      dataIndex: '',
      render: (record: ListarGestanteResponse) => (
        <Button
          shape="round"
          variant="alert"
          icon={<HistoryOutlined />}
          onClick={() => {
            setRepeticaoCio((currentState) => ({
              ...currentState,
              visible: true,
              matrizGestante: {
                id: record.id,
                codigo: record.codigo,
                dataCobertura: record.dataCobertura
              }
            }));
          }}
        />
      )
    },
    {
      title: 'Aborto',
      dataIndex: '',
      render: (record: ListarGestanteResponse) => (
        <Button
          shape="round"
          variant="danger"
          icon={<StopOutlined />}
          onClick={() => {
            setAborto((currentState) => ({
              ...currentState,
              visible: true,
              matrizGestante: {
                id: record.id,
                codigo: record.codigo,
                dataCobertura: record.dataCobertura
              }
            }));
          }}
        />
      )
    },
    {
      title: 'Excluir',
      dataIndex: '',
      render: (record: ListarGestanteResponse) => (
        <Button
          shape="round"
          variant="delete"
          icon={<DeleteOutlined />}
          onClick={() => {
            setExclusao((currentState) => ({
              ...currentState,
              visible: true,
              matriz: {
                id: record.id,
                codigo: record.codigo
              }
            }));
          }}
        />
      )
    }
  ];

  useEffect(() => {
    dispatch(updateID('cobertura-historico'));
    dispatch(updateTitle('Histórico das Coberturas'));
  }, []);

  return (
    <Card style={{ margin: '0px 0px 64px 0px' }}>
      <ModalParto {...parto} />
      <ModalAborto {...aborto} />
      <ModalExclusao {...exclusao} />
      <ModalRepeticaoCio {...repeticaoCio} />

      <CustomTable
        style={{}}
        columns={columns}
        emptyMsg="Nenhuma cobertura encontrada"
        data={data?.items || []}
        isLoading={isLoading}
        pagination={{
          page: pagination.page,
          perPage: pagination.perPage,
          totalPages: data?.totalPages || 0,
          totalElements: data?.total || 0
        }}
        changePageNumber={(page) => {
          setPagination((currentState) => ({
            ...currentState,
            page: page - 1
          }));
        }}
        changePageSize={(_, size) => {
          setPagination({ page: 0, perPage: size });
        }}
      />
    </Card>
  );
};

export default CoberturaHistorico;
