import { UseMutateFunction, useMutation } from 'react-query';
import api from 'services/api';
import { InputParams } from 'api/models';

export type CadastrarPartoRequest = {
  matriz: {
    peso: number;
    scoreCorporal: string;
  };
  data: string;
  duracaoLactacao: number;
  loteRacaId: string;
  pesoMedio: number;
  vivos: number;
  natimortos: number;
  mumificados: number;
};

export type CadastrarPartoResponse = {
  idLote: string;
  numeroLote: string;
};

const cadastrarParto = async (
  matrizId: string,
  body: CadastrarPartoRequest
): Promise<CadastrarPartoResponse> => {
  const response = await api.put<CadastrarPartoResponse>(
    `matriz/${matrizId}/parir`,
    body
  );
  return response.data;
};

type Variables = {
  matrizId: string;
  body: CadastrarPartoRequest;
};

export type OutputParams = {
  mutate: UseMutateFunction<
    CadastrarPartoResponse,
    unknown,
    Variables,
    unknown
  >;
  isLoading: boolean;
};

export const useCadastrarParto = ({
  onSuccess,
  onError
}: InputParams<CadastrarPartoResponse>): OutputParams => {
  return useMutation(
    (params: Variables) => cadastrarParto(params.matrizId, params.body),
    {
      onSuccess,
      onError
    }
  );
};
