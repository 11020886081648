import { ListarGestanteRequest } from '../types';
import { BaseHttpParams, applyHttpRangeFormat, useHttp } from 'api/useHttp';

type InputParams = BaseHttpParams<Blob> & {
  queryParams?: ListarGestanteRequest;
};

export const useGetPDFGestantes = (input: InputParams) => {
  const useHttpResp = useHttp<void, any, Blob>({
    url: 'relatorio/matrizes/gestantes/pdf',
    method: 'GET',
    onSuccess: input.onSuccess,
    onError: input.onError,
    responseType: 'blob'
  });

  const fetchData = (queryParams: ListarGestanteRequest = {}) => {
    useHttpResp.fetchData({
      queryParams: {
        page: queryParams?.page || input.queryParams.page,
        perPage: queryParams?.perPage || input.queryParams.perPage,
        codigo: queryParams?.codigo || input.queryParams.codigo,
        cioPrevisto: applyHttpRangeFormat(
          queryParams?.cioPrevisto || input.queryParams.cioPrevisto
        ),
        partoPrevisto: applyHttpRangeFormat(
          queryParams?.partoPrevisto || input.queryParams.partoPrevisto
        )
      }
    });
  };

  return { ...useHttpResp, fetchData };
};
