import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  html, body, #root {
    min-height: 100%;
    background: ${(props) => props.theme.backgroundColor};
  }

  body {
    -webkit-font-smoothing: antialiased !important;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  .ant-input[disabled], .ant-input-suffix, .ant-input-prefix {
    color: #000 !important;
  }

  .ant-input-number-input[disabled] {
    color: #000 !important;
  }
  
  .ant-select-selection-item, .ant-select-selection-search-input, 
  .ant-select-arrow {
    color: #000 !important;
  }

  .ant-picker-input > input, .ant-picker-suffix {
    color: #000 !important;
  }
`;
