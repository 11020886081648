import { useDispatch } from 'react-redux';
import { Button, Col, Form, Input, notification, Row, Typography } from 'antd';

import {
  LoginRequest,
  LoginResponse,
  useMakeLogin
} from 'api/autenticacao/useMakeLogin';
import { signInSuccess } from 'store/modules/auth/actions';

const responsaveis = [
  {
    nome: 'Eduardo von Atzingen',
    funcao: 'Responsável Zootécnico',
    whatsapp: '(62) 99605-0507',
    email: 'sossuinos1@uol.com.br'
  },
  {
    nome: 'Marcos Laelber',
    funcao: 'Responsável Técnico',
    whatsapp: '(88) 99640-3189',
    email: 'laelber1@gmail.com'
  },
  {
    nome: 'Wilker Bruno',
    funcao: 'Responsável Técnico',
    whatsapp: '(88) 99938-2182 ',
    email: 'wilkerbruno16@gmail.com'
  }
];

const Autenticacao: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMakeLogin({
    onSuccess: (data: LoginResponse) => {
      form.resetFields();
      dispatch(
        signInSuccess(data.accessToken, {
          id: data.id,
          nome: data.nome,
          nomeGranja: data.nomeGranja,
          contrato: data?.contrato || undefined
        })
      );
    },
    onError: () => {
      notification.warning({
        message: 'Credenciais inválidas!',
        description: 'Por favor, verifique suas credenciais e tente novamente.'
      });
    }
  });

  const autenticar = async (values: LoginRequest) => {
    try {
      mutate(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row style={{ height: '100vh' }}>
      <Col span={14} style={{ backgroundColor: '#4cab5b' }}>
        <Typography.Title
          level={3}
          style={{ color: '#fff', marginLeft: '48px', marginTop: '64px' }}
        >
          MeuSuíno
        </Typography.Title>
        <Typography.Text italic style={{ color: '#fff', marginLeft: '48px' }}>
          Sistema para gerenciamento de granjas de suinocultura.
        </Typography.Text>

        <Typography.Title
          level={3}
          style={{ color: '#fff', marginLeft: '48px', marginTop: '64px' }}
        >
          Responsáveis
        </Typography.Title>
        {responsaveis.map((r) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '16px'
            }}
            key={r.email}
          >
            <Typography.Text style={{ color: '#fff', marginLeft: '48px' }}>
              Nome: {r.nome}
            </Typography.Text>
            <Typography.Text style={{ color: '#fff', marginLeft: '48px' }}>
              Função: {r.funcao}
            </Typography.Text>
            <Typography.Text style={{ color: '#fff', marginLeft: '48px' }}>
              Whatsapp: {r.whatsapp}
            </Typography.Text>
            <Typography.Text style={{ color: '#fff', marginLeft: '48px' }}>
              E-mail: {r.email}
            </Typography.Text>
          </div>
        ))}
      </Col>

      <Col span={10}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: '#fff'
          }}
        >
          <Typography.Title level={3} style={{ margin: '64px 0px 48px 0px' }}>
            Acessar MeuSuíno
          </Typography.Title>

          <Form
            layout="vertical"
            form={form}
            colon={false}
            className="login-form"
            wrapperCol={{ style: { width: '100%' } }}
            onFinish={(values) => {
              autenticar({ email: values.email, senha: values.senha });
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="senha"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '8px'
              }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.Password />
            </Form.Item>

            {/* <Form.Item>
              <Button
                type="text"
                htmlType="button"
                style={{ padding: '0px', margin: '0px' }}
                onClick={() => history.push('/esqueci-minha-senha')}
              >
                Esqueci minha senha
              </Button>
            </Form.Item> */}

            <Form.Item style={{ marginBottom: '8px', marginTop: '48px' }}>
              <Button
                block
                type="primary"
                htmlType="button"
                loading={isLoading}
                className="login-form-button"
                style={{
                  backgroundColor: '#4cab5b',
                  borderWidth: 0
                }}
                onClick={() => {
                  form.submit();
                }}
              >
                {isLoading ? '' : 'Entrar'}
              </Button>
            </Form.Item>

            {/* <Form.Item noStyle>
              <Button
                type="text"
                htmlType="button"
                className="login-form-button"
                style={{ padding: '0px' }}
                onClick={() => history.push('/criar-conta')}
              >
                Não tem uma conta? Crie agora
              </Button>
            </Form.Item> */}
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Autenticacao;
