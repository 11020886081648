import { useEffect, useState } from 'react';
import { SendOutlined, ClearOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  notification,
  Divider,
  Table
} from 'antd';

import api from 'services/api';
import { AvaliacaoCorporalForm, scoreCorporalOptions } from 'models';
import PigDatePicker from 'components/PigDatePicker';
import SelectScoreCorporal from 'components/SelectScoreCorporal';
import { formatToBRL } from 'utils/utils';
import { applyMaskDecimalWithComma } from 'utils/masks';

interface ScoreCorporalProps {
  suinoId: string;
  suinoSexo: string;
  isDescartado: boolean;
}

const ScoreCorporal: React.FC<ScoreCorporalProps> = ({
  suinoId,
  suinoSexo,
  isDescartado
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [loadingAvaliacoes, setLoadingAvaliacoes] = useState(false);

  const registrarAvaliacao = async (values: AvaliacaoCorporalForm) => {
    if (!loading) {
      setLoading(true);
      try {
        const sexo = suinoSexo === 'FEMEA' ? 'matriz' : 'reprodutor';
        await api.post(`${sexo}/${suinoId}/avaliar`, values);
        form.resetFields();
        notification.success({ message: 'Avaliação registrada com sucesso!' });
        getAvaliacoes();
      } catch (error) {
        notification.error({
          message: 'Erro ao registrar avaliação!'
        });
      }
      setLoading(false);
    }
  };

  const getAvaliacoes = async () => {
    if (!loadingAvaliacoes) {
      setLoadingAvaliacoes(true);
      try {
        const { data } = await api.get(`suino/${suinoId}/avaliacao-corporal`);
        setAvaliacoes(data);
      } catch (e) {
        notification.error({
          message: 'Erro ao buscar avaliações!'
        });
      }
      setLoadingAvaliacoes(false);
    }
  };

  useEffect(() => {
    getAvaliacoes();
  }, [suinoId]);

  return (
    <>
      <Form
        layout="vertical"
        colon={false}
        form={form}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          registrarAvaliacao({
            score: values.scoreCorporal,
            peso: values.peso,
            data: values.dataAvaliacao,
            observacao: values.observacao
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Score Corporal"
              name="scoreCorporal"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectScoreCorporal
                placeholder="Selecionar"
                disabled={isDescartado}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.dataAvaliacao !== next.dataAvaliacao
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Data da Avaliação"
                  name="dataAvaliacao"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    disabled={isDescartado}
                    startValue={getFieldValue('dataAvaliacao')}
                    callback={(_, date) => {
                      form.setFieldsValue({ dataAvaliacao: date });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso na Avaliação"
              name="peso"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  pattern: new RegExp(/^([0-9]{1,})$/, 'g'),
                  message: 'Deve estar no formato 0'
                }
              ]}
            >
              <Input suffix="Kgs" disabled={isDescartado} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Observação"
              name="observacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input.TextArea
                showCount
                maxLength={250}
                disabled={isDescartado}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end" style={{ marginTop: '32px' }}>
          <Col span={4}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              disabled={loading || isDescartado}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>
          <Col span={4}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={loading}
              disabled={isDescartado}
              onClick={() => {
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider orientation="left">Histórico</Divider>

      <Table
        rowKey={'id'}
        size="small"
        dataSource={avaliacoes}
        loading={loading || loadingAvaliacoes}
        pagination={{
          size: 'small',
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          showSizeChanger: true
        }}
        columns={[
          {
            key: 'data',
            title: 'Data',
            dataIndex: 'data',
            render: (value) => formatToBRL(value)
          },
          {
            key: 'score',
            title: 'Score',
            dataIndex: 'score',
            render: (value) => {
              const values = scoreCorporalOptions.find((e) => e.key === value);
              return values ? values.title : 'N/A';
            }
          },
          {
            key: 'peso',
            title: 'Peso',
            dataIndex: 'peso',
            render: (value) => {
              return value ? applyMaskDecimalWithComma(value, 0) : 0.0;
            }
          },
          {
            key: 'observacao',
            title: 'Observação',
            dataIndex: 'observacao'
          }
        ]}
      />
    </>
  );
};

export default ScoreCorporal;
