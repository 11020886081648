import { ColumnsType } from 'antd/lib/table';
import { ConfigProvider, Empty, Table } from 'antd';

import { IPagination } from 'models';

interface Props {
  emptyMsg?: string;
  isLoading: boolean;
  columns: ColumnsType<any>;
  data: any[];
  hasPagination?: boolean;
  pagination?: IPagination;
  changePageNumber?: (page: number) => void;
  changePageSize?: (current: number, size: number) => void;
  summary?: (data: readonly any[]) => React.ReactNode;
  scroll?: { x?: string | number | true; y?: string | number } & {
    scrollToFirstRowOnChange?: boolean;
  };
  style?: React.CSSProperties;
}

export const CustomTable: React.FC<Props> = ({
  emptyMsg = 'Nenhum resultado encontrado',
  isLoading,
  columns,
  data,
  hasPagination = true,
  pagination = {} as IPagination,
  changePageNumber,
  changePageSize,
  summary,
  scroll,
  style
}) => {
  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyMsg} />
      )}
    >
      <Table
        bordered
        rowKey={'id'}
        size="small"
        loading={isLoading}
        style={style}
        scroll={scroll}
        summary={summary}
        columns={columns}
        dataSource={data}
        pagination={
          hasPagination
            ? {
                size: 'small',
                total: pagination.totalElements,
                pageSize: pagination.perPage,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total}`,
                showSizeChanger: true,
                onShowSizeChange: (current, size) => {
                  changePageSize(current, size);
                },
                onChange: (page) => {
                  changePageNumber(page);
                }
              }
            : false
        }
      />
    </ConfigProvider>
  );
};

export default CustomTable;
