import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';

import { findAllCodigos } from 'services';
import { MatrizCodigoDTO, TipoSuino } from 'models';

interface Props extends SelectProps {
  tipos?: TipoSuino[];
}

export const SelectSuinoCodigo: React.FC<Props> = ({ tipos, ...props }) => {
  const [codigos, setCodigos] = useState<MatrizCodigoDTO[]>([]);

  const getCodigos = async () => {
    try {
      const data = await findAllCodigos(tipos);
      setCodigos(data);
    } catch (error) {
      setCodigos([]);
    }
  };

  useEffect(() => {
    getCodigos();
  }, []);

  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .startsWith(input.toLowerCase())
      }
    >
      {codigos.map((codigo) => (
        <Select.Option key={codigo.codigo} value={codigo.codigo}>
          {`${codigo.codigo} - ${codigo.apelido}`}
        </Select.Option>
      ))}
    </Select>
  );
};
export default SelectSuinoCodigo;
