import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Form, Card, Button, Modal, Input, notification } from 'antd';
import { CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

import { Table } from './table';
import { buscarTodasRacas } from 'store/modules/racas/actions';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';
import {
  CadastrarRacaRequest,
  CadastrarRacaResponse,
  ListarRacaRequest,
  useCreateRaca,
  useListRaca
} from 'api/racas';

const NovaRaca: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: (raca: CadastrarRacaResponse) => void;
  onError: (error: unknown) => void;
}> = ({ open, setOpen, onSuccess, onError }) => {
  const [form] = Form.useForm<CadastrarRacaRequest>();
  const { isLoading, fetchData } = useCreateRaca({ onSuccess, onError });

  return (
    <Modal
      open={open}
      title="Nova Raça"
      okText="Salvar"
      onOk={() => form.submit()}
      okButtonProps={{
        icon: <SaveOutlined />
      }}
      onCancel={() => {
        form.resetFields(['nome']);
        setOpen(false);
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        danger: true,
        icon: <CloseOutlined />
      }}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        colon={false}
        layout="vertical"
        onFinish={(values) => {
          fetchData({ body: { nome: values.nome } });
        }}
      >
        <Form.Item
          label="Nome"
          name="nome"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Raca: React.FC = (): any => {
  const dispatch = useDispatch();
  const [params, setParams] = useState<ListarRacaRequest>({
    page: 0,
    perPage: 10
  });
  const [openNovaRaca, setOpenNovaRaca] = useState(false);
  const { data, isLoading, refetch } = useListRaca({
    queryParams: params
  });

  useEffect(() => {
    dispatch(updateID('raca'));
    dispatch(updateTitle('Raças'));
  }, []);

  return (
    <Card
      title={
        <Button
          type="primary"
          htmlType="button"
          icon={<PlusOutlined />}
          onClick={() => setOpenNovaRaca(true)}
        >
          Nova Raça
        </Button>
      }
      style={{ margin: '0px 0px 64px 0px' }}
    >
      <NovaRaca
        open={openNovaRaca}
        setOpen={(value) => {
          setOpenNovaRaca(value);
        }}
        onSuccess={(raca) => {
          refetch();
          setOpenNovaRaca(false);
          dispatch(buscarTodasRacas());
          notification.success({ message: 'Raça cadastrada com sucesso' });
        }}
        onError={(error) => {
          notification.error({ message: 'Erro ao cadastrar raça' });
        }}
      />

      <Table
        isLoading={isLoading}
        onSaveCallback={() => {
          refetch();
          dispatch(buscarTodasRacas());
        }}
        data={data?.items || []}
        pagination={{
          page: data?.page || 0,
          perPage: data?.perPage || 10,
          totalElements: data?.total || 0,
          totalPages: data?.totalPages || 0
        }}
        changePageSize={(_, perPage) => {
          setParams((currentState) => ({
            ...currentState,
            page: 0,
            perPage: perPage
          }));
        }}
        changePageNumber={(page) => {
          setParams((currentState) => ({
            ...currentState,
            page: page - 1
          }));
        }}
      />
    </Card>
  );
};

export default Raca;
