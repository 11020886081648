import { authActionsType } from '../auth/actionsType';

/* eslint-disable no-param-reassign */
const INITIAL_STATE = {
  id: '',
  title: ''
};

export default function currentPage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@current_page/UPDATE_ID':
      return { ...state, id: action.payload.id };
    case '@current_page/UPDATE_TITLE':
      return { ...state, title: action.payload.title };
    case authActionsType.SIGN_OUT:
      return { ...state, id: '', title: '' };
    default:
      return state;
  }
}
