import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import moment from 'moment';
import { DatePicker, Input } from 'antd';
import { useEffect, useState } from 'react';

type Props = {
  startValue?: Date;
  placeholder?: string;
  format?: string;
  disabled?: boolean;
  callback?: (pigDay: number | null, gregorianDay: Date | null) => void;
};

const PigDatePicker: React.FC<Props> = ({
  startValue,
  placeholder = 'dd/mm/aaaa',
  format = 'DD/MM/YYYY',
  disabled = false,
  callback = (pigDay, gregorianDay) => {
    //
  }
}) => {
  const [pigDay, setPigDay] = useState<number | null>(null);
  const [date, setDate] = useState<Date | null>(null);

  const calculatePigDay = (date: moment.Moment) => {
    const initPigCalendar = moment([1969, 0, 1]);
    const totalDays = date.diff(initPigCalendar, 'days');
    return (totalDays % 1000) + 1;
  };

  useEffect(() => {
    if (startValue) setDate(startValue);
  }, [startValue]);

  useEffect(() => {
    if (date) setPigDay(calculatePigDay(moment(date)));
    else setPigDay(null);
  }, [date]);

  useEffect(() => {
    callback(pigDay, date);
  }, [pigDay]);

  return (
    <div
      style={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1.2fr 0.8fr'
      }}
    >
      <DatePicker
        disabled={disabled}
        placeholder={placeholder}
        format={format}
        locale={locale}
        style={{ width: '100%' }}
        value={date ? moment(date, format) : null}
        onChange={(date) => {
          if (moment.isMoment(date)) {
            setDate(date.toDate());
            // setPigDay(calculatePigDay(date));
          } else if (!date) {
            setDate(null);
            // setPigDay(null);
          }
        }}
      />
      <Input
        disabled
        value={pigDay ? 'CP ' + pigDay : 'CP '}
        title="Calendário Pig"
      />
    </div>
  );
};

export default PigDatePicker;
