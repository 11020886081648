import { Button, Col, Form, Input, Row, notification } from 'antd';
import { ClearOutlined, SaveOutlined } from '@ant-design/icons';

import {
  convertFormattedToNumber,
  decimalRegexWithComma,
  integerRegex
} from 'utils/masks';

import { useEncerrarFaseLote } from 'api/encerrarFaseLote/useEncerrarFaseLote';

import { countDays, formatToISO } from 'utils/utils';
import PigDatePicker from 'components/PigDatePicker';
import { FormItem, defaultFormProps } from 'components/Antd';
import SelectScoreCorporal from 'components/SelectScoreCorporal';

type IProps = {
  loteId: string;
  dataNascimento: string;
  fase: string | undefined;
  tipoLote: string;
  submitCallback?: () => void;
};

type EncerrarFaseForm = {
  data: Date;
  peso: string;
  mae:
    | {
        peso: string;
        scoreCorporal: string;
      }
    | undefined;
};

export const EncerrarFase: React.FC<IProps> = (props) => {
  const [form] = Form.useForm<EncerrarFaseForm>();
  const isMaternidade =
    props.fase && props.fase.toLowerCase() === 'maternidade';
  const { mutate: encerrarFase, isLoading } = useEncerrarFaseLote({
    onSuccess: () => {
      notification.success({ message: 'Fase encerrada com sucesso!' });
      props.submitCallback();
      form.resetFields();
    },
    onError: () => {
      notification.error({ message: 'Ocorreu um erro ao encerrar a fase' });
    }
  });

  return (
    <Form
      {...defaultFormProps}
      form={form}
      onFinish={(values) => {
        encerrarFase({
          loteId: props.loteId,
          body: {
            matriz: isMaternidade
              ? {
                  peso: convertFormattedToNumber(values.mae.peso),
                  scoreCorporal: values.mae.scoreCorporal
                }
              : undefined,
            data: formatToISO(values.data),
            peso: convertFormattedToNumber(values.peso)
          }
        });
      }}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <FormItem
            label="Data"
            name="data"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              {
                validator: (_, date) => {
                  if (
                    date &&
                    isMaternidade &&
                    countDays(props.dataNascimento, date.toISOString()) > 60
                  ) {
                    return Promise.reject();
                  }

                  return Promise.resolve();
                },
                message: 'Desmamas com mais de 60 dias não são aceitas'
              }
            ]}
          >
            <PigDatePicker
              callback={(_, date) => {
                form.setFieldsValue({
                  data: date
                });
              }}
            />
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <FormItem
            label="Peso Médio do Lote"
            name="peso"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              {
                pattern: decimalRegexWithComma,
                message: 'Deve estar no formato 0,00'
              }
            ]}
          >
            <Input allowClear />
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <FormItem
            label="Peso da Mãe"
            name={['mae', 'peso']}
            hidden={!isMaternidade || props.tipoLote === 'LOTE_EXTERNO'}
            rules={[
              {
                required: isMaternidade && props.tipoLote === 'LOTE_INTERNO',
                message: 'Campo obrigatório'
              },
              {
                pattern: integerRegex,
                message: 'Deve estar no formato 0'
              }
            ]}
          >
            <Input allowClear />
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <FormItem
            label="Score da Mãe"
            name={['mae', 'scoreCorporal']}
            hidden={!isMaternidade || props.tipoLote === 'LOTE_EXTERNO'}
            rules={[
              {
                required: isMaternidade && props.tipoLote === 'LOTE_INTERNO',
                message: 'Campo obrigatório'
              }
            ]}
          >
            <SelectScoreCorporal allowClear />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="end">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Button
            block
            danger
            htmlType="button"
            icon={<ClearOutlined />}
            onClick={() => {
              form.resetFields();
            }}
          >
            Limpar
          </Button>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Button
            block
            type="primary"
            htmlType="button"
            icon={<SaveOutlined />}
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
