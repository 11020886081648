import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import JsFileDownload from 'js-file-download';
import { Button, Card, notification } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

import { ITabKey } from 'models';
import { updateTitle } from 'store/modules/currentPage/actions';
import { LoteResponse, useGetLoteById } from 'api/getLoteById/useGetLoteById';
import { useGetMortesByLoteId } from 'api/getMortesByLoteId/useGetMortesByLoteId';
import { useGetVendasByLoteId } from 'api/getVendasByLoteId/useGetVendasByLoteId';
import { useGetTransferenciasByLoteId } from 'api/getTransferenciasByLoteId/useGetTransferenciasByLoteId';

import Morte from './Morte';
import Venda from './Venda';
import { Adocao } from './Adocao';
import Transferencia from './Transferencia';
import InformacoesBasicas from './InformacoesBasicas';
import { usePDFFichaLote } from 'api/relatorios/getPDFFichaLote/useGetPDFFichaLote';

interface RouteParams {
  uuid: string;
}

const LoteFicha: React.FC = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams<RouteParams>();
  const {
    data: lote,
    isLoading,
    refetch: refetchLote
  } = useGetLoteById({
    id: uuid,
    onError: () => {
      notification.error({
        message: 'Erro ao carregar dados do lote!'
      });
    }
  });
  const { data: mortes, refetch: refetchMortes } = useGetMortesByLoteId({
    id: uuid,
    onError: () => {
      notification.error({ message: 'Ocorreu um erro ao carregar as mortes' });
    }
  });
  const { data: vendas, refetch: refetchVendas } = useGetVendasByLoteId({
    id: uuid,
    onError: () => {
      notification.error({ message: 'Ocorreu um erro ao carregar as vendas' });
    }
  });
  const { data: transferencias, refetch: refetchTransferencias } =
    useGetTransferenciasByLoteId({
      id: uuid,
      onError: () => {
        notification.error({
          message: 'Ocorreu um erro ao carregar os dados do lote'
        });
      }
    });
  const { isLoading: isLoadingFicha, refetch: refetchFicha } = usePDFFichaLote({
    params: {
      id: uuid
    },
    onSuccess: (reprot) => {
      JsFileDownload(reprot, `lote-${lote.codigo}.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });
  const [activeTabKey, setActiveTabKey] = useState('informacoes');
  const tabs: ITabKey = {
    adocao: (
      <Adocao
        loteId={lote?.id || ''}
        exigirMaeBiologica={lote?.tipo && lote.tipo === 'LOTE_INTERNO'}
      />
    ),
    informacoes: (
      <InformacoesBasicas
        isLoading={isLoading}
        lote={lote || ({} as LoteResponse)}
        onSubmitCallback={() => {
          refetchLote();
        }}
      />
    ),
    transferencia: (
      <Transferencia
        lote={{
          id: uuid,
          numero: lote?.codigo || '',
          saldo: lote?.saldo || 0
        }}
        transferencias={transferencias}
        onSubmitCallback={() => {
          refetchLote();
          refetchTransferencias();
        }}
      />
    ),
    venda: (
      <Venda
        lote={{
          id: uuid,
          saldo: lote?.saldo || 0,
          dataNascimento: lote?.dataNascimento || undefined,
          previsaoVenda: lote?.previsaoVenda || undefined
        }}
        vendas={vendas}
        onSubmitCallback={() => {
          refetchLote();
          refetchVendas();
        }}
      />
    ),
    morte: (
      <Morte
        lote={{
          id: uuid,
          saldo: lote?.saldo || 0
        }}
        mortes={mortes}
        onSubmitCallback={() => {
          refetchLote();
          refetchMortes();
        }}
      />
    )
  };

  useEffect(() => {
    dispatch(updateTitle(`Ficha do Lote`));
  }, []);

  return (
    <Card
      style={{ marginBottom: '200px' }}
      title={`Lote Nº ${lote?.codigo || ''}`}
      extra={
        <Button
          danger
          type="primary"
          htmlType="button"
          icon={<FilePdfOutlined />}
          loading={isLoading || isLoadingFicha}
          onClick={() => {
            refetchFicha();
          }}
        >
          Ficha
        </Button>
      }
      onTabChange={(key) => {
        setActiveTabKey(key);
      }}
      activeTabKey={activeTabKey}
      tabList={[
        { key: 'informacoes', tab: 'Informações Básicas' },
        { key: 'adocao', tab: 'Adoção' },
        { key: 'transferencia', tab: 'Transferências' },
        { key: 'venda', tab: 'Vendas' },
        { key: 'morte', tab: 'Mortes' }
      ]}
    >
      {tabs[activeTabKey]}
    </Card>
  );
};

export default LoteFicha;
