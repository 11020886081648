import { UseMutateFunction, useMutation } from 'react-query';
import api from 'services/api';
import { InputParams } from 'api/models';

export type CadastrarMontaRequest = {
  bisnagaId: string;
  reprodutorId: string;
  doses: number;
  data: string;
  ordem: number;
};

export type CadastrarCoberturaRequest = {
  coberturas: CadastrarMontaRequest[];
  peso: number;
  scoreCorporal: string;
};

export type CadastrarCoberturaResponse = {
  loteId: string;
};

const cadastrarCobertura = async (
  matrizId: string,
  body: CadastrarCoberturaRequest
): Promise<CadastrarCoberturaResponse> => {
  const response = await api.post<CadastrarCoberturaResponse>(
    `matriz/${matrizId}/cobrir`,
    body
  );
  return response.data;
};

type Variables = {
  matrizId: string;
  body: CadastrarCoberturaRequest;
};

export type OutputParams = {
  mutate: UseMutateFunction<
    CadastrarCoberturaResponse,
    unknown,
    Variables,
    unknown
  >;
  isLoading: boolean;
};

export const useCadastrarCobertura = ({
  onSuccess,
  onError
}: InputParams<CadastrarCoberturaResponse>): OutputParams => {
  const { mutate, isLoading } = useMutation(
    (params: Variables) => cadastrarCobertura(params.matrizId, params.body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
