import { parseISO } from 'date-fns';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, notification, Row } from 'antd';

import { countDays, formatToBRL, formatToISO } from 'utils/utils';
import { convertFormattedToNumber, integerRegex } from 'utils/masks';
import { useCadastrarRepeticaoCio } from 'api/cadastrarRepeticaoCio/useCadastrarRepeticaoCio';

import { FormItem } from 'components/Antd';
import PigDatePicker from 'components/PigDatePicker';
import SelectScoreCorporal from 'components/SelectScoreCorporal';

export interface IModalRepeticaoCioProps {
  visible: boolean;
  matrizGestante: { id: string; codigo: string; dataCobertura: string };
  onCancel: () => void;
  callback?: () => void;
}

const ModalRepeticaoCio: React.FC<IModalRepeticaoCioProps> = ({
  visible,
  matrizGestante,
  onCancel,
  callback
}) => {
  const [form] = Form.useForm();
  const { mutate: cadastrarAborto, isLoading } = useCadastrarRepeticaoCio({
    onSuccess: () => {
      notification.success({
        message: 'Repetição de Cio cadastrado com sucesso'
      });
      form.resetFields();
      onCancel();
      callback();
    },
    onError: () => {
      notification.error({
        message: 'Ocorreu um erro ao cadastrar a repetição de cio'
      });
    }
  });

  return (
    <Modal
      open={visible}
      confirmLoading={isLoading}
      title={`Repetição Cio - ${matrizGestante?.codigo}`}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      okText="Registrar"
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{
        icon: <SendOutlined />
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        icon: <CloseOutlined />,
        danger: true
      }}
    >
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        initialValues={{
          dataCobertura: matrizGestante?.dataCobertura
            ? formatToBRL(matrizGestante.dataCobertura)
            : undefined
        }}
        onFinish={(values) => {
          cadastrarAborto({
            matrizId: matrizGestante.id,
            body: {
              data: formatToISO(values.dataRepeticaoCio),
              peso: convertFormattedToNumber(values.matriz.peso),
              scoreCorporal: values.matriz.scoreCorporal
            }
          });
        }}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <FormItem
              label="Data da Cobertura"
              name={['dataCobertura']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <PigDatePicker
                disabled
                startValue={parseISO(matrizGestante.dataCobertura)}
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label="Dias Não Produtivos"
              name={['diasNaoProdutivos']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled suffix="dias" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <FormItem
              label="Matriz - Score Corporal"
              name={['matriz', 'scoreCorporal']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectScoreCorporal allowClear />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label="Matriz - Peso"
              name={['matriz', 'peso']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: integerRegex,
                  message: 'Deve estar no formato 0'
                }
              ]}
            >
              <Input suffix="kgs" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.dataRepeticaoCio !== next.dataRepeticaoCio
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  required
                  name="dataRepeticaoCio"
                  label="Data da Repetição Cio"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataRepeticaoCio')}
                    callback={(_, date) => {
                      form.setFieldsValue({
                        dataRepeticaoCio: date,
                        diasNaoProdutivos:
                          date && matrizGestante?.dataCobertura
                            ? countDays(
                                matrizGestante.dataCobertura,
                                date.toISOString()
                              )
                            : '-'
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default ModalRepeticaoCio;
