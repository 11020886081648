import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateID, updateTitle } from 'store/modules/currentPage/actions';
import TotalDeracaoCosumida from './ProducaoTotalRacao';
const RelatorioRacao: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateID('relatorios-racao'));
    dispatch(updateTitle('Relatórios de Ração'));
  }, []);

  return (
    <div style={{ marginBottom: '64px' }}>
      <Collapse>
        <Collapse.Panel
          key="producao-total-racao"
          header="Total de Ração Produzida"
        >
          <TotalDeracaoCosumida />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default RelatorioRacao;
