import axios from 'axios';
import { store } from '../store';
import { signOut } from '../store/modules/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
  (request) => {
    if (
      !(
        (request.url === '/autenticacao' &&
          request.method.toUpperCase() === 'POST') ||
        (request.url === '/produtor' &&
          request.method.toUpperCase() === 'POST') ||
        (request.url === '/produtor/esqueci-minha-senha' &&
          request.method.toUpperCase() === 'POST') ||
        (request.url.startsWith('/produtor/esqueci-minha-senha/') &&
          request.method.toUpperCase() === 'GET') ||
        (request.url.startsWith('/produtor/redefinir-senha/') &&
          request.method.toUpperCase() === 'POST') ||
        (request.url.startsWith('/produtor/confirmar-cadastro/') &&
          request.method.toUpperCase() === 'POST')
      )
    ) {
      request.headers.Authorization = `Bearer ${store.getState()?.auth?.token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

export default api;
