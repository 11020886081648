import { descarteActionsType } from './actionsType';
import { authActionsType } from '../auth/actionsType';

const INITIAL_STATE = {
  motivos: []
};

export default function descarte(state = INITIAL_STATE, action) {
  switch (action.type) {
    case descarteActionsType.ATUALIZAR_MOTIVOS_EM_CACHE:
      return { motivos: action.payload.motivos };

    case authActionsType.SIGN_OUT:
      return { motivos: [] };

    default:
      return state;
  }
}
