import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Card, Descriptions, notification } from 'antd';

import api from 'services/api';
import { CriterioManejoDTO } from 'models/criterio-manejo';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

const CriteriosManejo: React.FC = () => {
  const dispatch = useDispatch();
  const [criterios, setCriterios] = useState<CriterioManejoDTO[]>([]);

  const buscarCriterios = async () => {
    try {
      const { data } = await api.get('/produtor/criterios-manejo');
      setCriterios(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        notification.error({
          message: 'Erro ao buscar critérios de manejo!',
          description: error.response.data.message
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Erro ao buscar critérios de manejo!',
          description: error.message
        });
      }
    }
  };

  useEffect(() => {
    buscarCriterios();
    dispatch(updateID('criterios-manejo'));
    dispatch(updateTitle('Critérios de Manejo'));
  }, []);

  return (
    <Card title="Critérios de Manejo adotado pelo sistema">
      {criterios.map((criterio) => (
        <Descriptions key={criterio.valorEnum}>
          <Descriptions.Item key={criterio.valorEnum} span={24}>
            {criterio.descricao}? {criterio.quantidadeDias} dias
          </Descriptions.Item>
        </Descriptions>
      ))}
    </Card>
  );
};

export default CriteriosManejo;
