import { useEffect, useState } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import { Col, Form, Input, InputNumber, Modal, notification, Row } from 'antd';

import {
  applyMaskInteger,
  convertFormattedToNumber,
  decimalRegexWithComma,
  integerRegex
} from 'utils/masks';
import { countDays, formatToISO } from 'utils/utils';
import { useCadastrarParto } from 'api/cadastrarParto/useCadastrarParto';

import SelectRacas from 'components/SelectRacas';
import PigDatePicker from 'components/PigDatePicker';
import { FormItem, defaultFormProps } from 'components/Antd';
import SelectScoreCorporal from 'components/SelectScoreCorporal';
import { ListarGestanteResponse } from 'api/relatorios/matrizes';

export interface IModalPartoProps {
  visible: boolean;
  matrizGestante: ListarGestanteResponse;
  onCancel: () => void;
  callback?: () => void;
}

type PartoForm = {
  matrizId: string;
  qntdDiasParaDesmame: number;
  dataInicioGestacao: Date;
  dataNascimento: Date;
  previsaoParto: Date;
  racaId: string;
  diasGestacao: string;
  pesoMedioNascimento: string;
  nascidosVivos: number;
  nascidosNatimortos: number;
  nascidosMumificados: number;
  nascidosTotal: number;
  pai: {
    codigo: string;
    racaId: string;
  };
  mae: {
    codigo: string;
    racaId: string;
  };
  matriz: {
    scoreCorporal: string;
    peso: string;
  };
};

const ModalParto: React.FC<IModalPartoProps> = ({
  visible,
  matrizGestante,
  onCancel,
  callback
}) => {
  const [form] = Form.useForm<PartoForm>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { mutate: cadastrarParto, isLoading } = useCadastrarParto({
    onSuccess: (response) => {
      notification.success({
        message: `Lote Nº ${response.numeroLote} cadastrado com sucesso`
      });
      form.resetFields();
      onCancel();
      callback();
    },
    onError: () => {
      notification.error({ message: 'Ocorreu um erro ao cadastrar o parto' });
    }
  });

  const isValidNumber = (value: any) => {
    if (value === null) return false;
    if (value === undefined) return false;
    return true;
  };

  const onValuesChange = (changedValues: any, values: PartoForm) => {
    if (
      isValidNumber(changedValues.nascidosVivos) ||
      isValidNumber(changedValues.nascidosNatimortos) ||
      isValidNumber(changedValues.nascidosMumificados)
    ) {
      let total = 0;
      if (values.nascidosVivos) total += values.nascidosVivos;
      if (values.nascidosNatimortos) total += values.nascidosNatimortos;
      if (values.nascidosMumificados) total += values.nascidosMumificados;
      form.setFieldsValue({ nascidosTotal: total });
    }
  };

  const checkConfirmation = () => {
    const diasGestante = countDays(
      matrizGestante.dataCobertura,
      form.getFieldValue('dataNascimento').toISOString()
    );

    if (diasGestante < 105 || diasGestante > 125) {
      setOpenConfirmation(true);
    } else {
      onFinish(form.getFieldsValue());
    }
  };

  const onFinish = (values: any) => {
    const formValues = {
      matrizId: matrizGestante.id,
      body: {
        data: formatToISO(values.dataNascimento),
        duracaoLactacao: values.qntdDiasParaDesmame,
        loteRacaId: values.racaId,
        pesoMedio: convertFormattedToNumber(values.pesoMedioNascimento),
        mumificados: values.nascidosMumificados,
        natimortos: values.nascidosNatimortos,
        vivos: values.nascidosVivos,
        matriz: {
          peso: convertFormattedToNumber(values.matriz.peso),
          scoreCorporal: values.matriz.scoreCorporal
        }
      }
    };
    cadastrarParto(formValues);
  };

  useEffect(() => {
    if (matrizGestante) {
      form.setFieldsValue({
        matrizId: matrizGestante.id,
        qntdDiasParaDesmame: 21,
        dataInicioGestacao: parseISO(matrizGestante.dataCobertura),
        dataNascimento: null,
        previsaoParto: parseISO(matrizGestante.partoPrevisto),
        pai: {
          codigo: matrizGestante?.pai?.codigo || undefined,
          racaId: matrizGestante?.pai?.raca?.id || undefined
        },
        mae: {
          codigo: matrizGestante.codigo,
          racaId: matrizGestante?.raca?.id || undefined
        },
        racaId: null,
        diasGestacao: applyMaskInteger(matrizGestante.diasGestante),
        matriz: {
          scoreCorporal: null,
          peso: null
        }
      });
    }
  }, [matrizGestante]);

  return (
    <Modal
      open={visible}
      width="60%"
      confirmLoading={isLoading}
      title={`Parto - ${matrizGestante?.codigo}`}
      onCancel={() => {
        onCancel();
      }}
      okText="Registrar"
      onOk={() => {
        form.validateFields().then(() => {
          checkConfirmation();
        });
      }}
      okButtonProps={{
        icon: <SendOutlined />
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        icon: <CloseOutlined />,
        danger: true
      }}
    >
      <Modal
        open={openConfirmation}
        title="Deseja continuar o cadastro?"
        okText="Confirmar"
        cancelText="Cancelar"
        onOk={() => {
          onFinish(form.getFieldsValue());
          setOpenConfirmation(false);
        }}
        onCancel={() => {
          setOpenConfirmation(false);
        }}
      >
        {`A gestação levou ${
          form.getFieldValue('dataNascimento')
            ? countDays(
                matrizGestante.dataCobertura,
                form.getFieldValue('dataNascimento').toISOString()
              )
            : ''
        } dias e está fora dos padrões`}
      </Modal>

      <Form {...defaultFormProps} form={form} onValuesChange={onValuesChange}>
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <FormItem
              label="Matriz - Score Corporal"
              name={['matriz', 'scoreCorporal']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectScoreCorporal allowClear />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              label="Matriz - Peso"
              name={['matriz', 'peso']}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: integerRegex,
                  message: 'Deve estar no formato 0'
                }
              ]}
            >
              <Input suffix="kgs" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={8}>
            <FormItem
              noStyle
              shouldUpdate={(prev, next) =>
                prev.dataInicioGestacao !== next.dataInicioGestacao
              }
            >
              {({ getFieldValue }) => (
                <FormItem name="dataInicioGestacao" label="Data da Cobertura">
                  <PigDatePicker
                    disabled
                    startValue={getFieldValue('dataInicioGestacao')}
                    callback={(_, date) => {
                      form.setFieldsValue({ dataInicioGestacao: date });
                    }}
                  />
                </FormItem>
              )}
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              noStyle
              shouldUpdate={(prev, next) =>
                prev.previsaoParto !== next.previsaoParto
              }
            >
              {({ getFieldValue }) => (
                <FormItem name="previsaoParto" label="Parto Previsto">
                  <PigDatePicker
                    disabled
                    startValue={getFieldValue('previsaoParto')}
                    callback={(_, date) => {
                      form.setFieldsValue({ previsaoParto: date });
                    }}
                  />
                </FormItem>
              )}
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              noStyle
              shouldUpdate={(prev, next) =>
                prev.dataNascimento !== next.dataNascimento
              }
            >
              {({ getFieldValue }) => (
                <FormItem
                  name="dataNascimento"
                  label="Data do Parto"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataNascimento')}
                    callback={(_, date) => {
                      form.setFieldsValue({
                        dataNascimento: date,
                        diasGestacao: applyMaskInteger(
                          differenceInDays(
                            date,
                            getFieldValue('dataInicioGestacao')
                          )
                        )
                      });
                    }}
                  />
                </FormItem>
              )}
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem name="diasGestacao" label="Dias de Gestação">
              <Input disabled />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              name="racaId"
              label="Raça do Lote"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectRacas placeholder="Selecionar" onlyActive />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              name="pesoMedioNascimento"
              label="Peso Médio Nascimento"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input suffix="Kgs" />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              name="qntdDiasParaDesmame"
              label="Dias p/Desmame"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={0} />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              name="nascidosVivos"
              label="Qntd Vivos"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={0} />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              name="nascidosNatimortos"
              label="Qntd Natimortos"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={0} />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              name="nascidosMumificados"
              label="Qntd Mumificados"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 0, message: 'Não deve ser menor que 0' }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={0} />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              name="nascidosTotal"
              label="Total Nascidos"
              rules={[
                { required: true, message: 'Deve ser maior que 0' },
                { type: 'number', min: 1, message: 'Deve ser maior que 0' }
              ]}
            >
              <InputNumber disabled style={{ width: '100%' }} />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem name={['mae', 'codigo']} label="Nº Mãe">
              <Input disabled />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem name={['mae', 'racaId']} label="Raça da Mãe">
              <SelectRacas disabled placeholder="Selecionar" />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem name={['pai', 'codigo']} label="Nº Pai">
              <Input disabled />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem name={['pai', 'racaId']} label="Raça do Pai">
              <SelectRacas disabled placeholder="Selecionar" />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default ModalParto;
