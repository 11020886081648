import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { RacaResponse } from 'api/models';

export type GenitorResponse = {
  id: string;
  raca: RacaResponse;
  codigo: string;
  tipo: string;
};

export type FaseLoteResponse = {
  id: string;
  tipo: string;
  previsao: boolean;
  inicio: string;
  fim: string;
  pesoInicial: number;
  pesoFinal: number;
  gpd: number;
  saldoInicial: number;
  saldoFinal: number;
};

export type LoteResponse = {
  id: string;
  tipo: string;
  codigo: string;
  fase: string;
  raca: RacaResponse;
  idade: number;
  dataNascimento: string;
  pesoMedioNascimento: number;
  nascidosVivos: number;
  nascidosNatimortos: number;
  nascidosMumificados: number;
  nascidosTotal: number;
  saldo: number;
  pai: GenitorResponse;
  mae: GenitorResponse;
  previsaoVenda: string;
  fases:
    | {
        maternidade: FaseLoteResponse | undefined;
        creche: FaseLoteResponse | undefined;
        crescimento: FaseLoteResponse | undefined;
        terminacao: FaseLoteResponse | undefined;
      }
    | undefined;
};

export const getLoteById = async (id: string): Promise<LoteResponse> => {
  const response = await api.get<LoteResponse>(`lote/${id}`);
  return response.data;
};

export type InputParams = {
  id: string;
  onSuccess?: (lote: LoteResponse) => void;
  onError?: () => void;
};

export type OutputParams = {
  data: LoteResponse;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<LoteResponse, unknown>>;
};

export const useGetLoteById = ({
  id,
  onError,
  onSuccess
}: InputParams): OutputParams => {
  return useQuery(['get-lote-by-id', id], () => getLoteById(id), {
    onError,
    onSuccess
  });
};
