import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'services/api';
import { atualizarRacasEmCache } from './actions';
import { authActionsType } from '../auth/actionsType';
import { racaActionsType } from './actionsType';

export function* buscarTodasRacas() {
  const { data } = yield call(api.get, '/raca', {
    params: {
      page: 0,
      perPage: 100
    }
  });
  yield put(atualizarRacasEmCache(data?.items || []));
}

export default all([
  takeLatest(authActionsType.SIGN_IN_SUCCESS, buscarTodasRacas),
  takeLatest(racaActionsType.LOAD, buscarTodasRacas)
]);
