import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import { Button, Col, Form, Row, Table, notification } from 'antd';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';

import { CustomTable } from 'components';
import CustomRangePicker from 'components/CustomRangePicker';
import {
  formatToISO,
  formatToBRL,
  firstAndLastDayOfMonth as currentMonth
} from 'utils/utils';
import { PageRequest } from 'api/models';
import {
  applyCurrencyMask,
  applyMaskDecimalWithComma,
  applyNumberMask
} from 'utils/masks';
import {
  ListarVendaLeitaoRealizadaResponse,
  useListarVendaLeitaoRealizada
} from 'api/relatorios/lotes/listarVendaLeitaoRealizada/useListarVendaLeitaoRealizada';
import { usePDFVendaLeitaoRealizada } from 'api/relatorios/lotes/getPDFVendaLeitaoRealizada/useGetPDFVendaLeitaoRealizada';

type SearchParams = PageRequest & {
  periodo?: [Date, Date];
};

const RelatorioVendasRealizadas: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    periodo: [currentMonth().firstDay, currentMonth().lastDay]
  });
  const { data, isLoading } = useListarVendaLeitaoRealizada({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFVendaLeitaoRealizada({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    },
    onSuccess: (data: Blob) => {
      JsFileDownload(data, `vendas-realizadas.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            periodo: values.dataVenda
          });
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Data da Venda"
              name="dataVenda"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject();
                    if (!value[0] || !value[1]) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <CustomRangePicker
                initialValue={searchParams.periodo}
                callback={(startDate, endDate) => {
                  form.setFieldsValue({
                    dataVenda: [startDate, endDate]
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <CustomTable
        isLoading={isLoading}
        style={{ marginTop: '32px' }}
        data={data?.items || []}
        pagination={{
          page: searchParams.page,
          perPage: searchParams.perPage,
          totalElements: data?.total || 0,
          totalPages: data?.totalPages || 0
        }}
        changePageNumber={(page) => {
          setSearchParams((prev) => ({ ...prev, page: page - 1 }));
        }}
        changePageSize={(_, size) => {
          setSearchParams((prev) => ({ ...prev, page: 0, perPage: size }));
        }}
        columns={[
          {
            title: 'Data',
            render: (record: ListarVendaLeitaoRealizadaResponse) =>
              formatToBRL(record.data)
          },
          {
            title: 'Nº Lote',
            dataIndex: 'numeroLote'
          },
          {
            title: 'Qntd',
            dataIndex: 'qntd'
          },
          {
            title: 'Idade (dias)',
            dataIndex: 'idadeLote'
          },
          {
            title: 'Peso Médio (Kg)',
            render: (record: ListarVendaLeitaoRealizadaResponse) =>
              applyNumberMask(record.pesoMedio, { places: 2 })
          },
          {
            title: 'GPD (Kg)',
            render: (record: ListarVendaLeitaoRealizadaResponse) =>
              applyNumberMask(record.gpdMedio, { places: 3 })
          },
          {
            title: 'Peso Total (Kg)',
            render: (record: ListarVendaLeitaoRealizadaResponse) =>
              applyNumberMask(record.pesoVendido, { places: 2 })
          },
          {
            title: 'Aprov. (R$)',
            render: (record: ListarVendaLeitaoRealizadaResponse) =>
              applyCurrencyMask(record.aprovFinanceiro)
          },
          {
            title: 'R$/Kg',
            render: (record: ListarVendaLeitaoRealizadaResponse) =>
              applyCurrencyMask(record.precoMedioKg)
          }
        ]}
        summary={(data: readonly ListarVendaLeitaoRealizadaResponse[]) => {
          let idadeMedia = 0;
          let totalLeitoes = 0;
          let gpdMedio = 0;
          let totalKgsVendidos = 0;
          let totalAprovFinanceiro = 0;

          data.forEach((e) => {
            idadeMedia += e.idadeLote * e.qntd;
            totalLeitoes += e.qntd;
            gpdMedio += e.gpdMedio * e.qntd;
            totalKgsVendidos += e.pesoVendido;
            totalAprovFinanceiro += e.aprovFinanceiro;
          });

          gpdMedio = gpdMedio / totalLeitoes;
          idadeMedia = idadeMedia / totalLeitoes;
          const pesoMedio = totalKgsVendidos / totalLeitoes;
          const precoMedioKg = totalAprovFinanceiro / totalKgsVendidos;

          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2} align="left">
                  <span style={{ fontWeight: 'bold' }}>Total:</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1} align="left">
                  <span>{totalLeitoes}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={1} align="left">
                  <span>{applyMaskDecimalWithComma(idadeMedia, 0)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={1} align="left">
                  <span>{applyNumberMask(pesoMedio)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={1} align="left">
                  <span>{applyNumberMask(gpdMedio, { places: 3 })}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} colSpan={1} align="left">
                  <span>
                    {applyNumberMask(totalKgsVendidos, { places: 2 })}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} colSpan={1} align="left">
                  <span>{applyCurrencyMask(totalAprovFinanceiro)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} colSpan={1} align="left">
                  <span>{applyCurrencyMask(precoMedioKg)}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </>
  );
};

export default RelatorioVendasRealizadas;
