import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';
import { InputParams } from 'api/models';

export type CadastrarProducaoRacaoRequest = {
  dia: string;
  preEmKg: number;
  inicial1EmKg: number;
  inicial2EmKg: number;
  crescimentoEmKg: number;
  terminacaoEmKg: number;
  reposicaoEmKg: number;
  gestacaoEmKg: number;
  preLactacaoEmKg: number;
  lactacaoEmKg: number;
};

export type CadastrarProducaoRacaoResponse = {
  id: string;
};

const cadastrarProducaoRacao = async (
  body: CadastrarProducaoRacaoRequest
): Promise<CadastrarProducaoRacaoResponse> => {
  const response = await api.post<CadastrarProducaoRacaoResponse>(
    `racao`,
    body
  );
  return response.data;
};

type Variables = {
  body: CadastrarProducaoRacaoRequest;
};

export type OutputParams = {
  mutate: UseMutateFunction<
    CadastrarProducaoRacaoResponse,
    unknown,
    Variables,
    unknown
  >;
  isLoading: boolean;
};

export const useCadastrarProducaoRacao = ({
  onSuccess,
  onError
}: InputParams<CadastrarProducaoRacaoResponse>): OutputParams => {
  const { mutate, isLoading } = useMutation(
    ['create-producao-racao'],
    (params: Variables) => cadastrarProducaoRacao(params.body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
