import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';

type NascimentoRequest = {
  data: string;
  peso: number;
  vivos: number;
  natimortos: number;
  mumificados: number;
};

export type CadastrarLoteExternoRequest = {
  racaId: string;
  nascimento: NascimentoRequest;
  codigoMae: string;
  maeRacaId: string;
  codigoPai: string;
  paiRacaId: string;
};

export type CadastrarLoteExternoResponse = {
  id: string;
  numero: number;
};

export const cadastrarLoteExterno = async (
  body: CadastrarLoteExternoRequest
): Promise<CadastrarLoteExternoResponse> => {
  const response = await api.post<CadastrarLoteExternoResponse>(
    'lote/lote-externo',
    body
  );
  return response.data;
};

export type InputParams = {
  onSuccess: (data: CadastrarLoteExternoResponse) => void;
  onError: (error: unknown) => void;
};

export type OutputParams = {
  mutate: UseMutateFunction<
    CadastrarLoteExternoResponse,
    unknown,
    CadastrarLoteExternoRequest,
    unknown
  >;
  isLoading: boolean;
};

export const useCadastrarLoteExterno = ({
  onSuccess,
  onError
}: InputParams): OutputParams => {
  const { mutate, isLoading } = useMutation(
    (body: CadastrarLoteExternoRequest) => cadastrarLoteExterno(body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
