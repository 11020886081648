import { useState } from 'react';
import { format } from 'date-fns';
import JsFileDownload from 'js-file-download';
import { Button, Col, Form, notification, Row } from 'antd';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';

import { CustomTable } from 'components';
import DatePicker from 'components/DatePicker';
import { applyMaskDecimalWithComma } from 'utils/masks';
import { useGetResultadoAnual } from 'api/relatorios/resultado-geral/getResultadoAnual/useGetResultadoAnual';
import { useGetPDFResultadoGeral } from 'api/relatorios/resultado-geral/getPDFResultadoGeral/useGetPDFResultadoGeral';

const ResultadoGeral: React.FC = () => {
  const [form] = Form.useForm();
  const [params, setParams] = useState({
    ano: new Date().getFullYear()
  });
  const { data, isLoading } = useGetResultadoAnual({
    params: {
      ano: params.ano
    },
    onError: () => {
      notification.error({ message: 'Erro ao busca índices' });
    }
  });
  const { isLoading: isLoadingPDF, refetch: refetchPDF } =
    useGetPDFResultadoGeral({
      params: {
        ano: params.ano
      },
      onError: () => {
        notification.error({ message: 'Erro ao gerar PDF' });
      },
      onSuccess: (reportBytes) => {
        JsFileDownload(reportBytes, `resultado-greal-${params.ano}.pdf`);
      }
    });

  const formatValue = (hasDecimalPlace: boolean, value: number) => {
    if (!hasDecimalPlace) return applyMaskDecimalWithComma(value, 0);
    return applyMaskDecimalWithComma(value, 2);
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        initialValues={{ ano: new Date() }}
        onFinish={(values) => {
          setParams({ ano: Number(format(values.ano, 'yyyy')) });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Ano"
              name="ano"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <DatePicker picker="year" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoadingPDF}
              disabled={isLoading}
              onClick={() => {
                form.validateFields().then(() => {
                  refetchPDF();
                });
              }}
            >
              PDF
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading}
              disabled={isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <CustomTable
        style={{ marginTop: '32px' }}
        data={data || []}
        isLoading={isLoading}
        hasPagination={false}
        key={'indice'}
        changePageSize={() => {
          //
        }}
        changePageNumber={() => {
          //
        }}
        columns={[
          {
            title: 'Índice',
            render: (record: any) => record.indice
          },
          {
            title: 'Jan',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.janeiro)
          },
          {
            title: 'Fev',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.fevereiro)
          },
          {
            title: 'Mar',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.marco)
          },
          {
            title: 'Abr',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.abril)
          },
          {
            title: 'Mai',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.maio)
          },
          {
            title: 'Jun',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.junho)
          },
          {
            title: 'Jul',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.julho)
          },
          {
            title: 'Ago',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.agosto)
          },
          {
            title: 'Set',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.setembro)
          },
          {
            title: 'Out',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.outubro)
          },
          {
            title: 'Nov',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.novembro)
          },
          {
            title: 'Dez',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.dezembro)
          },
          {
            title: 'Média',
            render: (record: any) =>
              formatValue(record.hasDecimalPlace, record.media)
          }
        ]}
      />
    </>
  );
};

export default ResultadoGeral;
