import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import RelatorioMarras from './RelatorioMarras';
import RelatorioLeitoas from './RelatorioLeitoas';
import RelatorioTodasMatrizes from './RelatorioTodasMatrizes';
import RelatorioMatrizesVazias from './RelatorioMatrizesVazias';
import RelatorioMatrizesPorCiclo from './RelatorioMatrizesPorCiclo';
import RelatorioMatrizesLactantes from './RelatorioMatrizesLactantes';
import RelatorioMatrizesGestantes from './RelatorioMatrizesGestantes';
import RelatorioMatrizesOcorrencias from './RelatorioMatrizesOcorrencias';
import RelatorioMatrizesDescartadas from './RelatorioMatrizesDescartadas';

const RelatoriosMatrizes: React.FC = () => {
  const dispatch = useDispatch();
  const collapseStyle = { marginTop: '16px' };

  useEffect(() => {
    dispatch(updateID('relatorios-matrizes'));
    dispatch(updateTitle('Relatórios das Matrizes'));
  }, []);

  return (
    <div style={{ marginBottom: '64px' }}>
      <Collapse>
        <Collapse.Panel key="todas" header="Todas">
          <RelatorioTodasMatrizes />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="vazias" header="Vazias">
          <RelatorioMatrizesVazias />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="marras" header="Marrãs">
          <RelatorioMarras />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="lactantes" header="Lactantes">
          <RelatorioMatrizesLactantes />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="gestantes" header="Gestantes">
          <RelatorioMatrizesGestantes />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="leitoas" header="Leitoas">
          <RelatorioLeitoas />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="por-ciclo" header="Por Ciclo">
          <RelatorioMatrizesPorCiclo />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="ocorrencias" header="Ocorrências">
          <RelatorioMatrizesOcorrencias />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="matriz-descartes-por-motivo" header="Descartes">
          <RelatorioMatrizesDescartadas />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
export default RelatoriosMatrizes;
