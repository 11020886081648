import React, { useState } from 'react';
import {
  CloseOutlined,
  EditOutlined,
  SaveOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Table
} from 'antd';

import { SearchParams } from '..';
import { formatToBRL } from 'utils/utils';
import { applyMaskInteger, integerRegex } from 'utils/masks';

import CustomRangePicker from 'components/CustomRangePicker';
import { ListarProducaoRacaoResponse } from 'api/listarProducaoRacao/useListarProducaoRacao';
import { useCadastrarProducaoRacao } from 'api/cadastrarProducaoRacao/useCadastrarProducaoRacao';
import { useAtualizarProducaoRacao } from 'api/atualizarProducaoRacao/useAtualizarProducaoRacao';

interface IHistoricoProps {
  producao: ListarProducaoRacaoResponse[];
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  isLoading: boolean;
  onSuccessCallback?: (id: string) => void;
}

const Historico: React.FC<IHistoricoProps> = ({
  producao,
  isLoading,
  searchParams,
  setSearchParams,
  onSuccessCallback
}) => {
  const [formRow] = Form.useForm();
  const [formFilter] = Form.useForm();
  const [isEditing, setIsEditing] = useState('');
  const { mutate: createProducao, isLoading: isCreating } =
    useCadastrarProducaoRacao({
      onSuccess: (data) => {
        notification.success({ message: 'Produção registrada com sucesso!' });
        formFilter.submit();
        formRow.resetFields();
        setIsEditing('');
        onSuccessCallback(data.id);
      },
      onError: () => {
        notification.error({
          message: 'Erro ao registrar produção!'
        });
      }
    });
  const { mutate: updateProducao, isLoading: isUpdating } =
    useAtualizarProducaoRacao({
      onSuccess: () => {
        notification.success({ message: 'Produção atualizada com sucesso!' });
        formFilter.submit();
        formRow.resetFields();
        setIsEditing('');
        onSuccessCallback(undefined);
      },
      onError: () => {
        notification.error({
          message: 'Erro ao atualizar produção!'
        });
      }
    });

  const edit = (record: ListarProducaoRacaoResponse) => {
    formRow.setFieldsValue({
      id: record.id,
      dia: record.dia,
      preEmKg: applyMaskInteger(record.preEmKg),
      inicial1EmKg: applyMaskInteger(record.inicial1EmKg),
      inicial2EmKg: applyMaskInteger(record.inicial2EmKg),
      crescimentoEmKg: applyMaskInteger(record.crescimentoEmKg),
      terminacaoEmKg: applyMaskInteger(record.terminacaoEmKg),
      reposicaoEmKg: applyMaskInteger(record.reposicaoEmKg),
      gestacaoEmKg: applyMaskInteger(record.gestacaoEmKg),
      preLactacaoEmKg: applyMaskInteger(record.preLactacaoEmKg),
      lactacaoEmKg: applyMaskInteger(record.lactacaoEmKg)
    });
    setIsEditing(record.dia);
  };

  const onSave = () => {
    formRow.validateFields().then(() => {
      const hasId = formRow.getFieldValue('id');

      if (hasId) {
        updateProducao({
          dia: formRow.getFieldValue('dia'),
          body: {
            preEmKg: formRow.getFieldValue('preEmKg'),
            inicial1EmKg: formRow.getFieldValue('inicial1EmKg'),
            inicial2EmKg: formRow.getFieldValue('inicial2EmKg'),
            crescimentoEmKg: formRow.getFieldValue('crescimentoEmKg'),
            terminacaoEmKg: formRow.getFieldValue('terminacaoEmKg'),
            reposicaoEmKg: formRow.getFieldValue('reposicaoEmKg'),
            gestacaoEmKg: formRow.getFieldValue('gestacaoEmKg'),
            preLactacaoEmKg: formRow.getFieldValue('preLactacaoEmKg'),
            lactacaoEmKg: formRow.getFieldValue('lactacaoEmKg')
          }
        });
      } else {
        createProducao({
          body: {
            dia: formRow.getFieldValue('dia'),
            preEmKg: formRow.getFieldValue('preEmKg'),
            inicial1EmKg: formRow.getFieldValue('inicial1EmKg'),
            inicial2EmKg: formRow.getFieldValue('inicial2EmKg'),
            crescimentoEmKg: formRow.getFieldValue('crescimentoEmKg'),
            terminacaoEmKg: formRow.getFieldValue('terminacaoEmKg'),
            reposicaoEmKg: formRow.getFieldValue('reposicaoEmKg'),
            gestacaoEmKg: formRow.getFieldValue('gestacaoEmKg'),
            preLactacaoEmKg: formRow.getFieldValue('preLactacaoEmKg'),
            lactacaoEmKg: formRow.getFieldValue('lactacaoEmKg')
          }
        });
      }
    });
  };

  const columns = [
    {
      title: 'Ação',
      dataIndex: 'operation',
      render: (_: any, record: ListarProducaoRacaoResponse) => {
        return isEditing === record.dia ? (
          <span>
            <Popconfirm title="Tem certeza?" onConfirm={() => setIsEditing('')}>
              <Button size="small" shape="round" icon={<CloseOutlined />}>
                Cancelar
              </Button>
            </Popconfirm>
            <Button
              size="small"
              shape="round"
              icon={<SaveOutlined />}
              style={{ marginTop: '8px' }}
              onClick={() => onSave()}
            >
              Salvar
            </Button>
          </span>
        ) : (
          <Button
            size="small"
            shape="round"
            disabled={isEditing !== ''}
            icon={<EditOutlined />}
            onClick={() => edit(record)}
          >
            Editar
          </Button>
        );
      }
    },
    {
      title: 'Dia',
      width: '10%',
      dataIndex: 'dia',
      render: (value: string) => formatToBRL(value)
    },
    {
      title: 'Pré',
      width: '9%',
      dataIndex: 'preEmKg',
      editable: true,
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Inic. 1',
      width: '9%',
      dataIndex: 'inicial1EmKg',
      editable: true,
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Inic. 2',
      width: '9%',
      dataIndex: 'inicial2EmKg',
      editable: true,
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Cresc.',
      width: '9%',
      dataIndex: 'crescimentoEmKg',
      editable: true,
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Term.',
      width: '9%',
      editable: true,
      dataIndex: 'terminacaoEmKg',
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Repos.',
      width: '9%',
      editable: true,
      dataIndex: 'reposicaoEmKg',
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Gest.',
      width: '9%',
      editable: true,
      dataIndex: 'gestacaoEmKg',
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Pre Lact.',
      width: '9%',
      editable: true,
      dataIndex: 'preLactacaoEmKg',
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Lact.',
      width: '9%',
      editable: true,
      dataIndex: 'lactacaoEmKg',
      render: (value: number) => applyMaskInteger(value)
    },
    {
      title: 'Total',
      width: '9%',
      dataIndex: 'totalEmKg',
      render: (value: number) => applyMaskInteger(value)
    }
  ];

  const summary = (data: readonly ListarProducaoRacaoResponse[]) => {
    let totalPre = 0.0;
    let totalInicial1 = 0.0;
    let totalInicial2 = 0.0;
    let totalCrescimento = 0.0;
    let totalTerminacao = 0.0;
    let totalReposicao = 0.0;
    let totalGestacao = 0.0;
    let totalPreLactacao = 0.0;
    let totalLactacao = 0.0;
    data.forEach((e) => {
      totalPre += e.preEmKg;
      totalInicial1 += e.inicial1EmKg;
      totalInicial2 += e.inicial2EmKg;
      totalCrescimento += e.crescimentoEmKg;
      totalTerminacao += e.terminacaoEmKg;
      totalReposicao += e.reposicaoEmKg;
      totalGestacao += e.gestacaoEmKg;
      totalPreLactacao += e.preLactacaoEmKg;
      totalLactacao += e.lactacaoEmKg;
    });
    const total =
      totalPre +
      totalInicial1 +
      totalInicial2 +
      totalCrescimento +
      totalTerminacao +
      totalReposicao +
      totalGestacao +
      totalPreLactacao +
      totalLactacao;

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={2} align="right">
            <span style={{ fontWeight: 'bold' }}>Total (Kgs):</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalPre.toFixed(0) || 0} (${(
                (totalPre / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalInicial1.toFixed(0) || 0} (${(
                (totalInicial1 / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalInicial2.toFixed(0) || 0} (${(
                (totalInicial2 / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalCrescimento.toFixed(0) || 0} (${(
                (totalCrescimento / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalTerminacao.toFixed(0) || 0} (${(
                (totalTerminacao / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalReposicao.toFixed(0) || 0} (${(
                (totalReposicao / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalGestacao.toFixed(0) || 0} (${(
                (totalGestacao / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalPreLactacao.toFixed(0) || 0} (${(
                (totalPreLactacao / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            <span style={{ fontWeight: 'bold' }}>
              {`${totalLactacao.toFixed(0) || 0} (${(
                (totalLactacao / total || 0) * 100
              ).toFixed(0)}%)`}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11} colSpan={2}>
            <span style={{ fontWeight: 'bold' }}>{`${
              total.toFixed(0) || 0
            } (100%)`}</span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const EditableCell: React.FC<{
    editing: string;
    dataIndex: string;
    title: string;
    record: ListarProducaoRacaoResponse;
    children: React.ReactNode;
  }> = ({ editing, dataIndex, title, record, children, ...restProps }) => {
    return (
      <td {...restProps}>
        {record && record.dia === editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Campo obrigatório`
              },
              {
                pattern: integerRegex,
                message: 'Remover ",00"'
              }
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ListarProducaoRacaoResponse) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing
      })
    };
  });

  return (
    <>
      <Form
        colon={false}
        layout="vertical"
        form={formFilter}
        onFinish={(values) => {
          setSearchParams({
            periodo: values.periodo
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Período"
              name="periodo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject();
                    if (!value[0] || !value[1]) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <CustomRangePicker
                initialValue={searchParams.periodo}
                callback={(startDate, endDate) => {
                  formFilter.setFieldsValue({
                    periodo: [startDate, endDate]
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row gutter={[16, 16]} justify="end">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Button
            block
            type="primary"
            htmlType="button"
            onClick={() => {
              formFilter.submit();
            }}
            loading={isLoading}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>
        </Col>
      </Row>

      <Form layout="vertical" form={formRow} component={false}>
        <Table
          rowKey={'dia'}
          scroll={{ x: '100vw' }}
          size="small"
          style={{ marginTop: '32px' }}
          components={{
            body: {
              cell: EditableCell
            }
          }}
          bordered
          summary={summary}
          pagination={false}
          loading={isLoading}
          dataSource={producao}
          columns={mergedColumns}
          rowClassName="editable-row"
        />
      </Form>
    </>
  );
};
export default Historico;
