import api from 'services/api';
import { store } from '../store';
import { PageResponse } from 'api/models';
import { ListarRacaRequest, ListarRacaResponse } from 'api/racas';
import { atualizarRacasEmCache } from '../store/modules/racas/actions';

export const listarRacas = async (
  params: ListarRacaRequest
): Promise<PageResponse<ListarRacaResponse>> => {
  const response = await api.get<PageResponse<ListarRacaResponse>>('raca', {
    params
  });
  return response.data;
};

export const getRacas = async (): Promise<ListarRacaResponse[]> => {
  if (store.getState().racas.racas && store.getState().racas.racas.length > 0) {
    return Promise.resolve(store.getState().racas.racas);
  }

  try {
    const response = await listarRacas({ page: 0, perPage: 200 });
    store.dispatch(atualizarRacasEmCache(response.items));
    return Promise.resolve(response.items);
  } catch (error) {
    return Promise.reject(error);
  }
};
