import { Modal, notification } from 'antd';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';

import { useExcluirGestacao } from 'api/excluirGestacao/useExcluirGestacao';

export interface ModalExclusaoProps {
  visible: boolean;
  matriz: { id: string; codigo: string };
  onCancel: () => void;
  onCallback?: () => void;
}

export const ModalExclusao: React.FC<ModalExclusaoProps> = ({
  visible,
  matriz,
  onCancel,
  onCallback
}) => {
  const { mutate, isLoading } = useExcluirGestacao({
    onSuccess: () => {
      notification.success({ message: 'Gestação excluída com sucesso!' });
      onCancel();
      onCallback();
    },
    onError: () => {
      notification.error({ message: 'Erro ao excluir gestação!' });
    }
  });

  return (
    <Modal
      open={visible}
      confirmLoading={isLoading}
      title={`Excluir gestação da Matriz ${matriz?.codigo}?`}
      onCancel={() => {
        onCancel();
      }}
      okText="Excluir"
      onOk={() => {
        mutate(matriz.id);
      }}
      okButtonProps={{
        icon: <SendOutlined />
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        icon: <CloseOutlined />,
        danger: true
      }}
    >
      <p>A matriz voltará a ter as condições do momento da cobertura.</p>
      <p>Os reprodutores terão as coberturas removidas da sua ficha.</p>
      <p>Os estoques de sêmen terão suas doses devolvidas.</p>
    </Modal>
  );
};
