import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import racas from './racas/sagas';
import descarte from './descarte/sagas';

export default function* rootSaga() {
  return yield all([auth, user, racas, descarte]);
}
