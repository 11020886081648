import { useState } from 'react';
import { ConfigProvider, Empty, Modal, Table } from 'antd';

import {
  useListarMatrizes,
  ListarMatrizResponse
} from 'api/listarMatrizes/useListarMatrizes';
import { PageRequest, RacaResponse } from 'api/models';
import { mapperStatusAnimal, mapperUltimoEvento } from 'utils/utils';

interface Props {
  open: boolean;
  onSelect: (matriz: ListarMatrizResponse) => void;
  onCancel: () => void;
}

const ModalMatrizesLactantes: React.FC<Props> = ({
  open = false,
  onSelect,
  onCancel
}) => {
  const [selectedRow, setSelectedRow] = useState({} as ListarMatrizResponse);
  const [pagination, setPagination] = useState<PageRequest>({
    page: 0,
    perPage: 5
  });
  const { data, isLoading } = useListarMatrizes({
    params: {
      status: 'VAZIA',
      evento: 'DESMAME',
      page: pagination.page,
      perPage: pagination.perPage
    }
  });
  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo'
    },
    {
      title: 'Raça',
      dataIndex: 'raca',
      render: (value: RacaResponse) => value.nome
    },
    {
      title: 'Idade (dias)',
      dataIndex: 'idade'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value: string) => mapperStatusAnimal(value)
    },
    {
      title: 'Motivo',
      dataIndex: 'situacao',
      render: (value: string) => mapperUltimoEvento(value)
    }
  ];

  return (
    <Modal
      title="Matrizes Lactantes"
      width={'70%'}
      open={open}
      okText="Selecionar"
      onOk={() => {
        onSelect(selectedRow);
        setSelectedRow({} as ListarMatrizResponse);
      }}
      cancelText="Cancelar"
      onCancel={() => {
        onCancel();
      }}
    >
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma matriz encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          rowKey={'id'}
          columns={columns}
          loading={isLoading}
          dataSource={data?.items || []}
          onRow={(item) => ({
            onClick: () => {
              setSelectedRow(item);
            }
          })}
          rowSelection={{
            type: 'radio',
            onSelect: (record) => {
              setSelectedRow(record);
            },
            selectedRowKeys: selectedRow ? [selectedRow.id] : []
          }}
          pagination={{
            size: 'small',
            total: data?.total || 0,
            pageSize: pagination.perPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: false,
            onChange: (page) => {
              setPagination((currentState) => ({
                ...currentState,
                page: page - 1
              }));
            }
          }}
        />
      </ConfigProvider>
    </Modal>
  );
};

export default ModalMatrizesLactantes;
