import { useState } from 'react';
import { GiPig } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Layout, Menu, Typography } from 'antd';
import {
  FaBalanceScaleRight,
  FaSyringe,
  FaClinicMedical,
  FaPlus
} from 'react-icons/fa';
import {
  MenuUnfoldOutlined,
  FilePdfOutlined,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  ProfileOutlined,
  CalendarOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import history from 'services/history';
import { signOut } from 'store/modules/auth/actions';

interface IReduxSelectorState {
  currentPage: {
    id: string;
    title: string;
  };
  user: {
    user: {
      nomeGranja: string;
    };
  };
}

const DefaultLayout: React.FC<any> = ({ children }) => {
  const dispatch = useDispatch();
  const { id, title } = useSelector(
    (state: IReduxSelectorState) => state.currentPage
  );
  const nomeGranja = useSelector(
    (state: IReduxSelectorState) => state.user.user.nomeGranja
  );
  const [showSider, setShowSider] = useState(true);

  const menu = (
    <Menu>
      <Menu.Item
        key="meus-dados"
        icon={<UserOutlined />}
        onClick={() => history.push('/meus-dados')}
      >
        Meus Dados
      </Menu.Item>
      <Menu.Item
        key="criterios-manejo"
        icon={<ProfileOutlined />}
        onClick={() => history.push('/criterios-manejo')}
      >
        Critérios Manejo
      </Menu.Item>
      <Menu.Item
        key="como-usar"
        icon={<QuestionCircleOutlined />}
        onClick={() => history.push('/como-usar')}
      >
        Como usar?
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="sair"
        icon={<LogoutOutlined />}
        onClick={() => dispatch(signOut())}
      >
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ height: '100vh' }}>
      {showSider && (
        <Layout.Sider
          theme="light"
          style={{ overflow: 'auto', height: '100vh' }}
        >
          <div style={{ padding: '24px', textAlign: 'center' }}>MeuSuíno</div>

          <Menu theme="light" mode="inline" defaultSelectedKeys={[id]}>
            <Menu.Item
              key="producao-racao"
              icon={<FaBalanceScaleRight size={15} />}
              onClick={() => history.push('/producao-racao')}
            >
              Produção Ração
            </Menu.Item>

            <Menu.Item
              key="raca"
              icon={<FaPlus size={15} />}
              onClick={() => history.push('/racas')}
            >
              Raças
            </Menu.Item>

            <Menu.SubMenu key="suino" icon={<GiPig size={18} />} title="Suínos">
              <Menu.Item
                key="suino-cadastrar"
                onClick={() => history.push('/suino/cadastro')}
              >
                Cadastrar
              </Menu.Item>
              <Menu.Item
                key="suino-matrizes"
                onClick={() => history.push('/suino/matrizes')}
              >
                Matrizes
              </Menu.Item>
              <Menu.Item
                key="suino-reprodutores"
                onClick={() => history.push('/suino/reprodutores')}
              >
                Reprodutores
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="estoque-semen"
              icon={<FaSyringe size={14} />}
              title="Estoque Sêmen"
            >
              <Menu.Item
                key="estoque-semen-lista"
                onClick={() => history.push('/estoque-semen/lista')}
              >
                Estoques
              </Menu.Item>
              <Menu.Item
                key="estoque-semen-fornecedores"
                onClick={() => history.push('/estoque-semen/fornecedores')}
              >
                Fornecedores
              </Menu.Item>
              <Menu.Item
                key="estoque-semen-entrada"
                onClick={() => history.push('/estoque-semen/entrada')}
              >
                Compra/Coleta
              </Menu.Item>
              <Menu.Item
                key="estoque-semen-saida"
                onClick={() => history.push('/estoque-semen/saida')}
              >
                Venda/Descarte
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu key="lotes" icon={<GiPig size={18} />} title="Lotes">
              <Menu.Item
                key="lotes-lotes"
                onClick={() => history.push('/lote/lista')}
              >
                Lotes
              </Menu.Item>
              {/* <Menu.Item
                key="lotes-morte"
                onClick={() => history.push('/lote/lista')}
              >
                Morte
              </Menu.Item>
              <Menu.Item
                key="lotes-venda"
                onClick={() => history.push('/lote/lista')}
              >
                Venda
              </Menu.Item>
              <Menu.Item
                key="lotes-transferencia"
                onClick={() => history.push('/lote/lista')}
              >
                Transferência
              </Menu.Item> */}
              <Menu.Item
                key="lotes-cadastrar-lote-externo"
                onClick={() => history.push('/lote/cadastro')}
              >
                Cadastrar
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="cobertura"
              icon={<FaClinicMedical size={15} />}
              title="Cobertura"
            >
              <Menu.Item
                key="cobertura-historico"
                onClick={() => history.push('/cobertura/historico')}
              >
                Histórico
              </Menu.Item>
              <Menu.Item
                key="cobertura-cadastrar"
                onClick={() => history.push('/cobertura/cadastro')}
              >
                Cadastrar
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="relatorios"
              icon={<FilePdfOutlined />}
              title="Relatórios"
            >
              <Menu.Item
                key="relatorios-alvos"
                onClick={() => history.push('/relatorios/alvos-producao')}
              >
                Resultado Geral
              </Menu.Item>
              <Menu.Item
                key="relatorios-lotes"
                onClick={() => history.push('/relatorios/lotes')}
              >
                Lotes
              </Menu.Item>
              <Menu.Item
                key="relatorios-matrizes"
                onClick={() => history.push('/relatorios/matrizes')}
              >
                Matrizes
              </Menu.Item>
              <Menu.Item
                key="relatorios-reprodutores"
                onClick={() => history.push('/relatorios/reprodutores')}
              >
                Reprodutores
              </Menu.Item>
              <Menu.Item
                key="relatorios-racao"
                onClick={() => history.push('/relatorios/racao')}
              >
                Ração
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item
              key="calendario-pig"
              icon={<CalendarOutlined size={15} />}
              onClick={() => history.push('/calendario-pig')}
            >
              Calendário PIG
            </Menu.Item>
          </Menu>
        </Layout.Sider>
      )}

      <Layout className="site-layout">
        <Layout.Header
          className="site-layout-background"
          style={{
            padding: '0px 16px 0px 16px',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <MenuUnfoldOutlined
            style={{ fontSize: '16px' }}
            onClick={() => setShowSider((current) => !current)}
          />
          <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
            {title}
          </Typography.Title>
          <Dropdown overlay={menu}>
            <Button type="text">
              {nomeGranja} <DownOutlined />
            </Button>
          </Dropdown>
        </Layout.Header>

        <Layout.Content>
          <div
            id="scrollable-content-div"
            style={{
              padding: '16px 16px 16px 16px',
              height: '100%',
              width: '100%',
              overflow: 'auto'
            }}
          >
            {children}
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
