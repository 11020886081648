import { PageResponse } from 'api/models';
import { BaseHttpParams, useHttp } from 'api/useHttp';
import { ListarRacaRequest, ListarRacaResponse } from './types';

type R = PageResponse<ListarRacaResponse>;

type InputParams = BaseHttpParams<R> & {
  queryParams: ListarRacaRequest;
};

export const useListRaca = (params: InputParams) => {
  return useHttp<void, ListarRacaRequest, R>({
    url: 'raca',
    method: 'GET',
    queryParams: params.queryParams,
    onSuccess: params.onSuccess,
    onError: params.onError,
    callOnMount: true,
    enableErrorNotification: false
  });
};
