import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';

export type TransferenciaLeitaoResponse = {
  id: string;
  qntd: number;
  data: string;
  loteOrigemId: string;
  loteOrigemNumero: number;
  loteDestinoId: string;
  loteDestinoNumero: number;
  tipoTransferencia: string;
};

export const getTransferenciaByLoteId = async (
  loteId: string
): Promise<TransferenciaLeitaoResponse[]> => {
  const response = await api.get<TransferenciaLeitaoResponse[]>(
    `lote/${loteId}/transferencia`
  );
  return response.data;
};

export type InputParams = {
  id: string;
  onSuccess?: (mortes: TransferenciaLeitaoResponse[]) => void;
  onError?: () => void;
};

export type OutputParams = {
  data: TransferenciaLeitaoResponse[];
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<TransferenciaLeitaoResponse[], unknown>>;
};

export const useGetTransferenciasByLoteId = ({
  id,
  onError,
  onSuccess
}: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['get-transferencias-by-lote-id', id],
    () => getTransferenciaByLoteId(id),
    {
      onError,
      onSuccess
    }
  );
  return { data, isLoading, refetch };
};
