import { useState } from 'react';
import { Alert, Button, Form, Modal, notification } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

import { IPagination } from 'models';
import { CustomTable, CustomInput } from 'components';
import { CustomSelect } from 'components/shared/CustomSelect';
import { ListarFornecedorResponse } from 'api/listarFornecedores/useListarFornecedores';
import {
  useEditarFornecedor,
  EditarFornecedorRequest
} from 'api/editarFornecedor/useEditarFornecedor';

const EditarFornecedor: React.FC<{
  fornecedor: ListarFornecedorResponse;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  onError: (error: unknown) => void;
}> = ({ fornecedor, open, setOpen, onSuccess, onError }) => {
  const [form] = Form.useForm<ListarFornecedorResponse>();
  const { mutate, isLoading } = useEditarFornecedor({
    onSuccess: () => {
      form.resetFields();
      onSuccess();
    },
    onError
  });

  return (
    <Modal
      open={open}
      title="Editar Fornecedor"
      okText="Salvar"
      onOk={() => form.submit()}
      okButtonProps={{
        icon: <SaveOutlined />
      }}
      onCancel={() => {
        form.resetFields(['nome']);
        setOpen(false);
      }}
      cancelText="Cancelar"
      cancelButtonProps={{
        danger: true,
        icon: <CloseOutlined />
      }}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        colon={false}
        layout="vertical"
        initialValues={{
          ...fornecedor,
          ativo: String(fornecedor.ativo)
        }}
        onFinish={(values) => {
          mutate({ id: fornecedor.id, nome: values.nome, ativo: values.ativo });
        }}
      >
        <Form.Item
          label="Nome"
          name="nome"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <CustomInput />
        </Form.Item>

        <Form.Item
          label="Status"
          name="ativo"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <CustomSelect
            options={[
              { value: 'true', label: 'ATIVO' },
              { value: 'false', label: 'INATIVO' }
            ]}
          />
        </Form.Item>
      </Form>

      <Alert
        showIcon
        type="warning"
        description="Os fornecedores inativos não ficam disponíveis para seleção nos cadastros."
      />
    </Modal>
  );
};

type Props = {
  isLoading: boolean;
  data: ListarFornecedorResponse[];
  changePageNumber: (page: number) => void;
  changePageSize: (current: number, size: number) => void;
  pagination: IPagination;
  onSaveCallback: () => void;
};

export const Table = ({
  isLoading,
  data,
  changePageNumber,
  changePageSize,
  pagination,
  onSaveCallback
}: Props): any => {
  const [editarFornecedor, setEditarFornecedor] = useState({
    open: false,
    fornecedor: {} as EditarFornecedorRequest
  });

  const columns = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      width: '40%'
    },
    {
      title: 'Status',
      key: 'ativo',
      dataIndex: 'ativo',
      width: '40%',
      render: (value: boolean) => (value ? 'ATIVO' : 'INATIVO')
    },
    {
      title: 'Editar',
      key: 'acao',
      dataIndex: '',
      width: '20%',
      render: (
        value: string | null | undefined,
        record: ListarFornecedorResponse
      ) => (
        <Button
          id={`edit-button-${record.id}`}
          size="small"
          shape="round"
          htmlType="button"
          icon={<EditOutlined />}
          onClick={() => {
            setEditarFornecedor({ open: true, fornecedor: record });
          }}
        />
      )
    }
  ];

  return (
    <>
      <EditarFornecedor
        open={editarFornecedor.open}
        fornecedor={editarFornecedor.fornecedor}
        setOpen={(value) => {
          setEditarFornecedor((prev) => ({ ...prev, open: value }));
        }}
        onSuccess={() => {
          setEditarFornecedor({
            open: false,
            fornecedor: {} as EditarFornecedorRequest
          });
          notification.success({ message: 'Fornecedor editado com sucesso' });
          onSaveCallback();
        }}
        onError={(error) => {
          notification.error({ message: 'Erro ao editar fornecedor' });
        }}
      />

      <CustomTable
        data={data}
        hasPagination
        columns={columns}
        isLoading={isLoading}
        emptyMsg="Nenhum fornecedor encontrado"
        pagination={pagination}
        changePageSize={changePageSize}
        changePageNumber={changePageNumber}
      />
    </>
  );
};
