import React, { useEffect, useState } from 'react';
import {
  ClearOutlined,
  DeleteFilled,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Table
} from 'antd';

import { fasesLote } from 'models';
import { formatToBRL } from 'utils/utils';
import {
  decimalRegexWithComma,
  applyMaskDecimalWithComma,
  convertFormattedToNumber
} from 'utils/masks';
import { MorteLeitaoResponse } from 'api/getMortesByLoteId/useGetMortesByLoteId';

import { formatToISO } from 'utils/utils';
import PigDatePicker from 'components/PigDatePicker';
import { onErrorNotification } from 'api/models';
import SelectMotivoDescarte from 'components/SelectMotivoDescarte';
import { useCadastrarMorteLeitao } from 'api/cadastrarMorteLeitao/useCadastrarMorteLeitao';
import { useExcluirOcorrencia } from 'api/excluirOcorrencia/excluirOcorrencia';

interface IProps {
  lote: {
    id: string;
    saldo: number;
  };
  mortes: MorteLeitaoResponse[];
  onSubmitCallback: () => void;
}

const Morte: React.FC<IProps> = ({ lote, mortes, onSubmitCallback }) => {
  const [form] = Form.useForm();
  const isDisabled = lote?.saldo === 0 || false;
  const [openModal, setOpenModal] = useState(false);
  const { mutate: excluirOcorrencia, isLoading: isLoadingExclusao } =
    useExcluirOcorrencia({
      onSuccess: (response) => {
        notification.success({ message: 'Morte excluída com sucesso!' });
        onSubmitCallback();
        form.resetFields();
        setOpenModal(false);
      },
      onError: (error) => {
        onErrorNotification(error);
      }
    });
  const { mutate: cadastrarMorteLeitao, isLoading } = useCadastrarMorteLeitao({
    onSuccess: () => {
      onSubmitCallback();
      form.resetFields();
      notification.success({ message: 'Morte registrada com sucesso!' });
    },
    onError: (e) => {
      onErrorNotification(e);
    }
  });
  const columns = [
    {
      title: 'Data',
      render: (value: MorteLeitaoResponse) => formatToBRL(value.data)
    },
    {
      title: 'Qntd',
      render: (value: MorteLeitaoResponse) => value.qntd
    },
    {
      title: 'Peso Médio/Animal (kgs)',
      render: (value: MorteLeitaoResponse) =>
        applyMaskDecimalWithComma(value.pesoMedio, 2)
    },
    {
      title: 'Peso Total (kgs)',
      render: (value: MorteLeitaoResponse) =>
        applyMaskDecimalWithComma(value.pesoTotal, 2)
    },
    {
      title: 'Causa',
      render: (value: MorteLeitaoResponse) => value.motivo || '-'
    },
    {
      title: 'Fase',
      render: (value: MorteLeitaoResponse) =>
        fasesLote.find((causa) => causa.key === value.faseOcorencia)?.value ||
        '-'
    },
    ,
    {
      title: 'Excluir',
      render: function excluir(value: MorteLeitaoResponse) {
        return (
          <>
            <Button
              shape="circle"
              size="small"
              danger
              icon={<DeleteFilled />}
              onClick={() => setOpenModal(true)}
            />
            <Modal
              title="Excluir morte?"
              okType="danger"
              open={openModal}
              okText="Excluir"
              onOk={() =>
                excluirOcorrencia({ loteId: lote.id, ocorrenciaId: value.id })
              }
              onCancel={() => setOpenModal(false)}
              confirmLoading={isLoading || isLoadingExclusao}
            >
              <span>
                Ao excluir a morte, os animais mortos serão devolvidos ao saldo
                do lote.
              </span>
            </Modal>
          </>
        );
      }
    }
  ];
  const summary = (data: readonly MorteLeitaoResponse[]) => {
    let totalAnimais = 0;
    let pesoTotal = 0;

    data.forEach((value) => {
      totalAnimais += value.qntd || 0;
      pesoTotal += value.qntd * value.pesoMedio || 0;
    });

    const pesoMedio = pesoTotal / totalAnimais;

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>Resumo: </span>
          </Table.Summary.Cell>

          <Table.Summary.Cell index={1} colSpan={1} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {totalAnimais?.toFixed(0) || 0}
            </span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={4} align="left">
            <span style={{ fontWeight: 'bold' }}>
              {pesoMedio?.toFixed(2).replace('.', ',') || 0}
            </span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const calcularPesoTotalDescartado = (
    qntdAnimais: number,
    pesoMedioAnimal: number
  ) => {
    if (!qntdAnimais || !pesoMedioAnimal) return 0;
    return qntdAnimais * pesoMedioAnimal;
  };

  useEffect(() => {
    form.setFieldsValue({
      saldo: lote?.saldo || 0
    });
  }, [lote.saldo]);

  return (
    <>
      <Form
        layout="vertical"
        colon={false}
        form={form}
        wrapperCol={{ style: { width: '100%' } }}
        onFinish={(values) => {
          cadastrarMorteLeitao({
            loteId: lote.id,
            body: {
              data: formatToISO(values.dataMorte),
              motivo: values.causaMorte,
              observacao: values.observacao,
              qntdAnimais: values.qntdAnimais,
              pesoMedio: convertFormattedToNumber(values.pesoMedioAnimal)
            }
          });
        }}
        onValuesChange={(changedValues, values) => {
          if (changedValues.qntdAnimais || changedValues.pesoMedioAnimal) {
            const pesoDescartado = calcularPesoTotalDescartado(
              values.qntdAnimais,
              values.pesoMedioAnimal &&
                Number(values.pesoMedioAnimal.replace(',', '.'))
            );
            form.setFieldsValue({
              totalDescartado: applyMaskDecimalWithComma(pesoDescartado, 2)
            });
          }
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Saldo Hoje"
              name="saldo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.dataMorte !== next.dataMorte}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Data da Morte"
                  name="dataMorte"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    disabled={isDisabled}
                    startValue={getFieldValue('dataMorte')}
                    callback={(_, date) => {
                      form.setFieldsValue({ dataMorte: date });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Qntd Animais"
              name="qntdAnimais"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 1, message: 'Não deve ser menor que 1' },
                {
                  type: 'number',
                  max: lote.saldo,
                  message: 'Sem saldo suficiente'
                }
              ]}
            >
              <InputNumber disabled={isDisabled} style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso Médio/Animal"
              name="pesoMedioAnimal"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input suffix="kgs" disabled={isDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Peso Descartado"
              name="totalDescartado"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input disabled suffix="kgs" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Causa da Morte"
              name="causaMorte"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <SelectMotivoDescarte
                allowClear
                showSearch
                disabled={isDisabled}
                placeholder="Selecionar"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={16} xl={18} xxl={18}>
            <Form.Item
              label="Observação"
              name="observacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input.TextArea
                rows={1}
                showCount
                maxLength={250}
                style={{ width: '100%' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end" style={{ marginTop: '32px' }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              disabled={isLoading || isDisabled}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={isLoading}
              disabled={isDisabled}
              onClick={() => {
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider orientation="left" style={{ marginTop: '32px' }}>
        Histórico das Mortes
      </Divider>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma morte encontrada!"
          />
        )}
      >
        <Table
          bordered
          size="small"
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined spin />
          }}
          dataSource={mortes}
          pagination={{
            size: 'small',
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            showSizeChanger: true
          }}
          columns={columns}
          summary={summary}
        />
      </ConfigProvider>
    </>
  );
};

export default Morte;
