import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Table, notification } from 'antd';

import { CustomTable } from 'components';
import { applyMaskDecimalWithComma } from 'utils/masks';
import CustomRangePicker from 'components/CustomRangePicker';
import {
  firstAndLastDayOfMonth as currentMonth,
  formatToBRL,
  formatToISO
} from 'utils/utils';
import { PageRequest } from 'api/models';
import {
  FaseEncerradaResponse,
  useListarFaseLoteEncerrada
} from 'api/relatorios/lotes/listarFaseLoteEncerrada/useListarFaseLoteEncerrada';
import { usePDFFaseLoteEncerrada } from 'api/relatorios/lotes/getPDFFaseLoteEncerrada/useGetPDFFaseLoteEncerrada';

type Params = {
  fase: string;
};

type SearchParams = PageRequest & {
  numeroLote?: number;
  periodo?: [Date, Date];
};

const RelatorioFaseLoteEncerrada: React.FC<Params> = ({ fase }: Params) => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    numeroLote: null,
    periodo: [currentMonth().firstDay, currentMonth().lastDay]
  });
  const { data, isLoading } = useListarFaseLoteEncerrada({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      fase: fase,
      numeroLote: searchParams.numeroLote,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFFaseLoteEncerrada({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      fase: fase,
      numeroLote: searchParams.numeroLote,
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    },
    onSuccess: (data: Blob) => {
      JsFileDownload(data, `fase-encerrada.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            numeroLote: values.numeroLote,
            periodo: values.periodo
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item
              label="Número do Lote"
              name="numeroLote"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Período"
              name="periodo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório'
                },
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject();
                    if (!value[0] || !value[1]) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <CustomRangePicker
                initialValue={searchParams.periodo}
                callback={(startDate, endDate) => {
                  form.setFieldsValue({
                    periodo: [startDate, endDate]
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <CustomTable
        isLoading={isLoading}
        style={{ marginTop: '32px' }}
        data={data?.items || []}
        pagination={{
          page: searchParams.page,
          perPage: searchParams.perPage,
          totalElements: data?.total,
          totalPages: data?.totalPages
        }}
        changePageNumber={(page) => {
          setSearchParams((prev) => ({ ...prev, page: page - 1 }));
        }}
        changePageSize={(_, size) => {
          setSearchParams((prev) => ({ ...prev, page: 0, perPage: size }));
        }}
        columns={[
          {
            title: 'Data',
            render: (record: FaseEncerradaResponse) => formatToBRL(record.data)
          },
          {
            title: 'Nº Matriz',
            render: (record: FaseEncerradaResponse) =>
              record.codigoMatriz || '-'
          },
          {
            title: 'Nº Lote',
            dataIndex: 'numeroLote'
          },
          {
            title: 'Idade (dias)',
            dataIndex: 'idadeLote'
          },
          {
            title: 'Qntd',
            render: (record: FaseEncerradaResponse) => record.qntd
          },
          {
            title: 'Peso Médio',
            render: (record: FaseEncerradaResponse) =>
              applyMaskDecimalWithComma(record.pesoMedio, 2)
          },
          {
            title: 'GPD Médio',
            render: (record: FaseEncerradaResponse) =>
              applyMaskDecimalWithComma(record.gpd, 3)
          }
        ]}
        summary={(data: readonly FaseEncerradaResponse[]) => {
          let idadeMedia = 0;
          let totalLeitoes = 0;
          let gpdMedio = 0;
          let pesoTotal = 0;

          data.forEach((e) => {
            totalLeitoes += e.qntd;
            idadeMedia += e.idadeLote * e.qntd;
            gpdMedio += e.gpd * e.qntd;
            pesoTotal += e.pesoMedio * e.qntd;
          });

          gpdMedio = gpdMedio / totalLeitoes;
          idadeMedia = idadeMedia / totalLeitoes;
          const pesoMedio = pesoTotal / totalLeitoes;

          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3} align="center">
                  <span style={{ fontWeight: 'bold' }}>Total:</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1} align="left">
                  <span>{applyMaskDecimalWithComma(idadeMedia, 0)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={1} align="left">
                  <span>{totalLeitoes}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={1} align="left">
                  <span>{applyMaskDecimalWithComma(pesoMedio, 2)}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={1} align="left">
                  <span>{applyMaskDecimalWithComma(gpdMedio, 3)}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </>
  );
};

export default RelatorioFaseLoteEncerrada;
