import { useMutation } from 'react-query';

import api from 'services/api';

export type EditarMatrizRequest = {
  racaId: string;
  codigo: string;
  dataNascimento: string;
  dataEntradaPlantel: string;
  registroPBB: string;
  pesoEntradaPlantel: number;
  pesoHoje: number;
  granjaOrigem: string;
  apelido: string;
  pai: string;
  mae: string;
  partosPreSistema: number;
};

const editarMatriz = async (
  id: string,
  body: EditarMatrizRequest
): Promise<void> => {
  const response = await api.put(`matriz/${id}`, body);
  return response.data;
};

export type InputParams = {
  onSuccess: () => void;
  onError: (error: unknown) => void;
};

export const useEditarMatriz = ({ onSuccess, onError }: InputParams) => {
  return useMutation(
    (params: { id: string; body: EditarMatrizRequest }) =>
      editarMatriz(params.id, params.body),
    {
      onSuccess,
      onError
    }
  );
};
