import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';

import { UseQueryPDFInput } from 'api/relatorios/types';
import { ResultadoAnualRequest } from '../getResultadoAnual/useGetResultadoAnual';

export const getPDFResultadoGeral = async (
  params: ResultadoAnualRequest
): Promise<Blob> => {
  const response = await api.get<Blob>('relatorio/resultado-geral/pdf', {
    responseType: 'blob',
    params
  });
  return response.data;
};

export type InputParams = {
  params: ResultadoAnualRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: Blob;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Blob, unknown>>;
};

export const useGetPDFResultadoGeral = (
  params: UseQueryPDFInput<ResultadoAnualRequest>
): OutputParams => {
  return useQuery(
    ['get-resultado-geral-pdf', params],
    () => getPDFResultadoGeral(params.params),
    {
      enabled: false,
      onError: params.onError,
      onSuccess: params.onSuccess
    }
  );
};
