import { useState } from 'react';
import JsFileDownload from 'js-file-download';
import { Form, Table, Row, Col, Button, Input, notification } from 'antd';
import {
  FilePdfOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';

import {
  mapperTagTipoSuino,
  formatToBRL,
  fromDateArrayToRange
} from 'utils/utils';

import {
  ListarGestanteResponse,
  useGetPDFGestantes,
  useListGestantes
} from 'api/relatorios/matrizes';
import { PageRequest } from 'api/models';
import CustomRangePicker from 'components/CustomRangePicker';

type SearchParams = PageRequest & {
  codigo?: string;
  partoPrevisto?: [Date, Date];
  cioPrevisto?: [Date, Date];
};

const RelatorioMatrizesGestantes: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: 0,
    perPage: 10,
    codigo: null,
    partoPrevisto: [null, null],
    cioPrevisto: [null, null]
  });
  const { data, isLoading } = useListGestantes({
    queryParams: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      partoPrevisto: fromDateArrayToRange(searchParams?.partoPrevisto),
      cioPrevisto: fromDateArrayToRange(searchParams?.cioPrevisto)
    }
  });
  const { isLoading: isLoadingPDF, fetchData: fetchPDF } = useGetPDFGestantes({
    queryParams: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      partoPrevisto: fromDateArrayToRange(searchParams?.partoPrevisto),
      cioPrevisto: fromDateArrayToRange(searchParams?.cioPrevisto)
    },
    onSuccess: (data) => {
      JsFileDownload(data, `matrizes-gestantes.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });
  const columns = [
    {
      title: 'Nº Matriz',
      dataIndex: 'codigo'
    },
    {
      title: 'Idade (dias)',
      dataIndex: 'idade'
    },
    {
      title: 'Situação',
      render: (record: ListarGestanteResponse) =>
        mapperTagTipoSuino(record.maturidade)
    },
    {
      title: 'Partos',
      dataIndex: 'partos'
    },
    {
      title: 'Data Cobertura',
      render: (record: ListarGestanteResponse) =>
        formatToBRL(record.dataCobertura)
    },
    {
      title: 'Nº Reprodutor',
      render: (record: ListarGestanteResponse) => record?.pai?.codigo || '-'
    },
    {
      title: 'Gestação (dias)',
      dataIndex: 'diasGestante'
    },
    {
      title: 'Parto Previsto',
      render: (record: ListarGestanteResponse) =>
        record.partoPrevisto ? formatToBRL(record.partoPrevisto) : '-'
    },
    {
      title: 'Cio Previsto',
      render: (record: ListarGestanteResponse) =>
        record.cioPrevisto ? formatToBRL(record.cioPrevisto) : '-'
    }
  ];

  const isValidFields = (): boolean => {
    const cioPrevisto = form.getFieldValue('cioPrevisto');
    const partoPrevisto = form.getFieldValue('partoPrevisto');
    if (!cioPrevisto && !partoPrevisto) {
      form.setFields([
        {
          name: 'cioPrevisto',
          errors: ['Informe a data prevista do parto ou cio']
        },
        {
          name: 'partoPrevisto',
          errors: ['Informe a data prevista do parto ou cio']
        }
      ]);
      return false;
    }
    return true;
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        colon={false}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            partoPrevisto: values.partoPrevisto,
            cioPrevisto: values.cioPrevisto
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Matriz"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Parto Previsto"
              name="partoPrevisto"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({
                    partoPrevisto: [startDate, endDate]
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Repetição de Cio"
              name="cioPrevisto"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({
                    cioPrevisto: [startDate, endDate]
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  fetchPDF();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                if (isValidFields()) {
                  form.submit();
                }
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        bordered
        size="small"
        rowKey={'id'}
        style={{ marginTop: '32px' }}
        dataSource={data?.items || []}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined spin />
        }}
        pagination={{
          size: 'small',
          total: data?.total || 0,
          pageSize: searchParams.perPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          showSizeChanger: true,
          onShowSizeChange: (_, pageSize) => {
            setSearchParams((currentState) => ({
              ...currentState,
              page: 0,
              perPage: pageSize
            }));
          },
          onChange: (page) => {
            setSearchParams((currentState) => ({
              ...currentState,
              page: page - 1
            }));
          }
        }}
        columns={columns}
      />
    </>
  );
};

export default RelatorioMatrizesGestantes;
