import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';

export type CadastrarFornecedorRequest = {
  nome: string;
};

export type CadastrarFornecedorResponse = {
  id: string;
};

const cadastrarFornecedor = async (
  body: CadastrarFornecedorRequest
): Promise<CadastrarFornecedorResponse> => {
  const response = await api.post<CadastrarFornecedorResponse>(
    'fornecedor',
    body
  );
  return response.data;
};

export type InputParams = {
  onSuccess: (data: CadastrarFornecedorResponse) => void;
  onError: (error: unknown) => void;
};

export type OutputParams = {
  mutate: UseMutateFunction<
    CadastrarFornecedorResponse,
    unknown,
    CadastrarFornecedorRequest,
    unknown
  >;
  isLoading: boolean;
};

export const useCadastrarFornecedor = ({
  onSuccess,
  onError
}: InputParams): OutputParams => {
  const { mutate, isLoading } = useMutation(
    (body: CadastrarFornecedorRequest) => cadastrarFornecedor(body),
    {
      onSuccess,
      onError
    }
  );
  return { mutate, isLoading };
};
