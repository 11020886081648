import { Calendar, Card } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { calcularPigDay } from 'utils/utils';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

const CalendarioPIG: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateID('calendario-pig'));
    dispatch(updateTitle('Calendário PIG'));
  }, []);

  return (
    <Card>
      <Calendar
        dateCellRender={(date) => (
          <span style={{ fontWeight: 'bold' }}>
            {calcularPigDay(date.toISOString())}
          </span>
        )}
      />
    </Card>
  );
};

export default CalendarioPIG;
