import { PageResponse } from 'api/models';
import { ListarGestanteRequest, ListarGestanteResponse } from '../types';
import { BaseHttpParams, applyHttpRangeFormat, useHttp } from 'api/useHttp';
import { useMemo } from 'react';

export type R = PageResponse<ListarGestanteResponse>;

export type Q = {
  page?: number;
  perPage?: number;
  codigo?: string;
  cioPrevisto?: string;
  partoPrevisto?: string;
};

export type InputParams = BaseHttpParams<R> & {
  queryParams: ListarGestanteRequest;
};

export const useListGestantes = (params: InputParams) => {
  const queryParams = useMemo((): Q => {
    return {
      page: params.queryParams.page,
      perPage: params.queryParams.perPage,
      codigo: params.queryParams.codigo,
      cioPrevisto: applyHttpRangeFormat(params.queryParams.cioPrevisto),
      partoPrevisto: applyHttpRangeFormat(params.queryParams.partoPrevisto)
    };
  }, [JSON.stringify(params.queryParams)]);

  return useHttp<void, Q, R>({
    url: 'relatorio/matrizes/gestantes',
    method: 'GET',
    queryParams: queryParams,
    onSuccess: params.onSuccess,
    onError: params.onError,
    callOnMount: true
  });
};
