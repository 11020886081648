import { useQuery } from 'react-query';

import api from 'services/api';
import { AlvoProducaoResponse, Params } from './types';

export const useGetAlvosProducao = (params: Params) => {
  return useQuery({
    queryKey: ['get-alvos-producao'],
    queryFn: async () => {
      const response = await api.get<AlvoProducaoResponse[]>(
        '/relatorio/alvos-producao'
      );

      return response.data;
    },
    retry: 0,
    staleTime: 0,
    cacheTime: 0,
    initialData: undefined,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: params.onError,
    onSuccess: params.onSuccess
  });
};
