import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ClearOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select
} from 'antd';

import api from 'services/api';
import { PageResponse } from 'api/models';
import { ListarFornecedorResponse } from 'api/listarFornecedores/useListarFornecedores';

import { decimalRegexWithComma } from 'utils/masks';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import SelectRacas from 'components/SelectRacas';
import PigDatePicker from 'components/PigDatePicker';
import ModalReprodutores from 'pages/Cobertura/Cadastro/ModalReprodutores';

interface IEntrada {
  tipoOperacao: 'COMPRA' | 'COLETA';
  dataColeta: string;
  aproveitamentoFinanceiro: string;
  doses: number;
  fornecedorId?: string;
  racaId?: string;
  reprodutorId?: string;
  reprodutorCodigo?: string;
}

const Entrada: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fornecedores, setFornecedores] = useState<ListarFornecedorResponse[]>(
    []
  );
  const [searchReprodutor, setSearchReprodutor] = useState(false);

  const cadastrarEntrada = async (values: IEntrada) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await api.post('/estoque-semen/bisnaga', {
          ...values,
          aproveitamentoFinanceiro: values.aproveitamentoFinanceiro
            ? values.aproveitamentoFinanceiro.replace(',', '.')
            : null
        });
        form.resetFields();
        notification.success({
          message: `Operação registrada com sucesso!`
        });
      } catch (error) {
        notification.error({
          message: 'Ocorreu um erro ao registrar a operação!'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const buscarFornecedores = async () => {
    try {
      const { data } = await api.get<PageResponse<ListarFornecedorResponse>>(
        '/fornecedor',
        {
          params: {
            page: 0,
            perPage: 200,
            ativo: true
          }
        }
      );
      setFornecedores(data.items);
    } catch (error) {
      notification.error({
        message: 'Ocorreu um erro ao carregar os fornecedores!'
      });
    }
  };

  useEffect(() => {
    buscarFornecedores();
    dispatch(updateID('estoque-semen-entrada'));
    dispatch(updateTitle('Estoque Sêmen - Compra/Coleta'));
  }, []);

  return (
    <Card>
      <ModalReprodutores
        visible={searchReprodutor}
        onOK={(reprodutor) => {
          form.setFieldsValue({
            reprodutorId: reprodutor.id,
            reprodutorCodigo: reprodutor.codigo
          });
          setSearchReprodutor(false);
        }}
        onCancel={() => {
          setSearchReprodutor(false);
        }}
      />

      <Form
        layout="vertical"
        form={form}
        colon={false}
        wrapperCol={{ style: { width: '100%' } }}
        onValuesChange={(changedValues) => {
          if (changedValues.tipoOperacao) {
            form.setFieldsValue({
              fornecedorId: undefined,
              racaId: undefined,
              reprodutorId: undefined,
              reprodutorCodigo: undefined
            });
          }
        }}
        onFinish={(values) => {
          cadastrarEntrada(values);
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Tipo da Operação"
              name="tipoOperacao"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select placeholder="Selecionar" allowClear>
                <Select.Option key="COMPRA" value="COMPRA">
                  Compra
                </Select.Option>
                <Select.Option key="COLETA" value="COLETA">
                  Coleta
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.tipoOperacao !== next.tipoOperacao
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('tipoOperacao') === 'COLETA' && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Reprodutor"
                    name="reprodutorCodigo"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <Input.Search
                      disabled
                      placeholder="Buscar reprodutor"
                      enterButton={
                        <Button type="primary" icon={<SearchOutlined />} />
                      }
                      onSearch={() => {
                        setSearchReprodutor(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>

          <Form.Item noStyle name="reprodutorId" hidden>
            <Input type="hidden" disabled />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.tipoOperacao !== next.tipoOperacao
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('tipoOperacao') === 'COMPRA' && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Fornecedor"
                    name="fornecedorId"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <Select placeholder="Selecionar" showSearch>
                      {fornecedores.map((fornecedor) => (
                        <Select.Option
                          key={fornecedor.id}
                          value={fornecedor.id}
                        >
                          {fornecedor.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.tipoOperacao !== next.tipoOperacao
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('tipoOperacao') === 'COMPRA' && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label="Raça"
                    name="racaId"
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <SelectRacas
                      allowClear
                      showSearch
                      placeholder="Selecionar"
                    />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.dataColeta !== next.dataColeta}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="dataColeta"
                  label="Data da Operação"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <PigDatePicker
                    startValue={getFieldValue('dataColeta')}
                    callback={(_, date) => {
                      form.setFieldsValue({ dataColeta: date });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Quantidade de Doses"
              name="doses"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'number', min: 1, message: 'Deve ser no mínimo 1' }
              ]}
            >
              <InputNumber step={1} min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Custo Total"
              name="aproveitamentoFinanceiro"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              rules={[
                {
                  pattern: decimalRegexWithComma,
                  message: 'Deve estar no formato 0,00'
                }
              ]}
            >
              <Input prefix="R$" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end" style={{ marginTop: '16px' }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              htmlType="button"
              icon={<ClearOutlined />}
              disabled={isLoading}
              onClick={() => {
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SendOutlined />}
              loading={isLoading}
              onClick={() => {
                form.submit();
              }}
            >
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Entrada;
