import QueryString from 'qs';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import api from 'services/api';
import { useDidMount } from 'hooks';
import { RangeRequest, onErrorNotification } from './models';

export type BaseHttpParams<R> = {
  onSuccess?: (data: R) => void;
  onError?: (error: any) => void;
};

export type HttpMethods = 'POST' | 'PUT' | 'GET' | 'PATCH' | 'DELETE';

export type UseHttpRequestParams<B, Q> = {
  url: string;
  method: HttpMethods;
  body?: B;
  queryParams?: Q;
  responseType?: 'json' | 'blob';
};

export type UseHttpParams<B, Q, R> = BaseHttpParams<R> &
  UseHttpRequestParams<B, Q> & {
    callOnMount?: boolean;
    enableErrorNotification?: boolean;
  };

export const useHttp = <B, Q, R>({
  url,
  method,
  body,
  queryParams,
  callOnMount = false,
  responseType = 'json',
  enableErrorNotification = true,
  onSuccess,
  onError
}: UseHttpParams<B, Q, R>) => {
  const isMounted = useDidMount();
  const [data, setData] = useState<R>(null);
  const [errors, setErrors] = useState(null);
  const [reload, setReload] = useState(callOnMount ? 1 : 0);
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(undefined);

  const refetch = () => setReload((prev) => prev + 1);

  const fetchData = async (params: { body?: B; queryParams?: Q } = {}) => {
    if (!isLoading) {
      try {
        setIsLoading(true);

        const ctrl = new AbortController();
        setController(ctrl);

        const response = await api(url, {
          method: method,
          data: params.body,
          params: params.queryParams,
          signal: ctrl.signal,
          responseType: responseType,
          paramsSerializer: (params) => {
            return QueryString.stringify(params, { arrayFormat: 'repeat' });
          }
        });

        setData(response.data);
        if (onSuccess) onSuccess(response.data);
      } catch (e) {
        setErrors(e);
        if (onError) onError(e);

        if (enableErrorNotification && e instanceof AxiosError) {
          onErrorNotification(e);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (reload > 0 || isMounted) fetchData({ body, queryParams });

    return () => controller && controller.abort();
  }, [body, queryParams, reload]);

  return { isLoading, data, errors, fetchData, refetch };
};

export const applyHttpRangeFormat = (range: RangeRequest<any>) => {
  if (!range) return null;
  if (!range.inicio || !range.fim) return null;

  return range.inicio + '/' + range.fim;
};
