import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateID, updateTitle } from 'store/modules/currentPage/actions';
import AlvosProducao from './AlvosProducao';
import ResultadoGeral from './ResultadoGeral';
import { EstoqueGranja } from './EstoqueGranja';

const RelatoriosAlvos: React.FC = () => {
  const dispatch = useDispatch();
  const collapseStyle = { marginTop: '16px' };

  useEffect(() => {
    dispatch(updateID('relatorios-alvos'));
    dispatch(updateTitle('Relatórios Gerais'));
  }, []);

  return (
    <div style={{ marginBottom: '64px' }}>
      <Collapse>
        <Collapse.Panel key="estoque-granja" header="Estoque Granja">
          <EstoqueGranja />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="resultado-geral" header="Resultado Geral">
          <ResultadoGeral />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={collapseStyle}>
        <Collapse.Panel key="alvos-producao" header="Alvos de Produção">
          <AlvosProducao />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
export default RelatoriosAlvos;
