import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type ListarLeitoaRequest = PageRequest & {
  codigo?: string;
  cioPrevisto?: RangeRequest<string>;
  partoPrevisto?: RangeRequest<string>;
};

export type ListarLeitoaResponse = {
  id: string;
  codigo: string;
  idade: number;
  idadePrimeiraCobertura: number;
  primeiraCobertura: string;
  diasGestante: number;
  partoPrevisto: string;
  cioPrevisto: string;
  entradaPlanel: string;
  tempoPlantel: number;
};

export const listarLeitoas = async (
  params: ListarLeitoaRequest
): Promise<PageResponse<ListarLeitoaResponse>> => {
  const response = await api.get<PageResponse<ListarLeitoaResponse>>(
    'relatorio/matrizes/leitoas',
    {
      params: {
        ...params,
        cioPrevisto: applyHttpRangeFormat(params.cioPrevisto),
        partoPrevisto: applyHttpRangeFormat(params.partoPrevisto)
      }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarLeitoaRequest;
  onError?: (error: unknown) => void;
};

export type OutputParams = {
  data: PageResponse<ListarLeitoaResponse>;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<
    QueryObserverResult<PageResponse<ListarLeitoaResponse>, unknown>
  >;
};

export const useListarLeitoas = (input: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-leitoas', input.params],
    () => listarLeitoas(input.params),
    {
      onError: input.onError
    }
  );
  return { data, isLoading, refetch };
};
