import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';

import { RangeRequest } from 'api/models';
import { applyHttpRangeFormat } from 'api/useHttp';

export type ListarProducaoRacaoRequest = {
  periodo?: RangeRequest<string>;
};

export type ListarProducaoRacaoResponse = {
  id: string;
  tipoMovimentacao: string;
  dia: string;
  preEmKg: number;
  inicial1EmKg: number;
  inicial2EmKg: number;
  crescimentoEmKg: number;
  terminacaoEmKg: number;
  reposicaoEmKg: number;
  gestacaoEmKg: number;
  preLactacaoEmKg: number;
  lactacaoEmKg: number;
  totalEmKg: number;
  totalCevadoEmKg: number;
};

export const listarProducaoRacao = async (
  params: ListarProducaoRacaoRequest
): Promise<ListarProducaoRacaoResponse[]> => {
  const response = await api.get<ListarProducaoRacaoResponse[]>(
    'racao/por-dia',
    {
      params: { ...params, periodo: applyHttpRangeFormat(params.periodo) }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarProducaoRacaoRequest;
};

export type OutputParams = {
  isLoading: boolean;
  data: ListarProducaoRacaoResponse[];
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<ListarProducaoRacaoResponse[], unknown>>;
};

export const useListarProducaoRacao = (input: InputParams): OutputParams => {
  const { data, isLoading, refetch } = useQuery(
    ['listar-racao-por-dia', input.params],
    () => listarProducaoRacao(input.params)
  );
  return { data, isLoading, refetch };
};
