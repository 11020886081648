import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from 'react-query';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';

import GlobalStyle from './styles/global';
import { defaultTheme } from './styles/defaultTheme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
      initialData: undefined,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  }
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={defaultTheme}>
            <ConfigProvider locale={ptBR}>
              <Router history={history}>
                <GlobalStyle />
                <Routes />
              </Router>
            </ConfigProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
