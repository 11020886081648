import { authActionsType } from './actionsType';

const INITIAL_STATE = {
  token: null,
  logged: false,
  loading: false
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authActionsType.SIGN_IN_REQUEST:
      return { ...state, loading: true };

    case authActionsType.SIGN_IN_SUCCESS:
      return { token: action.payload.token, loading: false, logged: true };

    case authActionsType.SIGN_FAILURE:
      return { ...state, loading: false };

    case authActionsType.SIGN_OUT:
      return { ...state, token: null, logged: false };

    default:
      return state;
  }
}
