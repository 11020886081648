import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Card,
  ConfigProvider,
  Empty,
  List,
  notification
} from 'antd';
import { ArrowUpOutlined, LoadingOutlined } from '@ant-design/icons';

import api from 'services/api';
import { PageResponse } from 'api/models';
import { ListBisnagaSemenResponse, IPaginacao } from 'models';
import { updateID, updateTitle } from 'store/modules/currentPage/actions';

import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import { formatToBRL } from 'utils/utils';

const Estoques: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [estoques, setEstoques] = useState<ListBisnagaSemenResponse[]>([]);
  const [paginacao, setPaginacao] = useState<IPaginacao>({
    page: 0,
    totalPages: 0,
    totalItems: 0,
    perPage: 30
  });

  const buscarEstoquesSemen = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const { data } = await api.get<PageResponse<ListBisnagaSemenResponse>>(
          '/estoque-semen',
          {
            params: {
              page: paginacao.page,
              perPage: paginacao.perPage,
              dosesMin: 1
            }
          }
        );
        setPaginacao((prev) => ({
          ...prev,
          totalItems: data.total
        }));
        setEstoques((prev) => [...prev, ...data.items]);
      } catch (error) {
        setEstoques([]);
        notification.error({
          message: 'Ocorreu um erro ao buscar os estoques'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    buscarEstoquesSemen();
  }, [paginacao.page]);

  useEffect(() => {
    dispatch(updateID('estoque-semen-lista'));
    dispatch(updateTitle('Estoque Sêmen'));
  }, []);

  return (
    <>
      <BackTop
        target={() =>
          document.getElementById('scrollable-content-div') || window
        }
      >
        <Button
          shape="round"
          type="primary"
          htmlType="button"
          icon={<ArrowUpOutlined />}
        >
          Voltar
        </Button>
      </BackTop>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={false}
            description={
              isLoading ? <LoadingOutlined /> : 'Nenhum estoque encontrado!'
            }
          />
        )}
      >
        <InfiniteScrollWrapper
          next={() => {
            setPaginacao((prev) => ({
              ...prev,
              page: prev.page + 1
            }));
          }}
          dataLength={estoques.length}
          hasMore={estoques.length < paginacao.totalItems}
          scrollableTarget="scrollable-content-div"
          style={{ overflow: 'hidden' }}
        >
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 3,
              xxl: 4
            }}
            dataSource={estoques}
            renderItem={(estoque) => (
              <List.Item key={`${estoque.id}-item`}>
                <Card
                  key={`${estoque.id}-card`}
                  style={{
                    height: '120px',
                    backgroundColor: estoque.isVencido ? '#858585' : '#3C3C3C',
                    color: '#fff',
                    borderRadius: '8px',
                    padding: '0px',
                    cursor: 'pointer'
                  }}
                  bodyStyle={{
                    padding: '16px',
                    height: '100%'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '50%',
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start'
                    }}
                  >
                    {`${estoque.doador.codigoDoador} - ${estoque.doador.racaDoador.nome}`}
                  </div>

                  <div
                    style={{
                      width: '100%',
                      height: '50%',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>
                      {`${formatToBRL(estoque.dataColeta)}`}
                      {estoque.isVencido ? ' - Vencido' : ''}
                    </span>
                    <span>{estoque.saldo + ' dose(s) '}</span>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </InfiniteScrollWrapper>
      </ConfigProvider>
    </>
  );
};

export default Estoques;
