import api from 'services/api';
import { InputParams } from 'api/models';
import { UseMutationResult, useMutation } from 'react-query';

const excluirOcorrencia = async (
  loteId: string,
  ocorrenciaId: string
): Promise<void> => {
  await api.delete<void>(`lote/${loteId}/ocorrencias/${ocorrenciaId}`);
};

type Params = { loteId: string; ocorrenciaId: string };

export const useExcluirOcorrencia = ({
  onSuccess,
  onError
}: InputParams<void>): UseMutationResult<void, unknown, Params, unknown> => {
  return useMutation(
    (params: Params) => excluirOcorrencia(params.loteId, params.ocorrenciaId),
    {
      onSuccess,
      onError
    }
  );
};
