import api from 'services/api';
import { UseMutateFunction, useMutation } from 'react-query';

import { InputParams } from 'api/models';

export type CadastrarMorteLeitaoRequest = {
  data: string;
  qntdAnimais: number;
  pesoMedio: number;
  motivo: string;
  observacao: string;
};

export type CadastrarMorteLeitaoResponse = {
  id: string;
};

const cadastrarMorteLeitao = async (
  loteId: string,
  body: CadastrarMorteLeitaoRequest
): Promise<CadastrarMorteLeitaoResponse> => {
  const response = await api.post<CadastrarMorteLeitaoResponse>(
    `lote/${loteId}/descarte`,
    body
  );
  return response.data;
};

export type OutputParams = {
  mutate: UseMutateFunction<
    CadastrarMorteLeitaoResponse,
    unknown,
    { loteId: string; body: CadastrarMorteLeitaoRequest },
    unknown
  >;
  isLoading: boolean;
};

export const useCadastrarMorteLeitao = ({
  onSuccess,
  onError
}: InputParams<CadastrarMorteLeitaoResponse>): OutputParams => {
  return useMutation(
    (body: { loteId: string; body: CadastrarMorteLeitaoRequest }) =>
      cadastrarMorteLeitao(body.loteId, body.body),
    {
      onSuccess,
      onError
    }
  );
};
