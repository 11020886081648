/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteScrollWrapper = ({ children, ...props }) => {
  return <InfiniteScroll {...props}>{children}</InfiniteScroll>;
};

export default InfiniteScrollWrapper;

InfiniteScrollWrapper.propTypes = {
  children: PropTypes.element.isRequired
};
