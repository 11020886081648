import { racaActionsType } from './actionsType';

export function buscarTodasRacas() {
  return { type: racaActionsType.LOAD };
}

export function atualizarRacasEmCache(racas) {
  return {
    type: racaActionsType.UPDATE,
    payload: { racas }
  };
}
