import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { firstAndLastDayOfMonth, formatToISO } from 'utils/utils';

import { ITabKey } from 'models';
import { updateID, updateTitle } from '../../store/modules/currentPage/actions';

import Historico from './Historico';
import { useListarProducaoRacao } from 'api/listarProducaoRacao/useListarProducaoRacao';

export type SearchParams = {
  periodo?: [Date, Date];
};

const ProducaoRacao: React.FC = () => {
  const dispatch = useDispatch();
  const currentMonth = firstAndLastDayOfMonth();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    periodo: [currentMonth.firstDay, currentMonth.lastDay]
  });
  const { data, isLoading, refetch } = useListarProducaoRacao({
    params: {
      periodo: {
        inicio: searchParams?.periodo[0]
          ? formatToISO(searchParams.periodo[0])
          : undefined,
        fim: searchParams?.periodo[1]
          ? formatToISO(searchParams.periodo[1])
          : undefined
      }
    }
  });
  const [activeTabKey, setActiveTabKey] = useState('historico');
  const tabs: ITabKey = {
    historico: (
      <Historico
        producao={data || []}
        isLoading={isLoading}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onSuccessCallback={() => {
          refetch();
        }}
      />
    )
  };

  useEffect(() => {
    dispatch(updateID('producao-racao'));
    dispatch(updateTitle('Ração'));
  }, []);

  return (
    <Card
      onTabChange={(key) => {
        setActiveTabKey(key);
      }}
      activeTabKey={activeTabKey}
      tabList={[{ key: 'historico', tab: 'Histórico' }]}
    >
      {tabs[activeTabKey]}
    </Card>
  );
};

export default ProducaoRacao;
