import React, { useState } from 'react';
import JsFileDownload from 'js-file-download';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Tag,
  Form,
  Input,
  Row,
  Col,
  Button,
  Popover,
  notification
} from 'antd';

import { formatToBRL, formatToISO, mapperTagStatusAnimal } from 'utils/utils';

import { PageRequest } from 'api/models';

import { CustomTable } from 'components';
import { defaultFormProps } from 'components/Antd';
import CustomRangePicker from 'components/CustomRangePicker';
import {
  ListarMarraResponse,
  useListarMarras
} from 'api/relatorios/matrizes/listarMarras/useListarMarras';
import { usePDFMarras } from 'api/relatorios/matrizes/getPDFMarras/useGetPDFMarras';

type Filtro = PageRequest & {
  codigo?: string;
  vacinaDose1?: [Date, Date];
  vacinaDose2?: [Date, Date];
};

const RelatorioMarras: React.FC = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<Filtro>({
    page: 0,
    perPage: 10,
    codigo: null,
    vacinaDose1: [null, null],
    vacinaDose2: [null, null]
  });
  const { data, isLoading } = useListarMarras({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      statusNotIn: ['DESCARTADO'],
      vacina1: {
        inicio: searchParams?.vacinaDose1[0]
          ? formatToISO(searchParams.vacinaDose1[0])
          : undefined,
        fim: searchParams?.vacinaDose1[1]
          ? formatToISO(searchParams.vacinaDose1[1])
          : undefined
      },
      vacina2: {
        inicio: searchParams?.vacinaDose2[0]
          ? formatToISO(searchParams.vacinaDose2[0])
          : undefined,
        fim: searchParams?.vacinaDose2[1]
          ? formatToISO(searchParams.vacinaDose2[1])
          : undefined
      }
    }
  });
  const { isLoading: isLoadingPDF, refetch } = usePDFMarras({
    params: {
      page: searchParams.page,
      perPage: searchParams.perPage,
      codigo: searchParams.codigo,
      statusNotIn: ['DESCARTADO'],
      vacina1: {
        inicio: searchParams?.vacinaDose1[0]
          ? formatToISO(searchParams.vacinaDose1[0])
          : undefined,
        fim: searchParams?.vacinaDose1[1]
          ? formatToISO(searchParams.vacinaDose1[1])
          : undefined
      },
      vacina2: {
        inicio: searchParams?.vacinaDose2[0]
          ? formatToISO(searchParams.vacinaDose2[0])
          : undefined,
        fim: searchParams?.vacinaDose2[1]
          ? formatToISO(searchParams.vacinaDose2[1])
          : undefined
      }
    },
    onSuccess: (data) => {
      JsFileDownload(data, `marrãs.pdf`);
    },
    onError: () => {
      notification.error({ message: 'Erro ao gerar PDF' });
    }
  });
  const vacinaPopover = (title: React.ReactNode) => {
    return (
      <Popover
        content={
          <div>
            <span>Parvovirose</span>
            <br />
            <span>Leptospirose</span>
            <br />
            <span>Erisipela</span>
            <br />
          </div>
        }
        title={
          <span>
            <b>Vacina</b>
          </span>
        }
      >
        {title}
      </Popover>
    );
  };
  const columns = [
    {
      title: 'Nº Matriz',
      dataIndex: 'codigo'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value: string) => mapperTagStatusAnimal(value)
    },
    {
      title: 'Idade (dias)',
      dataIndex: 'idade'
    },
    {
      title: vacinaPopover('Vacina 1ª Dose'),
      render: (record: ListarMarraResponse) => formatToBRL(record.vacinaDose1)
    },
    {
      title: vacinaPopover('Vacina 2ª Dose'),
      render: (record: ListarMarraResponse) => formatToBRL(record.vacinaDose2)
    },
    {
      title: <span title="Dias não produtivos">DNP</span>,
      dataIndex: 'dnp'
    },
    {
      title: 'Raça',
      dataIndex: 'raca'
    },
    {
      title: 'Observação',
      render: (record: ListarMarraResponse) => columnObservation(record.idade)
    }
  ];

  const columnObservation = (idade: number) => {
    if (idade > 210) return <Tag color={'green'}>Apta p/Cobertura</Tag>;
    if ((155 <= idade && idade <= 165) || (175 <= idade && idade <= 185))
      return <Tag color={'yellow'}>Aplicar Vacina</Tag>;
    return '';
  };

  return (
    <>
      <Form
        form={form}
        {...defaultFormProps}
        onFinish={(values) => {
          setSearchParams({
            page: 0,
            perPage: 10,
            codigo: values.codigo,
            vacinaDose1: values.vacinaDose1,
            vacinaDose2: values.vacinaDose2
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Nº Marrã"
              name="codigo"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Vacina 1ª Dose"
              name="vacinaDose1"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ vacinaDose1: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Form.Item
              label="Vacina 2ª Dose"
              name="vacinaDose2"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <CustomRangePicker
                callback={(startDate, endDate) => {
                  form.setFieldsValue({ vacinaDose2: [startDate, endDate] });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="end">
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              danger
              type="primary"
              htmlType="button"
              icon={<FilePdfOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.validateFields().then(() => {
                  refetch();
                });
              }}
            >
              Relatório
            </Button>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingPDF}
              onClick={() => {
                form.submit();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      <CustomTable
        data={data?.items || []}
        isLoading={isLoading}
        style={{ marginTop: '32px' }}
        emptyMsg="Nenhuma marrã encontrada"
        pagination={{
          page: searchParams.page,
          perPage: searchParams.perPage,
          totalPages: data?.totalPages || 0,
          totalElements: data?.total || 0
        }}
        changePageSize={(_, size) => {
          setSearchParams((currentState) => ({
            ...currentState,
            page: 0,
            perPage: size
          }));
        }}
        changePageNumber={(page) => {
          setSearchParams((currentState) => ({
            ...currentState,
            page: page - 1
          }));
        }}
        columns={columns}
      />
    </>
  );
};

export default RelatorioMarras;
