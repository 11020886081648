import api from 'services/api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query';
import { applyHttpRangeFormat } from 'api/useHttp';
import { PageRequest, PageResponse, RangeRequest } from 'api/models';

export type ListarFaseLoteEncerradaRequest = PageRequest & {
  fase: string;
  periodo?: RangeRequest<string>;
  numeroLote?: number;
};

export type FaseEncerradaResponse = {
  id: string;
  data: string;
  codigoMatriz?: string;
  numeroLote: number;
  idadeLote: number;
  qntd: number;
  pesoMedio: number;
  gpd: number;
};

export const listarFaseLoteEncerrada = async (
  params: ListarFaseLoteEncerradaRequest
): Promise<PageResponse<FaseEncerradaResponse>> => {
  const response = await api.get<PageResponse<FaseEncerradaResponse>>(
    'relatorio/lote/fase-encerrada',
    {
      params: {
        ...params,
        periodo: applyHttpRangeFormat(params.periodo)
      }
    }
  );
  return response.data;
};

export type InputParams = {
  params: ListarFaseLoteEncerradaRequest;
  onError?: (error: unknown) => void;
};

export const useListarFaseLoteEncerrada = (input: InputParams) => {
  return useQuery(
    ['listar-fase-lote-encerrada', input.params],
    () => listarFaseLoteEncerrada(input.params),
    {
      onError: input.onError
    }
  );
};
